import { Box } from "@mui/material";
import AppointmentBookedSuccess from "components/Redirects/AppointmentBookedSuccess";

export default function BookAppointmentSuccessPage() {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        bgcolor: "#fafafa",
        display: "grid",
        placeItems: "center",
      }}
    >
      <AppointmentBookedSuccess />
    </Box>
  );
}
