import { useOktaAuth } from "@okta/okta-react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "Styles/Register.css";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Button, Box, CircularProgress } from "@mui/material";
import { toastr } from "react-redux-toastr";

import { setUserType } from "actions/auth";
import HomeLayout from "components/Layouts/HomeLayout";
import Footer from "components/Layouts/Footer";
import appConfig from "appConfig";
import Loading from "components/UI/Loading";
export const SetUserPage = (props) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [btnClicked, setBtnClicked] = useState("");

  const welcomeToast = () => {
    toastr.success(`Welcome ${userInfo?.given_name}`);
  };
  const PatientDashboard = () => {
    dispatch(setUserType("Patient", authState?.accessToken?.accessToken)).then(
      (res) => {
        navigate("/patient/dashboard");
        welcomeToast();
      }
    );
  };
  const PractitionerDashboard = () => {
    dispatch(
      setUserType("Practitioner", authState?.accessToken?.accessToken)
    ).then((res) => {
      navigate("/doctor/profile/edit");
      welcomeToast();
    });
  };

  useEffect(() => {
    oktaAuth
      .getUser()
      .then((info) => {
        setUserInfo(info);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [authState, oktaAuth]);

  if (!authState) {
    return <Loading />;
  }

  return (
    <div>
      <HomeLayout />
      <div>
        {authState.isAuthenticated && !userInfo && (
          <div>Loading user information...</div>
        )}

        {authState.isAuthenticated && userInfo && (
          <div>
            <Container fluid className="p-0">
              <div>
                <Container fluid className="p-0">
                  <Row className="g-0 ">
                    <Box
                      component={Col}
                      xs={6}
                      sx={{
                        backgroundColor: (theme) => theme.palette.primary.main,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                      }}
                    >
                      <div className="bicon">
                        <div>
                          <img
                            src={appConfig.setuserpageicon}
                            alt="DolphinIcon"
                          />
                        </div>
                      </div>
                    </Box>
                    <Col
                      xs={6}
                      className="center "
                      style={{ display: "flex", "flex-direction": "column" }}
                    >
                      <Card className="about22card ">
                        <CardBody className="about2">
                          <img
                            className="logoicon"
                            src={appConfig.icon}
                            alt="Logo"
                          />

                          <Row className="about2row">
                            <div className="about2h3">
                              <h3>Select User Type</h3>
                            </div>
                          </Row>
                          <Row className="about2btnrow mt-10">
                            <div className="d-grid about2btn mb ">
                              <Button
                                variant="contained"
                                disabled={Boolean(btnClicked)}
                                onClick={() => {
                                  setBtnClicked("Patient");
                                  PatientDashboard();
                                }}
                              >
                                {btnClicked === "Patient" ? (
                                  <CircularProgress size={"1.5rem"} />
                                ) : (
                                  "Continue as a Patient"
                                )}
                              </Button>
                            </div>
                          </Row>
                          <Row className="about2btnrow">
                            <div className="d-grid about2btn">
                              <Button
                                variant="contained"
                                disabled={Boolean(btnClicked)}
                                onClick={() => {
                                  setBtnClicked("Doctor");
                                  PractitionerDashboard();
                                }}
                              >
                                {btnClicked === "Doctor" ? (
                                  <CircularProgress size={"1.5rem"} />
                                ) : (
                                  "Continue as a Doctor"
                                )}
                              </Button>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Container>
            <Footer />
          </div>
        )}
      </div>
    </div>
  );
};
export default SetUserPage;
