import authReducer from "reducers/auth";
import sampleReducer from "reducers/sampleReducer";
import medicalRecordsReducer from "reducers/medicalRecords";
import { reducer as toastrReducer } from "react-redux-toastr";
import practitionerReducer from "reducers/practitionerRecords";
import patientReducer from "reducers/patientRecords";

const DhophinHReducer = {
  auth: authReducer,
  sample: sampleReducer,
  medicalRecords: medicalRecordsReducer,
  toastr: toastrReducer,
  patientRecords: patientReducer,
  practitionerRecords: practitionerReducer,
};

export default DhophinHReducer;
