import { useState, useEffect } from "react";
import {
  CardBody,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
  Container,
} from "reactstrap";
import { toastr } from "react-redux-toastr";
import { Box } from "@mui/material";
import { GradCap, UserTie, Home } from "components/UI/Icons";
import "Styles/Register.css";
import classnames from "classnames";

import { useSelector, useDispatch } from "react-redux";
import {
  practitionerPostRecords,
  practitionerGetRecords,
  specialityRecords,
} from "actions/practitionerAction";
import { checkExistingUser } from "actions/auth";

import ProfileDetailForm from "Pages/Forms/PractitionerProfileForm";
import AcademicProfileForm from "Pages/Forms/AcademicProfileForm";
import ProfessionalDetailForm from "Pages/Forms/ProfessionalDetailForm";
import Loading from "components/UI/Loading";
import useAuth from "hooks/useAuth";

import { toBase64 } from "utilities";
import moment from "moment";

const PractitionerProfileForm = () => {
  const { accessToken } = useAuth();
  const practitionerData = useSelector(
    (state) => state?.practitionerRecords?.practitionerRecords
  );
  const [isLoading, setIsLoading] = useState(true);

  const [id, setId] = useState(null);

  const [selectedOption, setSelectedOption] = useState([]);
  const [specialitiesResponse, setSpecialitiesResponse] = useState([]);
  const [specialitiesListPayload, setSpecialitiesListPayload] = useState([]);
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [selectedSpecialization, setSelectedSpecialization] = useState([]);
  const [specializationResponse, setSpecializationResponse] = useState([]);
  const [specializationListPayload, setSpecializationListPayload] = useState(
    []
  );
  const dispatch = useDispatch();
  const [certificate, setCertificate] = useState();
  const [certificateList, setCertificateList] = useState([]);
  const [certificateResp, setCertificateResp] = useState([]);
  const [awardResp, setAwardResp] = useState([]);
  const [speciality, setSpeciality] = useState([{ name: "" }]);
  const [profileDetails, setProfileDetails] = useState();
  const [acadamicDetails, setAcademicDetails] = useState();
  const [awardName, setAwardName] = useState(null);
  const [postacadamicList, setPostAcadamicList] = useState([]);
  const [deletedAcademic, setDeletedAcadamicList] = useState([]);
  const [activeTab, setactiveTab] = useState(1);
  const [profilePic, setProfilePic] = useState(null);
  const [academic, setAcademic] = useState({
    college_name: "",
    course: "",
    year: "",
    _destroy: "false",
  });
  const [award, setAward] = useState();
  const [awardlist, setAwardList] = useState([]);
  const [academicList, setAcademicList] = useState([]);
  const [certificateName, setCertificateName] = useState(null);
  const [certificateType, setCertificateType] = useState(null);
  const [awardType, setAwardType] = useState(null);

  const [isNewSpecialityUpdate, setIsNewSpecialityUpdate] = useState(false);
  const [certificateUrl, setCertificateUrl] = useState(null);
  const [awardUrl, setAwardUrl] = useState(null);

  useEffect(() => {
    certificateSet();
  }, [certificateUrl]);

  useEffect(() => {
    awardsSet();
  }, [awardUrl]);

  useEffect(() => {
    if (accessToken) {
      dispatch(specialityRecords(accessToken)).then((res) =>
        setSpeciality(res.payload?.specialities, "...xgsghg")
      );

      dispatch(checkExistingUser(accessToken)).then((res) => {
        setId(res.payload.user.id);
      });
    }
  }, [accessToken]);

  useEffect(() => {
    if (id !== null) {
      if (accessToken) {
        dispatch(practitionerGetRecords(accessToken, id))
          .then((res) => {
            setAcademicList(res.payload?.practitioner?.acadamic_details);
            setCertificateList(res?.payload?.practitioner?.certifications);
            setCertificateResp(res?.payload?.practitioner?.certifications);
            setAwardResp(res?.payload?.practitioner?.award_publications);
            setAwardList(res?.payload?.practitioner?.award_publications);
            setProfilePic(res?.payload?.practitioner?.avatar_url);
            setSelectedOption(res?.payload?.practitioner?.user_specialities);
            setSpecialitiesResponse(
              res?.payload?.practitioner?.user_specialities
            );
            setSelectedSpecialization(
              res?.payload?.practitioner?.user_specializations
            );
            setSpecializationResponse(
              res?.payload?.practitioner?.user_specializations
            );
            res?.payload?.practitioner?.user_specialities.length === 0 &&
              setIsNewSpecialityUpdate(true);
          })
          .then(() => setIsLoading(false));
      }
    }
  }, [id]);

  const validURL = (str) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };

  const specialityOptionUIFormat = speciality
    .map(({ id, name, display }) => ({
      speciality_id: id,
      label: display,
      value: name,
      _destroy: "false",
    }))
    .filter(
      (item) =>
        !selectedOption
          ?.map((item) => item.speciality_id)
          .includes(item.speciality_id)
    );

  const handleAcademicDetails = (values) => {
    setAcademicDetails(values);
  };

  const onDeleteRecord = (id, arrayindex) => {
    if (id) {
      let choosedAcc = academicList.filter((acc) => acc.id === id);
      let filteredAcc = academicList.filter((acc) => acc.id !== id);
      choosedAcc[0] && Object.assign(choosedAcc[0], { _destroy: true });
      setDeletedAcadamicList([...deletedAcademic, ...choosedAcc]);
      setAcademicList(filteredAcc);
      setPostAcadamicList([...academicList, ...deletedAcademic]);
    } else {
      let filteredAcc = academicList.filter(
        (acc, index) => index !== arrayindex
      );
      setAcademicList(filteredAcc);
      setPostAcadamicList([...academicList, ...deletedAcademic]);
    }
  };

  const certificateClose = (id, arrayindex) => {
    if (id) {
      let filteredAcc = certificateList.filter((acc) => acc.id !== id);
      setCertificateList(filteredAcc);
    } else {
      let filteredAcc = certificateList.filter(
        (_, index) => index !== arrayindex
      );
      setCertificateList(filteredAcc);
    }
  };

  const removeAwards = (id, arrayindex) => {
    if (id) {
      let filteredAcc = awardlist.filter((acc) => acc.id !== id);
      setAwardList(filteredAcc);
    } else {
      let filteredAcc = awardlist.filter((_, index) => index !== arrayindex);
      setAwardList(filteredAcc);
    }
  };

  const certificateSet = () => {
    setCertificate({
      title: certificateName,
      type: certificateType,
      image: certificateUrl,
      _destroy: false,
    });
  };

  const awardsSet = () => {
    setAward({
      title: awardName,
      image: awardUrl,
      type: awardType,
      _destroy: false,
    });
  };

  const certificationAdd = (e) => {
    setCertificateName(e.currentTarget.files[0]?.name);
    setCertificateType(e.currentTarget.files[0].type);
    toBase64(e.currentTarget.files[0]).then((res) => {
      setCertificateUrl(res);
    });
  };

  const Awards = (e) => {
    if (Math.round(e.target?.files[0]?.size / 1024) < 5120) {
      setAwardName(e.currentTarget.files[0]?.name);
      setAwardType(e.currentTarget.files[0].type);
      toBase64(e.currentTarget.files[0]).then((res) => setAwardUrl(res));
    } else {
      toastr.error("File size should not exceed 5 MB");
      e.target.value = "";
    }
  };

  const limitErrorToast = () => {
    toastr.error("Photo size should not exceed 1 MB");
  };

  const setProfileImage = (e) => {
    let changeToBase64 = e.currentTarget.files[0];
    if (Math.round(e.target?.files[0]?.size / 1024) < 1024) {
      toBase64(changeToBase64).then((res) => setProfilePic(res));
      e.target.value = "";
    } else {
      e.target.value = "";
      limitErrorToast();
    }
  };

  const AddAcademic = () => {
    setAcademicList([...academicList, academic]);
    setPostAcadamicList(academicList);
    setAcademic({
      college_name: "",
      course: "",
      year: "",
      _destroy: "false",
    });
  };

  useEffect(() => {
    setPostAcadamicList([...academicList, ...deletedAcademic]);
  }, [academicList]);

  const AddAwards = () => {
    setAwardList((prev) => [...prev, award]);
  };

  const AddCertificate = () => {
    setCertificateList((prev) => [...prev, certificate]);
  };

  const handleTab = (tabNo) => {
    setactiveTab(tabNo);
  };

  const handleProfileData = (values) => {
    setProfileDetails(values);
  };

  const handleSpecializationsFormat = (list) => {
    return [...list, ...specializationListPayload].map((data) => ({
      id: data.id,
      specialization_id: data.specialization_id,
      _destroy: data._destroy,
    }));
  };

  const handleSpecialitiesFormat = (list) => {
    return [...list, ...specialitiesListPayload].map((data) => ({
      id: data.id,
      speciality_id: data.speciality_id,
      _destroy: data._destroy,
    }));
  };
  const certificatePayload = () => {
    const certificateIds = certificateResp.map((obj) => obj?.id);
    const listIds = certificateList.map((onj) => {
      if (onj?.id) {
        return onj?.id;
      }
    });
    let idsToRemove = certificateIds.filter(
      (val) => !listIds.find((arr1Obj) => arr1Obj === val)
    );

    let newPostCertificate = certificateList.filter((obj) => {
      if (!obj?.id) {
        return obj;
      }
    });
    let deletedOldCertificates = [];
    deletedOldCertificates = certificateResp.filter((obj) => {
      if (obj?.id) {
        if (idsToRemove.includes(obj.id)) {
          Object.assign(obj, { _destroy: true });
          return obj;
        }
      }
    });
    let postCertificate = [...newPostCertificate, ...deletedOldCertificates];
    return postCertificate;
  };
  const awardsPayload = () => {
    const awardsIds = awardResp.map((obj) => obj?.id);
    const listIds = awardlist.map((onj) => {
      if (onj?.id) {
        return onj?.id;
      }
    });
    let idsToRemove = awardsIds.filter(
      (val) => !listIds.find((arr1Obj) => arr1Obj === val)
    );

    let newPostAwards = awardlist.filter((obj) => {
      if (!obj?.id) {
        return obj;
      }
    });
    let deletedOldAwards = [];
    deletedOldAwards = awardResp.filter((obj) => {
      if (obj?.id) {
        if (idsToRemove.includes(obj.id)) {
          Object.assign(obj, { _destroy: true });
          return obj;
        }
      }
    });
    let postAwards = [...newPostAwards, ...deletedOldAwards];
    return postAwards;
  };
  const pratitionerProfileSubmit = (values) => {
    const isImageUrl = validURL(profilePic);
    let certiPayload = certificatePayload();
    let awardPayload = awardsPayload();

    const data = {
      practitioner: {
        mobile_number: profileDetails?.mobile_number,
        first_name: profileDetails?.first_name,
        middle_name: profileDetails?.middle_name,
        last_name: profileDetails?.last_name,
        preferred_language: "English",
        date_of_birth: moment(profileDetails?.date_of_birth).format(
          "YYYY-MM-DD"
        ),
        gender: profileDetails?.gender,
        avatar_img: profilePic,
        country: profileDetails?.country,
        street_name: profileDetails?.street_name,
        city: profileDetails?.city,
        state: profileDetails?.state,
        zipcode: profileDetails?.zipcode,
        user_specialities_attributes: handleSpecialitiesFormat(selectedOption),
        user_specializations_attributes: handleSpecializationsFormat(
          selectedSpecialization
        ),
        acadamic_details_attributes: postacadamicList,
        professional_detail_attributes: {
          nip_number: acadamicDetails?.nip_number,
          professional_bio: values?.professional_bio,
          website_link:
            values.website_link.length !== 0
              ? "https://" +
                values.website_link.replace(/(http:\/\/|https:\/\/)/g, "")
              : undefined,
          linkedin_link:
            values.linkedin_link.length !== 0
              ? "https://" +
                values.linkedin_link.replace(/(http:\/\/|https:\/\/)/g, "")
              : undefined,
          years_of_experience: yearsOfExperience,
        },
        certification_files: certiPayload,
        award_publication_files: awardPayload,
      },
    };

    if (isImageUrl) {
      delete data.practitioner.avatar_img;
    }
    if (accessToken) {
      setIsLoading(true);
      dispatch(practitionerPostRecords(accessToken, id, data))
        .then((res) => {
          if (res?.payload?.success) {
            toastr.success("Profile updated successfully");
            window.location.assign("/doctor/profile?refresh=true");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toastr.error("Error:", err);
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Container
          className="pt-30 pb-50"
          style={{ "padding-top": "40px", color: "#272727" }}
        >
          <CardBody>
            <div id="basic-pills-wizard" className="twitter-bs-wizard">
              <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified wizard-notselect">
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({ active: activeTab === 1 })}
                  >
                    <div
                      className="step-icon"
                      data-bs-placement="top"
                      title="Profile Details"
                    >
                      <Box
                        component={Home}
                        sx={{
                          "& path": {
                            fill: (theme) => theme.palette.primary.main,
                          },
                        }}
                      />
                    </div>
                  </NavLink>
                  Profile Details
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({ active: activeTab === 2 })}
                  >
                    <div
                      className="step-icon"
                      data-bs-placement="top"
                      title="Academic Document"
                    >
                      <Box
                        component={GradCap}
                        sx={{
                          "& path": {
                            fill: (theme) => theme.palette.primary.main,
                          },
                        }}
                      />
                    </div>
                  </NavLink>
                  Academic Details
                </NavItem>

                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({ active: activeTab === 3 })}
                  >
                    <div
                      className="step-icon"
                      data-bs-placement="top"
                      title="Professional Details"
                    >
                      <Box
                        component={UserTie}
                        sx={{
                          "& path": {
                            fill: (theme) => theme.palette.primary.main,
                          },
                        }}
                      />
                    </div>
                  </NavLink>
                  Professional Details
                </NavItem>
              </ul>

              <TabContent
                className="twitter-bs-wizard-tab-content border-line"
                activeTab={activeTab}
              >
                <TabPane tabId={1}>
                  <ProfileDetailForm
                    practitionerData={practitionerData}
                    handleTab={handleTab}
                    handleProfileData={handleProfileData}
                  />
                </TabPane>
                <TabPane tabId={2}>
                  <AcademicProfileForm
                    activeTab={activeTab}
                    practitionerData={practitionerData}
                    handleTab={handleTab}
                    AddAcademic={AddAcademic}
                    handleAcademicDetails={handleAcademicDetails}
                    certificationAdd={certificationAdd}
                    AddCertificate={AddCertificate}
                    certificateClose={certificateClose}
                    specialityOption={specialityOptionUIFormat}
                    selectedOption={selectedOption}
                    academicList={academicList}
                    setAcademic={setAcademic}
                    academic={academic}
                    onDeleteRecord={onDeleteRecord}
                    certificateList={certificateList}
                    isNewSpecialityUpdate={isNewSpecialityUpdate}
                    yearsOfExperience={yearsOfExperience}
                    setYearsOfExperience={setYearsOfExperience}
                    selectedSpecialization={selectedSpecialization}
                    setSelectedSpecialization={setSelectedSpecialization}
                    specializationListPayload={specializationListPayload}
                    setSpecializationListPayload={setSpecializationListPayload}
                    specializationResponse={specializationResponse}
                    setSpecializationResponse={setSpecializationResponse}
                    specialitiesResponse={specialitiesResponse}
                    setSpecialitiesResponse={setSpecialitiesResponse}
                    specialitiesListPayload={specialitiesListPayload}
                    setSpecialitiesListPayload={setSpecialitiesListPayload}
                    setSelectedOption={setSelectedOption}
                  />
                </TabPane>
                <TabPane tabId={3}>
                  <ProfessionalDetailForm
                    profileDetails={profileDetails}
                    activeTab={activeTab}
                    handleTab={handleTab}
                    profilePic={profilePic}
                    academicList={academicList}
                    pratitionerProfileSubmit={pratitionerProfileSubmit}
                    setProfileImage={setProfileImage}
                    Awards={Awards}
                    AddAwards={AddAwards}
                    awardlist={awardlist}
                    removeAwards={removeAwards}
                  />
                </TabPane>
              </TabContent>
            </div>
          </CardBody>
        </Container>
      )}
    </>
  );
};

export default PractitionerProfileForm;
