import { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import {
  useCancelAppointmentMutation,
  useGetAppointmentDetailsQuery,
} from "api/AppointmentsApi";
import { TickIcon } from "components/UI/Icons";
import useAuth from "hooks/useAuth";
import useExistingUser from "hooks/useExistingUser";
import { Navigate, useNavigate } from "react-router-dom";
import DefaultLoader from "components/UI/DefaultLoader";
import RescheduleCard from "components/Redirects/RescheduleSuccess";

export default function SlotBookedPage() {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();
  const navigate = useNavigate();

  const [appointmentDetails, setAppointmentDetails] = useState({});
  const [payNowClicked, setPayNowClicked] = useState(false);
  const [appointmentStatus, setAppointmentStatus] = useState("");

  useEffect(() => {
    setAppointmentDetails(
      JSON.parse(sessionStorage.getItem("appointmentDetails") || "{}")
    );
  }, []);

  const {
    data: appointmentDetailsData,
    isLoading,
    isError,
  } = useGetAppointmentDetailsQuery(
    {
      accessToken: JSON.parse(sessionStorage.getItem("okta-token-storage"))
        ?.accessToken?.accessToken,
      userType: "patients",
      userId: existingUser?.user?.id,
      appointmentId: appointmentDetails?.appointmentId,
    },
    {
      pollingInterval: 3000,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
      skip:
        !appointmentDetails?.appointmentId ||
        appointmentDetails?.rescheduled ||
        appointmentStatus === "unpaid" ||
        appointmentStatus === "scheduled",
    }
  );

  useEffect(() => {
    if (appointmentDetailsData) {
      setAppointmentStatus(appointmentDetailsData?.appointment?.status);
    }
  }, [appointmentDetailsData]);
  console.log({ appointmentStatus });

  const [cancelAppointment, { isLoading: isCancelAppointmentLoading }] =
    useCancelAppointmentMutation();

  const handleCancelAppointment = async () => {
    try {
      await cancelAppointment({
        accessToken,
        userType: existingUser?.user?.type?.toLowerCase() + "s",
        userId: existingUser?.user?.id,
        appointmentId: appointmentDetails?.appointmentId,
        reason: "",
      }).unwrap();
      navigate("/patient/dashboard");
    } catch (err) {
      console.log(err);
    }
  };

  return isError ? (
    <Navigate to={"/page_not_found"} replace={true} />
  ) : (
    <Box
      sx={{
        minHeight: "100vh",
        bgcolor: "#fafafa",
        display: "grid",
        placeItems: "center",
      }}
    >
      {appointmentDetails?.rescheduled ? (
        <RescheduleCard />
      ) : (
        <Box
          sx={{
            maxWidth: "42rem",
            width: "100%",
            display: "grid",
            placeItems: "center",
            bgcolor: "#fff",
            p: "1.75rem",
            borderRadius: "10px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
            minHeight: "23rem",
          }}
        >
          {isLoading ||
          !(
            appointmentStatus === "unpaid" || appointmentStatus === "scheduled"
          ) ? (
            <DefaultLoader height="100%" />
          ) : (
            <>
              <Box
                component={TickIcon}
                sx={{
                  "& path": { fill: (theme) => theme.palette.primary.main },
                  height: "3.75rem",
                  width: "3.75rem",
                }}
              />
              <Typography
                fontSize={20}
                fontWeight={700}
                sx={{ mb: "2rem", color: "primary.main" }}
              >
                Slot booked successfully!
              </Typography>
              <Typography
                mb={4}
                fontSize={14}
                textAlign={"center"}
                maxWidth={"60ch"}
              >
                Thanks for booking the slot for the appointment. Next step would
                be to complete the payment.
              </Typography>
              <Typography
                mb={4}
                fontSize={14}
                textAlign={"center"}
                maxWidth={"60ch"}
              >
                Use the below options to continue with the payment or cancel the
                appointment booking.
              </Typography>
              <Box>
                <Button
                  variant="outlined"
                  sx={{ width: "14rem" }}
                  onClick={handleCancelAppointment}
                  disabled={isCancelAppointmentLoading || payNowClicked}
                >
                  {isCancelAppointmentLoading ? (
                    <CircularProgress size={"1.5rem"} />
                  ) : (
                    "Cancel"
                  )}
                </Button>
                <Button
                  variant="contained"
                  sx={{ width: "14rem", ml: 2 }}
                  disabled={isCancelAppointmentLoading || payNowClicked}
                  onClick={() => {
                    setPayNowClicked(true);
                    const aEl = document.createElement("a");
                    aEl.href =
                      appointmentDetailsData?.appointment?.payment_link;
                    aEl.click();
                  }}
                >
                  {payNowClicked ? (
                    <CircularProgress size={"1.5rem"} />
                  ) : (
                    "Pay Now"
                  )}
                </Button>
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}
