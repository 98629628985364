import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toastr } from "react-redux-toastr";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const DoctorsApiSlice = createApi({
  reducerPath: "DoctorsApiSlices",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
  }),
  tagTypes: ["Bookmarks"],
  endpoints: (builder) => ({
    getDoctorDetails: builder.query({
      keepUnusedDataFor: 0.0000000003,
      query: ({ accessToken, practitionerId }) => ({
        url: `practitioners/${practitionerId}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getSpecialitiesList: builder.query({
      query: ({ accessToken }) => ({
        url: `specialities`,
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getPractitionersList: builder.query({
      query: ({
        accessToken,
        specialistId,
        search,
        page,
        perPage,
        gender,
        minExp,
        maxExp,
      }) => ({
        url: "practitioners",
        method: "GET",
        params: {
          speciality_ids: specialistId,
          search: search,
          page: page,
          per_page: perPage,
          gender,
          min_years_of_experience: minExp,
          max_years_of_experience: maxExp,
        },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getMedicalRecordsListUpAndDown: builder.query({
      query: ({
        accessToken,
        patientId,
        doctorId,
        fileName,
        page,
        perPage,
        appointmentId,
      }) => ({
        url: `patients/${patientId}/schedulers/medical_report_list`,
        method: "GET",
        params: {
          file_name: fileName,
          page: page,
          per_page: perPage,
          appointment_id: appointmentId,
          practitioner_id: doctorId,
        },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getBookmarkedDoctors: builder.query({
      providesTags: ["Bookmarks"],
      query: ({ accessToken, patientId, page, perPage, search }) => ({
        url: `patients/${patientId}/bookmarks`,
        method: "GET",
        params: {
          page,
          search,
          per_page: perPage,
        },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    setBookmarkDoctor: builder.mutation({
      invalidatesTags: ["Bookmarks"],
      query: ({ accessToken, patientId, practitionerId }) => ({
        url: `patients/${patientId}/bookmarks`,
        method: "POST",
        body: {
          bookmarks: {
            practitioner_id: practitionerId,
          },
        },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    removeBookmarkDoctor: builder.mutation({
      invalidatesTags: ["Bookmarks"],
      query: ({ accessToken, patientId, practitionerId }) => ({
        url: `patients/${patientId}/bookmarks`,
        method: "DELETE",
        params: {
          practitioner_id: practitionerId,
        },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const res = await queryFulfilled;
          if (res?.data?.success) {
            toastr.success(res?.data?.message);
          } else {
            toastr.error(res?.data?.message);
          }
        } catch (error) {
          console.error(error);
        }
      },
    }),
    patientIntakeForm: builder.query({
      query: ({
        accessToken,
        patientId,
        appointmentId,
        formType,
        doctorId,
      }) => ({
        url: `patients/${patientId}/intake_forms/intake_form_questions`,
        method: "GET",
        params: {
          appointment_id: appointmentId,
          form_type: formType,
          practitioner_id: doctorId,
        },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    createAppointment: builder.mutation({
      query: ({
        accessToken,
        patientId,
        practitionerId,
        shareReportsList,
        intakeFormAnswers,
      }) => ({
        url: `/patients/${patientId}/appointments/create`,
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: {
          appointments: {
            practitioner_id: practitionerId,
            agree_to_share_medical_reports: true,
            share_medical_reports_attributes: shareReportsList,
            intake_form_answers_attributes: intakeFormAnswers,
          },
        },
      }),
    }),
    getConsultedDoctorsList: builder.query({
      query: ({ accessToken, search, page, perPage, userId }) => ({
        url: `/patients/${userId}/consulted_doctors`,
        method: "GET",
        params: {
          page,
          per_page: perPage,
          search,
        },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
  }),
});

export const {
  useGetDoctorDetailsQuery,
  useGetSpecialitiesListQuery,
  useGetPractitionersListQuery,
  useGetMedicalRecordsListUpAndDownQuery,
  useGetBookmarkedDoctorsQuery,
  useSetBookmarkDoctorMutation,
  useRemoveBookmarkDoctorMutation,
  usePatientIntakeFormQuery,
  useCreateAppointmentMutation,
  useGetConsultedDoctorsListQuery,
} = DoctorsApiSlice;
