import { Container, Card, Row, Button, Col } from "reactstrap";
import "Styles/Register.css";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { checkExistingUser } from "actions/auth";
import appConfig from "appConfig";
import Loading from "components/UI/Loading";
import { customStyles } from "Styles/custumStyle";
import {
  getHealthcareProviderList,
  getselectedProviderId,
} from "../../actions/patientAction";
import { useNavigate } from "react-router";
import FHIR from "fhirclient";
import Select from "react-select";
import patientprovider from "../../assets/images/patientprovider.svg";

const PatientProvider = () => {
  let navigate = useNavigate();
  const [userName, setUserName] = useState(null);
  const [validate, setValidate] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [healthProviderList, setHealthProviderList] = useState([]);
  const [fhirHealthcareProviders, setFhirHealthcareProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState();
  const [providerLoader, setProviderLoader] = useState(false);
  const userInfo = useSelector((state) => state.auth.existingUser);

  const providerId = useSelector(
    (state) => state.patientRecords.selectedProviderId
  );
  const dispatch = useDispatch();

  const fetchrecord = async (p) => {
    providerId == null && setShowError(true);
    if (validate) {
      const selectedHealthcareOption = fhirHealthcareProviders.find(
        (item) => item.id === p
      );
      if (!selectedHealthcareOption) return;
      localStorage.setItem(
        "fhirAccessToken",
        selectedHealthcareOption.ehr_system.access_token_url
      );
      localStorage.setItem(
        "fhirClientId",
        selectedHealthcareOption.ehr_system.client_id
      );
      FHIR.oauth2.authorize({
        iss: selectedHealthcareOption.ehr_system.authorize_url,
        redirect_uri: process.env.REACT_APP_BASE_URL + "/patient/dashboard",
        client_id: selectedHealthcareOption.ehr_system.client_id,
        scope: selectedHealthcareOption.ehr_system.scope.join(", "),
        code_challenge: "test",
        completeInTarget: true,
      });
      setProviderLoader(true);
    }
  };

  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);

  const OnSelectProvider = (data) => {
    setSelectedProvider(data.value);
    dispatch(getselectedProviderId(data.value));
    localStorage.setItem("providerId", data.value);
    setValidate(true);
    setShowError(false);
  };
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (authState) {
      if (authState?.accessToken?.accessToken) {
        !userInfo.length > 0 &&
          dispatch(checkExistingUser(authState?.accessToken?.accessToken)).then(
            (res) => {
              setUserName(res.payload.user.full_name);
            }
          );
      }
    }
  }, [authState]);
  useEffect(() => {
    authState?.accessToken?.accessToken &&
      dispatch(
        getHealthcareProviderList(authState?.accessToken?.accessToken)
      ).then((res) => {
        setFhirHealthcareProviders(res.payload.fhir_healthcare_providers);
        const list = healthProvideroptionSet(
          res.payload.fhir_healthcare_providers
        );
        setHealthProviderList(list);
      });
  }, [authState]);

  const healthProvideroptionSet = (list) => {
    return list.map(({ id, organization_name }) => {
      return { value: id, label: organization_name };
    });
  };

  return (
    <div className="container-xxl">
      {userName && (
        <div>
          {!providerLoader ? (
            <Container fluid className="center patientcontainer container-xxl ">
              <Card className="patientcard">
                <div>
                  <img
                    src={appConfig.icon}
                    alt="DolphinIcon"
                    className="patientcardlogo"
                  />
                </div>

                <div className="patientcardwelcome">
                  <div className="camelCase">
                    {" "}
                    Hey {userName.toLowerCase()}!
                  </div>
                </div>
                <Container className="cont-1">
                  <Row className="square border square-b">
                    <Col lg={1} sm={2} className="col-test">
                      <img src={patientprovider} alt="info logo"></img>
                    </Col>
                    <Col lg={10} sm={10} className="col-test1">
                      You need your healthcare provider's Login Id and Password
                      to proceed.
                    </Col>
                  </Row>
                </Container>
                <Row className="patientCardDropDown">
                  <div>
                    {/* <div className="proceed">  */}

                    {/* </div> */}
                    <Select
                      inputId="aria-example-input"
                      placeholder="Choose Provider"
                      name="aria-live-color"
                      onMenuOpen={onMenuOpen}
                      onMenuClose={onMenuClose}
                      onChange={OnSelectProvider}
                      options={healthProviderList}
                      className="select-provider-dropdown"
                      styles={customStyles}
                    />
                  </div>
                  {showError && (
                    <p className="validationprovider ">Select your provider</p>
                  )}
                </Row>

                <Row>
                  <div>
                    <Button
                      type="button"
                      className="Fetchrecordbtn"
                      onClick={() => fetchrecord(selectedProvider)}
                    >
                      Fetch Medical Records
                    </Button>
                  </div>
                </Row>
                <Row className="mb-15">
                  <button
                    className="btn btn-secondary  ProfileBtn mr-bt"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>
                </Row>
              </Card>
            </Container>
          ) : (
            <Loading />
          )}
        </div>
      )}
    </div>
  );
};

export default PatientProvider;
