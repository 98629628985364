import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import SearchIcon from "assets/images/SearchIcon.svg";
import norecord from "assets/images/norecord.png";
import SadEmoji from "assets/images/emoji-sad.svg";
import {
  useCreateAppointmentMutation,
  useGetMedicalRecordsListUpAndDownQuery,
  useGetSpecialitiesListQuery,
} from "api/DoctorsApi";
import useAuth from "hooks/useAuth";
import useExistingUser from "hooks/useExistingUser";
import { useEffect, useState } from "react";
import DefaultLoader from "components/UI/DefaultLoader";
import UploadAndDownloadBtn from "components/UploadAndDownloadBtns";
import _ from "lodash";
import PromptPopup from "components/UI/PromptPopup";
import { NavigateNext } from "@mui/icons-material";
import { toastr } from "react-redux-toastr";
import FeatureComingSoon from "components/UI/FeatureComingSoon";
import { useDispatch, useSelector } from "react-redux";
import { getShareReports, setShareReports } from "store/shareReports";
import {
  getAnswers,
  getIsTAndCAgreed,
  setAnswers,
  setTAndCAgreed,
} from "store/intakeForm";
import CustomLink from "components/UI/CustomLink";
import CPagination from "components/UI/CPagination";
import { useAddMoreShareFilesMutation } from "../api/AppointmentsApi";
import { linkColor } from "../components/UI/BreadcrumbsList";
import { VerifiedIcon } from "components/UI/Icons";
import { setBookafyLink } from "store/bookafy";
import RefundAndCancellationsPopup from "components/UI/RefundAndCancellationsPopup";
import DoctorCard from "components/UI/DoctorCard";
import UnsavedDataPopup from "components/UI/UnsavedDataPopup";

const fileTypeExt = {
  "application/pdf": ".pdf",
  "images/png": ".png",
  "images/jpeg": ".jpeg",
};

export default function ShareRecordsWithDoctorPage() {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();
  const navigate = useNavigate();
  const { state } = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const RSshareReports = useSelector(getShareReports);
  const RSIFIsTAndCAgreed = useSelector(getIsTAndCAgreed);
  const RSIFanswers = useSelector(getAnswers);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [fileName, setFileName] = useState("");
  const [reportsList, setReportsList] = useState([]);
  const [selectedRecordsId, setSelectedRecordsId] = useState([]);
  const [selectedRecordsList, setSelectedRecordsList] = useState([]);
  const [showTandCPopup, setShowTandCPopup] = useState(false);

  const [showConsentPopup, setShowConsentPopup] = useState(false);
  const [showCancelConfirmationPopup, setShowCancelConfirmationPopup] =
    useState(false);

  const [showFeatureComingSoonModal, setShowFeatureComingSoonModal] =
    useState(false);

  const [addMoreShareFiles, { isLoading: isAddMoreShareFilesLoading }] =
    useAddMoreShareFilesMutation();

  const {
    data: medicalRecordsListData,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useGetMedicalRecordsListUpAndDownQuery(
    {
      accessToken,
      patientId: existingUser.user.id,
      page,
      perPage,
      fileName,
      appointmentId: params?.appointmentId,
      doctorId: params?.id,
    },
    { refetchOnReconnect: true, refetchOnMountOrArgChange: true }
  );

  const { data: specialitiesListData } = useGetSpecialitiesListQuery(
    { accessToken },
    { refetchOnReconnect: true, skip: params?.specialist === "about_doctor" }
  );

  const handleCloseTab = (evt) => {
    evt.preventDefault();
    const confirmMsg =
      "Are you sure you want to cancel the booking? You will lose all your data filled so far?";
    evt.returnValue = confirmMsg;
    return confirmMsg;
  };

  useEffect(() => {
    if (!params?.appointmentId) {
      window.addEventListener("beforeunload", handleCloseTab);
    }
    return () => {
      window.removeEventListener("beforeunload", handleCloseTab);
    };
  }, []);

  const handleInputChange = _.debounce((evt) => {
    setFileName(evt.target.value.trim());
  }, 300);

  useEffect(() => {
    if (
      !isError &&
      !isLoading &&
      !isFetching &&
      medicalRecordsListData?.success
    ) {
      setReportsList(
        medicalRecordsListData?.reports?.map((el) => {
          if (selectedRecordsId.includes(el.id)) {
            return {
              ...el,
              checked: true,
            };
          }
          return {
            ...el,
            checked: false,
          };
        })
      );
    }
  }, [
    isFetching,
    isLoading,
    medicalRecordsListData,
    isError,
    selectedRecordsId,
  ]);

  useEffect(() => {
    if (params?.id === RSshareReports.doctorId) {
      setSelectedRecordsId(RSshareReports.ids);
      setSelectedRecordsList(RSshareReports.list);
    }
  }, []);

  const clearState = () => {
    dispatch(setTAndCAgreed(false));
    dispatch(setAnswers({}));
    dispatch(setShareReports({ doctorId: null, list: [], ids: [] }));
  };

  const [createAppointment, { isLoading: isCreateAppointmentLoading }] =
    useCreateAppointmentMutation();

  const handleCreateAppointment = async () => {
    try {
      const response = await createAppointment({
        accessToken,
        patientId: existingUser?.user?.id,
        practitionerId: params?.id,
        shareReportsList: selectedRecordsList.map((el) => ({
          practitioner_id: params?.id,
          patient_id: existingUser?.user?.id,
          report_type: el?.patient_uploaded
            ? "UploadMedicalReport"
            : "DownloadMedicalReport",
          report_id: el?.id,
        })),
        intakeFormAnswers: Object.keys(RSIFanswers).map((el) => ({
          question_id: el,
          question_answer: RSIFanswers[el].trim(),
        })),
      }).unwrap();
      if (response?.success) {
        sessionStorage.setItem(
          "appointmentDetails",
          JSON.stringify({
            appointmentId: response?.appointment?.id,
            userId: existingUser?.user?.id,
            rescheduled: false,
          })
        );
        clearState();
        sessionStorage.removeItem("intakeForm");
        dispatch(setBookafyLink(response?.calendar_url));
        sessionStorage.setItem(
          "bookafyLink",
          JSON.stringify({ bookafyLink: response?.calendar_url })
        );
        navigate(
          `/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}/book_appointment`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const BreadcrumbsItemsViaSpecialities = [
    <CustomLink to={"/patient/dashboard"} onConfirmClick={clearState}>
      Dashboard
    </CustomLink>,
    <CustomLink
      to={"/patient/dashboard/all_specialities"}
      onConfirmClick={clearState}
    >
      All Specialities
    </CustomLink>,
    <CustomLink
      to={`/patient/dashboard/doctors_list/${params?.specialist}`}
      onConfirmClick={clearState}
    >
      {
        specialitiesListData?.specialities?.find(
          (el) => el?.id === parseInt(params?.specialist)
        )?.display
      }
    </CustomLink>,
    <CustomLink
      to={`/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}`}
      onConfirmClick={clearState}
    >
      About Doctor
    </CustomLink>,
    <Link
      to={`/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}/general_intake_form`}
    >
      <Typography fontSize={12} color={linkColor}>
        General Intake Form
      </Typography>
    </Link>,
    <CustomLink
      to={`/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}/share_records`}
      onConfirmClick={clearState}
    >
      Share Medical Reports
    </CustomLink>,
  ];

  const BreadcrumbsItemsViaPopup = [
    <CustomLink to={"/patient/dashboard"} onConfirmClick={clearState}>
      Dashboard
    </CustomLink>,
    <CustomLink
      to={"/patient/dashboard/all_specialities"}
      onConfirmClick={clearState}
    >
      All Specialities
    </CustomLink>,
    <CustomLink
      to={`/patient/dashboard/doctors_list/${params?.specialist}`}
      onConfirmClick={clearState}
    >
      {
        specialitiesListData?.specialities?.find(
          (el) => el?.id === parseInt(params?.specialist)
        )?.display
      }
    </CustomLink>,
    <Link
      to={`/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}/general_intake_form`}
    >
      <Typography fontSize={12} color={linkColor}>
        General Intake Form
      </Typography>
    </Link>,
    <CustomLink
      to={`/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}/share_records`}
      onConfirmClick={clearState}
    >
      Share Medical Reports
    </CustomLink>,
  ];

  const BreadcrumbsItems = [
    <CustomLink to={"/patient/dashboard"} onConfirmClick={clearState}>
      Dashboard
    </CustomLink>,
    <CustomLink
      to={`/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}`}
      onConfirmClick={clearState}
    >
      About Doctor
    </CustomLink>,
    <Link
      to={`/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}/general_intake_form`}
    >
      <Typography fontSize={12} color={linkColor}>
        General Intake Form
      </Typography>
    </Link>,
    <CustomLink
      to={`/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}/share_records`}
      onConfirmClick={clearState}
    >
      Share Medical Reports
    </CustomLink>,
  ];

  const breadcrumbs = [
    <Link
      to={`/${
        existingUser?.user?.type === "Patient" ? "patient" : "doctor"
      }/my_appointments`}
    >
      <Typography fontSize={12} color={linkColor}>
        My Appointments
      </Typography>
    </Link>,
    <Link
      to={`/${
        existingUser?.user?.type === "Patient" ? "patient" : "doctor"
      }/my_appointments`}
      state={{ tab: "upcoming" }}
    >
      <Typography fontSize={12} color={linkColor}>
        Upcoming Appointments
      </Typography>
    </Link>,
    <Link
      to={`/${
        existingUser?.user?.type === "Patient" ? "patient" : "doctor"
      }/my_appointments/${params?.appointmentId}`}
    >
      <Typography fontSize={12} color={linkColor}>
        Appointment Details
      </Typography>
    </Link>,
    <Link
      to={`/${
        existingUser?.user?.type === "Patient" ? "patient" : "doctor"
      }/my_appointments/${params?.appointmentId}/share_records`}
    >
      <Typography fontSize={12} color={linkColor}>
        Share Medical Reports
      </Typography>
    </Link>,
  ];

  const handleContinueBtnClick = async () => {
    if (params?.appointmentId) {
      try {
        await addMoreShareFiles({
          accessToken,
          userId: existingUser?.user?.id,
          appointmentId: params?.appointmentId,
          reportsList: selectedRecordsList.map((item) => ({
            report_id: item?.id,
            report_type: item?.patient_uploaded
              ? "UploadMedicalReport"
              : "DownloadMedicalReport",
          })),
        }).unwrap();
        navigate(`/patient/my_appointments/${params?.appointmentId}`);
      } catch (error) {
        console.log(error);
      }
    } else {
      handleCreateAppointment();
    }
  };

  return (
    <Box>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        {params?.appointmentId
          ? breadcrumbs
          : params?.specialist === "about_doctor"
          ? BreadcrumbsItems
          : state?.viaPopup
          ? BreadcrumbsItemsViaPopup
          : BreadcrumbsItemsViaSpecialities}
      </Breadcrumbs>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: reportsList?.length === 0 ? "1fr" : "1fr 17rem",
          alignItems: "start",
          gap: "2rem",
          fontFamily: "Open Sans",
          mt: "1rem",
        }}
      >
        <Box>
          {medicalRecordsListData?.practitioner && (
            <Box mb={2}>
              <DoctorCard
                img={medicalRecordsListData?.practitioner?.avatar_url}
                name={medicalRecordsListData?.practitioner?.full_name}
                consultationFees={
                  medicalRecordsListData?.practitioner?.consultation_fees
                }
                exp={medicalRecordsListData?.practitioner?.years_of_experience}
                noOfLikes={medicalRecordsListData?.practitioner?.total_likes}
                noOfReviews={
                  medicalRecordsListData?.practitioner?.total_reviews
                }
                qualification={medicalRecordsListData?.practitioner?.acadamic_details?.join(
                  ", "
                )}
                specialization={medicalRecordsListData?.practitioner?.specializations?.join(
                  ", "
                )}
                showActions={false}
                showLikesAndReviews={false}
              />
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              alignSelf: "center",
            }}
          >
            <Box>
              <Typography
                sx={{ fontSize: "1.125rem", fontWeight: 700, mb: ".5rem" }}
              >
                Share Medical Reports&nbsp;
                <Typography variant="span">
                  ({medicalRecordsListData?.reports_count || 0})
                </Typography>
              </Typography>
              <Typography fontSize={12} color={"#888"}>
                Select the required records to share with the Doctor.
              </Typography>
            </Box>
            {medicalRecordsListData?.total_count > 0 && (
              <Typography
                fontWeight={700}
                fontSize={"0.875rem"}
              >{`(${selectedRecordsId.length}/15) Selected`}</Typography>
            )}
          </Box>
          <>
            <Box
              sx={{
                display: "grid",
                gap: "0.625rem",
                alignItems: "start",
                borderRadius: "5px",
                mt: 2,
              }}
            >
              {reportsList?.map((el) => (
                <Box
                  sx={{
                    bgcolor: "#fafafa",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    p: ".75rem",
                  }}
                >
                  <Box>
                    <Box>
                      <Box
                        component={"a"}
                        href={el.attachment_url}
                        target="_blank"
                        sx={{
                          fontWeight: 700,
                          color: "primary.main",
                          fontSize: "0.75rem",
                        }}
                      >
                        {`${el.file_name}${
                          !el?.patient_uploaded
                            ? fileTypeExt[el?.file_type]
                            : ""
                        }`}
                      </Box>
                      {!el?.patient_uploaded && (
                        <>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <Box
                            component={VerifiedIcon}
                            sx={{
                              "& path": {
                                fill: (theme) => theme.palette.primary.main,
                              },
                            }}
                          />
                        </>
                      )}
                    </Box>
                    <Box>
                      <Box
                        component={"span"}
                        sx={{ fontSize: ".625rem", color: "#838383" }}
                      >
                        {el.patient_uploaded ? "Uploaded on" : "Downloaded on"}
                      </Box>{" "}
                      <Box component={"span"} sx={{ fontSize: "0.625rem" }}>
                        {el.created_at}
                      </Box>
                    </Box>
                  </Box>
                  <Checkbox
                    onChange={(evt) => {
                      if (
                        !selectedRecordsId.includes(el.id) &&
                        selectedRecordsId.length === 15
                      ) {
                        toastr.info("You can share only 15 files at a time");
                        return;
                      }
                      const list = reportsList.map((item) => {
                        if (item.id === el.id) {
                          if (
                            !selectedRecordsId.includes(el.id) &&
                            selectedRecordsId.length < 15
                          ) {
                            setSelectedRecordsId([
                              ...selectedRecordsId,
                              el?.id,
                            ]);
                            setSelectedRecordsList((prev) => [...prev, el]);
                          } else {
                            setSelectedRecordsId(
                              selectedRecordsId.filter((item) => item !== el.id)
                            );
                            setSelectedRecordsList((prev) =>
                              prev.filter((item) => item?.id !== el?.id)
                            );
                          }
                        }
                        return item;
                      });
                      setReportsList(list);
                    }}
                    checked={el.checked}
                  />
                </Box>
              ))}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                }}
              >
                {!isFetching && !isLoading && (
                  <CPagination
                    page={page}
                    setPage={setPage}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    totalCount={medicalRecordsListData?.reports_count}
                  />
                )}
              </Box>
            </Box>
          </>
        </Box>
        <Box>
          {medicalRecordsListData?.total_count > 0 && (
            <TextField
              placeholder="Search files"
              onChange={(e) => {
                handleInputChange(e);
                setPage(1);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box component={"img"} src={SearchIcon} alt="" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              sx={{
                width: "100%",
                "& input": { p: ".5rem", fontSize: "0.875rem" },
              }}
            />
          )}
          {reportsList?.length > 0 && (
            <Box sx={{ display: "grid", gap: "3rem", mt: 6 }}>
              <UploadAndDownloadBtn
                refetch={refetch}
                downloadDisabled={
                  medicalRecordsListData?.connected_healthcare_providers_count ===
                  0
                }
              />
              <Box>
                {state?.viaGeneralIntakeForm && (
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: { xs: "12px", md: "14px" },
                        mb: 1.5,
                      }}
                    >
                      <Checkbox
                        id="t-and-c-checkbox"
                        checked={RSIFIsTAndCAgreed}
                        onChange={() =>
                          dispatch(setTAndCAgreed(!RSIFIsTAndCAgreed))
                        }
                      />
                      <Box>
                        <Box
                          component="label"
                          sx={{ mb: 0, cursor: "pointer" }}
                          htmlFor="t-and-c-checkbox"
                        >
                          I have read and accept the&nbsp;
                        </Box>
                        <Box
                          sx={{
                            textDecoration: "underline",
                            fontStyle: "italic",
                            color: "primary.main",
                            cursor: "pointer",
                          }}
                          onClick={() => setShowTandCPopup(true)}
                        >
                          terms &amp; conditions
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{ width: "8rem" }}
                    disabled={isCreateAppointmentLoading}
                    onClick={() => {
                      if (params?.appointmentId) navigate(-1);
                      setShowCancelConfirmationPopup(true);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    disabled={
                      selectedRecordsId.length === 0 ||
                      isAddMoreShareFilesLoading ||
                      isCreateAppointmentLoading ||
                      (state?.viaGeneralIntakeForm && !RSIFIsTAndCAgreed)
                    }
                    onClick={handleContinueBtnClick}
                    sx={{ width: "8rem" }}
                  >
                    {isAddMoreShareFilesLoading ||
                    isCreateAppointmentLoading ? (
                      <CircularProgress size={"1.5rem"} />
                    ) : state?.viaGeneralIntakeForm ? (
                      "Proceed"
                    ) : (
                      "Continue"
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {isLoading || isFetching ? (
          <DefaultLoader />
        ) : (
          <>
            {medicalRecordsListData?.total_count === 0 ? (
              <Box
                sx={{
                  minHeight: "calc(100vh - 70px - 60px - 2rem - 40px - 2rem)",
                  gridColumn: "1/3",
                  display: "grid",
                  placeContent: "center",
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    component={"img"}
                    sx={{ mb: "1rem" }}
                    src={SadEmoji}
                    alt=""
                  />
                  <Box
                    component="p"
                    sx={{
                      maxWidth: "21rem",
                      textAlign: "center",
                      fontSize: "0.875rem",
                    }}
                  >
                    {existingUser?.user?.features_status
                      ?.download_medical_report &&
                    medicalRecordsListData?.connected_healthcare_providers_count !==
                      0
                      ? "You have no records to share with the doctor. Use the below options to download or upload your records!"
                      : "You have no records to share with the doctor. Use the below option to upload your records!"}
                  </Box>
                  <UploadAndDownloadBtn
                    downloadDisabled={
                      medicalRecordsListData?.connected_healthcare_providers_count ===
                      0
                    }
                    refetch={refetch}
                  />
                </Box>
              </Box>
            ) : reportsList?.length === 0 ? (
              <>
                <Box>
                  <div className="d-flex flex-column justify-content-center align-items-center norecord-reports">
                    <img
                      src={norecord}
                      className="no-rec-img"
                      alt="no records"
                    ></img>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      No Reports Found
                    </Box>
                  </div>
                </Box>
                <Box sx={{ display: "grid", gap: "3rem" }}>
                  <UploadAndDownloadBtn
                    refetch={refetch}
                    downloadDisabled={
                      medicalRecordsListData?.connected_healthcare_providers_count ===
                      0
                    }
                  />
                  <Box>
                    {state?.viaGeneralIntakeForm && (
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: { xs: "12px", md: "14px" },
                            mb: 1.5,
                          }}
                        >
                          <Checkbox
                            id="t-and-c-checkbox"
                            checked={RSIFIsTAndCAgreed}
                            onChange={() =>
                              dispatch(setTAndCAgreed(!RSIFIsTAndCAgreed))
                            }
                          />
                          <Box>
                            <Box
                              component="label"
                              sx={{ mb: 0, cursor: "pointer" }}
                              htmlFor="t-and-c-checkbox"
                            >
                              I have read and accept the&nbsp;
                            </Box>
                            <Box
                              sx={{
                                textDecoration: "underline",
                                fontStyle: "italic",
                                color: "primary.main",
                                cursor: "pointer",
                              }}
                              onClick={() => setShowTandCPopup(true)}
                            >
                              terms &amp; conditions
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Button
                        variant="outlined"
                        sx={{ width: "8rem" }}
                        disabled={isCreateAppointmentLoading}
                        onClick={() => {
                          if (params?.appointmentId) navigate(-1);
                          setShowCancelConfirmationPopup(true);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        disabled={
                          selectedRecordsId.length === 0 ||
                          isAddMoreShareFilesLoading ||
                          isCreateAppointmentLoading ||
                          (state?.viaGeneralIntakeForm && !RSIFIsTAndCAgreed)
                        }
                        onClick={handleContinueBtnClick}
                        sx={{ width: "8rem" }}
                      >
                        {isAddMoreShareFilesLoading ||
                        isCreateAppointmentLoading ? (
                          <CircularProgress size={"1.5rem"} />
                        ) : state?.viaGeneralIntakeForm ? (
                          "Proceed"
                        ) : (
                          "Continue"
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <></>
            )}
          </>
        )}
        <FeatureComingSoon
          show={showFeatureComingSoonModal}
          hide={() => setShowFeatureComingSoonModal(false)}
        />
        {showTandCPopup && (
          <RefundAndCancellationsPopup
            show={showTandCPopup}
            hide={() => setShowTandCPopup(false)}
            showPatientConsent={true}
            doctor={"doctor"}
            contact={
              process.env.REACT_APP_CONTACT_INFO || "[email/phone number]"
            }
          />
        )}
        <UnsavedDataPopup
          open={showCancelConfirmationPopup}
          handleClose={() => setShowCancelConfirmationPopup(false)}
          onCancel={() => setShowCancelConfirmationPopup(false)}
          onConfirm={() => navigate(-3)}
        />
      </Box>
    </Box>
  );
}
