import { Box } from "@mui/material";

export default function Card({ children, sx }) {
  return (
    <Box
      sx={{
        p: "0.9375rem",
        borderRadius: "0.3125rem",
        border: "1px solid #e3e3e3",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
