import React, { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch, useSelector } from "react-redux";
import { practitionerGetRecords } from "actions/practitionerAction";
import Loading from "components/UI/Loading";
import Map from "assets/images/Map.png";
import Web from "assets/images/web.svg";
import LinkedIn from "assets/images/LinkedIn.svg";

import { Container, Col, Collapse } from "reactstrap";
import { useNavigate } from "react-router";
import "Styles/PractitionerProfile.css";
import ProfileCircle from "assets/images/ProfileCircle.png";
import { Box, Button, Typography } from "@mui/material";
import PracProfileWalkthrough from "components/UI/PracProfileWalkthrough";

const PractitionerView = () => {
  const loading = useSelector((state) => state.practitionerRecords.loading);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [col9, setcol9] = useState(false);
  const [col10, setcol10] = useState(false);
  const [col12, setcol12] = useState(false);
  const [col11, setcol11] = useState(false);
  const [col13, setcol13] = useState(false);
  const { authState } = useOktaAuth();
  const t_col9 = () => {
    setcol9(!col9);
    setcol10(false);
    setcol11(false);
    setcol12(false);
    setcol13(false);
  };
  const t_col11 = () => {
    setcol11(!col11);
    setcol10(false);
    setcol9(false);
    setcol12(false);
    setcol13(false);
  };
  const t_col12 = () => {
    setcol12(!col12);
    setcol10(false);
    setcol9(false);
    setcol11(false);
    setcol13(false);
  };
  const t_col13 = () => {
    setcol13(!col13);
    setcol10(false);
    setcol9(false);
    setcol11(false);
    setcol12(false);
  };

  const userInfo = useSelector((state) => state.auth.existingUser);
  const MaskCharacter = (str, mask, n = 4) => {
    // Slice the string and replace with
    // mask then add remaining string
    return ("" + str).slice(0, -n).replace(/./g, mask) + ("" + str).slice(-n);
  };
  const GoToEditPage = () => {
    navigate("/doctor/profile/edit");
  };

  const practitionerData = useSelector(
    (state) => state?.practitionerRecords?.practitionerRecords
  );

  useEffect(() => {
    if (authState) {
      if (authState?.accessToken?.accessToken) {
        dispatch(
          practitionerGetRecords(
            authState?.accessToken?.accessToken,
            userInfo.user.id
          )
        );
      }
    }
  }, [authState]);

  const GoToDashBoard = () => {
    navigate("/doctor/dashboard");
  };

  const t_col10 = () => {
    setcol10(!col10);
    setcol12(false);

    setcol9(false);
    setcol11(false);
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <div>
      {loading && <Loading />}
      {!loading && (
        <div>
          <PracProfileWalkthrough />
          <div className="page-content">
            <Container fluid className="ptp-5 pl-8 pr-20 rg-40">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mx: "54px",
                  px: "10px",
                }}
              >
                <Box>
                  <Typography
                    id="my-profile-heading"
                    fontSize={22}
                    fontWeight={700}
                  >
                    My Profile
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", "& > * + *": { ml: ".75rem" } }}>
                  <Button
                    id="Dashboard-CTA"
                    sx={{ width: "7rem" }}
                    onClick={GoToDashBoard}
                  >
                    <Typography fontSize={14} fontWeight={700}>
                      Dashboard
                    </Typography>
                  </Button>
                  <Button
                    id="Edit-CTA"
                    sx={{ width: "7rem" }}
                    variant="contained"
                    onClick={GoToEditPage}
                  >
                    <Typography fontSize={14} fontWeight={700}>
                      Edit
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <div className="row cg-pro mr-left">
                <div className="d-flex col-sm-2 profileviewimgouter mb-31">
                  <img
                    className="profileViewPicSize"
                    src={
                      practitionerData?.practitioner?.avatar_url == null
                        ? ProfileCircle
                        : practitionerData?.practitioner?.avatar_url
                    }
                    alt="ProfileImg"
                  />{" "}
                </div>

                <div className=" d-flex  justify-content-around col-sm-9  profile-content-prac">
                  <div className="d-flex namelink cg-4 flx-wp">
                    <h2 className="mt-8 camelCase mt-0 name-wrap">
                      {practitionerData?.practitioner?.full_name.toLowerCase()}
                    </h2>
                    {practitionerData?.practitioner?.professional_detail
                      ?.linkedin_link && (
                      <a
                        href={
                          practitionerData?.practitioner?.professional_detail
                            ?.linkedin_link
                        }
                        className="linkredirect brk"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={LinkedIn} alt="Linkedin" />
                        <Typography
                          sx={{
                            "&:hover": {
                              textDecoration: "underline",
                            },
                            color: (theme) => theme.palette.primary.main,
                          }}
                        >
                          LinkedIn
                        </Typography>
                      </a>
                    )}{" "}
                    {practitionerData?.practitioner?.professional_detail
                      ?.website_link &&
                      practitionerData?.practitioner?.professional_detail
                        ?.linkedin_link && <>|</>}
                    {practitionerData?.practitioner?.professional_detail
                      ?.website_link && (
                      <a
                        href={
                          practitionerData?.practitioner?.professional_detail
                            ?.website_link
                        }
                        className="linkredirect  brk"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={Web} alt="Website" />
                        <Typography
                          sx={{
                            "&:hover": {
                              textDecoration: "underline",
                            },
                            color: (theme) => theme.palette.primary.main,
                          }}
                        >
                          Website
                        </Typography>
                      </a>
                    )}
                  </div>
                  <div className=" d-flex grey font-16">
                    {practitionerData?.practitioner?.specializations?.join(
                      ", "
                    )}
                  </div>
                  <div className="row cg-20">
                    <div className="col-sm-2">
                      <LableTypo label={"Mobile Number"} />
                      <p className="grey">
                        {practitionerData?.practitioner?.mobile_number}
                      </p>
                    </div>
                    <div className="col-sm-4">
                      <LableTypo label={"Email"} />
                      <p className="grey1">
                        {practitionerData?.practitioner?.email}
                      </p>
                    </div>

                    <div className="col-sm-2">
                      <LableTypo label={"Date of Birth"} />
                      <p className="grey">
                        {practitionerData?.practitioner?.date_of_birth}
                      </p>
                    </div>
                    <div className="col-sm-2">
                      <LableTypo label={"Gender"} />
                      <p className="grey">
                        {practitionerData?.practitioner?.gender}
                      </p>
                    </div>
                  </div>
                  <div className="row cg-20">
                    <div className="col-sm-2">
                      <LableTypo label={"Language"} />
                      <p className="grey">English</p>
                    </div>
                    <div className="col-sm-4">
                      <LableTypo label={"Address"} />
                      <div className="grey1">
                        {practitionerData?.practitioner?.street_name ? (
                          <span>
                            {" "}
                            {practitionerData?.practitioner?.street_name},{" "}
                          </span>
                        ) : (
                          <></>
                        )}
                        {practitionerData?.practitioner?.city ? (
                          <span>{practitionerData?.practitioner?.city}, </span>
                        ) : (
                          <></>
                        )}
                        {practitionerData?.practitioner?.state ? (
                          <span>{practitionerData?.practitioner?.state}, </span>
                        ) : (
                          <></>
                        )}
                        {practitionerData?.practitioner?.country ? (
                          <span>
                            {practitionerData?.practitioner?.country},{" "}
                          </span>
                        ) : (
                          <p></p>
                        )}

                        {practitionerData?.practitioner?.zipcode}
                        {practitionerData?.practitioner?.street_name && (
                          <>{"."}</>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-3 national-provider">
                      <LableTypo label={"NPI (National Provider ID)"} />
                      <p className="grey1  ">
                        {practitionerData?.practitioner?.professional_detail
                          ?.nip_number &&
                          MaskCharacter(
                            practitionerData?.practitioner?.professional_detail
                              ?.nip_number,
                            "*"
                          )}
                      </p>
                    </div>
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "20px",
                    }}
                  >
                    <Box sx={{ width: "160px" }}>
                      <LableTypo label={"Years of experience"} />
                      <p className="grey1  ">
                        {
                          practitionerData?.practitioner?.professional_detail
                            ?.years_of_experience
                        }
                      </p>
                    </Box>
                    <Box sx={{ width: "50%" }}>
                      <LableTypo label={"Specialities"} />
                      <p className="grey1  ">
                        {practitionerData?.practitioner?.specialities?.join(
                          ", "
                        )}
                      </p>
                    </Box>
                  </Box>
                </div>
                {/* </div> */}
              </div>
              {/* </CardBody> */}
              {/* </Card> */}
              <Col xl={12} className="acc-item">
                {/* <Card> */}
                {/* <CardBody className='txtAlignLeft'> */}
                <div
                  className="accordion accordion-flush acc-items"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header-btn" id="headingFlushOne">
                      <Box
                        component={"button"}
                        className={`accordion-button bgcolorWhite  fw-medium  ${
                          !col9 ? "collapsed" : ""
                        } `}
                        type="button"
                        onClick={t_col9}
                        sx={{
                          cursor: "pointer",
                          color: (theme) =>
                            `${theme.palette.primary.main} !important`,
                        }}
                      >
                        Professional Bio
                      </Box>
                    </h2>

                    <Collapse isOpen={col9} className="accordion-collapse">
                      <div className="accordion-body">
                        <div className="text-muted">
                          <div>
                            <p className="word-brk no-rec align-para">
                              {
                                practitionerData?.practitioner
                                  ?.professional_detail?.professional_bio
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header-btn" id="headingFlushTwo">
                      <Box
                        component={"button"}
                        className={`accordion-button bgcolorWhite  fw-medium  ${
                          !col10 ? "collapsed" : ""
                        }`}
                        type="button"
                        onClick={t_col10}
                        sx={{
                          cursor: "pointer",
                          color: (theme) =>
                            `${theme.palette.primary.main} !important`,
                        }}
                      >
                        Education
                      </Box>
                    </h2>

                    <Collapse isOpen={col10} className="accordion-collapse">
                      <div className="accordion-body">
                        <div className="text-muted">
                          <div className="row mt-10 no-rec text-left">
                            {practitionerData?.practitioner?.acadamic_details.map(
                              (item) => (
                                <div
                                  key={item?.id}
                                  className="col-sm-6 d-flex mb-20"
                                >
                                  <div className="col-sm-4">
                                    <h5>{item?.year}</h5>
                                  </div>
                                  <div className="col-sm-8">
                                    <div>
                                      <h5> {item?.college_name}</h5>
                                    </div>
                                    <div>
                                      <h6>{item?.course}</h6>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header-btn" id="headingFlushThree">
                      <Box
                        component={"button"}
                        className={`accordion-button bgcolorWhite  fw-medium ${
                          !col11 ? "collapsed" : ""
                        }`}
                        type="button"
                        onClick={t_col11}
                        sx={{
                          cursor: "pointer",
                          color: (theme) =>
                            `${theme.palette.primary.main} !important`,
                        }}
                      >
                        Certifications
                      </Box>
                    </h2>
                    <Collapse isOpen={col11} className="accordion-collapse">
                      <div className="accordion-body">
                        <div className="text-muted">
                          <div className="row no-rec">
                            {practitionerData?.practitioner?.certifications.map(
                              (item) => (
                                <div
                                  key={item?.id}
                                  className="col-sm-2 certificateCard justify-content-center mx-2"
                                >
                                  <div
                                    className="text-center m-2 p-2 text-center badge-align"
                                    onClick={() => openInNewTab(item.url)}
                                  >
                                    {item.filename}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                  <div className="accordion-item ">
                    <h2 className="accordion-header-btn" id="headingFlushThree">
                      <Box
                        component={"button"}
                        className={`accordion-button blue bgcolorWhite fw-medium ${
                          !col12 ? "collapsed" : ""
                        }`}
                        type="button"
                        onClick={t_col12}
                        sx={{
                          cursor: "pointer",
                          color: (theme) =>
                            `${theme.palette.primary.main} !important`,
                        }}
                      >
                        Awards and Publications
                      </Box>
                    </h2>
                    <Collapse isOpen={col12} className="accordion-collapse">
                      <div className="accordion-body">
                        <div className="text-muted">
                          {practitionerData?.practitioner?.award_publications
                            ?.length !== 0 ? (
                            <div className="row">
                              {practitionerData?.practitioner?.award_publications?.map(
                                (item) => (
                                  <div
                                    key={item?.id}
                                    className="col-sm-2 certificateCard justify-content-center mx-2"
                                  >
                                    <div
                                      className="text-center m-2 p-2 text-center badge-align"
                                      onClick={() => openInNewTab(item.url)}
                                    >
                                      {item.filename}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          ) : (
                            <div className="no-rec">No Records Found</div>
                          )}
                        </div>
                      </div>
                    </Collapse>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header-btn" id="headingFlushTwo">
                      <Box
                        component={"button"}
                        className={`accordion-button bgcolorWhite  fw-medium  ${
                          !col13 ? "collapsed" : ""
                        }`}
                        type="button"
                        onClick={t_col13}
                        sx={{
                          cursor: "pointer",
                          color: (theme) =>
                            `${theme.palette.primary.main} !important`,
                        }}
                      >
                        Location
                      </Box>
                    </h2>

                    <Collapse isOpen={col13} className="accordion-collapse">
                      <div className="accordion-body">
                        <div className="mt-10 d-flex">
                          <img src={Map} alt="Location Of address" />
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </Col>
            </Container>
          </div>
        </div>
      )}
    </div>
  );
};

export default PractitionerView;

const LableTypo = ({ label }) => {
  return (
    <Typography
      sx={{
        color: (theme) => theme.palette.primary.main,
        marginBottom: "6px",
      }}
    >
      {label}
    </Typography>
  );
};
