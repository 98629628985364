import { useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  useGetAppointmentDetailsQuery,
  useUpdateAppointmentStatusMutation,
} from "api/AppointmentsApi";
import useAuth from "hooks/useAuth";
import useExistingUser from "hooks/useExistingUser";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import PractitionerAppointmentCard from "../components/PractitionerAppointmentCard";
import DefaultLoader from "components/UI/DefaultLoader";
import AppointmentShareReportItem from "../components/AppointmentShareReportItem";
import { linkColor } from "../components/UI/BreadcrumbsList";
import { KeyboardArrowDown, NavigateNext } from "@mui/icons-material";
import AddConsultationNotesPopup from "../components/AddConsultationNotesPopup";
import FeatureComingSoon from "../components/UI/FeatureComingSoon";
import { fileTypeExt } from "utilities/constants";
import CancelAppointmentPopup from "components/CancelAppointmentPopup";
import moment from "moment";

const appointmentStatus = {
  completed: "Completed",
  doctor_no_show: "Doctor no show",
  patient_no_show: "Patient no show",
};

export default function PractitionerAppointmentDetailsPage() {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();
  const params = useParams();
  const navigate = useNavigate();
  const {
    data: appointmentDetailsData,
    isLoading,
    isError,
    isFetching,
  } = useGetAppointmentDetailsQuery(
    {
      accessToken,
      userType: "practitioners",
      userId: existingUser?.user?.id,
      appointmentId: params?.appointmentId,
    },
    { refetchOnReconnect: true, refetchOnMountOrArgChange: true }
  );

  const [showCancelAppointmentPopup, setShowCancelAppointmentPopup] =
    useState(false);
  const [showAddConsultationNotesPopup, setShowAddConsultationNotesPopup] =
    useState(false);
  const [featureComingSoon, setFeatureComingSoon] = useState(false);

  const [updateStatusAnchorEl, setUpdateStatusAnchorEl] = useState(null);
  const handleUpdateStatusBtnClick = (event) => {
    setUpdateStatusAnchorEl(event.currentTarget);
  };
  const handleUpdateStatusMenuClose = () => {
    setUpdateStatusAnchorEl(null);
  };

  useEffect(() => {
    if (!isLoading) {
      if (
        appointmentDetailsData?.category === "completed" &&
        appointmentDetailsData?.appointment?.tag_name
      ) {
        navigate("/doctor/my_appointments", {
          state: { tab: "completed" },
        });
      }
    }
  }, [appointmentDetailsData, isLoading]);

  const [updateAppointmentStatus, { isLoading: isUpdateAppointmentLoading }] =
    useUpdateAppointmentStatusMutation();

  const handleUpdateAppointmentStatus = async (status) => {
    handleUpdateStatusMenuClose();
    try {
      await updateAppointmentStatus({
        accessToken,
        userType: "practitioners",
        userId: existingUser?.user?.id,
        appointmentId: params?.appointmentId,
        status,
      }).unwrap();
      navigate("/doctor/my_appointments", {
        state: { tab: "completed" },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const breadcrumbs = [
    <Link
      to={`/${
        existingUser?.user?.type === "Patient" ? "patient" : "doctor"
      }/my_appointments`}
    >
      <Typography fontSize={12} color={linkColor}>
        My Appointments
      </Typography>
    </Link>,
    <Link
      to={`/${
        existingUser?.user?.type === "Patient" ? "patient" : "doctor"
      }/my_appointments`}
      state={{ tab: appointmentDetailsData?.category }}
    >
      <Typography fontSize={12} color={linkColor}>
        {appointmentDetailsData?.category === "upcoming" &&
          "Upcoming Appointments"}
        {appointmentDetailsData?.category === "inprogress" &&
          "In Progress Appointments"}
        {appointmentDetailsData?.category === "completed" &&
          "Completed Appointments"}
      </Typography>
    </Link>,
    <Link
      to={`/${
        existingUser?.user?.type === "Patient" ? "patient" : "doctor"
      }/my_appointments/${params?.appointmentId}`}
    >
      <Typography fontSize={12} color={linkColor}>
        Appointment Details
      </Typography>
    </Link>,
  ];

  return isError ? (
    <Navigate to="/page_not_found" />
  ) : (
    <>
      {isLoading || isFetching ? (
        <DefaultLoader height={"calc(100vh - 70px - 60px)"} />
      ) : (
        <>
          <FeatureComingSoon
            show={featureComingSoon}
            hide={() => setFeatureComingSoon(false)}
          />
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
          <Box sx={{ mt: ".5rem" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: "1.5rem",
                maxWidth: "43.75rem",
              }}
            >
              <Typography fontSize={18} fontWeight={700}>
                Appointment Details
              </Typography>
              {/* <Button
                sx={{ color: "error.main", fontSize: "0.75rem" }}
                onClick={() => setFeatureComingSoon(true)}
              >
                Help &amp; Support
              </Button> */}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
                "& > * + *": {
                  ml: { xs: 0, lg: "1rem" },
                },
              }}
            >
              <Box
                component="div"
                sx={{ maxWidth: { xs: "100%", lg: "43.75rem" }, width: "100%" }}
              >
                <PractitionerAppointmentCard
                  id={appointmentDetailsData?.appointment?.id}
                  title={appointmentDetailsData?.appointment?.title}
                  status={
                    appointmentDetailsData?.category !== "inprogress" &&
                    appointmentDetailsData?.appointment?.tag_name
                  }
                  consultationId={
                    appointmentDetailsData?.appointment?.consultation_id
                  }
                  appointmentType={
                    appointmentDetailsData?.appointment?.appointment_type
                  }
                  date={appointmentDetailsData?.appointment?.appointment_date}
                  endTime={appointmentDetailsData?.appointment?.end_time}
                  startTime={appointmentDetailsData?.appointment?.start_time}
                  navigationDisabled={true}
                  showActionButtons={true}
                  patientId={appointmentDetailsData?.appointment?.patient_id}
                  type={appointmentDetailsData?.category}
                  callDisabled={
                    appointmentDetailsData?.category === "completed" ||
                    (appointmentDetailsData?.category === "inprogress" &&
                      moment
                        .utc(appointmentDetailsData?.appointment?.end_time)
                        .local()
                        .diff(new Date()) < 0) ||
                    !appointmentDetailsData?.appointment?.join_url
                  }
                  patientName={
                    appointmentDetailsData?.appointment?.patient_name
                  }
                  joinUrl={appointmentDetailsData?.appointment?.join_url}
                />
                <Box sx={{ bgcolor: "#F5F5F5", p: "1rem", pt: "1.5rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography fontSize={18} fontWeight={700}>
                      Shared Files (
                      {appointmentDetailsData?.shared_medical_reports?.length ||
                        0}
                      )
                    </Typography>
                  </Box>
                  <Box sx={{ mt: "1.5rem", display: "grid", gap: "1rem" }}>
                    {appointmentDetailsData?.shared_medical_reports?.map(
                      (item) => (
                        <AppointmentShareReportItem
                          key={item?.id}
                          id={item?.id}
                          appointmentId={params?.appointmentId}
                          fileName={`${item?.file_name}${
                            item?.report_type === "DownloadMedicalReport"
                              ? fileTypeExt[item?.file_type]
                              : ""
                          }`}
                          verified={
                            item?.report_type === "DownloadMedicalReport"
                          }
                          fileLink={item?.attachment_url}
                          sharedReportTime={item?.shared_report_time}
                          showRevokeOption={false}
                          newItem={!item?.is_read}
                        />
                      )
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                component={"div"}
                sx={{
                  mt: { xs: "1rem", lg: 0 },
                  maxWidth: { xs: "100%", lg: "16rem" },
                  width: "100%",
                }}
              >
                {appointmentDetailsData?.category === "upcoming" && (
                  <Button
                    fullWidth
                    variant="outlined"
                    color={"error"}
                    onClick={() => setShowCancelAppointmentPopup(true)}
                  >
                    Cancel Appointment
                  </Button>
                )}
                {appointmentDetailsData?.category === "inprogress" && (
                  <>
                    <Button
                      fullWidth
                      variant="outlined"
                      disableElevation
                      onClick={handleUpdateStatusBtnClick}
                      endIcon={<KeyboardArrowDown />}
                      disabled={isUpdateAppointmentLoading}
                    >
                      {isUpdateAppointmentLoading ? (
                        <CircularProgress size={"1.5rem"} />
                      ) : (
                        "Update Status"
                      )}
                    </Button>
                    <Menu
                      anchorEl={updateStatusAnchorEl}
                      open={Boolean(updateStatusAnchorEl)}
                      onClose={handleUpdateStatusMenuClose}
                      sx={{
                        "& ul": { width: "16rem" },
                        "& li": { py: ".5rem" },
                      }}
                    >
                      {Object.keys(appointmentStatus).map((item, idx) => (
                        <>
                          <MenuItem
                            sx={{ width: "100%" }}
                            key={item}
                            onClick={() => handleUpdateAppointmentStatus(item)}
                          >
                            {appointmentStatus[item]}
                          </MenuItem>
                          {Object.keys(appointmentStatus).length - 1 !==
                            idx && <Divider />}
                        </>
                      ))}
                    </Menu>
                  </>
                )}
                {appointmentDetailsData?.category === "completed" && (
                  <Button
                    fullWidth
                    variant={"contained"}
                    onClick={() => {
                      if (
                        appointmentDetailsData?.appointment?.consultation_notes
                      ) {
                        setShowAddConsultationNotesPopup(true);
                      } else {
                        navigate("add_clinical_notes");
                      }
                    }}
                  >
                    Add Clinical Notes
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}
      {showCancelAppointmentPopup && (
        <CancelAppointmentPopup
          show={showCancelAppointmentPopup}
          hide={() => setShowCancelAppointmentPopup(false)}
          appointmentId={appointmentDetailsData?.appointment?.id}
          gotoPage={() =>
            navigate("/doctor/my_appointments", {
              state: { tab: "completed" },
            })
          }
        />
      )}
      {showAddConsultationNotesPopup && (
        <AddConsultationNotesPopup
          show={showAddConsultationNotesPopup}
          close={() => setShowAddConsultationNotesPopup(false)}
          appointmentId={params?.appointmentId}
        />
      )}
    </>
  );
}
