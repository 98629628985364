const { createSlice } = require("@reduxjs/toolkit");

const intakeFormSlice = createSlice({
  name: "intakeForm",
  initialState: {
    doctorId: null,
    answers: {},
    tAndCAgreed: false,
  },
  reducers: {
    setDoctorId: (state, action) => {
      state.doctorId = action.payload;
    },
    setAnswers: (state, action) => {
      state.answers = action.payload;
    },
    setTAndCAgreed: (state, action) => {
      state.tAndCAgreed = action.payload;
    },
  },
});

export const getAnswers = (state) => state.intakeForm.answers;
export const getDoctorId = (state) => state.intakeForm.doctorId;
export const getIsTAndCAgreed = (state) => state.intakeForm.tAndCAgreed;

export const { setAnswers, setDoctorId, setTAndCAgreed } =
  intakeFormSlice.actions;
export default intakeFormSlice.reducer;
