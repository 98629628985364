import { Box } from "@mui/material";
import useExistingUser from "hooks/useExistingUser";
import React, { useEffect, useRef } from "react";

const UpdateSchedule = () => {
  const { existingUser } = useExistingUser();

  const iframeRef = useRef(null);

  useEffect(() => {
    const handleIframeLoad = () => {
      const iframeWindow = iframeRef.current.contentWindow;
      iframeWindow.postMessage(
        {
          username: existingUser?.user?.email,
          password: process.env.REACT_APP_UPDATE_SCHEDULER_PASSWORD,
        },
        "*"
      );
    };

    iframeRef.current.addEventListener("load", handleIframeLoad);
  }, []);

  return (
    <Box
      component="iframe"
      ref={iframeRef}
      src={`${process.env.REACT_APP_BASE_URL}/scheduler_form.html`}
      title="Prefilled Form"
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default UpdateSchedule;
