import { ADD_ITEM, DELETE_ITEM } from "actionTypes/action-types";

const initialState = {
  numOfItems: 15,
};

const sampleReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ITEM:
      return {
        ...state,
        numOfItems: state.numOfItems + 1,
      };

    case DELETE_ITEM:
      return {
        ...state,
        numOfItems: state.numOfItems - 1,
      };
    default:
      return state;
  }
};

export default sampleReducer;
