import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import BasicModal from "./UI/BasicModal";
import { ThumbsDownIcon, ThumbsUpIcon } from "./UI/Icons";
import { useAddReviewToPractitionerMutation } from "../api/AppointmentsApi";
import useAuth from "../hooks/useAuth";
import { useState } from "react";

export default function AddReviewPopup({
  appointmentId,
  userId,
  practitionerName,
  open,
  close,
  practitionerId,
}) {
  const { accessToken } = useAuth();

  const [likeOrDislike, setLikeOrDislike] = useState();
  const [description, setDescription] = useState("");

  const [addReviewToPractitioner, { isLoading }] =
    useAddReviewToPractitionerMutation();

  const handleAddReviewToPractitioner = async () => {
    try {
      await addReviewToPractitioner({
        accessToken,
        userId,
        appointmentId,
        practitionerId,
        like: likeOrDislike,
        description,
      }).unwrap();
      close();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <BasicModal show={open} close={close}>
      <Box sx={{ mt: ".5rem" }}>
        <Typography fontWeight={700} fontSize={14} sx={{ mb: ".75rem" }}>
          Add Review
        </Typography>
        <Typography fontSize={12} sx={{ mb: "2rem" }}>
          Let us know how your online consultation with&nbsp;
          <b>{practitionerName}</b>&nbsp;was.
        </Typography>
        <Typography fontSize={14} fontWeight={700}>
          Did you like the consultation with {practitionerName}?
        </Typography>
        <Box
          sx={{
            p: "1.5rem",
            display: "grid",
            placeContent: "center",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
          }}
        >
          <br />
          {[
            {
              id: 1,
              icon: (
                <Box
                  component={ThumbsUpIcon}
                  sx={{
                    "& path": {
                      fill: likeOrDislike === true ? "#fff" : "#c6c6c6",
                    },
                  }}
                />
              ),
              onClick: () => setLikeOrDislike(true),
              selected: likeOrDislike === true,
            },
            {
              id: 2,
              icon: (
                <Box
                  component={ThumbsDownIcon}
                  sx={{
                    "& path": {
                      fill: likeOrDislike === false ? "#fff" : "#c6c6c6",
                    },
                  }}
                />
              ),
              onClick: () => setLikeOrDislike(false),
              selected: likeOrDislike === false,
            },
          ].map((item) => (
            <IconButton key={item?.id} onClick={item.onClick}>
              <Box
                sx={{
                  height: "42px",
                  width: "42px",
                  borderRadius: "99rem",
                  borderColor: (theme) => theme.palette.primary.main,
                  borderWidth: "1px",
                  borderStyle: "solid",
                  bgcolor: item.selected
                    ? (theme) => theme.palette.primary.main
                    : "#fff",
                }}
              >
                {item.icon}
              </Box>
            </IconButton>
          ))}
        </Box>
        <Typography fontSize={14} fontWeight={700} sx={{ mb: ".7rem" }}>
          Comments
        </Typography>
        <TextField
          fullWidth={true}
          multiline={true}
          maxRows={4}
          minRows={4}
          value={description}
          onChange={(evt) => {
            if (evt.target.value.length < 251) setDescription(evt.target.value);
          }}
          helperText={
            <Box
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >{`${description.length}/250`}</Box>
          }
        />
        <Typography fontSize={12} sx={{ mt: ".6rem", mb: "1.5rem" }}>
          *You cannot edit your review once it is submitted
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant={"contained"}
            sx={{ width: "50%" }}
            disabled={
              likeOrDislike === undefined || description === "" || isLoading
            }
            onClick={handleAddReviewToPractitioner}
          >
            {isLoading ? <CircularProgress size={"1.5rem"} /> : "Submit"}
          </Button>
        </Box>
      </Box>
    </BasicModal>
  );
}
