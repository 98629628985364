import { Box, IconButton, Typography } from "@mui/material";
import {
  useRevokeSharedReportMutation,
  useUpdateFileStatusMutation,
} from "api/AppointmentsApi";
import useAuth from "hooks/useAuth";
import useExistingUser from "hooks/useExistingUser";
import moment from "moment";
import { useState } from "react";
import PromptPopup from "./UI/PromptPopup";
import { toastr } from "react-redux-toastr";
import { ReverseIcon, VerifiedIcon } from "./UI/Icons";
import { useParams } from "react-router";
import { useRollbar } from "@rollbar/react";

export default function AppointmentShareReportItem({
  fileName,
  fileLink,
  sharedReportTime,
  id,
  appointmentId,
  showRevokeOption = true,
  lastItem = false,
  verified,
  newItem,
}) {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();
  const params = useParams();
  const rollbar = useRollbar();

  const [revokeSharedReport, { isLoading }] = useRevokeSharedReportMutation();

  const [showRevokeConfirmationPopup, setShowRevokeConfirmationPopup] =
    useState(false);

  const handleRevokeSharedReport = async () => {
    try {
      await revokeSharedReport({
        accessToken,
        userType: existingUser?.user?.type?.toLowerCase() + "s",
        userId: existingUser?.user?.id,
        sharedReportId: id,
        appointmentId,
      }).unwrap();
      setShowRevokeConfirmationPopup(false);
    } catch (error) {
      rollbar.error("error while revoking file", error, {
        payload: {
          accessToken,
          userType: existingUser?.user?.type?.toLowerCase() + "s",
          userId: existingUser?.user?.id,
          sharedReportId: id,
          appointmentId,
        },
      });
    }
  };

  const [updateFileStatus] = useUpdateFileStatusMutation();

  const handleUpdateFileStatus = async () => {
    if (newItem) {
      try {
        await updateFileStatus({
          accessToken,
          userType: existingUser?.user?.type,
          userId: existingUser?.user?.id,
          appointmentId: params?.appointmentId,
          reportId: id,
        }).unwrap();
      } catch (error) {
        rollbar.error("error while updating file status", error, {
          payload: {
            accessToken,
            userType: existingUser?.user?.type,
            userId: existingUser?.user?.id,
            appointmentId: params?.appointmentId,
            reportId: id,
          },
        });
      }
    }
  };

  const handleRevokeConfirmationPopupClose = () => {
    setShowRevokeConfirmationPopup(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        bgcolor: "#fafafa",
        borderRadius: "5px",
        py: ".75rem",
        px: "0.625rem",
      }}
    >
      <Box sx={{ display: "grid", gap: ".25rem" }}>
        <Box
          component={"a"}
          href={fileLink}
          target={"_blank"}
          sx={{ display: "flex", alignItems: "center" }}
          onClick={handleUpdateFileStatus}
        >
          <Typography fontSize={12} sx={{ color: "primary.main" }}>
            {fileName}
          </Typography>
          {verified && (
            <Box
              ml={2}
              component={VerifiedIcon}
              sx={{
                "& path": { fill: (theme) => theme.palette.primary.main },
              }}
            />
          )}
          {newItem && (
            <Box
              ml={2}
              sx={{
                height: ".5rem",
                width: ".5rem",
                borderRadius: "99rem",
                bgcolor: (theme) => theme.palette.error.main,
              }}
            />
          )}
        </Box>
        <Box display={"flex"}>
          <Typography fontSize={10} sx={{ color: "text.secondary" }}>
            {moment.utc(sharedReportTime).local().format("MMM DD, YYYY")}
          </Typography>
          <Typography ml={2} fontSize={10} sx={{ color: "text.secondary" }}>
            {moment.utc(sharedReportTime).local().format("hh:mm A")}
          </Typography>
        </Box>
      </Box>
      {showRevokeOption && (
        <Box sx={{ pl: ".625rem", borderLeft: "1px solid #c7c7c7" }}>
          <IconButton
            onClick={() => {
              if (lastItem) {
                toastr.info(
                  "You cannot cancel access for this file. At least one file should be shared with the doctor"
                );
              } else {
                setShowRevokeConfirmationPopup(true);
              }
            }}
          >
            <Box
              component={ReverseIcon}
              sx={{
                "& > g > path": { fill: (theme) => theme.palette.primary.main },
              }}
            />
          </IconButton>
        </Box>
      )}
      {showRevokeConfirmationPopup && (
        <PromptPopup
          open={showRevokeConfirmationPopup}
          handleClose={handleRevokeConfirmationPopupClose}
          onNoClick={handleRevokeConfirmationPopupClose}
          onYesClick={handleRevokeSharedReport}
          yesBtnDisabled={isLoading}
          noBtnDisabled={isLoading}
        >
          <Typography fontSize={14} textAlign={"center"}>
            Are you sure you want to cancel access to the doctor for the shared
            file?
          </Typography>
        </PromptPopup>
      )}
    </Box>
  );
}
