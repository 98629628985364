import { Box, Button, Typography } from "@mui/material";
import {
  CallIcon,
  ClockIcon,
  DateOutlinedIcon,
  VideoCallIcon,
} from "components/UI/Icons";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import mtz from "moment-timezone";
import AddNoteToPatientPopup from "./AddNoteToPatientPopup";
import { useState } from "react";
import ViewNotesPopup from "./Patient/ViewNotesPopup";
import { toastr } from "react-redux-toastr";

export default function PractitionerAppointmentCard({
  id,
  title,
  consultationId,
  status,
  date,
  startTime,
  endTime,
  appointmentType,
  type = "upcoming",
  callDisabled = false,
  navigationDisabled = false,
  showActionButtons = false,
  patientId,
  patientName,
  joinUrl,
}) {
  const navigate = useNavigate();
  const [showAddNotesPopup, setShowAddNotesPopup] = useState(false);
  const [showViewNotesPopup, setShowViewNotesPopup] = useState(false);

  return (
    <Box
      component={navigationDisabled ? Box : Button}
      sx={{
        width: "100%",
        position: "relative",
        textAlign: "left",
        px: "1.25rem",
        py: "1rem",
        display: "flex",
        alignItems: "stretch",
        justifyContent: "space-between",
        boxShadow: "4px 8px 16px 0px rgba(0, 0, 0, 0.12)",
        borderRadius: "5px",
      }}
      onClick={() => {
        if (!navigationDisabled) {
          if (type === "completed") {
            if (!status) {
              navigate(`/doctor/my_appointments/${id}`);
            } else {
              toastr.info("No details to show for this appointment");
            }
          } else {
            navigate(`/doctor/my_appointments/${id}`);
          }
        }
      }}
    >
      {showViewNotesPopup && (
        <ViewNotesPopup
          appointmentId={id}
          show={showViewNotesPopup}
          close={() => setShowViewNotesPopup(false)}
        />
      )}
      {status && (
        <Box
          sx={{
            position: "absolute",
            top: "-1.2rem",
            px: "2rem",
            py: ".1rem",
            right: 0,
            bgcolor:
              type === "upcoming"
                ? "primary.main"
                : status?.includes("Cancelled")
                ? "error.main"
                : "#FBCD88",
            zIndex: -1,
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
        >
          <Typography fontSize={12} color={"#fff"}>
            {status}
          </Typography>
        </Box>
      )}
      <Box sx={{ display: "grid", gap: ".75rem" }}>
        <Typography
          fontSize={12}
          fontWeight={700}
          sx={{ color: "text.primary" }}
        >
          {patientName}
        </Typography>
        <Typography
          fontSize={12}
          fontWeight={700}
          sx={{ color: "text.secondary" }}
        >
          {title}
        </Typography>
        <Typography fontSize={12} sx={{ color: "text.secondary" }}>
          Consultation ID:&nbsp;{consultationId}
        </Typography>
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              bgcolor: "#f5f5f5",
              borderRadius: "99rem",
              px: ".5rem",
              py: ".25rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              component={DateOutlinedIcon}
              sx={{ "& path": { fill: (theme) => theme.palette.primary.main } }}
            />
            &nbsp;
            <Typography fontSize={10} fontWeight={700} color="text.primary">
              {date}
            </Typography>
          </Box>
          <Box
            sx={{
              bgcolor: "#f5f5f5",
              borderRadius: "99rem",
              px: ".5rem",
              py: ".25rem",
              ml: ".5rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              component={ClockIcon}
              sx={{ "& path": { fill: (theme) => theme.palette.primary.main } }}
            />
            &nbsp;
            <Typography fontSize={10} fontWeight={700} color={"text.primary"}>
              {moment.utc(startTime).local().format("hh:mm a")}&nbsp;-&nbsp;
              {moment.utc(endTime).local().format("hh:mm a")}&nbsp;
              {mtz.tz(mtz.tz.guess()).format("z")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Button
          variant="contained"
          disabled={callDisabled}
          sx={{
            height: "2.25rem",
            width: "2.25rem",
            p: 0,
            minWidth: 0,
          }}
          onClick={(evt) => {
            evt.stopPropagation();
            const aTag = document.createElement("a");
            aTag.href = joinUrl;
            aTag.target = "_blank";
            aTag.click();
          }}
        >
          {appointmentType !== "video" ? <CallIcon /> : <VideoCallIcon />}
        </Button>
        {showActionButtons && (
          <Box
            sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
          >
            <Button
              variant="outlined"
              onClick={(evt) => {
                evt.stopPropagation();
                navigate("patient_details");
              }}
            >
              View Patient Details
            </Button>
            {type === "upcoming" ? (
              <Button
                variant="contained"
                sx={{ ml: { xs: 0, md: "1rem" } }}
                onClick={(evt) => {
                  evt.stopPropagation();
                  setShowAddNotesPopup(true);
                }}
              >
                Add General Notes
              </Button>
            ) : (
              <Button
                variant={"contained"}
                sx={{
                  ml: { xs: 0, md: ".75rem" },
                  mt: { xs: ".25rem", md: 0 },
                }}
                onClick={(evt) => {
                  evt.stopPropagation();
                  setShowViewNotesPopup(true);
                }}
              >
                View General Notes
              </Button>
            )}
            {showAddNotesPopup && (
              <AddNoteToPatientPopup
                show={showAddNotesPopup}
                close={() => setShowAddNotesPopup(false)}
                patientId={patientId}
                appointmentId={id}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
