import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ModalClose from "assets/images/ModalClose.svg";
import { KYearsOfExperienceList } from "utilities/constants";

export default function FilterModal({
  open,
  handleClose,
  gender,
  setGender,
  setPage,
  exp,
  setExp,
}) {
  const [mGender, setMGender] = useState(gender);
  const [mExp, setMExp] = useState(exp);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ "& .MuiPaper-root": { maxWidth: "600px", width: "100%" } }}
    >
      <Box
        sx={{
          py: "1.875rem",
          position: "relative",
          fontFamily: "Open Sans",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: ".5rem", right: ".5rem" }}
          onClick={handleClose}
        >
          <img src={ModalClose} alt="" />
        </IconButton>
        <Box sx={{ fontSize: "1.25rem", fontWeight: 700, px: "1rem" }}>
          Filter by
        </Box>
        <Box>
          <Box sx={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
            <Box
              sx={{
                borderRight: "1px solid #e4e4e4",
                p: "1.5rem",
              }}
            >
              Gender
            </Box>
            <Box sx={{ p: "1rem" }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={mGender}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="Male"
                  control={
                    <Radio
                      checked={mGender === "Male"}
                      onChange={(evt) => setMGender(evt.target.value)}
                    />
                  }
                  label="Male"
                />
                <FormControlLabel
                  value="Female"
                  control={
                    <Radio
                      checked={mGender === "Female"}
                      onChange={(evt) => setMGender(evt.target.value)}
                    />
                  }
                  label="Female"
                />
              </RadioGroup>
            </Box>
          </Box>
          <Box sx={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
            <Box
              sx={{
                borderRight: "1px solid #e4e4e4",
                p: "1.5rem",
              }}
            >
              Years of experience
            </Box>
            <Box
              sx={{
                px: "1rem",
                display: "flex",
                alignItems: "center",
                "& > * + *": { ml: "1rem" },
              }}
            >
              <Autocomplete
                value={mExp?.min}
                disableClearable={true}
                options={KYearsOfExperienceList.slice(
                  0,
                  mExp?.max || undefined
                )}
                onChange={(_, val) =>
                  setMExp((prev) => ({ ...prev, min: val }))
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select" label="min" />
                )}
              />
              <Typography>to</Typography>
              <Autocomplete
                value={mExp?.max}
                disableClearable={true}
                options={KYearsOfExperienceList.slice(mExp?.min)}
                onChange={(_, val) =>
                  setMExp((prev) => ({ ...prev, max: val }))
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select" label="max" />
                )}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            placeItems: "center",
            gridTemplateColumns: "1fr 1fr",
            gap: "2.5rem",
            mt: "1.5rem",
          }}
        >
          <Button
            variant="outlined"
            fullWidth
            sx={{ justifySelf: "end", maxWidth: "50%", fontWeight: 700 }}
            onClick={() => {
              setMExp({ min: null, max: null });
              setMGender(undefined);
            }}
          >
            Clear Filters
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{ maxWidth: "50%", fontWeight: 700 }}
            disabled={
              (mExp.min === null && mExp.max !== null) ||
              (mExp.min !== null && mExp.max === null)
            }
            onClick={() => {
              setExp(mExp);
              setGender(mGender);
              setPage(1);
              handleClose();
            }}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
