import { Box, Accordion, AccordionSummary, Breadcrumbs } from "@mui/material";
import { useEffect, useState } from "react";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import cloneDeep from "lodash/cloneDeep";

import "Styles/MedicalRecords.css";
import Allergies1 from "assets/images/Allergies.png";
import HealthRecordHeader from "components/HealthRecord/HealthRecordHeader";
import { KMedicalRecordType } from "utilities/constants";
import { useGetAllergiesQuery } from "api/MedicalRecordApi";
import useAuth from "hooks/useAuth";
import { stringify } from "qs";
import DefaultLoader from "components/UI/DefaultLoader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NavigateNext } from "@mui/icons-material";
import { BCPatientAllergies } from "components/UI/BreadcrumbsList";
import CPagination from "components/UI/CPagination";
import NoHealthRecords from "components/UI/NoHealthRecords";

const groupBy = [
  { name: "None", value: "none", label: "None" },
  { name: "Category", value: "category", label: "Category" },
  { name: "Year", value: "year", label: "Year" },
];

const Allergy = () => {
  const { accessToken } = useAuth();

  const [groupedBy, setGroupedBy] = useState("none");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [hcps, setHcps] = useState([]);
  const [encounters, setEncounters] = useState([]);
  const [groupbyCurrentPage, setGroupByCurrentPage] = useState(1);
  const countPerPage = 10;
  const [groupByTitle, setGroupbyTitle] = useState(null);
  const [groupbycollection, setGroupbyCollection] = useState(
    cloneDeep([].slice(0, countPerPage))
  );
  const [expanded, setExpanded] = useState(false);

  const [groupTitle, setGroupTitle] = useState([]);
  const [code, setCode] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [mostRecentDataChecked, setMostRecentDataChecked] = useState(false);
  const [categories, setCategories] = useState([]);

  const {
    data: allergy,
    isLoading: loading,
    isFetching,
  } = useGetAllergiesQuery(
    {
      accessToken,
      page,
      perPage,
      query: `&${stringify({
        group_by: groupedBy === "none" ? undefined : groupedBy,
        code: code.length === 0 ? undefined : code.join(","),
        from_date:
          fromDate === null
            ? undefined
            : new Intl.DateTimeFormat("en-US").format(fromDate),
        to_date:
          toDate === null
            ? undefined
            : new Intl.DateTimeFormat("en-US").format(toDate),
        healthcare_provider_ids: hcps.length === 0 ? undefined : hcps.join(","),
        encounter: encounters.length === 0 ? undefined : encounters.join(","),
        category: categories.length === 0 ? undefined : categories.join(","),
      })}`,
    },
    { refetchOnMountOrArgChange: true, refetchOnReconnect: true }
  );

  useEffect(() => {
    if (!Array.isArray(allergy?.allergy_intolerances)) {
      setGroupTitle(Object.keys(allergy?.allergy_intolerances || []));
    }
  }, [allergy]);

  useEffect(() => {
    setExpanded(false);
  }, [groupedBy]);

  const handleGroupBy = (e) => {
    setGroupedBy(e.value);
  };

  const groupbyUpdatePage = (p) => {
    setGroupByCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setGroupbyCollection(
      cloneDeep(allergy?.allergy_intolerances[groupByTitle]?.slice(from, to))
    );
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setGroupByCurrentPage(1);
  };

  return (
    <Box sx={{ fontFamily: "Open Sans" }}>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        {BCPatientAllergies}
      </Breadcrumbs>
      <Box>
        <Box>
          <HealthRecordHeader
            groupBy={groupBy}
            handleGroupBy={handleGroupBy}
            icon={Allergies1}
            medicalRecordType={allergy}
            title={KMedicalRecordType.ALLERGIES}
            setCode={setCode}
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
            code={code}
            mostRecentDataChecked={mostRecentDataChecked}
            setMostRecentDataChecked={setMostRecentDataChecked}
            hcps={hcps}
            setHcps={setHcps}
            encounters={encounters}
            setEncounters={setEncounters}
            categories={categories}
            setCategories={setCategories}
          />
        </Box>

        {loading || isFetching ? (
          <DefaultLoader />
        ) : allergy?.allergy_intolerances?.length !== 0 ? (
          <Box sx={{ mt: "1rem" }}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(7, 1fr)",
                gap: "1rem",
                px: "1rem",
                py: ".25rem",
                mb: "0.625rem",
                fontSize: "1rem",
                color: "#8e8e8e",
              }}
            >
              <Box>Onset Date</Box>
              <Box>Category</Box>
              <Box>Component</Box>
              <Box>Provider</Box>
              <Box>Severity</Box>
              <Box>Reaction</Box>
              <Box>Note</Box>
            </Box>
            {!Array.isArray(allergy?.allergy_intolerances) ? (
              groupTitle?.length !== 0 ? (
                groupTitle?.map((title, index) => (
                  <Accordion
                    key={title}
                    elevation={0}
                    expanded={expanded === `panel${index + 1}`}
                    onChange={handleAccordionChange(`panel${index + 1}`)}
                    disableGutters={true}
                    sx={{
                      borderTop: "1px solid rgb(0,0,0,0.125)",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon color="primary" />}
                      aria-controls={`panel${index + 1}a-content`}
                      id={`panel${index + 1}a-header`}
                      onClick={() => {
                        setGroupbyCollection(
                          cloneDeep(
                            allergy?.allergy_intolerances[title]?.slice(0, 10)
                          )
                        );
                        setGroupbyTitle(title);
                      }}
                      sx={{ color: "primary.main", bgcolor: "#dcf0ff" }}
                    >
                      {title +
                        " (" +
                        allergy?.allergy_intolerances[title]?.length +
                        ")"}
                    </AccordionSummary>
                    <Box sx={{ mt: ".25rem", display: "grid", gap: ".25rem" }}>
                      {groupbycollection?.map((item) => (
                        <RecordCard key={item?.id} item={item} />
                      ))}
                      <Pagination
                        pageSize={10}
                        onChange={(p) => {
                          groupbyUpdatePage(p);
                        }}
                        className="align-pagination mt-3 mb-3"
                        current={groupbyCurrentPage}
                        total={allergy?.allergy_intolerances[title]?.length}
                        showTitle={false}
                      />
                    </Box>
                  </Accordion>
                ))
              ) : (
                <NoHealthRecords />
              )
            ) : (
              <Box
                sx={{
                  display: "grid",
                  gap: "0.625rem",
                  fontSize: "0.875rem",
                  mb: "1rem",
                }}
              >
                {allergy?.allergy_intolerances?.map((item) => (
                  <RecordCard key={item?.id} item={item} />
                ))}
              </Box>
            )}
            {groupedBy === "none" && (
              <CPagination
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                totalCount={allergy?.total_records}
              />
            )}
          </Box>
        ) : (
          <NoHealthRecords />
        )}
      </Box>
    </Box>
  );
};

export default Allergy;

const RecordCard = ({ item }) => {
  return (
    <Box
      sx={{
        p: "1rem",
        borderRadius: "5px",
        bgcolor: "#f8f8f8",
        display: "grid",
        gridTemplateColumns: "repeat(7, 1fr)",
        gap: "1rem",
        "& > div": {
          whiteSpace: "nowrap",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      }}
    >
      <Box component={"div"} title={item?.onset_period?.start}>
        {item?.onset_period?.start}
      </Box>
      <Box component={"div"} title={item?.code}>
        {item?.code}
      </Box>
      <Box component={"div"} title={item?.category[0]}>
        {item?.category[0]}
      </Box>
      <Box component={"div"} title={item?.heathcare_provider_name}>
        {item?.heathcare_provider_name}
      </Box>
      <Box component={"div"} title={item?.criticality}>
        {item?.criticality}
      </Box>
      <Box component={"div"} title={item?.reaction[0]}>
        {item?.reaction[0]}
      </Box>
      <Box component={"div"} title={item?.note[0]}>
        {item?.note[0]}
      </Box>
    </Box>
  );
};
