import { Box, Button, Dialog } from "@mui/material";
import appConfig from "../../appConfig";

export default function PatientConsentPopup({ show, close, onClickYes }) {
  return (
    <Dialog open={show} onClose={close}>
      <Box
        sx={{
          width: "330px",
          display: "grid",
          gap: 2,
          placeItems: "center",
          fontFamily: "Open Sans",
          px: "6px",
          py: "1.875rem",
        }}
      >
        <Box sx={{ textAlign: "center", fontSize: "14px", fontWeight: 600 }}>
          By clicking on <b>Accept</b>, you provide your consent to download the
          health data pulled from the Connected Healthcare Providers to
          the&nbsp;
          {appConfig.shortName}&nbsp;application.
        </Box>
        <Box sx={{ textAlign: "center", fontSize: "14px", fontWeight: 600 }}>
          The data downloaded in any way was not altered or modified by&nbsp;
          {appConfig.shortName}. Please note that&nbsp;{appConfig.shortName}
          &nbsp;will not be held accountable for any mishandling of the data
          outside the application.
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "2.5rem",
          }}
        >
          <Button
            variant="outlined"
            sx={{ fontWeight: 700, width: "7.3125rem" }}
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ fontWeight: 700, width: "7.3125rem" }}
            onClick={onClickYes}
          >
            Accept
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
