import { useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Link, Navigate, useLocation } from "react-router-dom";

import {
  useGetBookmarkedDoctorsQuery,
  useGetConsultedDoctorsListQuery,
} from "api/DoctorsApi";

import useAuth from "hooks/useAuth";
import useExistingUser from "hooks/useExistingUser";

import DefaultLoader from "components/UI/DefaultLoader";

import SearchIcon from "assets/images/SearchIcon.svg";
import { NoDoctorIcon } from "components/UI/Icons";
import _ from "lodash";
import { NavigateNext } from "@mui/icons-material";
import { linkColor } from "components/UI/BreadcrumbsList";
import TabPageLayout from "components/Layouts/TabPageLayout";
import CPagination from "components/UI/CPagination";
import DoctorMiniCard from "components/DoctorMiniCard";

export default function SavedDoctorsPage() {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();
  const location = useLocation();

  const [tabValue, setTabValue] = useState(
    location.state?.tab === "consulted" ? 1 : 0
  );
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [searchTextValue, setSearchTextValue] = useState("");

  useEffect(() => {
    setPage(1);
    setSearchText("");
    setSearchTextValue("");
  }, [tabValue]);

  const handleInputChange = _.debounce((evt) => {
    setSearchText(evt.target.value.trim());
  }, 300);

  const {
    data: savedDoctorsListData,
    isLoading,
    isError,
    isFetching,
  } = useGetBookmarkedDoctorsQuery(
    {
      accessToken,
      patientId: existingUser.user.id,
      page,
      search: searchText,
      perPage,
    },
    {
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
      skip: tabValue === 1,
    }
  );

  const {
    data: consultedDoctorsListData,
    isLoading: isConsultedDoctorsDataLoading,
    isFetching: isConsultedDoctorsDataFetching,
    isError: isConsultedDoctorsDataError,
  } = useGetConsultedDoctorsListQuery(
    {
      accessToken,
      userId: existingUser.user.id,
      page,
      search: searchText,
      perPage,
    },
    {
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
      skip: tabValue === 0,
    }
  );

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  const breadcrumbs = [
    <Link
      to={`/${
        existingUser?.user?.type === "Patient" ? "patient" : "doctor"
      }/my_doctors`}
      state={{ tab: "upcoming" }}
    >
      <Typography fontSize={12} color={linkColor}>
        My Doctors
      </Typography>
    </Link>,
    <Link
      to={`/${
        existingUser?.user?.type === "Patient" ? "patient" : "doctor"
      }/my_doctors`}
    >
      <Typography fontSize={12} color={linkColor}>
        {tabValue === 1 ? "Consulted Doctors" : "Saved Doctors"}
      </Typography>
    </Link>,
  ];

  return isError || isConsultedDoctorsDataError ? (
    <Navigate to="/page_not_found" />
  ) : (
    <Box
      sx={{
        fontFamily: "Open Sans",
      }}
    >
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "8fr 4fr",
          alignItems: "start",
          gap: "2rem",
          mt: "1rem",
        }}
      >
        <Box
          sx={{ fontSize: "1.125rem", fontWeight: 700, alignSelf: "center" }}
        >
          My Doctors{" "}
          {tabValue === 0 && Boolean(savedDoctorsListData?.bookmarks_count) && (
            <Typography variant="span">
              ({savedDoctorsListData?.bookmarks_count})
            </Typography>
          )}
          {tabValue === 1 &&
            Boolean(consultedDoctorsListData?.consulted_doctors_count) && (
              <Typography variant="span">
                ({consultedDoctorsListData?.consulted_doctors_count})
              </Typography>
            )}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {((tabValue === 0 &&
            savedDoctorsListData?.total_count !== 0 &&
            !isLoading) ||
            (tabValue === 1 &&
              consultedDoctorsListData?.total_count !== 0 &&
              !isConsultedDoctorsDataLoading)) && (
            <TextField
              placeholder="Search Doctors"
              value={searchTextValue}
              onChange={(e) => {
                handleInputChange(e);
                setPage(1);
                setSearchTextValue(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box component={"img"} src={SearchIcon} alt="" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              sx={{
                width: "294px",
                "& input": { p: ".5rem", fontSize: "0.875rem" },
              }}
            />
          )}
        </Box>
      </Box>
      <TabPageLayout
        handleTabChange={handleTabChange}
        tabValue={tabValue}
        tabList={["Saved Doctors", "Consulted Doctors"]}
        panelList={[
          <Box>
            {isLoading || isFetching ? (
              <DefaultLoader />
            ) : savedDoctorsListData?.total_count === 0 ||
              savedDoctorsListData?.bookmarks_count === 0 ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "1rem",
                  minHeight: "60vh",
                }}
              >
                <Box
                  component={NoDoctorIcon}
                  sx={{
                    "& path": {
                      fill: (theme) => theme.palette.primary.main,
                    },
                  }}
                />
                <Typography>No saved doctors to show!</Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "8fr 4fr",
                  gap: "2rem",
                }}
              >
                <Box sx={{ display: "grid", gap: "1.25rem" }}>
                  {savedDoctorsListData?.practitioners?.map((el) => (
                    <DoctorMiniCard
                      key={el?.id}
                      id={el?.id}
                      name={el?.full_name}
                      avatarUrl={el?.avatar_url}
                      specializations={el?.specializations}
                      yearsOfExp={el?.years_of_experience}
                      showBookmarkButton={true}
                    />
                  ))}
                  {!isFetching && (
                    <CPagination
                      page={page}
                      perPage={perPage}
                      setPage={setPage}
                      setPerPage={setPerPage}
                      totalCount={savedDoctorsListData?.bookmarks_count}
                    />
                  )}
                </Box>
              </Box>
            )}
          </Box>,
          <Box>
            {isConsultedDoctorsDataLoading || isConsultedDoctorsDataFetching ? (
              <DefaultLoader />
            ) : consultedDoctorsListData?.total_count === 0 ||
              consultedDoctorsListData?.consulted_doctors_count === 0 ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "1rem",
                  minHeight: "60vh",
                }}
              >
                <Box
                  component={NoDoctorIcon}
                  sx={{
                    "& path": {
                      fill: (theme) => theme.palette.primary.main,
                    },
                  }}
                />
                <Typography>No consulted doctors to show!</Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "8fr 4fr",
                  gap: "2rem",
                }}
              >
                <Box sx={{ display: "grid", gap: "1.25rem" }}>
                  {consultedDoctorsListData?.consulted_doctors?.map((el) => (
                    <DoctorMiniCard
                      key={el?.id}
                      id={el?.id}
                      name={el?.full_name}
                      avatarUrl={el?.avatar_url}
                      specializations={el?.specializations}
                      yearsOfExp={el?.years_of_experience}
                      showBookmarkButton={false}
                    />
                  ))}
                  {!isConsultedDoctorsDataFetching && (
                    <CPagination
                      page={page}
                      perPage={perPage}
                      setPage={setPage}
                      setPerPage={setPerPage}
                      totalCount={
                        consultedDoctorsListData?.consulted_doctors_count
                      }
                    />
                  )}
                </Box>
              </Box>
            )}
          </Box>,
        ]}
      />
    </Box>
  );
}
