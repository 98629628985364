import { ArrowDropDown } from "@mui/icons-material";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Pagination,
  Typography,
} from "@mui/material";
import { useState } from "react";

export default function CPagination({
  totalCount,
  perPage,
  setPerPage,
  page,
  setPage,
}) {
  const [anchorEnteriesEl, setAnchorEnteriesEl] = useState(null);
  const open = Boolean(anchorEnteriesEl);
  const handleClick = (event) => {
    setAnchorEnteriesEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEnteriesEl(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        "& > * + *": { ml: ".5rem" },
      }}
    >
      {totalCount > 10 && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography fontSize={14}>show</Typography>&nbsp;
          <Button
            sx={{
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "primary",
              minWidth: 0,
              py: 0,
              pr: 0,
              pl: ".25rem",
              height: "32px",
            }}
            onClick={handleClick}
          >
            {perPage}&nbsp;
            <ArrowDropDown />
          </Button>
          &nbsp;
          <Typography fontSize={14}>entries</Typography>
          &nbsp;
          <Menu
            anchorEl={anchorEnteriesEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {[10, 25, 50].map((item) => (
              <MenuItem onClick={() => setPerPage(item)}>{item}</MenuItem>
            ))}
          </Menu>
        </Box>
      )}
      {totalCount > perPage && (
        <Pagination
          variant="outlined"
          shape="rounded"
          page={page}
          onChange={(_, val) => setPage(val)}
          count={Math.ceil(totalCount / perPage)}
        />
      )}
    </Box>
  );
}
