import { useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import moment from "moment";
import { useGetConsultationNotesQuery } from "api/AppointmentsApi";
import useExistingUser from "hooks/useExistingUser";
import useAuth from "hooks/useAuth";
import BasicModal from "components/UI/BasicModal";
import { NotesWarningIcon } from "components/UI/Icons";
import PDFViewer from "./PDFViewer";

export default function ConsultationNotesListPopup({
  show,
  close,
  appointmentId,
}) {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();
  const { data, isLoading } = useGetConsultationNotesQuery(
    {
      accessToken,
      userType: existingUser?.user?.type?.toLowerCase() + "s",
      userId: existingUser?.user?.id,
      appointmentId,
    },
    { refetchOnMountOrArgChange: true, refetchOnReconnect: true }
  );

  const [pdfLink, setPdfLink] = useState("");
  const [showPdfPopup, setShowPdfPopup] = useState(false);

  return (
    <BasicModal show={show} close={close}>
      <Box mt={2}>
        {isLoading ? (
          <Box
            sx={{
              height: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : data?.total_count === 0 ? (
          <Box
            sx={{
              minHeight: "18.75rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              "& > * + *": { mt: "1rem" },
            }}
          >
            <Box
              component={NotesWarningIcon}
              sx={{
                "& path": { stroke: (theme) => theme.palette.primary.main },
              }}
            />
            <Typography>No notes yet!</Typography>
          </Box>
        ) : (
          <>
            <Typography fontSize={14} fontWeight={700}>
              Take a look at your clinical notes from your Doctor!
            </Typography>
            <Box
              mt={2}
              sx={{
                display: "grid",
                alignItems: "start",
                gap: "1rem",
                maxHeight: "250px",
                overflowY: "auto",
              }}
            >
              {data?.consultation_note?.map((item) => (
                <Box
                  key={item?.id}
                  sx={{ p: "1rem", borderRadius: "5px", bgcolor: "#fafafa" }}
                >
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      mb: ".5rem",
                      display: "inline-flex",
                      cursor: "pointer",
                    }}
                    fontSize={12}
                    onClick={() => {
                      setPdfLink(item?.attachment_url);
                      setShowPdfPopup(true);
                    }}
                  >
                    {item?.file_name}
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <Typography fontSize={10}>
                      {moment
                        .utc(item?.created_time)
                        .local()
                        .format("MMMM, DD YYYY")}
                    </Typography>
                    <Typography ml={2} fontSize={10}>
                      {moment.utc(item?.created_time).local().format("hh:mm A")}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </>
        )}
      </Box>
      {showPdfPopup && pdfLink !== "" && (
        <PDFViewer
          show={showPdfPopup}
          close={() => setShowPdfPopup(false)}
          pdfLink={pdfLink}
        />
      )}
    </BasicModal>
  );
}
