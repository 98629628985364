import React from "react";
import "Styles/TermsCondition.css";
import { Box } from "@mui/material";
import PrivacyPolicyContent from "components/UI/PrivacyPolicyContent";
function PrivacyPolicy() {
  return (
    <Box sx={{ maxWidth: "980px", width: "100%", mx: "auto", p: 2 }}>
      <PrivacyPolicyContent />
    </Box>
  );
}

export default PrivacyPolicy;
