import {
  SET_USERTYPE_REQUEST,
  SET_USERTYPE_SUCCESS,
  CHECK_EXISTING_USER_REQUEST,
  CHECK_EXISTING_USER_SUCCESS,
  CHECK_EXISTING_USER_FAILURE,
  GET_EPIC_TOPKEN_REQUEST,
  GET_EPIC_TOPKEN_SUCCESS,
  GET_EPIC_TOPKEN_FAILURE,
} from "actions/auth";

const initialState = {
  loading: true,
  error: false,
  userResponse: [],
  existingUser: [],
  epic_access_token: null,
  epic_loading: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERTYPE_REQUEST: {
      return { ...state, loading: true };
    }
    case SET_USERTYPE_SUCCESS: {
      return { ...state, userResponse: action.payload, loading: false };
    }
    case CHECK_EXISTING_USER_REQUEST: {
      return { ...state, loading: true };
    }
    case CHECK_EXISTING_USER_SUCCESS: {
      return { ...state, existingUser: action.payload, loading: false };
    }
    case CHECK_EXISTING_USER_FAILURE: {
      return { ...state, loading: true, error: action.payload.error };
    }
    case GET_EPIC_TOPKEN_REQUEST: {
      return { ...state, epic_loading: true };
    }
    case GET_EPIC_TOPKEN_SUCCESS: {
      return {
        ...state,
        epic_access_token: action.payload,
        epic_loading: false,
      };
    }
    case GET_EPIC_TOPKEN_FAILURE: {
      return { ...state, error: action.payload.error, epic_loading: false };
    }

    default:
      return state;
  }
};

export default authReducer;
