import MedicalReportUpload from "components/HealthRecord/MedicalReportUpload";
import MedicalRecordItem from "components/PatientDashboard/MedicalRecordItem";
import norecord from "assets/images/norecord.png";
import SearchIcon from "assets/images/SearchIcon.svg";
import { Row } from "reactstrap";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useGetPatientMedicalRecordQuery } from "api/MedicalRecordApi";
import _ from "lodash";
import useAuth from "hooks/useAuth";
import { Box, Breadcrumbs, Button as MuiButton } from "@mui/material";
import DefaultLoader from "components/UI/DefaultLoader";
import { useNavigate } from "react-router";
import { NavigateNext } from "@mui/icons-material";
import { BCPatientMedicalReports } from "components/UI/BreadcrumbsList";
import CPagination from "components/UI/CPagination";
import "Styles/UploadPopUp.css";

const UMR_SUPPORTED_FORMATS =
  process.env.REACT_APP_SUPPORTED_FORMAT_UMR || "application/pdf";

export default function MedicalRecordsList() {
  const { accessToken } = useAuth();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth.existingUser);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [fileExt, setFileExt] = useState("");
  const [selectedFile, setSelectedFile] = useState({
    file: null,
    fileName: "",
  });
  const [reportsList, setReportsList] = useState([]);
  const [noOfEntries, setNoOfEntries] = useState(10);
  const [totalRecords, setTotalRecords] = useState(noOfEntries);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [search, setSearch] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const {
    data: uploadedMedicalReportsData,
    isFetching: isUploadedMedicalReportsFetching,
    isLoading,
    isError,
    refetch,
  } = useGetPatientMedicalRecordQuery(
    {
      accessToken,
      patientId: user.id,
      pageNo: currentPageNo,
      showRecords: noOfEntries,
      search: search,
    },
    { refetchOnMountOrArgChange: true, refetchOnReconnect: true }
  );

  useEffect(() => {
    setCurrentPageNo(1);
  }, [search]);

  useEffect(() => {
    if (isError) navigate("/page_not_found");
  }, [isError]);

  useEffect(() => {
    if (!isUploadedMedicalReportsFetching && !isLoading) {
      setReportsList(uploadedMedicalReportsData?.reports);
      setTotalRecords(uploadedMedicalReportsData?.upload_medical_reports_count);
    }
  }, [uploadedMedicalReportsData, isUploadedMedicalReportsFetching]);

  useEffect(() => {
    if (selectedFile.file) {
      setShowUploadFileModal(true);
    }
  }, [selectedFile]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [reportsList]);

  useEffect(() => {
    setCurrentPageNo(1);
  }, [noOfEntries]);

  const limitErrorToast = () => {
    toastr.error("File size exceeds the maximum limit of 300 MB");
  };

  const handleFileUpload = (evt) => {
    if (evt.target.files[0]) {
      if (
        !UMR_SUPPORTED_FORMATS.split(",").includes(evt.target.files[0].type)
      ) {
        toastr.error(
          "Unsupported file format. The app supports only PDF, .jpg, .jpeg, and .png formats."
        );
      } else if (Math.round(evt.target?.files[0]?.size / 1024) < 307200) {
        setFileExt(evt.target.files[0].name.split(".").pop());
        setSelectedFile({
          file: evt.target.files[0],
          fileName: evt.target.files[0].name.split(".").slice(0, -1).join("."),
        });
      } else {
        limitErrorToast();
      }
      evt.target.value = "";
    }
  };

  const handleInputChange = _.debounce((evt) => {
    setSearch(evt.target.value.trim());
  }, 300);

  return (
    <div>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        {BCPatientMedicalReports}
      </Breadcrumbs>
      <div className="mt-3">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <Box
            sx={{
              fontWeight: 700,
              fontSize: "1.125rem",
            }}
          >
            Medical Reports&nbsp;
            {!isLoading && Boolean(totalRecords) && (
              <Box component={"span"}>({totalRecords})</Box>
            )}
          </Box>
          <Box sx={{ display: "flex", gap: "1.875rem" }}>
            <Box sx={{ position: "relative" }}>
              <Box
                component={"img"}
                src={SearchIcon}
                alt=""
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: ".5rem",
                  transform: "translateY(-50%)",
                }}
              />
              <Box
                component="input"
                sx={{ pl: "2.25rem", width: "19.6875rem" }}
                type="text"
                className="form-control"
                placeholder="Search"
                value={searchVal}
                onChange={(evt) => {
                  setSearchVal(evt.target.value);
                  handleInputChange(evt);
                }}
              />
            </Box>
            <MuiButton variant="contained" sx={{ p: 0, width: "118px" }}>
              <Box
                component="label"
                sx={{
                  m: 0,
                  py: "0.375rem",
                  cursor: "pointer",
                  width: "100%",
                }}
                htmlFor="upload-file-input-dashboard"
              >
                Upload
              </Box>
              <input
                type="file"
                className="visually-hidden"
                id="upload-file-input-dashboard"
                accept={UMR_SUPPORTED_FORMATS}
                onChange={handleFileUpload}
              />
            </MuiButton>
          </Box>
        </div>
        <Box sx={{ fontSize: "0.75rem", color: "#666" }}>
          Upload your prescriptions, diagnosis, scan reports, and test results
          here.
          <p>Supported file formats: Pdf, jpg, jpeg, and png.</p>
        </Box>
        <Box sx={{ display: "grid", gap: "0.9375rem" }}>
          {isLoading || isUploadedMedicalReportsFetching ? (
            <DefaultLoader />
          ) : reportsList?.length > 0 ? (
            reportsList?.map((item) => (
              <MedicalRecordItem
                key={item?.id}
                id={item?.id}
                fileUrl={item?.attachment_url}
                name={item?.file_name}
                uploadedDate={item?.created_at}
                onDeleteModalClose={() => {
                  if (reportsList.length <= 1 && currentPageNo !== 1) {
                    setCurrentPageNo((prev) => prev - 1);
                  } else {
                    refetch();
                  }
                }}
              />
            ))
          ) : (
            <div className="d-flex flex-column justify-content-center align-items-center norecord-reports">
              <img src={norecord} className="no-rec-img" alt="no records"></img>
              <Row className="justify-content-center">No Reports Found</Row>
            </div>
          )}
        </Box>
        <Box sx={{ mt: "1rem" }}>
          {!isUploadedMedicalReportsFetching && (
            <CPagination
              page={currentPageNo}
              perPage={noOfEntries}
              setPage={setCurrentPageNo}
              setPerPage={setNoOfEntries}
              totalCount={totalRecords}
            />
          )}
        </Box>
        {showUploadFileModal && (
          <MedicalReportUpload
            show={showUploadFileModal}
            close={() => {
              setFileExt("");
              setSelectedFile({
                file: null,
                fileName: "",
              });
              setShowUploadFileModal(false);
              setSearch("");
              setSearchVal("");
            }}
            fileExt={fileExt}
            selectedFile={selectedFile}
            setFileExt={setFileExt}
            setSelectedFile={setSelectedFile}
          />
        )}
      </div>
    </div>
  );
}
