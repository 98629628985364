import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { patientGetRecords } from "actions/patientAction";
import { useOktaAuth } from "@okta/okta-react";
import { Container, Card, CardBody } from "reactstrap";
import "Styles/PatientProfile.css";
import ProfileCircle from "assets/images/ProfileCircle.png";
import { useNavigate } from "react-router-dom";
import Loading from "components/UI/Loading";
import "Styles/Register.css";
import { Box, Button, Typography } from "@mui/material";
import appConfig from "appConfig";

const PatientView = () => {
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const userInfo = useSelector((state) => state.auth.existingUser);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.patientRecords.loading);
  const patientData = useSelector(
    (state) => state.patientRecords.patientRecords
  );

  const GoToEditPage = () => {
    navigate("/patient/profile/edit");
  };
  useEffect(() => {
    if (authState) {
      if (authState?.accessToken?.accessToken) {
        dispatch(
          patientGetRecords(
            authState?.accessToken?.accessToken,
            userInfo.user.id
          )
        );
      }
    }
  }, [authState]);

  const GoToDashBoard = () => {
    navigate("/patient/dashboard");
  };

  return (
    <div>
      {!loading ? (
        <>
          <div className="page-content">
            <Container fluid className="pl-30 pr-20 mt-2 ">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography fontSize={22} fontWeight={700}>
                    My Profile
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", "& > * + *": { ml: ".75rem" } }}>
                  <Button sx={{ width: "7rem" }} onClick={GoToDashBoard}>
                    <Typography fontSize={14} fontWeight={700}>
                      Dashboard
                    </Typography>
                  </Button>
                  <Button
                    sx={{ width: "7rem" }}
                    variant="contained"
                    onClick={GoToEditPage}
                  >
                    <Typography fontSize={14} fontWeight={700}>
                      Edit
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Card className="mt-28 mr-25">
                <CardBody>
                  <Box
                    sx={{ display: { xs: "block", lg: "flex" }, gap: "2rem" }}
                  >
                    <div className="d-flex col-lg-2 profileviewimgouter ml-19">
                      <img
                        className="profileViewPicSize"
                        src={
                          patientData?.patient?.avatar_url === null
                            ? ProfileCircle
                            : patientData?.patient?.avatar_url
                        }
                        alt="ProfileImg"
                      />{" "}
                    </div>
                    <Box className="align-items-start txtAlignLeft justify-content-around mr-tp-10">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "baseline",
                          gap: "2rem",
                        }}
                      >
                        <h2 className="camelCase">
                          {patientData?.patient?.full_name.toLowerCase()}
                        </h2>
                      </Box>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(4, 1fr)",
                          gap: "1rem",
                          maxWidth: "850px",
                        }}
                      >
                        <Box>
                          <LableTypo lable={`${appConfig.shortName} ID`} />
                          <Typography fontSize={14} className="grey">
                            {patientData?.patient?.dolphin_id}
                          </Typography>
                        </Box>
                        <Box>
                          <LableTypo lable={"Mobile Number"} />
                          <Typography fontSize={14} className="grey">
                            {patientData?.patient?.mobile_number}
                          </Typography>
                        </Box>
                        <Box>
                          <LableTypo lable={"Email"} />
                          <Typography fontSize={14} className="grey1">
                            {patientData?.patient?.email}
                          </Typography>
                        </Box>
                        <Box>
                          <LableTypo lable={"Date of Birth"} />
                          <Typography fontSize={14} className="grey">
                            {patientData?.patient?.date_of_birth}
                          </Typography>
                        </Box>
                        <Box>
                          <LableTypo lable={"Gender"} />
                          <Typography fontSize={14} className="grey">
                            {patientData?.patient?.gender}
                          </Typography>
                        </Box>
                        <Box>
                          <LableTypo lable={"Language"} />
                          <Typography fontSize={14} className="grey">
                            {patientData?.patient?.preferred_language}
                          </Typography>
                        </Box>
                        <Box>
                          <LableTypo lable={"Address"} />
                          <div className="grey1 address ">
                            {patientData?.patient?.street_name ? (
                              <span>{patientData?.patient?.street_name}, </span>
                            ) : (
                              <></>
                            )}
                            {patientData?.patient?.city ? (
                              <span>{patientData?.patient?.city}, </span>
                            ) : (
                              <></>
                            )}
                            {patientData?.patient?.state ? (
                              <span>{patientData?.patient?.state}, </span>
                            ) : (
                              <></>
                            )}
                            {patientData?.patient?.country ? (
                              <span>{patientData?.patient?.country}, </span>
                            ) : (
                              <Typography></Typography>
                            )}

                            {patientData?.patient?.zipcode}
                            {patientData?.patient?.street_name && <>{"."}</>}
                          </div>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </CardBody>
              </Card>
              {/* <MyCardSection /> */}
            </Container>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default PatientView;

const LableTypo = ({ lable }) => {
  return (
    <Typography
      sx={{
        color: (theme) => theme.palette.primary.main,
        marginBottom: "6px",
      }}
    >
      {lable}
    </Typography>
  );
};
