import { useState } from "react";
import appConfig from "appConfig";
import RefundAndCancellationsPopup from "components/UI/RefundAndCancellationsPopup";
import useExistingUser from "hooks/useExistingUser";
import { Box, Typography } from "@mui/material";
import TermsAndConditionsPopup from "components/UI/TermsAndConditionsPopup";
import PrivacyPolicyPopup from "components/UI/PrivacyPolicyPopup";

import FeatureComingSoon from "components/UI/FeatureComingSoon";

const Footer = () => {
  const { existingUser } = useExistingUser();

  const [showFeatureComingSoonPopup, setShowFeatureComingSoonPopup] =
    useState(false);
  const [showTermsAndConditionsPopup, setShowTermsAndConditionsPopup] =
    useState(false);
  const [showPrivacyPolicyPopup, setShowPrivacyPolicyPopup] =
    useState(false);
  const [showRefundCancellationPopup, setShowRefundCancellationPopup] =
    useState(false);

  return (
    <>
      <Box
        component="footer"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "60px",
          borderTop: "1px solid #e9e9ef",
          position: "absolute",
          bottom: 0,
          width: "100%",
          bgcolor: "#fff",
        }}
      >
        <Typography fontSize={12} mr={1} color={"GrayText"}>
          &copy;{` ${new Date().getFullYear()} ${appConfig.name}`}
        </Typography>
        &nbsp;&bull;&nbsp;
        <Typography
          fontSize={12}
          mx={1}
          color={"GrayText"}
          sx={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={() => setShowTermsAndConditionsPopup(true)}
        >
          Terms &amp; Conditions
        </Typography>
        &nbsp;&bull;&nbsp;
        {existingUser?.user?.type === "Patient" && (
          <>
            <Typography
              fontSize={12}
              mx={1}
              color={"GrayText"}
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => setShowRefundCancellationPopup(true)}
            >
              Cancellation &amp; Refund Policy
            </Typography>
            &nbsp;&bull;&nbsp;
          </>
        )}
        <Typography
          fontSize={12}
          ml={1}
          color={"GrayText"}
          sx={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={() => setShowPrivacyPolicyPopup(true)}
        >
          Privacy Policy
        </Typography>
      </Box>
      {showTermsAndConditionsPopup && (
        <TermsAndConditionsPopup
          show={showTermsAndConditionsPopup}
          close={() => setShowTermsAndConditionsPopup(false)}
        />
      )}
      {showPrivacyPolicyPopup && (
        <PrivacyPolicyPopup
          show={showPrivacyPolicyPopup}
          close={() => setShowPrivacyPolicyPopup(false)}
        />
      )}      
      {showRefundCancellationPopup && (
        <RefundAndCancellationsPopup
          show={showRefundCancellationPopup}
          hide={() => setShowRefundCancellationPopup(false)}
        />
      )}
      {showFeatureComingSoonPopup && (
        <FeatureComingSoon
          show={showFeatureComingSoonPopup}
          hide={() => setShowFeatureComingSoonPopup(false)}
        />
      )}
    </>
  );
};

export default Footer;
