import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const MedicalRecordApiSlice = createApi({
  reducerPath: "MedicalRecordApis",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
  }),
  tagTypes: ["Dashboard", "Uploads", "My Downloads"],
  endpoints: (builder) => ({
    getPatientDashboard: builder.query({
      providesTags: ["Dashboard"],
      query: ({ accessToken }) => ({
        url: "FHIR/Dashboard",
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getEpicData: builder.mutation({
      invalidatesTags: ["Dashboard"],
      query: ({ accessToken, patientId, epicData }) => ({
        url: `patients/${patientId}/ehr_provider`,
        method: "POST",
        body: {
          patient_ehr_provider: {
            patient_ehr_id: epicData.patient,
            provider_name: "Epic",
            expires_in: epicData.expires_in,
            access_token: epicData.access_token,
            fhir_api_endpoint_id: localStorage.getItem("providerId"),
          },
        },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getPatientMedicalRecord: builder.query({
      providesTags: ["Uploads"],
      query: ({ patientId, accessToken, pageNo, showRecords, search }) => ({
        url: `patients/${patientId}/upload_medical_reports`,
        params: { file_name: search, page: pageNo, per_page: showRecords },
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    uploadPatientMedicalRecord: builder.mutation({
      invalidatesTags: ["Dashboard", "Uploads"],
      query: ({
        patientId,
        fileName,
        fileType,
        fileAsString,
        accessToken,
      }) => ({
        url: `patients/${patientId}/upload_medical_reports`,
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: {
          upload_medical_reports: {
            file_name: fileName,
            file_type: fileType,
            attachment: fileAsString,
          },
        },
      }),
    }),
    deletePatientMedicalRecord: builder.mutation({
      query: ({ patientId, accessToken, reportId }) => ({
        url: `patients/${patientId}/upload_medical_reports/${reportId}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getVitalSigns: builder.query({
      query: ({ page, perPage, query, accessToken }) => ({
        url: `FHIR/Observation/VitalSigns?page=${page}&per_page=${perPage}${query}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getMedicalHistories: builder.query({
      query: ({ page, perPage, query, accessToken }) => ({
        url: `FHIR/Condition/MedicalHistory?page=${page}&per_page=${perPage}${query}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getLabResults: builder.query({
      query: ({ page, perPage, query, accessToken }) => ({
        url: `FHIR/Observation/Lab?page=${page}&per_page=${perPage}${query}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getImmunizations: builder.query({
      query: ({ page, perPage, query, accessToken }) => ({
        url: `FHIR/Immunization?page=${page}&per_page=${perPage}${query}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getAllergies: builder.query({
      query: ({ page, perPage, query, accessToken }) => ({
        url: `FHIR/AllergyIntolerance?page=${page}&per_page=${perPage}${query}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getVitalSignsHealthcareProviders: builder.query({
      keepUnusedDataFor: 0.000000000003,
      query: ({ accessToken }) => ({
        url: "FHIR/Observation/VitalSigns/HealthCareProviders",
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getMedicalHistoriesHealthcareProviders: builder.query({
      keepUnusedDataFor: 0.000000000003,
      query: ({ accessToken }) => ({
        url: "FHIR/Condition/MedicalHistory/HealthCareProviders",
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getLabHealthcareProviders: builder.query({
      keepUnusedDataFor: 0.000000000003,
      query: ({ accessToken }) => ({
        url: "FHIR/Observation/Lab/HealthCareProviders",
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getAllergiesHealthcareProviders: builder.query({
      keepUnusedDataFor: 0.000000000003,
      query: ({ accessToken }) => ({
        url: "FHIR/AllergyIntolerance/HealthCareProviders",
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getImmunizationsHealthcareProviders: builder.query({
      keepUnusedDataFor: 0.000000000003,
      query: ({ accessToken }) => ({
        url: "FHIR/Immunization/HealthCareProviders",
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getVitalSignsEncounterTypes: builder.query({
      keepUnusedDataFor: 0.000000000003,
      query: ({ accessToken }) => ({
        url: "FHIR/Observation/VitalSigns/Encounters",
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getLabResultsEncounterTypes: builder.query({
      keepUnusedDataFor: 0.000000000003,
      query: ({ accessToken }) => ({
        url: "FHIR/Observation/Lab/Encounters",
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getMyDownloadsList: builder.query({
      providesTags: ["My Downloads"],
      query: ({ accessToken, patientId, page, noOfEntries, search }) => ({
        url: `patients/${patientId}/download_medical_reports`,
        method: "GET",
        params: {
          page,
          per_page: noOfEntries,
          file_name: search,
        },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getGlobalSearchList: builder.query({
      query: ({ accessToken, search }) => ({
        url: `patients/dashbaord/search/list`,
        method: "GET",
        params: {
          search: search,
        },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    createDownloadMedicalReports: builder.mutation({
      invalidatesTags: ["My Downloads"],
      query: ({
        accessToken,
        patientId,
        fileName,
        healthcareProvidersId,
        code,
        category,
        encounter,
        resourceType,
        fromDate,
        toDate,
        recentData,
      }) => ({
        url: `patients/${patientId}/download_medical_reports`,
        method: "POST",
        body: {
          download_medical_reports: {
            file_name: fileName,
            healthcare_provider_ids: healthcareProvidersId,
            code,
            category,
            encounter,
            resource_type: resourceType,
            from_date: fromDate,
            to_date: toDate,
            recent_data: recentData,
          },
        },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    deleteDownloadMedicalRecord: builder.mutation({
      query: ({ accessToken, patientId, recordId }) => ({
        url: `patients/${patientId}/download_medical_reports/${recordId}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getAllergyCategoryComponents: builder.query({
      query: ({ accessToken, query }) => ({
        url: `FHIR/AllergyIntolerance/Components?${query}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getHealthRecordsList: builder.query({
      keepUnusedDataFor: 0.000000000003,
      query: ({ accessToken }) => ({
        url: "FHIR/HealthRecords",
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
  }),
});

export const {
  useGetPatientDashboardQuery,
  useUploadPatientMedicalRecordMutation,
  useGetPatientMedicalRecordQuery,
  useDeletePatientMedicalRecordMutation,
  useGetVitalSignsQuery,
  useGetAllergiesQuery,
  useGetImmunizationsQuery,
  useGetLabResultsQuery,
  useGetMedicalHistoriesQuery,
  useGetVitalSignsHealthcareProvidersQuery,
  useGetAllergiesHealthcareProvidersQuery,
  useGetImmunizationsHealthcareProvidersQuery,
  useGetLabHealthcareProvidersQuery,
  useGetMedicalHistoriesHealthcareProvidersQuery,
  useGetLabResultsEncounterTypesQuery,
  useGetVitalSignsEncounterTypesQuery,
  useGetMyDownloadsListQuery,
  useCreateDownloadMedicalReportsMutation,
  useDeleteDownloadMedicalRecordMutation,
  useGetAllergyCategoryComponentsQuery,
  useGetGlobalSearchListQuery,
  useGetHealthRecordsListQuery,
  useGetEpicDataMutation,
} = MedicalRecordApiSlice;
