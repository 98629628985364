import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Box, Button, Typography } from "@mui/material";
import moment from "moment";
import {
  CallIcon,
  DateOutlinedIcon,
  VideoCallIcon,
  ClockIcon,
} from "./UI/Icons";
import NoProfilePic from "assets/images/empty_pic.svg";
import ViewNotesPopup from "./Patient/ViewNotesPopup";
import mtz from "moment-timezone";

export default function AppointmentCard({
  id,
  name,
  appointmentDate,
  startTime,
  endTime,
  avatarUrl,
  unreadNotes,
  specializations,
  consultationId,
  appointmentType,
  callDisabled,
  status,
  type = "upcoming",
  navigationDisabled,
  showViewNoteButton = false,
  refetch,
  joinUrl,
  paymentUrl,
  appointmentTitle,
}) {
  const navigate = useNavigate();
  const [showViewNotesPopup, setShowViewNotesPopup] = useState(false);

  return (
    <>
      {showViewNotesPopup && (
        <ViewNotesPopup
          appointmentId={id}
          show={showViewNotesPopup}
          close={() => {
            refetch();
            setShowViewNotesPopup(false);
          }}
        />
      )}
      <Box
        sx={{
          position: "relative",
        }}
      >
        {status && (
          <Box
            sx={{
              position: "absolute",
              top: "-1.2rem",
              px: "2rem",
              py: ".1rem",
              right: 0,
              bgcolor:
                type === "upcoming"
                  ? "primary.main"
                  : status?.includes("Cancelled")
                    ? "error.main"
                    : "#FBCD88",
              zIndex: -1,
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            <Typography fontSize={12} color={"#fff"}>
              {status}
            </Typography>
          </Box>
        )}
        <Box
          component={navigationDisabled ? Box : Button}
          sx={{
            zIndex: 1,
            bgcolor: "#fff",
            width: "100%",
            p: "1rem",
            boxShadow: "4px 8px 16px 0px rgba(0, 0, 0, 0.16)",
            borderRadius: "5px",
            textAlign: "left",
            display: "flex",
            alignItems: "stretch",
            justifyContent: "space-between",
          }}
          onClick={() => {
            if (!navigationDisabled) {
              navigate(`/patient/my_appointments/${id}`);
            }
          }}
        >
          <Box
            sx={{
              display: "flex",
              "& > * + *": { ml: "1rem" },
              flex: 1,
            }}
          >
            <Box
              component={"img"}
              sx={{
                display: "block",
                width: "5rem",
                height: "7.75rem",
                objectFit: "cover",
                borderRadius: "5px",
              }}
              src={avatarUrl || NoProfilePic}
            />
            <Box
              sx={{
                maxWidth: "45ch",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography
                fontSize={12}
                fontWeight={700}
                sx={{ color: "text.primary" }}
              >
                {name}
              </Typography>
              <Typography
                fontSize={12}
                title={specializations?.join(", ")}
                sx={{
                  color: "#797979",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {specializations?.join(", ")}
              </Typography>
              <Typography fontSize={12} sx={{ color: "#797979" }}>
                Consultation ID:&nbsp;{consultationId}
              </Typography>
              <Typography fontSize={12} color={"text.primary"}>
                {appointmentTitle}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  "& > * + *": { ml: ".75rem" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    bgcolor: "#f5f5f5",
                    borderRadius: "99rem",
                    px: ".3rem",
                    py: ".15rem",
                  }}
                >
                  <Box
                    component={DateOutlinedIcon}
                    sx={{ "& path": { fill: (theme) => theme.palette.primary.main } }}
                  />
                  &nbsp;
                  <Typography
                    fontSize={10}
                    fontWeight={700}
                    color={"text.primary"}
                  >
                    {appointmentDate}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    bgcolor: "#f5f5f5",
                    borderRadius: "99rem",
                    px: ".3rem",
                    py: ".15rem",
                  }}
                >
                  <Box
                    component={ClockIcon}
                    sx={{ "& path": { fill: (theme) => theme.palette.primary.main } }}
                  />
                  &nbsp;
                  <Typography
                    fontSize={10}
                    fontWeight={700}
                    color={"text.primary"}
                  >
                    {moment.utc(startTime).local().format("hh:mm a")}
                    &nbsp;-&nbsp;
                    {moment.utc(endTime).local().format("hh:mm a")}&nbsp;
                    {mtz.tz(mtz.tz.guess()).format("z")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <Button
              variant="contained"
              disabled={callDisabled}
              sx={{
                height: "2.25rem",
                width: "2.25rem",
                p: 0,
                minWidth: 0,
              }}
              onClick={(evt) => {
                evt.stopPropagation();
                const aTag = document.createElement("a");
                aTag.href = joinUrl;
                aTag.target = "_blank";
                aTag.click();
              }}
            >
              {appointmentType !== "video" ? <CallIcon /> : <VideoCallIcon />}
            </Button>
            {paymentUrl && (
              <Button
                variant="contained"
                color="secondary"
                sx={{ width: "11.25rem", color: "#fff" }}
                onClick={() => window.location.assign(paymentUrl)}
              >
                Pay Now
              </Button>
            )}
            {showViewNoteButton && (
              <Box
                sx={{
                  bgcolor: "#f5f5f5",
                  borderRadius: "99rem",
                  position: "relative",
                }}
              >
                <Badge badgeContent={unreadNotes} color="error">
                  <Button
                    sx={{ borderRadius: "99rem" }}
                    onClick={(evt) => {
                      evt.stopPropagation();
                      setShowViewNotesPopup(true);
                    }}
                  >
                    <Typography fontSize={12}>View Notes</Typography>
                  </Button>
                </Badge>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
