import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "hooks/useAuth";
import { checkExistingUser } from "actions/auth";

export default function useExistingUser() {
  const { accessToken } = useAuth();
  const existingUser = useSelector((state) => state.auth.existingUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (existingUser.length === 0 || !existingUser.success) {
      accessToken && dispatch(checkExistingUser(accessToken));
    }
  }, [accessToken]);

  return { existingUser };
}
