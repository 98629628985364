import { Box } from "@mui/material";

export default function SpecialityCard({ img, title, id, setShowNoHcpPopup }) {
  const handleOnClick = () => {
    setShowNoHcpPopup(`/patient/dashboard/doctors_list/${id}`);
  };

  return (
    <Box
      sx={{
        width: "8.75rem",
        height: "8.75rem",
        borderRadius: "5px",
        padding: ".5rem",
        boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.15)",
        display: "grid",
        placeItems: "center",
        gap: ".5rem",
        cursor: "pointer",
        fontSize: "0.75rem",
        "&:hover": {
          transform: "scale(1.01)",
        },
        "&:active": {
          transform: "scale(0.99)",
        },
      }}
      onClick={handleOnClick}
    >
      <Box
        component="img"
        src={img}
        alt=""
        sx={{ height: "3.75rem", width: "3.75rem", borderRadius: "99rem" }}
      />
      <Box sx={{ textAlign: "center" }}>{title}</Box>
    </Box>
  );
}
