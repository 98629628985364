import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  Collapse,
  Typography,
} from "@mui/material";
import UnsavedDataPopup from "./UnsavedDataPopup";
import { useDispatch } from "react-redux";
import { setAnswers, setTAndCAgreed } from "store/intakeForm";
import { setShareReports } from "store/shareReports";
import { ChevronRight, ExpandLess } from "@mui/icons-material";
import {
  AllergyIcon,
  DashboardIcon,
  DoctorIcon,
  DownloadIcon2,
  EventIcon,
  HealthRecordIcon,
  ImmunizationsIcon,
  LabResultsIcon,
  MedicalHistoriesIcon,
  VitalSignsIcon,
} from "./Icons";
import useExistingUser from "hooks/useExistingUser";

const pathnames = {
  vitalSigns: "vital_signs",
  medicalHistories: "medical_histories",
  allergies: "allergies",
  labResults: "lab_reports",
  immunizations: "immunizations",
};

export default function PatientSidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { existingUser } = useExistingUser();
  const params = useParams();
  const dispatch = useDispatch();
  const currentPage = location.pathname.split("/")[3];

  const [showUnsavedDataPopup, setShowUnsavedDataPopup] = useState(false);
  const [toPath, setToPath] = useState("");

  const [isHealthRecordsExpanded, setIsHealthRecordsExpanded] = useState(false);

  useEffect(() => {
    if (
      [
        pathnames.allergies,
        pathnames.immunizations,
        pathnames.labResults,
        pathnames.medicalHistories,
        pathnames.vitalSigns,
      ].includes(currentPage)
    ) {
      setIsHealthRecordsExpanded(true);
    } else {
      setIsHealthRecordsExpanded(false);
    }
  }, [currentPage]);

  const handleNavigation = (path) => {
    const pathEndsWith =
      location.pathname.split("/")[location.pathname.split("/").length - 1];
    if (
      !params?.appointmentId &&
      (pathEndsWith === "share_records" ||
        pathEndsWith === "general_intake_form" ||
        pathEndsWith === "book_appointment")
    ) {
      setToPath(path);
      setShowUnsavedDataPopup(true);
    } else {
      navigate(path);
    }
  };

  const clearGeneralIntakeFormState = () => {
    dispatch(setTAndCAgreed(false));
    dispatch(setAnswers({}));
    dispatch(setShareReports({ doctorId: null, list: [], ids: [] }));
  };

  return (
    <Box sx={{ pt: "1rem" }}>
      <UnsavedDataPopup
        open={showUnsavedDataPopup}
        handleClose={() => setShowUnsavedDataPopup(false)}
        onCancel={() => setShowUnsavedDataPopup(false)}
        onConfirm={() => {
          clearGeneralIntakeFormState();
          setShowUnsavedDataPopup(false);
          navigate(toPath, { replace: true });
          setToPath("");
        }}
      />
      <List component={"nav"} sx={{ "& span": { fontSize: "14px" } }}>
        <ListItemButton
          onClick={() => handleNavigation("/patient/dashboard")}
          sx={
            location.pathname === "/patient/dashboard"
              ? {
                  background: (theme) => theme.palette.primary.main,
                  ":hover": {
                    background: (theme) => theme.palette.primary.light,
                  },
                }
              : null
          }
        >
          <ListItemIcon sx={{ minWidth: "32px !important" }}>
            {location.pathname === "/patient/dashboard" ? (
              <Box
                component={DashboardIcon}
                sx={{
                  "& path": { fill: "#FFFFFF" },
                }}
              />
            ) : (
              <DashboardIcon />
            )}
          </ListItemIcon>
          <Typography
            fontSize={14}
            sx={
              location.pathname === "/patient/dashboard"
                ? {
                    color: "#FFFFFF",
                    fontWeight: 700,
                  }
                : { color: "#838383" }
            }
          >
            Dashboard
          </Typography>
        </ListItemButton>
        <ListItemButton
          onClick={() => setIsHealthRecordsExpanded((prev) => !prev)}
        >
          <ListItemIcon sx={{ minWidth: "32px !important" }}>
            {isHealthRecordsExpanded ||
            [
              pathnames.allergies,
              pathnames.immunizations,
              pathnames.labResults,
              pathnames.medicalHistories,
              pathnames.vitalSigns,
            ].includes(currentPage) ? (
              <Box
                component={HealthRecordIcon}
                sx={{
                  "& path": { stroke: (theme) => theme.palette.primary.main },
                }}
              />
            ) : (
              <HealthRecordIcon />
            )}
          </ListItemIcon>
          <Typography
            fontSize={14}
            sx={
              isHealthRecordsExpanded ||
              [
                pathnames.allergies,
                pathnames.immunizations,
                pathnames.labResults,
                pathnames.medicalHistories,
                pathnames.vitalSigns,
              ].includes(currentPage)
                ? {
                    color: "primary.main",
                    fontWeight: 700,
                    width: "100%",
                  }
                : { color: "#838383", width: "100%" }
            }
          >
            Health Records
          </Typography>
          {isHealthRecordsExpanded ? (
            <ExpandLess sx={{ color: (theme) => theme.palette.primary.main }} />
          ) : (
            <ChevronRight
              sx={{
                color: [
                  pathnames.allergies,
                  pathnames.immunizations,
                  pathnames.labResults,
                  pathnames.medicalHistories,
                  pathnames.vitalSigns,
                ].includes(currentPage)
                  ? (theme) => theme.palette.primary.main
                  : "#838383",
              }}
            />
          )}
        </ListItemButton>
        <Collapse in={isHealthRecordsExpanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={
                location.pathname === "/patient/health_records/vital_signs"
                  ? {
                      background: (theme) => theme.palette.primary.main,
                      ":hover": {
                        background: (theme) => theme.palette.primary.light,
                      },
                      pl: 4,
                    }
                  : { pl: 4 }
              }
              onClick={() =>
                handleNavigation("/patient/health_records/vital_signs")
              }
            >
              <ListItemIcon sx={{ minWidth: "32px !important" }}>
                {currentPage === pathnames.vitalSigns ? (
                  <Box
                    component={VitalSignsIcon}
                    sx={{
                      "& path": {
                        stroke: "#FFFFFF",
                      },
                      "& ellipse": {
                        fill: "#FFFFFF",
                      },
                    }}
                  />
                ) : (
                  <VitalSignsIcon />
                )}
              </ListItemIcon>
              <Typography
                fontSize={14}
                sx={
                  currentPage === pathnames.vitalSigns
                    ? {
                        color: "#FFFFFF",
                        fontWeight: 700,
                      }
                    : { color: "#838383" }
                }
              >
                Vital Signs
              </Typography>
            </ListItemButton>
            <ListItemButton
              sx={
                location.pathname ===
                "/patient/health_records/medical_histories"
                  ? {
                      background: (theme) => theme.palette.primary.main,
                      ":hover": {
                        background: (theme) => theme.palette.primary.light,
                      },
                      pl: 4,
                    }
                  : { pl: 4 }
              }
              onClick={() =>
                handleNavigation("/patient/health_records/medical_histories")
              }
            >
              <ListItemIcon sx={{ minWidth: "32px !important" }}>
                {currentPage === pathnames.medicalHistories ? (
                  <Box
                    component={MedicalHistoriesIcon}
                    sx={{
                      "& path, & line": {
                        stroke: "#FFFFFF",
                      },
                    }}
                  />
                ) : (
                  <MedicalHistoriesIcon />
                )}
              </ListItemIcon>
              <Typography
                fontSize={14}
                sx={
                  currentPage === pathnames.medicalHistories
                    ? {
                        color: "#FFFFFF",
                        fontWeight: 700,
                      }
                    : { color: "#838383" }
                }
              >
                Medical Histories
              </Typography>
            </ListItemButton>
            <ListItemButton
              sx={
                location.pathname === "/patient/health_records/allergies"
                  ? {
                      background: (theme) => theme.palette.primary.main,
                      ":hover": {
                        background: (theme) => theme.palette.primary.light,
                      },
                      pl: 4,
                    }
                  : { pl: 4 }
              }
              onClick={() =>
                handleNavigation("/patient/health_records/allergies")
              }
            >
              <ListItemIcon sx={{ minWidth: "32px !important" }}>
                {currentPage === pathnames.allergies ? (
                  <Box
                    component={AllergyIcon}
                    sx={{
                      "& path": {
                        stroke: "#FFFFFF",
                      },
                      "& circle, & rect": {
                        fill: "#FFFFFF",
                      },
                    }}
                  />
                ) : (
                  <AllergyIcon />
                )}
              </ListItemIcon>
              <Typography
                fontSize={14}
                sx={
                  currentPage === pathnames.allergies
                    ? {
                        color: "#FFFFFF",
                        fontWeight: 700,
                      }
                    : { color: "#838383" }
                }
              >
                Allergies
              </Typography>
            </ListItemButton>
            <ListItemButton
              sx={
                location.pathname === "/patient/health_records/lab_reports"
                  ? {
                      background: (theme) => theme.palette.primary.main,
                      ":hover": {
                        background: (theme) => theme.palette.primary.light,
                      },
                      pl: 4,
                    }
                  : { pl: 4 }
              }
              onClick={() =>
                handleNavigation("/patient/health_records/lab_reports")
              }
            >
              <ListItemIcon sx={{ minWidth: "32px !important" }}>
                {currentPage === pathnames.labResults ? (
                  <Box
                    component={LabResultsIcon}
                    sx={{
                      "& path": {
                        stroke: "#FFFFFF",
                      },
                      "& > g > path, & > g > rect": {
                        fill: "#FFFFFF",
                      },
                    }}
                  />
                ) : (
                  <LabResultsIcon />
                )}
              </ListItemIcon>
              <Typography
                fontSize={14}
                sx={
                  currentPage === pathnames.labResults
                    ? {
                        color: "#FFFFFF",
                        fontWeight: 700,
                      }
                    : { color: "#838383" }
                }
              >
                Lab Results
              </Typography>
            </ListItemButton>
            <ListItemButton
              sx={
                location.pathname === "/patient/health_records/immunizations"
                  ? {
                      background: (theme) => theme.palette.primary.main,
                      ":hover": {
                        background: (theme) => theme.palette.primary.light,
                      },
                      pl: 4,
                    }
                  : { pl: 4 }
              }
              onClick={() =>
                handleNavigation("/patient/health_records/immunizations")
              }
            >
              <ListItemIcon sx={{ minWidth: "32px !important" }}>
                {currentPage === pathnames.immunizations ? (
                  <Box
                    component={ImmunizationsIcon}
                    sx={{
                      "& path": {
                        stroke: "#FFFFFF",
                      },
                      "& > g > path, & > g > rect": {
                        fill: "#FFFFFF",
                      },
                    }}
                  />
                ) : (
                  <ImmunizationsIcon />
                )}
              </ListItemIcon>
              <Typography
                fontSize={14}
                sx={
                  currentPage === pathnames.immunizations
                    ? {
                        color: "#FFFFFF",
                        fontWeight: 700,
                      }
                    : { color: "#838383" }
                }
              >
                Immunizations
              </Typography>
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButton
          id="my-appointments"
          onClick={() => handleNavigation("/patient/my_appointments")}
          sx={
            location.pathname === "/patient/my_appointments"
              ? {
                  background: (theme) => theme.palette.primary.main,
                  ":hover": {
                    background: (theme) => theme.palette.primary.light,
                  },
                }
              : null
          }
        >
          <ListItemIcon sx={{ minWidth: "32px !important" }}>
            {location.pathname === "/patient/my_appointments" ? (
              <Box
                component={EventIcon}
                sx={{
                  "& path": { stroke: "#FFFFFF" },
                }}
              />
            ) : (
              <Box
                component={EventIcon}
                sx={{ "& path": { stroke: "#838383" } }}
              />
            )}
          </ListItemIcon>
          <Typography
            fontSize={14}
            sx={
              location.pathname === "/patient/my_appointments"
                ? {
                    color: "#FFFFFF",
                    fontWeight: 700,
                  }
                : { color: "#838383" }
            }
          >
            My Appointments
          </Typography>
        </ListItemButton>
        <ListItemButton
          id="my-doctors"
          onClick={() => handleNavigation("/patient/my_doctors")}
          sx={
            location.pathname === "/patient/my_doctors"
              ? {
                  background: (theme) => theme.palette.primary.main,
                  ":hover": {
                    background: (theme) => theme.palette.primary.light,
                  },
                }
              : null
          }
        >
          <ListItemIcon sx={{ minWidth: "32px !important" }}>
            {location.pathname === "/patient/my_doctors" ? (
              <Box
                component={DoctorIcon}
                sx={{
                  "& > path": { stroke: "#FFFFFF" },
                  "& > g > path": {
                    fill: (theme) => "#FFFFFF",
                  },
                }}
              />
            ) : (
              <Box
                component={DoctorIcon}
                sx={{
                  "& > path": { stroke: "#838383" },
                  "& > g > path": { fill: "#838383" },
                }}
              />
            )}
          </ListItemIcon>
          <Typography
            fontSize={14}
            sx={
              location.pathname === "/patient/my_doctors"
                ? {
                    color: "#FFFFFF",
                    fontWeight: 700,
                  }
                : { color: "#838383" }
            }
          >
            My Doctors
          </Typography>
        </ListItemButton>
        {existingUser?.user?.features_status?.download_medical_report && (
          <ListItemButton
            id="my-downloads"
            onClick={() => handleNavigation("/patient/my_ehr_health_reports")}
            sx={
              location.pathname === "/patient/my_ehr_health_reports"
                ? {
                    background: (theme) => theme.palette.primary.main,
                    ":hover": {
                      background: (theme) => theme.palette.primary.light,
                    },
                  }
                : null
            }
          >
            <ListItemIcon sx={{ minWidth: "32px !important" }}>
              {location.pathname === "/patient/my_ehr_health_reports" ? (
                <Box
                  component={DownloadIcon2}
                  sx={{
                    "& > path": { stroke: "#FFFFFF" },
                    "& > g > path": {
                      fill: (theme) => "#FFFFFF",
                    },
                  }}
                />
              ) : (
                <Box
                  component={DownloadIcon2}
                  sx={{
                    "& > path": { stroke: "#838383" },
                    "& > g > path": { fill: "#838383" },
                  }}
                />
              )}
            </ListItemIcon>
            <Typography
              fontSize={14}
              sx={
                location.pathname === "/patient/my_ehr_health_reports"
                  ? {
                      color: "#FFFFFF",
                      fontWeight: 700,
                    }
                  : { color: "#838383" }
              }
            >
              My EHR Health Reports
            </Typography>
          </ListItemButton>
        )}
      </List>
    </Box>
  );
}
