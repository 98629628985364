import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { useSelector, useDispatch } from "react-redux";
import { checkExistingUser } from "actions/auth";
import Error404 from "Pages/Auth/Error404";

const SetUpPageRoute = ({ children }) => {
  const isExistingUser = useSelector((state) => state.auth.existingUser);
  const dispatch = useDispatch();

  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (isExistingUser.length === 0) {
      authState?.accessToken?.accessToken &&
        dispatch(checkExistingUser(authState?.accessToken?.accessToken));
    }
  }, [authState, oktaAuth]);

  return JSON.parse(sessionStorage.getItem("okta-token-storage"))?.accessToken
    ?.accessToken ? (
    isExistingUser?.length !== 0 ? (
      isExistingUser?.success ? (
        <Error404 />
      ) : (
        children
      )
    ) : (
      <Error404 />
    )
  ) : (
    <Navigate to="/login" />
  );
};

export default SetUpPageRoute;
