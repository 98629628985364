import { useEffect, useState } from "react";
import ReactJoyride, { STATUS } from "react-joyride";
import { useSetAppWalkthroughCompletionMutation } from "api/CommonApi";
import useAuth from "hooks/useAuth";
import useExistingUser from "hooks/useExistingUser";

const steps = [
  {
    target: "#my-dashboard",
    disableBeacon: true,
    content:
      "Have track of details like your Patients, and Upcoming appointments booked by the patients for consultation.",
  },
  {
    target: "#my-appointments",
    content:
      "View information on your Upcoming, In Progress, and Completed appointments.",
  },
  {
    target: "#profile-dropdown",
    content: "Access options like Profile, Help & Support, and more, etc.",
  },
];

export default function PracDashboardWalkthrough() {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();

  const [runWalkthrough, setRunWalkthrough] = useState(false);

  const [setAppWalkthroughCompletion] =
    useSetAppWalkthroughCompletionMutation();

  useEffect(() => {
    setRunWalkthrough(existingUser?.user?.web_app_walkthrough_status);
  }, [existingUser]);

  const handleJoyrideCallback = async ({ status }) => {
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunWalkthrough(false);
      await setAppWalkthroughCompletion({
        accessToken,
        userType: existingUser?.user?.type?.toLowerCase(),
        userId: existingUser?.user?.id,
      }).unwrap();
    }
  };
  return (
    <ReactJoyride
      callback={handleJoyrideCallback}
      spotlightClicks={false}
      disableCloseOnEsc
      disableOverlayClose
      continuous
      hideBackButton
      hideCloseButton
      run={runWalkthrough}
      scrollToFirstStep
      scrollOffset={80}
      spotlightPadding={0}
      showProgress
      showSkipButton
      steps={steps}
      styles={{
        options: {
          zIndex: 10000,
        },
      }}
    />
  );
}
