import { Box, Button, Typography } from "@mui/material";
import BasicModal from "components/UI/BasicModal";
import { WarningRounded } from "@mui/icons-material";

export default function NoHcpPopup({ show, close, onProceed }) {
  return (
    <BasicModal show={show} close={close} noClose={true} size="500px">
      <Box
        sx={{
          display: "grid",
          gap: 3,
          placeItems: "center",
          textAlign: "justify",
        }}
      >
        <Box sx={{ display: "flex", gap: ".5rem" }}>
          <WarningRounded color={"error"} />
          <Typography fontSize={18} fontWeight={700} color={"error.main"}>
            Attention
          </Typography>
        </Box>
        <Typography fontSize={14}>
          You will need to provide your medical report related to the condition
          to schedule an appointment with our Physicians.
        </Typography>
        <Typography fontSize={14}>
          Click "<b>Upload</b>" to upload medical report from your local device
          (or) We can connect to your healthcare provider and pull report for
          you. Click "<b>Connect Healthcare Provider</b>" to pull your medical
          health record from your provider.
        </Typography>
        <Typography fontSize={14}>
          If you don't have these documents to upload or cannot connect to your
          healthcare provider, no worries. Please collect the required documents
          and visit back to schedule an appointment.
        </Typography>
        <Button variant="contained" onClick={onProceed}>
          Ok
        </Button>
      </Box>
    </BasicModal>
  );
}
