import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import ProfileCircle from "assets/images/ProfileCircle.png";
import { BookmarkIconFilled } from "components/UI/Icons";
import { useNavigate } from "react-router-dom";
import { useRemoveBookmarkDoctorMutation } from "api/DoctorsApi";
import useAuth from "hooks/useAuth";
import useExistingUser from "hooks/useExistingUser";

export default function DoctorMiniCard({
  id,
  avatarUrl,
  name,
  showBookmarkButton,
  specializations,
  yearsOfExp,
}) {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();
  const navigate = useNavigate();

  const [unbookmardDoctor, { isLoading }] = useRemoveBookmarkDoctorMutation();

  const handleRemoveFromSavedDoctors = async (id) => {
    try {
      await unbookmardDoctor({
        accessToken,
        patientId: existingUser.user.id,
        practitionerId: id,
      }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        p: "0.625rem",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "5px",
        boxShadow: "4px 8px 16px 0px rgba(0, 0, 0, 0.16)",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Box
          component="img"
          src={avatarUrl || ProfileCircle}
          alt="profile picture"
          sx={{
            width: "4rem",
            height: "4.5rem",
            objectFit: avatarUrl ? "cover" : "contain",
            borderRadius: "5px",
            overflow: "hidden",
            bgcolor: "#f0f0f0",
          }}
        />
        <Box
          ml={2}
          mr={1}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
            {name}
          </Typography>
          <Box component={"div"} title={specializations?.join(", ")}>
            <Typography
              sx={{
                fontSize: "0.75rem",
                color: "#797979",
                maxWidth: "50ch",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {specializations?.join(", ")}
            </Typography>
          </Box>
          <Typography sx={{ fontSize: "0.75rem" }}>
            {yearsOfExp || 0} years of experience
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: showBookmarkButton ? "space-between" : "flex-end",
        }}
      >
        {showBookmarkButton &&
          (isLoading ? (
            <CircularProgress size={"1rem"} />
          ) : (
            <IconButton sx={{ p: 0 }}>
              <Box
                component={BookmarkIconFilled}
                sx={{
                  "& path": {
                    fill: (theme) => theme.palette.primary.main,
                  },
                }}
                onClick={() => handleRemoveFromSavedDoctors(id)}
              />
            </IconButton>
          ))}
        <Button
          sx={{ width: "7rem" }}
          variant="contained"
          onClick={() =>
            navigate(`/patient/dashboard/doctors_list/about_doctor/${id}`)
          }
        >
          Know More
        </Button>
      </Box>
    </Box>
  );
}
