import { Modal } from "reactstrap";
import { Box, Button } from "@mui/material";
import "Styles/DeleteAlert.css";

export default function DeleteAlertModal({
  message,
  show,
  close,
  handleDeleteMedicalRecord,
  buttonsDisabled,
}) {
  return (
    <Modal
      isOpen={show}
      toggle={close}
      centered={true}
      style={{ "--bs-modal-width": "330px" }}
    >
      <Box sx={{ px: "1rem", pt: "2.5rem", pb: "1.875rem" }}>
        <Box
          sx={{
            display: "grid",
            gap: "1.9375rem",
            placeItems: "center",
            fontWeight: "600",
          }}
        >
          <Box
            component={"span"}
            sx={{
              fontSize: "0.875rem",
              fontFamily: "Open Sans",
              color: "text.primary",
            }}
          >
            {message}
          </Box>
          <Box sx={{ display: "flex", gap: "2.5rem" }}>
            <Button
              disabled={buttonsDisabled}
              variant="outlined"
              sx={{ fontWeight: 700, width: "7.3125rem" }}
              onClick={close}
            >
              No
            </Button>
            <Button
              disabled={buttonsDisabled}
              variant="contained"
              sx={{ fontWeight: 700, width: "7.3125rem" }}
              onClick={handleDeleteMedicalRecord}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
