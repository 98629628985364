import { Box, Typography } from "@mui/material";
import BasicModal from "./UI/BasicModal";
import { InfoOutlined } from "@mui/icons-material";

export default function RescheduleInfoPopup({ show, hide }) {
  return (
    <BasicModal show={show} close={hide}>
      <Box sx={{ display: "grid", placeItems: "center", gap: 2 }}>
        <InfoOutlined color="primary" fontSize="large" />
        <Typography fontSize={14} textAlign={"center"}>
          You are trying to reschedule this appointment for the second time.
          Please contact our Support Team to proceed further
        </Typography>
      </Box>
    </BasicModal>
  );
}
