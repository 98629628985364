import { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  Box,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  Dialog,
} from "@mui/material";
import moment from "moment";
import ModalClose from "assets/images/ModalClose.svg";
import ReactDatePicker from "react-datepicker";
import DateLogo from "assets/images/date-logo.svg";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { KMedicalRecordType, KModalType } from "utilities/constants";
import {
  get_alleries_intolerance,
  get_histories,
  get_lab_test,
  get_vaccines,
  get_vtl_measurement,
} from "actions/medicalRecords";
import useAuth from "hooks/useAuth";
import "Styles/filterOptions.css";
import {
  useGetVitalSignsHealthcareProvidersQuery,
  useGetAllergiesHealthcareProvidersQuery,
  useGetImmunizationsHealthcareProvidersQuery,
  useGetMedicalHistoriesHealthcareProvidersQuery,
  useGetLabHealthcareProvidersQuery,
  useGetVitalSignsEncounterTypesQuery,
  useGetLabResultsEncounterTypesQuery,
  useGetAllergyCategoryComponentsQuery,
} from "api/MedicalRecordApi";
import queryString from "query-string";
import SearchIcon from "assets/images/SearchIcon.svg";
import { Close } from "@mui/icons-material";

export default function HealthRecordsMVPModal({
  show,
  close,
  type,
  code,
  setCode,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  modalType,
  setDataForDownload,
  setShowPatientConsentPopup,
  mostRecentDataChecked,
  setMostRecentDataChecked,
  hcps,
  setHcps,
  encounters,
  setEncounters,
  categories,
  setCategories,
}) {
  const { accessToken } = useAuth();
  const dispatch = useDispatch();

  const showEncounterType = [
    KMedicalRecordType.VITAL_SIGNS,
    KMedicalRecordType.LAB_RESULTS,
  ].includes(type);

  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);
  const [optionsForFilter, setOptionsForFilter] = useState([]);
  const [healthProviderList, setHealthProviderList] = useState([]);
  const [confirmHealthProviderList, setConfirmHealthProviderList] = useState(
    []
  );
  const [optionsForFilterLoading, setOptionsForFilterLoading] = useState(true);
  const [encounterTypes, setEncounterTypes] = useState({});
  const [showHealthcareProvidersSelect, setShowHealthcareProvidersSelect] =
    useState(false);
  const [selectAllHCPs, setSelectAllHCPs] = useState(false);
  const [selectedHCPsCount, setSelectedHCPsCount] = useState(0);
  const [componentsList, setComponentsList] = useState({});

  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const searchHCPRef = useRef(null);

  const [isCrctDateRange, setIsCrctDateRange] = useState(null);

  const { data: vitalSignsHCP,isLoading: vitalSignsLoading } = useGetVitalSignsHealthcareProvidersQuery(
    { accessToken },
    { refetchOnReconnect: true, skip: KMedicalRecordType.VITAL_SIGNS !== type }
  );
  const { data: labHCP,isLoading: labSignsLoading } = useGetLabHealthcareProvidersQuery(
    { accessToken },
    { refetchOnReconnect: true, skip: KMedicalRecordType.LAB_RESULTS !== type }
  );
  const { data: medicalHistoriesHCP,isLoading: medicalHistorySignsLoading } =
    useGetMedicalHistoriesHealthcareProvidersQuery(
      { accessToken },
      {
        refetchOnReconnect: true,
        skip: KMedicalRecordType.MEDICAL_HISTORIES !== type,
      }
    );
  const { data: immunizationsHCP, isLoading: immunizationSignsLoading } =
    useGetImmunizationsHealthcareProvidersQuery(
      { accessToken },
      {
        refetchOnReconnect: true,
        skip: KMedicalRecordType.IMMUNIZATIONS !== type,
      }
    );
  const { data: allergiesHCP, isLoading: allergieSignsLoading } = useGetAllergiesHealthcareProvidersQuery(
    { accessToken },
    { refetchOnReconnect: true, skip: KMedicalRecordType.ALLERGIES !== type }
  );

  const { data: vitalSignsET } = useGetVitalSignsEncounterTypesQuery(
    { accessToken },
    { refetchOnReconnect: true, skip: KMedicalRecordType.VITAL_SIGNS !== type }
  );
  const { data: labResultsET } = useGetLabResultsEncounterTypesQuery(
    { accessToken },
    { refetchOnReconnect: true, skip: KMedicalRecordType.LAB_RESULTS !== type }
  );

  const { data: componentsData } = useGetAllergyCategoryComponentsQuery(
    {
      accessToken,
      query: queryString.stringify({
        category: Object.keys(optionsForFilter)
          .filter((el) => optionsForFilter[el].checked)
          .join(","),
      }),
    },
    {
      refetchOnReconnect: true,
      skip: KMedicalRecordType.ALLERGIES !== type,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      const aDate = moment([
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
      ]);
      const bDate = moment([
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
      ]);
      if (aDate.diff(bDate, "days") > -180) {
        setIsCrctDateRange(true);
      } else {
        setIsCrctDateRange(false);
      }
    }
  }, [startDate, endDate]);

  useEffect(() => {
    let options = [];
    if (KMedicalRecordType.VITAL_SIGNS === type)
      options = vitalSignsHCP?.healthcare_providers;
    else if (KMedicalRecordType.MEDICAL_HISTORIES === type)
      options = medicalHistoriesHCP?.healthcare_providers;
    else if (KMedicalRecordType.ALLERGIES === type)
      options = allergiesHCP?.healthcare_providers;
    else if (KMedicalRecordType.LAB_RESULTS === type)
      options = labHCP?.healthcare_providers;
    else if (KMedicalRecordType.IMMUNIZATIONS === type)
      options = immunizationsHCP?.healthcare_providers;

    setHealthProviderList(
      options?.map(({ healthcare_provider_id, organization_name }) => ({
        value: healthcare_provider_id,
        label: organization_name,
        checked: hcps?.includes(healthcare_provider_id) || false,
      }))
    );
    setConfirmHealthProviderList(
      options?.map(({ healthcare_provider_id, organization_name }) => ({
        value: healthcare_provider_id,
        label: organization_name,
        checked: hcps?.includes(healthcare_provider_id) || false,
      }))
    );
  }, [
    vitalSignsHCP,
    allergiesHCP,
    medicalHistoriesHCP,
    immunizationsHCP,
    labHCP,
  ]);

  useEffect(() => {
    if (vitalSignsET?.success || labResultsET?.success) {
      if (KMedicalRecordType.VITAL_SIGNS === type && vitalSignsET?.encounters) {
        setEncounterTypes(
          Object.assign(
            {},
            ...vitalSignsET.encounters.map((el) => ({
              [el]: { checked: encounters?.includes(el) || false },
            }))
          )
        );
      } else if (
        KMedicalRecordType.LAB_RESULTS === type &&
        labResultsET?.encounters
      ) {
        setEncounterTypes(
          Object.assign(
            {},
            ...labResultsET.encounters.map((el) => ({
              [el]: { checked: encounters?.includes(el) || false },
            }))
          )
        );
      }
    } else {
      setEncounterTypes({});
    }
  }, [vitalSignsET, labResultsET]);

  useEffect(() => {
    if (componentsData?.success && componentsData?.components) {
      setComponentsList((prev) =>
        Object.assign(
          {},
          ...componentsData.components.map((el) => ({
            [el]: {
              checked:
                Object.keys(prev)
                  .filter((el) => prev[el]?.checked)
                  ?.includes(el) || false,
            },
          }))
        )
      );
    }
  }, [componentsData]);

  useEffect(() => {
    if (KMedicalRecordType.VITAL_SIGNS === type) {
      dispatch(get_vtl_measurement(accessToken))
        .then((res) => {
          setOptionsForFilter(
            Object.assign(
              {},
              ...res.payload.measurements.map((el) => ({
                [el]: { checked: code?.includes(el) || false },
              }))
            )
          );
          setOptionsForFilterLoading(false);
        })
        .catch(() => {
          setOptionsForFilterLoading(false);
        });
    } else if (KMedicalRecordType.MEDICAL_HISTORIES === type) {
      dispatch(get_histories(accessToken))
        .then((res) => {
          setOptionsForFilter(
            Object.assign(
              {},
              ...res.payload.histories.map((el) => ({
                [el]: { checked: code?.includes(el) || false },
              }))
            )
          );
          setOptionsForFilterLoading(false);
        })
        .catch(() => {
          setOptionsForFilterLoading(false);
        });
    } else if (KMedicalRecordType.ALLERGIES === type) {
      dispatch(get_alleries_intolerance(accessToken))
        .then((res) => {
          setOptionsForFilter(
            Object.assign(
              {},
              ...res.payload.categories.map((el) => ({
                [el]: { checked: categories?.includes(el) || false },
              }))
            )
          );
          setOptionsForFilterLoading(false);
        })
        .catch(() => {
          setOptionsForFilterLoading(false);
        });
    } else if (KMedicalRecordType.LAB_RESULTS === type) {
      dispatch(get_lab_test(accessToken))
        .then((res) => {
          setOptionsForFilter(
            Object.assign(
              {},
              ...res.payload.tests.map((el) => ({
                [el]: { checked: code?.includes(el) || false },
              }))
            )
          );
          setOptionsForFilterLoading(false);
        })
        .catch(() => {
          setOptionsForFilterLoading(false);
        });
    } else if (KMedicalRecordType.IMMUNIZATIONS === type) {
      dispatch(get_vaccines(accessToken))
        .then((res) => {
          setOptionsForFilter(
            Object.assign(
              {},
              ...res.payload.vaccines.map((el) => ({
                [el]: { checked: code?.includes(el) || false },
              }))
            )
          );
          setOptionsForFilterLoading(false);
        })
        .catch(() => {
          setOptionsForFilterLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (mostRecentDataChecked) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [mostRecentDataChecked]);

  useEffect(() => {
    return () => {
      if (modalType === KModalType.DOWNLOAD && setMostRecentDataChecked) {
        setMostRecentDataChecked(false);
      }
    };
  }, []);

  useEffect(() => {
    setSelectedHCPsCount(
      healthProviderList?.filter((el) => el.checked)?.length
    );
  }, [selectAllHCPs, healthProviderList]);

  const handleSelectAll = (checked) => {
    setHealthProviderList((prev) => prev?.map((el) => ({ ...el, checked })));
  };

  useEffect(() => {
    setSelectAllHCPs(
      healthProviderList?.filter((el) => el.checked)?.length ===
        healthProviderList?.length
    );
  }, [healthProviderList]);

  const heading = {
    [KMedicalRecordType.VITAL_SIGNS]: "Measurements",
    [KMedicalRecordType.ALLERGIES]: "Categories",
    [KMedicalRecordType.IMMUNIZATIONS]: "Vaccine",
    [KMedicalRecordType.MEDICAL_HISTORIES]: "Histories",
    [KMedicalRecordType.LAB_RESULTS]: "Test",
  };

  const handleDownloadMedicalReports = () => {
    const fDate = startDate?.getDate();
    const fMonth = startDate?.getMonth();
    const fYear = startDate?.getFullYear();

    const eDate = endDate?.getDate();
    const eMonth = endDate?.getMonth();
    const eYear = endDate?.getFullYear();

    const data = {
      fromDate:
        startDate === null ? undefined : `${fYear}-${fMonth + 1}-${fDate}`,
      toDate: endDate === null ? undefined : `${eYear}-${eMonth + 1}-${eDate}`,
      code:
        KMedicalRecordType.ALLERGIES === type
          ? Object.keys(componentsList).filter(
              (el) => componentsList[el].checked
            )
          : Object.keys(optionsForFilter).filter(
              (el) => optionsForFilter[el].checked
            ),
      category:
        KMedicalRecordType.ALLERGIES === type
          ? Object.keys(optionsForFilter).filter(
              (el) => optionsForFilter[el].checked
            )
          : [],
      recentData: mostRecentDataChecked,
      encounter: showEncounterType
        ? Object.keys(encounterTypes).filter((el) => encounterTypes[el].checked)
        : undefined,
      healthcareProvidersId: healthProviderList
        .filter((el) => el.checked)
        .map((el) => el.value),
    };
    setDataForDownload(data);
    close();
    setShowPatientConsentPopup(true);
  };

  return (
    <Dialog open={show} onClose={close}>
      <Box
        component="div"
        sx={{ fontFamily: "Open Sans", width: "470px" }}
        className="p-2 pb-4"
      >
        <div className="d-flex justify-content-between w-100">
          <span className="fw-bold text-lg text-black d-inline-block ms-3 mt-2">
            {modalType === KModalType.FILTER
              ? "Filter Reports"
              : "Download Reports"}
          </span>
          {!showHealthcareProvidersSelect && (
            <IconButton onClick={close}>
              <img src={ModalClose} alt="closebutton" />
            </IconButton>
          )}
        </div>
        <div className="px-3 mt-3">
          <div className="px-3 d-flex flex-column gap-3">
            {showHealthcareProvidersSelect ? (
              <Box sx={{ fontFamily: "Open Sans" }}>
                <Box
                  sx={{ display: "flex", gap: ".5rem" }}
                  className="text-black fw-bold mb-1"
                >
                  Select Healthcare Provider
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    color: "primary.main",
                  }}
                >
                  <FormControlLabel
                    sx={{ "& span": { fontSize: "0.875rem" }, mb: 0 }}
                    control={
                      <Checkbox
                        onChange={(evt) => {
                          handleSelectAll(evt.target.checked);
                          setSelectAllHCPs((prev) => !prev);
                        }}
                        checked={selectAllHCPs}
                      />
                    }
                    label={"Select all"}
                  />
                  <Box>({selectedHCPsCount}) selected</Box>
                </Box>
                <Box sx={{ position: "relative" }}>
                  <Box
                    component={"img"}
                    src={SearchIcon}
                    alt="SearchIcon"
                    onClick={() => {
                      searchHCPRef.current.focus();
                    }}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: ".5rem",
                      transform: "translateY(-50%)",
                      zIndex: 1,
                    }}
                  ></Box>
                  <Select
                    ref={searchHCPRef}
                    value={""}
                    inputId="aria-example-input"
                    placeholder="Search Healthcare Provider"
                    onMenuOpen={() => {}}
                    onMenuClose={() => {}}
                    name="aria-live-color"
                    onChange={(e) => {
                      const list = [
                        ...healthProviderList.filter(
                          (el) => el.value !== e.value
                        ),
                        { ...e, checked: true },
                      ];
                      setHealthProviderList(list);
                    }}
                    options={healthProviderList.filter((el) => !el.checked)}
                    className="select-provider-dropdown custom-dropdown mb-1"
                    styles={{
                      option: (styles, state) => ({
                        ...styles,
                        cursor: "pointer",
                      }),
                      control: (styles) => ({
                        ...styles,
                        cursor: "pointer",
                        paddingLeft: "30px",
                      }),
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    maxHeight: "60vh",
                    overflowY: "auto",
                    pl: ".75rem",
                  }}
                >
                  {healthProviderList
                    ?.sort((a, b) => a?.label?.localeCompare(b?.label))
                    ?.map((el) => (
                      <Box sx={{ display: "flex" }}>
                        <FormControlLabel
                          key={el.value}
                          sx={{
                            "& span": { fontSize: "0.875rem" },
                            mb: 0,
                          }}
                          control={
                            <Checkbox
                              onChange={(evt) => {
                                const list = [
                                  ...healthProviderList.filter(
                                    (item) => item.value !== el.value
                                  ),
                                  { ...el, checked: evt.target.checked },
                                ];
                                setHealthProviderList(list);
                              }}
                              checked={el.checked}
                            />
                          }
                          label={el.label}
                        />
                      </Box>
                    ))}
                </Box>
              </Box>
            ) : (
              <>
                <div className="">
                  <span className="text-black fw-bold mb-1 d-inline-block">
                    Healthcare Provider
                  </span>
                  <Box
                    sx={{
                      color: "#909090",
                      border: "1px solid #909090",
                      borderRadius: ".5rem",
                      px: "1rem",
                      py: ".5rem",
                      cursor: "pointer",
                      display: "flex",
                      gap: "12px",
                    }}
                    onClick={() => {
                      setConfirmHealthProviderList(healthProviderList);
                      setShowHealthcareProvidersSelect(true);
                    }}
                  >
                    <Box
                      component={"img"}
                      src={SearchIcon}
                      alt="SearchIcon"
                    ></Box>
                    Select Provider
                  </Box>
                  <Box sx={{ display: "grid", pl: "0.75rem" }}>
                    {healthProviderList
                      ?.filter((el) => el.checked)
                      ?.slice(0, 2)
                      ?.map((el) => (
                        <Box sx={{ display: "flex" }}>
                          <FormControlLabel
                            key={el.value}
                            sx={{ "& span": { fontSize: "0.875rem" }, mb: 0 }}
                            control={
                              <Checkbox
                                onChange={(evt) => {
                                  const list = [
                                    ...healthProviderList.filter(
                                      (item) => item.value !== el.value
                                    ),
                                    { ...el, checked: evt.target.checked },
                                  ];
                                  setHealthProviderList(list);
                                  setConfirmHealthProviderList(
                                    healthProviderList
                                  );
                                }}
                                checked={el.checked}
                              />
                            }
                            label={el.label}
                          />
                        </Box>
                      ))}
                  </Box>
                  {healthProviderList?.filter((el) => el.checked).length >
                    2 && (
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        sx={{ fontWeight: 700 }}
                        onClick={() => setShowHealthcareProvidersSelect(true)}
                      >
                        view more
                      </Button>
                    </Box>
                  )}
                </div>
                {optionsForFilterLoading && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                {Object.keys(optionsForFilter).length > 0 && (
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns:
                        KMedicalRecordType.ALLERGIES === type &&
                        KModalType.DOWNLOAD === modalType
                          ? "50% 50%"
                          : "1fr",
                    }}
                  >
                    <Box>
                      <span className="text-black fw-bold mb-1 d-inline-block">
                        {heading[type]}
                      </span>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns:
                            KMedicalRecordType.ALLERGIES === type &&
                            KModalType.DOWNLOAD === modalType
                              ? "1fr"
                              : "50% 50%",
                          maxHeight: "13.5rem",
                          overflowY: "auto",
                        }}
                      >
                        {Object.keys(optionsForFilter).map((el) => (
                          <Box
                            key={el}
                            sx={{
                              display: "flex",
                              gap: "0.25rem",
                              pl: "0.75rem",
                            }}
                          >
                            <FormControlLabel
                              sx={{ "& span": { fontSize: "0.875rem" }, mb: 0 }}
                              control={
                                <Checkbox
                                  onChange={() =>
                                    setOptionsForFilter((prev) => ({
                                      ...prev,
                                      [el]: { checked: !prev[el].checked },
                                    }))
                                  }
                                  checked={optionsForFilter[el].checked}
                                />
                              }
                              label={el}
                            />
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    {KMedicalRecordType.ALLERGIES === type &&
                      KModalType.DOWNLOAD === modalType &&
                      Object.keys(componentsList).length > 0 && (
                        <Box>
                          <span className="text-black fw-bold mb-1 d-inline-block">
                            Components
                          </span>
                          <Box
                            sx={{
                              maxHeight: "13.5rem",
                              overflowY: "auto",
                            }}
                          >
                            {Object.keys(componentsList).map((el) => (
                              <Box
                                key={el}
                                sx={{ display: "flex", pl: ".75rem" }}
                              >
                                <FormControlLabel
                                  sx={{
                                    "& span": { fontSize: "0.875rem" },
                                    mb: 0,
                                  }}
                                  control={
                                    <Checkbox
                                      onChange={() =>
                                        setComponentsList((prev) => ({
                                          ...prev,
                                          [el]: { checked: !prev[el]?.checked },
                                        }))
                                      }
                                      checked={componentsList[el].checked}
                                    />
                                  }
                                  label={el}
                                />
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      )}
                  </Box>
                )}
                {showEncounterType &&
                  Object.keys(encounterTypes).length > 0 && (
                    <div className="">
                      <span className="text-black fw-bold mb-1 d-inline-block">
                        Encounter Type
                      </span>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "50% 50%",
                        }}
                      >
                        {Object.keys(encounterTypes)?.map((el) => (
                          <Box key={el} sx={{ display: "flex", pl: ".75rem" }}>
                            <FormControlLabel
                              sx={{ "& span": { fontSize: "0.875rem" }, mb: 0 }}
                              control={
                                <Checkbox
                                  onChange={() =>
                                    setEncounterTypes((prev) => ({
                                      ...prev,
                                      [el]: { checked: !prev[el].checked },
                                    }))
                                  }
                                  checked={encounterTypes[el].checked}
                                />
                              }
                              label={el}
                            />
                          </Box>
                        ))}
                      </Box>
                    </div>
                  )}
                {KModalType.DOWNLOAD === modalType && (
                  <div className="">
                    <span className="text-black fw-bold mb-1 d-inline-block">
                      Recent Data
                    </span>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "50% 50%",
                        pl: "0.75rem",
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <FormControlLabel
                          sx={{ "& span": { fontSize: "0.875rem" }, mb: 0 }}
                          control={
                            <Checkbox
                              onChange={() =>
                                setMostRecentDataChecked((prev) => !prev)
                              }
                              checked={mostRecentDataChecked}
                            />
                          }
                          label={"Most Recent"}
                        />
                      </Box>
                    </Box>
                  </div>
                )}
                {!mostRecentDataChecked && (
                  <div className="">
                    <span className="text-black fw-bold mb-1 d-inline-block">
                      Date
                    </span>
                    <Box
                      sx={{
                        px: ".75rem",
                        display: "grid",
                        gridTemplateColumns: "50% 50%",
                        gap: ".5rem",
                      }}
                    >
                      <Box>
                        <Box sx={{ position: "relative" }}>
                          <Box
                            component={ReactDatePicker}
                            sx={{
                              border: "1px solid #b5b5b5",
                              width: "100%",
                              py: "0.75rem",
                              px: ".75rem",
                              borderRadius: ".75rem",
                            }}
                            disabled={mostRecentDataChecked}
                            ref={startDateRef}
                            showYearDropdown
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            placeholderText="From date"
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={endDate !== null ? endDate : new Date()}
                            scrollableYearDropdown
                            yearDropdownItemNumber={100}
                          />
                          {startDate !== null ? (
                            <Box
                              sx={{
                                position: "absolute",
                                right: ".75rem",
                                top: "50%",
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                              }}
                              onClick={() => setStartDate(null)}
                            >
                              <Close color="#272727" />
                            </Box>
                          ) : (
                            <Box
                              component="img"
                              sx={{
                                position: "absolute",
                                right: ".75rem",
                                top: "50%",
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                              }}
                              className="date-icon-filter"
                              src={DateLogo}
                              onClick={() => startDateRef.current.setFocus()}
                              alt="date-logo"
                            />
                          )}
                        </Box>
                      </Box>
                      <Box>
                        <Box sx={{ position: "relative" }}>
                          <Box
                            component={ReactDatePicker}
                            sx={{
                              border: "1px solid #b5b5b5",
                              width: "100%",
                              py: "0.75rem",
                              px: ".75rem",
                              borderRadius: ".75rem",
                            }}
                            disabled={mostRecentDataChecked}
                            ref={endDateRef}
                            showYearDropdown
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            placeholderText="To date"
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            maxDate={new Date()}
                            scrollableYearDropdown
                            yearDropdownItemNumber={100}
                          />
                          {endDate !== null ? (
                            <Box
                              sx={{
                                position: "absolute",
                                right: ".75rem",
                                top: "50%",
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                              }}
                              onClick={() => setEndDate(null)}
                            >
                              <Close color="#272727" />
                            </Box>
                          ) : (
                            <Box
                              component="img"
                              sx={{
                                position: "absolute",
                                right: ".75rem",
                                top: "50%",
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                              }}
                              className="date-icon-filter"
                              src={DateLogo}
                              onClick={() => endDateRef.current.setFocus()}
                              alt="date-logo"
                            />
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </div>
                )}
              </>
            )}
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: ".5rem",
              mt: ".75rem",
            }}
          >
            {showHealthcareProvidersSelect === true && (
              <>
                <Button
                  variant="outlined"
                  sx={{ width: "30%", fontWeight: 700 }}
                  onClick={() => {
                    setHealthProviderList(confirmHealthProviderList);
                    setShowHealthcareProvidersSelect(false);
                  }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  sx={{ width: "30%", fontWeight: 700 }}
                  onClick={() => {
                    setShowHealthcareProvidersSelect(false);
                    setConfirmHealthProviderList(healthProviderList);
                  }}
                >
                  Confirm
                </Button>
              </>
            )}
            {modalType === KModalType.FILTER
              ? showHealthcareProvidersSelect === false && (
                  <>
                    <Button
                      variant="outlined"
                      sx={{ width: "30%", fontWeight: 700 }}
                      onClick={() => {
                        setComponentsList((prev) =>
                          Object.assign(
                            {},
                            ...Object.keys(prev).map((el) => ({
                              [el]: { checked: false },
                            }))
                          )
                        );
                        setOptionsForFilter((prev) =>
                          Object.assign(
                            {},
                            ...Object.keys(prev).map((el) => ({
                              [el]: { checked: false },
                            }))
                          )
                        );
                        setEncounterTypes((prev) =>
                          Object.assign(
                            {},
                            ...Object.keys(prev).map((el) => ({
                              [el]: { checked: false },
                            }))
                          )
                        );
                        setStartDate(null);
                        setEndDate(null);
                        setHealthProviderList(
                          healthProviderList?.map((el) => ({
                            ...el,
                            checked: false,
                          }))
                        );
                      }}
                    >
                      Clear Filters
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ width: "30%", fontWeight: 700 }}
                      disabled={
                        startDate !== null && endDate !== null
                          ? false
                          : (startDate === null && endDate !== null) ||
                            (startDate !== null && endDate === null) ||
                            (startDate === null &&
                              endDate === null &&
                              !mostRecentDataChecked &&
                              Object.keys(encounterTypes)?.filter(
                                (el) => encounterTypes[el].checked
                              ).length <= 0 &&
                              Object.keys(optionsForFilter)?.filter(
                                (el) => optionsForFilter[el].checked
                              ).length <= 0 &&
                              Object.keys(componentsList)?.filter(
                                (el) => componentsList[el].checked
                              ).length <= 0 &&
                              healthProviderList
                                ?.filter((el) => el.checked)
                                .map((el) => el.value).length <= 0 &&
                              hcps?.length <= 0 &&
                              encounters?.length <= 0 &&
                              categories?.length <= 0 &&
                              code?.length <= 0 &&
                              fromDate === null &&
                              toDate === null) ||
                              vitalSignsLoading || 
                              allergieSignsLoading || 
                              labSignsLoading || 
                              medicalHistorySignsLoading || 
                              immunizationSignsLoading
                      }
                      onClick={() => {
                        setCode(
                          KMedicalRecordType.ALLERGIES === type
                            ? Object.keys(componentsList).filter(
                                (el) => componentsList[el].checked
                              )
                            : Object.keys(optionsForFilter).filter(
                                (el) => optionsForFilter[el].checked
                              )
                        );
                        setCategories(
                          KMedicalRecordType.ALLERGIES === type
                            ? Object.keys(optionsForFilter).filter(
                                (el) => optionsForFilter[el].checked
                              )
                            : []
                        );
                        setFromDate(startDate);
                        setToDate(endDate);
                        setHcps(
                          healthProviderList
                            ?.filter((el) => el.checked)
                            ?.map((el) => el.value)
                        );
                        setEncounters(
                          Object.keys(encounterTypes).filter(
                            (el) => encounterTypes[el].checked
                          )
                        );
                        close();
                      }}
                    >
                      Apply
                    </Button>
                  </>
                )
              : showHealthcareProvidersSelect === false && (
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {isCrctDateRange === false && (
                      <Box
                        sx={{ color: "red", mb: ".75rem", textAlign: "center" }}
                      >
                        Oops! Data download limit exceeded. Please adjust your
                        date range within 180 days.
                      </Box>
                    )}
                    <Button
                      variant="contained"
                      sx={{ width: "19.8125rem", mx: "auto", fontWeight: 700 }}
                      onClick={handleDownloadMedicalReports}
                      disabled={
                        isCrctDateRange === false ||
                        (startDate !== null && endDate !== null
                          ? false
                          : (startDate === null && endDate !== null) ||
                            (startDate !== null && endDate === null)) ||
                        (startDate === null &&
                          endDate === null &&
                          !mostRecentDataChecked &&
                          Object.keys(encounterTypes)?.filter(
                            (el) => encounterTypes[el].checked
                          ).length <= 0 &&
                          Object.keys(optionsForFilter)?.filter(
                            (el) => optionsForFilter[el].checked
                          ).length <= 0 &&
                          Object.keys(componentsList)?.filter(
                            (el) => componentsList[el].checked
                          ).length <= 0 &&
                          healthProviderList
                            ?.filter((el) => el.checked)
                            .map((el) => el.value).length <= 0)
                      }
                    >
                      Download Reports
                    </Button>
                  </Box>
                )}
          </Box>
        </div>
      </Box>
    </Dialog>
  );
}
