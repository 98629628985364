const { createSlice } = require("@reduxjs/toolkit");

const shareReportsSlice = createSlice({
  name: "shareReports",
  initialState: {
    fromShareReportsPage: false,
    reportsList: {
      doctorId: null,
      ids: [],
      list: [],
    },
  },
  reducers: {
    setShareReports: (state, action) => {
      state.reportsList = action.payload;
    },
    setFromShareReportsPage: (state, action) => {
      state.fromShareReportsPage = action.payload;
    },
  },
});

export const getShareReports = (state) => state.shareReports.reportsList;
export const getFromShareReportsPage = (state) =>
  state.shareReports.fromShareReportsPage;

export const { setShareReports, setFromShareReportsPage } =
  shareReportsSlice.actions;
export default shareReportsSlice.reducer;
