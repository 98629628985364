import { useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAuth from "hooks/useAuth";
import useExistingUser from "hooks/useExistingUser";
import { useGetConsultationNotesQuery } from "api/AppointmentsApi";
import BasicModal from "components/UI/BasicModal";
import moment from "moment/moment";
import PDFViewer from "./PDFViewer";

export default function AddConsultationNotesPopup({
  show,
  close,
  appointmentId,
}) {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();
  const navigate = useNavigate();

  const { data, isLoading, isFetching } = useGetConsultationNotesQuery(
    {
      accessToken,
      userType: existingUser?.user?.type?.toLowerCase() + "s",
      userId: existingUser?.user?.id,
      appointmentId,
    },
    { refetchOnMountOrArgChange: true, refetchOnReconnect: true }
  );

  const [pdfLink, setPdfLink] = useState("");
  const [showPdfPopup, setShowPdfPopup] = useState(false);

  return (
    <BasicModal show={show} close={close}>
      <Box sx={{ mt: "2rem" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            bgcolor: "#f6f6f6",
            p: ".5rem",
            borderRadius: "5px",
          }}
        >
          <Typography maxWidth={"70%"} fontSize={12}>
            Add another clinical note for your patient!
          </Typography>
          <Button
            variant={"contained"}
            onClick={() => navigate("add_clinical_notes")}
          >
            Add
          </Button>
        </Box>
        <Typography fontSize={14} fontWeight={700} mt={1}>
          Previous notes sent to the patient!
        </Typography>
        {isLoading || isFetching ? (
          <Box
            sx={{ height: "250px", display: "grid", placeContent: "center" }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            mt={1}
            sx={{
              display: "grid",
              alignItems: "start",
              gap: "1rem",
              maxHeight: "250px",
              overflowY: "auto",
            }}
          >
            {data?.consultation_note?.map((item) => (
              <Box
                key={item?.id}
                sx={{ p: "1rem", borderRadius: "5px", bgcolor: "#fafafa" }}
              >
                <Typography
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    mb: ".5rem",
                    display: "inline-flex",
                    cursor: "pointer",
                  }}
                  fontSize={12}
                  onClick={() => {
                    setPdfLink(item?.attachment_url);
                    setShowPdfPopup(true);
                  }}
                >
                  {item?.file_name}
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Typography fontSize={10}>
                    {moment
                      .utc(item?.created_time)
                      .local()
                      .format("MMMM, DD YYYY")}
                  </Typography>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Typography fontSize={10}>
                    {moment.utc(item?.created_time).local().format("hh:mm A")}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
      {showPdfPopup && pdfLink !== "" && (
        <PDFViewer
          show={showPdfPopup}
          close={() => setShowPdfPopup(false)}
          pdfLink={pdfLink}
        />
      )}
    </BasicModal>
  );
}
