import {
  SET_PRACTITIONERGET_REQUEST,
  SET_PRACTITIONERGET_SUCCESS,
  SET_PRACTITIONERGET_FAILURE,
  SET_PRACTITIONERPOST_REQUEST,
  SET_PRACTITIONERPOST_SUCCESS,
  SET_PRACTITIONERPOST_FAILURE,
  SET_SPECIALITY_REQUEST,
  SET_SPECIALITY_SUCCESS,
  SET_SPECIALITY_FAILURE,
} from "actions/practitionerAction";
const initialState = {
  loading: true,
  error: false,
  practitionerUpdated: false,
  practitionerRecords: [],
  speciality: [],
};

const practitionerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRACTITIONERGET_REQUEST: {
      return { ...state, loading: true };
    }

    case SET_PRACTITIONERGET_SUCCESS: {
      return { ...state, practitionerRecords: action.payload, loading: false };
    }

    case SET_PRACTITIONERGET_FAILURE: {
      return { ...state, loading: true };
    }

    case SET_PRACTITIONERPOST_REQUEST: {
      return { ...state, loading: true, practitionerUpdated: true };
    }

    case SET_PRACTITIONERPOST_SUCCESS: {
      return { ...state, loading: false, practitionerUpdated: false };
    }

    case SET_PRACTITIONERPOST_FAILURE: {
      return {
        ...state,
        loading: true,
        practitionerUpdated: true,
        error: action.payload.error,
      };
    }

    case SET_SPECIALITY_REQUEST: {
      return { ...state, loading: true };
    }

    case SET_SPECIALITY_SUCCESS: {
      return { ...state, speciality: action.payload, loading: false };
    }

    case SET_SPECIALITY_FAILURE: {
      return { ...state, loading: true, error: action.payload.error };
    }

    default:
      return state;
  }
};

export default practitionerReducer;
