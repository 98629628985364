import { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useCancelAppointmentMutation } from "api/AppointmentsApi";
import useAuth from "hooks/useAuth";
import useExistingUser from "hooks/useExistingUser";
import BasicModal from "components/UI/BasicModal";

export default function CancelAppointmentPopup({
  show,
  hide,
  appointmentId,
  gotoPage,
}) {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();

  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState("");

  const handleOnReasonChange = (value) => {
    setReason(value);
    if (value.length === 0) {
      setReasonError("this field cannot be empty");
    } else if (value.length > 512) {
      setReasonError("cannot exceed 512 characters");
    } else {
      setReasonError("");
    }
  };

  const [cancelAppointment, { isLoading: isCancelAppointmentLoading }] =
    useCancelAppointmentMutation();

  const handleCancelAppointment = async (evt) => {
    evt.preventDefault();

    if (reasonError) return;

    if (reason.length === 0) {
      setReasonError("this field cannot be empty");
      return;
    } else if (reason.length > 512) {
      setReasonError("cannot exceed 512 characters");
      return;
    }

    try {
      await cancelAppointment({
        accessToken,
        userType: existingUser?.user?.type?.toLowerCase() + "s",
        userId: existingUser?.user?.id,
        appointmentId: appointmentId,
        reason,
      }).unwrap();
      if (gotoPage) {
        gotoPage();
      }
      hide();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <BasicModal show={show} close={hide}>
      <Box component={"form"} mt={3} onSubmit={handleCancelAppointment}>
        {existingUser?.user?.type === "Patient" && (
          <Box
            borderRadius={2}
            mb={2}
            sx={{ bgcolor: (theme) => theme.palette.grey[100], p: 1 }}
          >
            <Typography mb={1}>Patient eligible for</Typography>
            <Box component={"ul"} mb={0}>
              <Box component={"li"}>
                <b>Full refund</b> - if cancelled before&nbsp;
                {process.env.REACT_APP_FULL_REFUND_TF || "24"}&nbsp;hours of the
                appointment time
              </Box>
              <Box component={"li"}>
                <b>No refund</b> - if cancelled within&nbsp;
                {process.env.REACT_APP_NO_REFUND_TF || "0 to 6"}&nbsp;hours of
                the appointment time
              </Box>
              <Box component={"li"}>
                <b>Partial refund</b> - if cancelled anytime between&nbsp;
                {process.env.REACT_APP_PARTIAL_REFUND_TF || "6 to 24"}
                &nbsp;hours of the appointment time
              </Box>
            </Box>
          </Box>
        )}
        <Typography fontSize={14} mb={1}>
          Why do you want to cancel this appointment?
        </Typography>
        <TextField
          autoFocus
          fullWidth
          multiline
          minRows={3}
          maxRows={3}
          value={reason}
          onChange={(evt) => handleOnReasonChange(evt.target.value)}
          error={Boolean(reasonError)}
          helperText={
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography fontSize={10}>{reasonError}</Typography>
              <Typography fontSize={10}>{reason.length}/512</Typography>
            </Box>
          }
        />
        <Box sx={{ display: "flex", mt: "1rem" }}>
          <Button
            fullWidth
            variant="outlined"
            disabled={isCancelAppointmentLoading}
            onClick={hide}
          >
            Cancel
          </Button>
          <Button
            fullWidth
            variant="contained"
            type="submit"
            sx={{ ml: "1rem" }}
            disabled={isCancelAppointmentLoading}
          >
            {isCancelAppointmentLoading ? (
              <CircularProgress size={"1.5rem"} />
            ) : (
              "Submit"
            )}
          </Button>
        </Box>
      </Box>
    </BasicModal>
  );
}
