import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";

import "Styles/Header.css";
import { useSelector, useDispatch } from "react-redux";
import { checkExistingUser } from "actions/auth";
import appConfig from "appConfig";
import useExistingUser from "hooks/useExistingUser";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import { HelpIcon, LogoutIcon, PersonIcon } from "components/UI/Icons";
import UnsavedDataPopup from "components/UI/UnsavedDataPopup";
import { setAnswers, setTAndCAgreed } from "store/intakeForm";
import { setShareReports } from "store/shareReports";
import FeatureComingSoon from "../UI/FeatureComingSoon";
/**
 * @author
 * @function Header
 **/

const Header = () => {
  const location = useLocation();
  const { existingUser } = useExistingUser();
  const [searchParams] = useSearchParams();
  const { authState, oktaAuth } = useOktaAuth();
  const [userName, setUserName] = useState("");
  const [userImage, setUserImage] = useState(null);
  const [showUnsavedDataPopup, setShowUnsavedDataPopup] = useState(false);
  const [toPath, setToPath] = useState("");
  const [pathEndsWith, setPathEndsWith] = useState("");

  const [type, setType] = useState(null);
  const dispatch = useDispatch();
  const logIn = useSelector((state) => state.auth.existingUser.success);

  const [anchorProfileMenuEl, setAnchorProfileMenuEl] = React.useState(null);
  const profileMenuOpen = Boolean(anchorProfileMenuEl);
  const handleProfileMenuClick = (event) => {
    setAnchorProfileMenuEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setAnchorProfileMenuEl(null);
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  useEffect(() => {
    if (
      !["share_records", "general_intake_form", "book_appointment"].includes(
        location.pathname.split("/").at(-1)
      )
    ) {
      sessionStorage.removeItem("intakeForm");
    }
    if (authState) {
      if (
        authState?.accessToken?.accessToken &&
        (location.state?.from || searchParams.get("refresh"))
      ) {
        dispatch(checkExistingUser(authState?.accessToken?.accessToken)).then(
          (res) => {
            setType(res.payload.user?.type);
            setUserImage(res.payload.user?.avatar_url);
            setUserName(res.payload.user?.full_name);
          }
        );
      } else if (existingUser?.user) {
        setType(existingUser?.user?.type);
        setUserImage(existingUser?.user?.avatar_url);
        setUserName(existingUser?.user?.full_name);
      }
    }
  }, [authState, location.pathname, dispatch]);

  useEffect(() => {
    setPathEndsWith(
      location.pathname.split("/")[location.pathname.split("/").length - 1]
    );
  }, []);

  function tog_center() {
    handleProfileMenuClose();
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  const [modal_center, setmodal_center] = useState(false);

  const navigate = useNavigate();

  const isCorsError = (err) =>
    err.name === "AuthApiError" &&
    !err.errorCode &&
    err.xhr.message === "Failed to fetch";

  const logout = async () => {
    localStorage.removeItem("isLoggedIn");

    const basename = window.location.origin + "/";
    try {
      await oktaAuth.signOut({ postLogoutRedirectUri: basename });
    } catch (err) {
      if (isCorsError(err)) {
        console.log(err);
      } else {
        throw err;
      }
    }
  };

  const clearGeneralIntakeFormState = () => {
    dispatch(setTAndCAgreed(false));
    dispatch(setAnswers({}));
    dispatch(setShareReports({ doctorId: null, list: [], ids: [] }));
  };

  const GoToDashBoard = () => {
    handleProfileMenuClose();
    if (
      pathEndsWith === "share_records" ||
      pathEndsWith === "general_intake_form"
    ) {
      setShowUnsavedDataPopup(true);
    } else {
      if (type === "Patient") {
        if (location.pathname === "/patient/dashboard") {
          window.location.reload(false);
        }
        navigate("/patient/dashboard");
      } else if (type === "Practitioner") {
        navigate("/doctor/dashboard");
      }
    }
  };

  const GoToPatientProfile = () => {
    handleProfileMenuClose();
    if (
      pathEndsWith === "share_records" ||
      pathEndsWith === "general_intake_form"
    ) {
      if (type === "Patient") {
        setToPath("/patient/profile");
      } else {
        setToPath("/doctor/profile");
      }
      setShowUnsavedDataPopup(true);
    } else {
      if (type === "Patient") {
        navigate("/patient/profile");
      } else {
        navigate("/doctor/profile");
      }
    }
  };

  const [featureComingSoon, setFeatureComingSoon] = useState(false);
  const goToHelpAndSupport = () => {
    setFeatureComingSoon(true);
    handleProfileMenuClose();
  };

  return (
    <React.Fragment>
      <FeatureComingSoon
        show={featureComingSoon}
        hide={() => setFeatureComingSoon(false)}
      />
      <UnsavedDataPopup
        open={showUnsavedDataPopup}
        handleClose={() => setShowUnsavedDataPopup(false)}
        onCancel={() => setShowUnsavedDataPopup(false)}
        onConfirm={() => {
          clearGeneralIntakeFormState();
          setShowUnsavedDataPopup(false);
          navigate(toPath, { replace: true });
          setToPath("");
        }}
      />
      <header id="page-topbar">
        <div className={"navbar-header pl-20 justify-content-between"}>
          <div className={"d-flex "} style={{ paddingLeft: "10px" }}>
            <Box
              component={"img"}
              sx={{
                cursor: logIn ? "pointer" : "default",
                maxWidth: "170px",
                maxHeight: "60px",
                display: "block",
              }}
              onClick={GoToDashBoard}
              src={appConfig.headericon}
              alt={`${appConfig.shortName} icon`}
            />
          </div>

          {authState?.accessToken?.accessToken && (
            <Box>
              <Button
                id="profile-dropdown"
                sx={{ color: "#272727" }}
                onClick={handleProfileMenuClick}
              >
                <Avatar src={userImage} />
                <Typography ml={1}>{userName}</Typography>
                <ArrowDropDown />
              </Button>
              <Menu
                anchorEl={anchorProfileMenuEl}
                open={profileMenuOpen}
                onClose={handleProfileMenuClose}
                sx={{ mt: "0.625rem", "& li": { py: ".75rem" } }}
              >
                {existingUser?.user && (
                  <MenuItem onClick={GoToPatientProfile}>
                    <Box
                      component={PersonIcon}
                      sx={{
                        "& path": {
                          stroke: (theme) => theme.palette.primary.main,
                        },
                      }}
                    />
                    <Typography sx={{ fontSize: "0.875rem", ml: "1rem" }}>
                      Profile
                    </Typography>
                  </MenuItem>
                )}
                {existingUser?.user && (
                  <MenuItem onClick={goToHelpAndSupport}>
                    <Box
                      component={HelpIcon}
                      sx={{
                        "& path": {
                          fill: (theme) => theme.palette.primary.main,
                        },
                      }}
                    />
                    <Typography sx={{ fontSize: "0.875rem", ml: "1rem" }}>
                      Help &amp; Support
                    </Typography>
                  </MenuItem>
                )}
                <MenuItem onClick={tog_center}>
                  <Box
                    component={LogoutIcon}
                    sx={{
                      "& path": {
                        stroke: (theme) => theme.palette.primary.main,
                      },
                    }}
                  />
                  <Typography sx={{ fontSize: "0.875rem", ml: "1rem" }}>
                    Sign out
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          )}
        </div>
      </header>
      <div>
        <Dialog open={modal_center} onClose={tog_center}>
          <Box sx={{ width: "475px" }} className="modal-body text-center p-30">
            <div className="mb-10">
              <img src={appConfig.icon} alt="logo" />
            </div>
            <p className="pb-20 modaltext">
              Are you sure you want to sign out?
            </p>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "1.25rem",
              }}
            >
              <Button
                variant="outlined"
                sx={{ width: "6.5625rem" }}
                onClick={() => {
                  setmodal_center(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{ width: "6.5625rem" }}
                onClick={logout}
              >
                OK
              </Button>
            </Box>
          </Box>
        </Dialog>
      </div>
    </React.Fragment>
  );
};

export default Header;
