import { useEffect, useState } from "react";
import { Box, Breadcrumbs, InputAdornment, TextField } from "@mui/material";
import _ from "lodash";

import { useGetMyDownloadsListQuery } from "api/MedicalRecordApi";

import useAuth from "hooks/useAuth";
import useExistingUser from "hooks/useExistingUser";

import DefaultLoader from "components/UI/DefaultLoader";
import MedicalRecordItem from "components/PatientDashboard/MedicalRecordItem";

import norecord from "assets/images/norecord.png";
import SearchIcon from "assets/images/SearchIcon.svg";
import { NavigateNext } from "@mui/icons-material";
import { BCPatientMyDownloads } from "components/UI/BreadcrumbsList";
import CPagination from "components/UI/CPagination";

const fileTypeExt = {
  "application/pdf": ".pdf",
  "images/png": ".png",
  "images/jpeg": ".jpeg",
};

export default function MyDownloadsListPage() {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();

  const [downloadsList, setDownloadsList] = useState([]);
  const [page, setPage] = useState(1);
  const [noOfEntries, setNoOfEntries] = useState(10);
  const [search, setSearch] = useState("");

  const {
    data: downloadsListData,
    isLoading,
    isFetching,
    refetch,
  } = useGetMyDownloadsListQuery({
    accessToken,
    patientId: existingUser.user.id,
    page,
    noOfEntries,
    search,
  });

  useEffect(() => {
    if (downloadsListData?.success) {
      setDownloadsList(downloadsListData?.reports);
    } else {
      setDownloadsList([]);
    }
  }, [downloadsListData]);

  useEffect(() => {
    setPage(1);
  }, [search]);

  useEffect(() => {
    setPage(1);
  }, [noOfEntries]);

  const handleInputChange = _.debounce((evt) => {
    setSearch(evt.target.value.trim());
  }, 300);

  return (
    <Box>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        {BCPatientMyDownloads}
      </Breadcrumbs>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          my: "1rem",
        }}
      >
        <Box
          sx={{
            fontWeight: 700,
            fontSize: "1.125rem",
            display: "flex",
            gap: ".5rem",
          }}
        >
          My EHR Health Reports&nbsp;
          {Boolean(downloadsListData?.download_medical_reports_count) &&
            `(${downloadsListData?.download_medical_reports_count})`}
        </Box>
        <Box>
          {downloadsListData?.total_count !== 0 && !isLoading && (
            <TextField
              placeholder="Search"
              onChange={(e) => {
                handleInputChange(e);
                setPage(1);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box component={"img"} src={SearchIcon} alt="" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              sx={{
                width: "21.6875rem",
                "& input": { p: ".5rem", fontSize: "0.875rem" },
              }}
            />
          )}
        </Box>
      </Box>
      {downloadsListData?.total_count !== 0 && !isLoading && (
        <Box sx={{ fontSize: "0.75rem", color: "#666", mb: "1rem" }}>
          *Click over the More option to Download or Delete the files.
        </Box>
      )}
      <Box sx={{ display: "grid", gap: "0.9375rem" }}>
        {isLoading || isFetching ? (
          <DefaultLoader height="calc(100vh - 354px )" />
        ) : downloadsList?.length > 0 ? (
          downloadsList?.map((item) => (
            <MedicalRecordItem
              key={item?.id}
              id={item?.id}
              fileUrl={item?.attachment_url}
              name={`${item?.file_name}${fileTypeExt[item?.file_type] || ""}`}
              uploadedDate={item?.created_at}
              onDeleteModalClose={() => {
                if (downloadsList.length <= 1 && page !== 1) {
                  setPage((prev) => prev - 1);
                } else {
                  refetch();
                }
              }}
            />
          ))
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center norecord-reports">
            <img src={norecord} className="no-rec-img" alt="no records"></img>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No Reports Found
            </Box>
          </div>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {!isFetching && (
          <CPagination
            page={page}
            perPage={noOfEntries}
            setPage={setPage}
            setPerPage={setNoOfEntries}
            totalCount={downloadsListData?.download_medical_reports_count}
          />
        )}
      </Box>
    </Box>
  );
}
