import { Box } from "@mui/material";
import { HealthcareDownloadIcon, FilterIcon } from "components/UI/Icons";
import useExistingUser from "hooks/useExistingUser";

const IconsInfo = () => {
  const { existingUser } = useExistingUser();

  return (
    <Box
      sx={{
        background: "#ECF4F7",
        borderRadius: "5px",
        padding: " 20px 18px",
        display: "flex",
        alignItems: "left",

        gap: { xs: "1rem", lg: "1.5rem" },
        fontSize: "0.75rem",
        color: "#272727",
      }}
    >
      <Box sx={{ display: "flex", gap: "0.25rem" }}>
        <Box sx={{ gap: "0.5rem", display: "flex" }}>
          <Box
            component={FilterIcon}
            sx={{
              width: "20px", height: "20px",
              "& path": {
                fill: (theme) => theme.palette.primary.main,
              }
            }}


          ></Box>
          <Box>
            Allows you to view only the required data from the fetched records.
          </Box>
        </Box>
        {existingUser?.user?.features_status?.download_medical_report && (
          <Box sx={{ gap: "0.5rem", display: "flex" }}>
            <Box
              component={HealthcareDownloadIcon}
              sx={{
                width: "20px", height: "20px",
                "& path": {
                  fill: (theme) => theme.palette.primary.main,
                }
              }}


            ></Box>
            <Box>
              Allows you to filter and download the required data from the
              fetched records.
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default IconsInfo;
