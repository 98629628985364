import { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useSelector } from "react-redux";

import { useUploadPatientMedicalRecordMutation } from "api/MedicalRecordApi";

import { toastr } from "react-redux-toastr";

import { toBase64 } from "utilities";

import "Styles/UploadPopUp.css";

import "Styles/Utilities.css";
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import BasicModal from "components/UI/BasicModal";

export default function MedicalReportUpload({
  show,
  close,
  setSelectedFile,
  setFileExt,
  selectedFile,
  fileExt,
}) {
  const [uploadBtnDisabled, setUploadBtnDisabled] = useState(false);
  const [isFileNameAllowed, setIsFileNameAllowed] = useState(false);
  const [uploadRequest, setUploadRequest] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [uploadPatientMedicalRecord] = useUploadPatientMedicalRecordMutation();

  const { user } = useSelector((state) => state.auth.existingUser);
  const { authState } = useOktaAuth();

  useEffect(() => {
    return () => {
      setFileExt("");
      setSelectedFile({ file: null, fileName: "" });
      uploadRequest?.abort();
    };
  }, []);

  useEffect(() => {
    if (selectedFile.fileName.trim() === "") {
      setErrorMsg("Enter a valid file name");
      setIsFileNameAllowed(true);
    } else {
      if (selectedFile.fileName?.length > 20) {
        setErrorMsg(" The file name should not exceed 20 characters");
        setIsFileNameAllowed(true);
      } else if (
        !(
          /^[-_0-9a-zA-Z ]+$/.test(selectedFile.fileName) &&
          selectedFile.fileName.length <= 20
        )
      ) {
        setIsFileNameAllowed(true);
        setErrorMsg(
          "Only alphanumeric, spaces, hyphens, and underscores are permitted"
        );
      } else {
        setIsFileNameAllowed(false);
        setErrorMsg("");
      }
    }
  }, [selectedFile?.fileName]);

  const handleUploadPatientMedicalRecordFile = async (evt) => {
    evt.preventDefault();
    if (!isFileNameAllowed) {
      setUploadBtnDisabled(true);
      try {
        const file = await toBase64(selectedFile?.file);
        const req = uploadPatientMedicalRecord({
          accessToken: authState?.accessToken?.accessToken,
          patientId: user.id,
          fileName: selectedFile.fileName.slice(0, 20).trim() + "." + fileExt,
          fileAsString: file,
          fileType: selectedFile.file.type,
        });
        setUploadRequest(req);
        const res = await req.unwrap();
        if (res.success) {
          toastr.success("Your file has been successfully uploaded!");
          setUploadBtnDisabled(false);
          close();
        } else {
          toastr.error(res?.message);
          setUploadBtnDisabled(false);
        }
      } catch (err) {
        if (err?.name !== "AbortError") {
          toastr.error("Medical Report upload failed");
        }
        setUploadBtnDisabled(false);
        close();
      }
    }
  };

  return (
    <BasicModal
      show={show}
      close={() => {
        uploadRequest?.abort();
        close();
      }}
      size="19.25rem"
    >
      <Box
        component="form"
        onSubmit={handleUploadPatientMedicalRecordFile}
        sx={{
          fontFamily: "Open Sans",
          mt: 2,
          px: 2,
          display: "grid",
          placeItems: "center",
          gap: 4,
        }}
      >
        <Box>
          <Box
            component="label"
            htmlFor="selected-file-name"
            sx={{ fontWeight: 700, mb: 0.5 }}
          >
            Enter file name
          </Box>
          <TextField
            type="text"
            placeholder="Enter file name"
            value={selectedFile.fileName}
            error={errorMsg !== ""}
            helperText={errorMsg}
            onChange={(evt) => {
              setSelectedFile((prev) => ({
                ...prev,
                fileName: evt.target.value,
              }));
            }}
            disabled={uploadBtnDisabled}
            id="selected-file-name"
            fullWidth
            autoFocus
          />
        </Box>
        <Button
          type="submit"
          variant="contained"
          sx={{ width: "14.125rem" }}
          disabled={uploadBtnDisabled || errorMsg !== ""}
        >
          {uploadBtnDisabled ? <CircularProgress size={"1.5rem"} /> : "Proceed"}
        </Button>
      </Box>
    </BasicModal>
  );
}
