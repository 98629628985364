import { Box, Button, IconButton, Popover } from "@mui/material";
import ModalClose from "assets/images/ModalClose.svg";
import MailIcon from "assets/images/mail-icon.svg";
import AppointmentIcon from "assets/images/appointment-icon.svg";

export default function ConsultPopup({
  open,
  anchorEl,
  handleClose,
  handleBookAppointmentClick,
  handleSendAMessage,
}) {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        mt: ".5rem",
        "& .MuiPopover-paper": { borderRadius: "15px" },
        "& .MuiModal-backdrop": { bgcolor: "rgb(0,0,0,0.5)" },
      }}
    >
      <Box
        sx={{
          p: "2rem",
          position: "relative",
          display: "flex",
          gap: "1.5rem",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: ".5rem", right: ".5rem", p: 0 }}
          onClick={handleClose}
        >
          <img src={ModalClose} alt="" />
        </IconButton>
        <Button
          sx={{
            bgcolor: "#faf6e5",
            height: "7.5rem",
            width: "7.5rem",
            borderRadius: "7px",
            display: "flex",
            flexDirection: "column",
            gap: ".25rem",
          }}
          onClick={handleSendAMessage}
        >
          <img src={MailIcon} alt="" />
          <span>Send a Message</span>
        </Button>
        <Button
          sx={{
            bgcolor: "#e5fbfb",
            height: "7.5rem",
            width: "7.5rem",
            borderRadius: "7px",
            display: "flex",
            flexDirection: "column",
            gap: ".25rem",
          }}
          onClick={handleBookAppointmentClick}
        >
          <img src={AppointmentIcon} alt="" />
          <span>Book Appointment</span>
        </Button>
      </Box>
    </Popover>
  );
}
