import { Box, Button, Typography } from "@mui/material";
import PlayStoreImg from "assets/images/playstore.png";
import AppStoreImg from "assets/images/appstore.png";
import appConfig from "appConfig";

export default function MobileApp() {
  const handleGotoStore = (to) => {
    window.location.assign(to);
  };

  return (
    <Box sx={{ minHeight: "100vh", display: "grid", placeItems: "center" }}>
      <Box
        sx={{
          maxWidth: "min(500px, 90%)",
          width: "100%",
          p: 2,
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          borderRadius: ".5rem",
          display: "grid",
          placeItems: "center",
          gap: "1.5rem",
        }}
      >
        <Box
          sx={{
            height: "5rem",
            width: "5rem",
            p: 1,
            borderRadius: ".5rem",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <Box
            component={"img"}
            src={appConfig.icon}
            height={"100%"}
            width={"100%"}
          />
        </Box>
        <Typography maxWidth={"20ch"} textAlign={"center"}>
          Download our mobile app to get seamless experience
        </Typography>
        <Box>
          <Button
            onClick={() =>
              handleGotoStore(process.env.REACT_APP_PLAY_STORE_LINK || "")
            }
          >
            <Box component={"img"} src={PlayStoreImg} />
          </Button>
          <Button
            onClick={() =>
              handleGotoStore(process.env.REACT_APP_APP_STORE_LINK || "")
            }
          >
            <Box component={"img"} src={AppStoreImg} />
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
