import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { Box, Button, Typography } from "@mui/material";
import _ from "lodash";
import FeatureComingSoon from "components/UI/FeatureComingSoon";
import MedicalRecordItem from "components/PatientDashboard/MedicalRecordItem";
import Card from "components/UI/Card";
import hospital from "assets/images/hospital.svg";
import hospitalDelete from "assets/images/hospitalDelete.svg";
import hospitalRefresh from "assets/images/hospitalRefresh.svg";
import Vitalsigns from "assets/images/Vitalsigns.png";
import Immunities from "assets/images/Immunities.png";
import Labresults from "assets/images/Labresults.png";
import Allergies from "assets/images/Allergies.png";
import Medicalhistories from "assets/images/MedicalHistories.png";
import "Styles/MedicalRecords.css";
import MedicalReportUpload from "components/HealthRecord/MedicalReportUpload";
import useAuth from "hooks/useAuth";
import {
  useGetPatientDashboardQuery,
  useGetGlobalSearchListQuery,
  useGetEpicDataMutation,
} from "api/MedicalRecordApi";
import DefaultLoader from "components/UI/DefaultLoader";

import DoctorSpecialityCard from "components/PatientDashboard/DoctorSpecialityCard";
import GlobalSearch from "components/PatientDashboard/GlobalSearch";

import { ChevronRight } from "@mui/icons-material";
import AppointmentCard from "components/AppointmentCard";
import ConsultedDoctorCard from "../../components/UI/ConsultedDoctorCard";
import NoHcpPopup from "components/NoHcpPopup";
import { useDispatch, useSelector } from "react-redux";
import useExistingUser from "hooks/useExistingUser";
import { getEpicAccessToken } from "actions/auth";

const UMR_SUPPORTED_FORMATS =
  process.env.REACT_APP_SUPPORTED_FORMAT_UMR || "application/pdf";

const PatientDashboard = () => {
  const navigate = useNavigate();
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const records = useSelector((state) => state.patientRecords.records);
  const epicData = useSelector((state) => state.auth.epic_access_token);
  const [postEpicRecords] = useGetEpicDataMutation();
  const [showNoHcpPopup, setShowNoHcpPopup] = useState("");
  const [showFeatureComingSoonModal, setShowFeatureComingSoonModal] =
    useState(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [fileExt, setFileExt] = useState("");
  const [selectedFile, setSelectedFile] = useState({
    file: null,
    fileName: "",
  });
  const [globalSearchText, setGlobalSearchText] = useState("");
  const handleInputChange = _.debounce((evt) => {
    setGlobalSearchText(evt.target.value.trim());
  }, 300);

  const handleClickAway = () => {
    setOpenGlobalSearchList(false);
  };

  const {
    data: patientDashboardInfo,
    isLoading: loading,
    refetch,
  } = useGetPatientDashboardQuery(
    { accessToken },
    { refetchOnMountOrArgChange: true, refetchOnReconnect: true }
  );
  const { data: globalSearchList, isFetching: isSearching } =
    useGetGlobalSearchListQuery(
      {
        accessToken,
        search: globalSearchText,
      },
      {
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
        skip: globalSearchText === "",
      }
    );

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");

    const code = searchParams?.get("code");
    if (code) {
      dispatch(
        getEpicAccessToken(
          code,
          localStorage.getItem("fhirAccessToken"),
          localStorage.getItem("fhirClientId")
        )
      ).then(() => {
        setSearchParams({});
        localStorage.removeItem("fhirAccessToken");
        localStorage.removeItem("fhirClientId");
      });
    }

    if (isLoggedIn === null) {
      localStorage.setItem("isLoggedIn", false);
    }
  }, []);

  useEffect(() => {
    if (
      records?.length === 0 &&
      accessToken &&
      existingUser?.user?.id &&
      epicData &&
      localStorage.getItem("providerId")
    ) {
      postEpicRecords({
        epicData,
        accessToken,
        patientId: existingUser?.user?.id,
      })
        .unwrap()
        .then((res) => {
          if (res?.success) {
            toastr.success(
              "Successfully connected with your healthcare provider"
            );
            localStorage.removeItem("providerId");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [existingUser, epicData]);

  const GoToProvider = () => {
    navigate("/patient/providers");
  };
  const [openGlobalSearchList, setOpenGlobalSearchList] = useState(false);
  const [globalSearchAnchorEl, setGlobalSearchAnchorEl] = useState(null);

  const handleGlobalSearchFocus = (event) => {
    setGlobalSearchAnchorEl(event.target);
    setOpenGlobalSearchList(true);
  };

  const isGlobalSearchListOpen =
    openGlobalSearchList && Boolean(globalSearchAnchorEl);
  const globalSearchId = isGlobalSearchListOpen ? "spring-popper" : undefined;

  useEffect(() => {
    if (selectedFile.file) {
      setShowUploadFileModal(true);
    }
  }, [selectedFile]);

  const RecordList = [
    {
      name: "Vital Signs",
      image: Vitalsigns,
      noOfRecords: patientDashboardInfo?.vital_signs,
      redirect: () => {
        navigate("/patient/health_records/vital_signs");
      },
    },
    {
      name: "Medical Histories",
      image: Medicalhistories,
      noOfRecords: patientDashboardInfo?.medical_histories,
      redirect: () => {
        navigate("/patient/health_records/medical_histories");
      },
    },
    {
      name: "Allergies",
      image: Allergies,
      noOfRecords: patientDashboardInfo?.allergy_intolerances,
      redirect: () => {
        navigate("/patient/health_records/allergies");
      },
    },

    {
      name: "Lab Results",
      image: Labresults,
      noOfRecords: patientDashboardInfo?.labs,
      redirect: () => {
        navigate("/patient/health_records/lab_reports");
      },
    },
    {
      name: "Immunizations",
      image: Immunities,
      noOfRecords: patientDashboardInfo?.immunizations,
      redirect: () => {
        navigate("/patient/health_records/immunizations");
      },
    },
  ];

  const limitErrorToast = () => {
    toastr.error("File size exceeds the maximum limit of 300 MB");
  };

  const handleFileUpload = (evt) => {
    if (evt.target.files[0]) {
      if (
        !UMR_SUPPORTED_FORMATS.split(",").includes(evt.target.files[0].type)
      ) {
        toastr.error(
          "Unsupported file format. The app supports only PDF, .jpg, .jpeg, and .png formats."
        );
      } else if (Math.round(evt.target?.files[0]?.size / 1024) < 307200) {
        setFileExt(evt.target.files[0].name.split(".").pop());
        setSelectedFile({
          file: evt.target.files[0],
          fileName: evt.target.files[0].name.split(".").slice(0, -1).join("."),
        });
      } else {
        limitErrorToast();
      }
      evt.target.value = "";
    }
  };

  return (
    <>
      <div>
        {loading ? (
          <DefaultLoader height="calc(100vh - 176px)" />
        ) : (
          <Box>
            <Box sx={{ mt: "1rem", display: "grid", gap: "1.25rem" }}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <GlobalSearch
                  handleClickAway={handleClickAway}
                  handleGlobalSearchFocus={handleGlobalSearchFocus}
                  handleInputChange={handleInputChange}
                  globalSearchAnchorEl={globalSearchAnchorEl}
                  isSearching={isSearching}
                  globalSearchList={globalSearchList}
                  globalSearchId={globalSearchId}
                  openGlobalSearchList={openGlobalSearchList}
                  globalSearchText={globalSearchText}
                  setShowNoHcpPopup={setShowNoHcpPopup}
                  connectedHCPLength={
                    patientDashboardInfo?.connected_healthcare_providers?.length
                  }
                />
              </Box>
              <Card>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: "1.875rem",
                  }}
                >
                  <Box sx={{ fontSize: "1.125rem", fontWeight: 700 }}>
                    Get a second opinion for your conditions from expert
                    Doctors!
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat( auto-fit, 6rem )",
                    columnGap: 0.5,
                    rowGap: 1.5,
                  }}
                >
                  {patientDashboardInfo?.specialities?.map((el) => (
                    <DoctorSpecialityCard
                      img={el.attachment_url}
                      title={el.display}
                      key={el.id}
                      id={el.id}
                      setShowNoHcpPopup={setShowNoHcpPopup}
                    />
                  ))}
                  <Button
                    id={"more-specialities"}
                    sx={{
                      position: "relative",
                      borderRadius: "99rem",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: "primary.main",
                      height: "4rem",
                      width: "4rem",
                      display: "grid",
                      placeContent: "center",
                      justifySelf: "center",
                      color: "primary.main",
                      cursor: "pointer",
                      mt: 0.25,
                    }}
                    onClick={() =>
                      navigate("/patient/dashboard/all_specialities")
                    }
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        borderRadius: "99rem",
                        bgcolor: "primary.light",
                        opacity: 0.1,
                      }}
                    />
                    More
                  </Button>
                </Box>
              </Card>
              {patientDashboardInfo?.appointments?.length !== 0 && (
                <Card>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography fontSize={18} fontWeight={700}>
                      Upcoming Appointments
                    </Typography>
                    {patientDashboardInfo?.total_count_of_appoinments > 2 && (
                      <Button
                        onClick={() => navigate("/patient/my_appointments")}
                      >
                        <Typography fontSize={14}>View All</Typography>
                      </Button>
                    )}
                  </Box>
                  <Box sx={{ display: "grid", gap: "1.75rem", mt: "1.25rem" }}>
                    {patientDashboardInfo?.appointments?.map((item) => (
                      <AppointmentCard
                        key={item?.id}
                        id={item?.id}
                        status={item?.tag_name}
                        appointmentType={item?.appointment_type}
                        avatarUrl={item?.avatar_url}
                        consultationId={item?.consultation_id}
                        appointmentDate={item?.appointment_date}
                        startTime={item?.start_time}
                        endTime={item?.end_time}
                        specializations={item?.specializations}
                        name={item?.practitioner_name}
                        unreadNotes={item?.comments_highlight}
                        joinUrl={item?.join_url}
                        appointmentTitle={item?.title}
                        callDisabled={!item?.join_url}
                      />
                    ))}
                  </Box>
                </Card>
              )}
              {patientDashboardInfo?.consulted_doctors?.length !== 0 && (
                <Card>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography fontSize={18} fontWeight={700}>
                      Doctors you have consulted
                    </Typography>
                    {patientDashboardInfo?.total_count_of_consulted_doctors >
                      3 && (
                      <Button
                        onClick={() =>
                          navigate("/patient/my_doctors", {
                            state: { tab: "consulted" },
                          })
                        }
                      >
                        <Typography fontSize={14}>View All</Typography>
                      </Button>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "grid",
                      gap: "1.5rem",
                      gridTemplateColumns: "repeat( auto-fit, 285px )",
                      mt: "1rem",
                    }}
                  >
                    {patientDashboardInfo?.consulted_doctors?.map((item) => (
                      <ConsultedDoctorCard
                        key={item?.id}
                        doctorName={item?.full_name}
                        img={item?.avatar_url}
                        bookmarked={item?.bookmarks}
                        specialization={item?.specializations?.join(", ")}
                        practitionerId={item?.id}
                      />
                    ))}
                  </Box>
                </Card>
              )}
              <Card>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: ".25rem",
                  }}
                >
                  <Box sx={{ fontSize: "1.125rem", fontWeight: 700 }}>
                    Connected Healthcare Provider
                  </Box>
                  <Button
                    id="HCP-CTA"
                    variant="contained"
                    sx={{ fontSize: "0.875rem" }}
                    onClick={GoToProvider}
                  >
                    Connect Healthcare Provider
                  </Button>
                </Box>

                {patientDashboardInfo?.connected_healthcare_providers
                  ?.length === 0 ? (
                  <Box sx={{ mt: "0.625rem" }}>
                    <Typography
                      sx={{ fontSize: "0.75rem", color: "#666", width: "65%" }}
                    >
                      You have no connections right now, click on "Add
                      Healthcare Provider" to connect to your healthcare
                      provider. You need your healthcare provider's Login ID and
                      Password to proceed.
                    </Typography>
                  </Box>
                ) : (
                  <>
                    <Box
                      sx={{ fontSize: "0.75rem", color: "#666", mb: ".5rem" }}
                    >
                      You need your healthcare provider's Login ID and Password
                      to proceed.
                    </Box>
                    <Box
                      sx={{
                        color: "#8e8e8e",
                        fontSize: "1rem",
                        mb: ".5rem",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Hospital Name</Typography>
                      <Typography sx={{ mr: "10px" }}>Actions</Typography>
                    </Box>

                    <Box sx={{ display: "grid", gap: "0.75rem" }}>
                      {patientDashboardInfo?.connected_healthcare_providers?.map(
                        (item) => (
                          <Box
                            sx={{
                              bgcolor: "#f9f9f9",
                              color: "primary.main",
                              p: "2px",
                              pl: "6px",
                              borderRadius: "0.3125rem",
                            }}
                            key={item?.id}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "1rem",
                                }}
                              >
                                <img src={hospital} alt="hospital-plus" />
                                {item.organization_name}
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "6px",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  sx={{ minWidth: 0, p: 0 }}
                                  onClick={() =>
                                    setShowFeatureComingSoonModal(true)
                                  }
                                >
                                  <Box
                                    component="img"
                                    src={hospitalDelete}
                                    alt="hospitalDelete"
                                  />
                                </Button>
                                <Button
                                  variant="contained"
                                  sx={{ minWidth: 0, p: 0 }}
                                  onClick={() =>
                                    setShowFeatureComingSoonModal(true)
                                  }
                                >
                                  <Box
                                    component={"img"}
                                    src={hospitalRefresh}
                                    alt="hospitalRefresh"
                                  />
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        )
                      )}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: ".5rem",
                      }}
                    >
                      <Button
                        onClick={() => {
                          setShowFeatureComingSoonModal(true);
                        }}
                      >
                        View More
                      </Button>
                    </Box>
                  </>
                )}
              </Card>

              <Card>
                <Typography fontSize={18} fontWeight={700}>
                  Health Records
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    rowGap: "1rem",
                    columnGap: "1.5rem",
                    gridTemplateColumns: "repeat( auto-fit, 16rem )",
                    mt: "1rem",
                  }}
                >
                  {RecordList.map((item) => {
                    return (
                      <Button
                        key={item?.name}
                        sx={{
                          width: "16rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: "10px",
                        }}
                        onClick={item?.redirect}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box
                            component={"img"}
                            src={item?.image}
                            sx={{ height: "66px", width: "66px" }}
                          />
                          <Box sx={{ ml: ".5rem", textAlign: "left" }}>
                            <Typography
                              color={"text.primary"}
                              fontSize={14}
                              fontWeight={700}
                            >
                              {item?.name}
                            </Typography>
                            <Typography fontSize={12} color="#838383">
                              {item?.noOfRecords}&nbsp;Records
                            </Typography>
                          </Box>
                        </Box>
                        <ChevronRight color="primary" sx={{ ml: ".5rem" }} />
                      </Button>
                    );
                  })}
                </Box>
              </Card>
              <Card>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: ".25rem",
                  }}
                >
                  <Box
                    sx={{
                      fontWeight: 700,
                      fontSize: "1.125rem",
                    }}
                  >
                    Medical Reports&nbsp;
                    {Boolean(
                      patientDashboardInfo?.upload_medical_reports_count
                    ) && (
                      <Box component={"span"}>
                        ({patientDashboardInfo?.upload_medical_reports_count})
                      </Box>
                    )}
                  </Box>
                  <Button
                    id="UMR-CTA"
                    variant="contained"
                    sx={{ p: 0, width: "118px" }}
                  >
                    <Box
                      component="label"
                      sx={{
                        m: 0,
                        py: "0.375rem",
                        cursor: "pointer",
                        width: "100%",
                      }}
                      htmlFor="upload-file-input-dashboard"
                    >
                      Upload
                    </Box>
                    <Box
                      component="input"
                      type="file"
                      sx={{ display: "none" }}
                      id="upload-file-input-dashboard"
                      accept={UMR_SUPPORTED_FORMATS}
                      onChange={handleFileUpload}
                    />
                  </Button>
                </Box>

                <Box sx={{ display: "grid", gap: ".25rem" }}>
                  <Box sx={{ fontSize: "0.75rem", color: "#666" }}>
                    Upload your prescriptions, diagnosis, scan reports, and test
                    results here.
                    <p>Supported file formats: Pdf, jpg, jpeg, and png.</p>
                  </Box>

                  <Box sx={{ display: "grid", gap: "1.25rem" }}>
                    {patientDashboardInfo?.upload_medical_reports_count > 0 &&
                      patientDashboardInfo?.upload_medical_reports?.map(
                        (report) => (
                          <MedicalRecordItem
                            key={report.id}
                            id={report.id}
                            name={report.file_name}
                            uploadedDate={report.created_at}
                            fileUrl={report.attachment_url}
                            onDeleteModalClose={refetch}
                          />
                        )
                      )}
                  </Box>
                  {patientDashboardInfo?.upload_medical_reports_count > 2 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: ".5rem",
                      }}
                    >
                      <Button
                        sx={{
                          color: "primary.main",
                          fontSize: "0.875rem",
                          fontWeight: 700,
                        }}
                        onClick={() =>
                          navigate("/patient/health_records/medical_reports")
                        }
                      >
                        View More
                      </Button>
                    </Box>
                  )}
                </Box>
              </Card>
            </Box>
          </Box>
        )}

        {showUploadFileModal && (
          <MedicalReportUpload
            show={showUploadFileModal}
            close={() => {
              setFileExt("");
              setSelectedFile({
                file: null,
                fileName: "",
              });
              setShowUploadFileModal(false);
            }}
            fileExt={fileExt}
            selectedFile={selectedFile}
            setFileExt={setFileExt}
            setSelectedFile={setSelectedFile}
          />
        )}

        {Boolean(showNoHcpPopup) && (
          <NoHcpPopup
            show={Boolean(showNoHcpPopup)}
            close={() => setShowNoHcpPopup("")}
            onProceed={() => {
              patientDashboardInfo?.connected_healthcare_providers_count > 0 ||
              patientDashboardInfo?.upload_medical_reports_count > 0
                ? navigate(showNoHcpPopup)
                : setShowNoHcpPopup("");
            }}
          />
        )}

        <FeatureComingSoon
          show={showFeatureComingSoonModal}
          hide={() => setShowFeatureComingSoonModal(false)}
        />
      </div>
    </>
  );
};

export default PatientDashboard;
