import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useGetPatientsIntakeFormQuery } from "../api/AppointmentsApi";
import useAuth from "../hooks/useAuth";
import useExistingUser from "../hooks/useExistingUser";
import { Link, Navigate, useParams } from "react-router-dom";
import { linkColor } from "../components/UI/BreadcrumbsList";
import { NavigateNext } from "@mui/icons-material";
import DefaultLoader from "../components/UI/DefaultLoader";

export default function PatientIntakeFormDetailsPage() {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();
  const params = useParams();
  const { data, isLoading, isError } = useGetPatientsIntakeFormQuery(
    {
      accessToken,
      userId: existingUser?.user?.id,
      appointmentId: params?.appointmentId,
    },
    { refetchOnMountOrArgChange: true, refetchOnReconnect: true }
  );

  const breadcrumbs = [
    <Link
      to={`/${
        existingUser?.user?.type === "Patient" ? "patient" : "doctor"
      }/my_appointments`}
    >
      <Typography fontSize={12} color={linkColor}>
        My Appointments
      </Typography>
    </Link>,
    <Link
      to={`/${
        existingUser?.user?.type === "Patient" ? "patient" : "doctor"
      }/my_appointments`}
      state={{ tab: "upcoming" }}
    >
      <Typography fontSize={12} color={linkColor}>
        Upcoming Appointments
      </Typography>
    </Link>,
    <Link
      to={`/${
        existingUser?.user?.type === "Patient" ? "patient" : "doctor"
      }/my_appointments/${params?.appointmentId}`}
    >
      <Typography fontSize={12} color={linkColor}>
        Appointment Details
      </Typography>
    </Link>,
    <Link
      to={`/${
        existingUser?.user?.type === "Patient" ? "patient" : "doctor"
      }/my_appointments/${params?.appointmentId}/patient_details`}
    >
      <Typography fontSize={12} color={linkColor}>
        Patient Details
      </Typography>
    </Link>,
  ];

  return isError ? (
    <Navigate to={"/page_not_found"} replace={true} />
  ) : isLoading ? (
    <DefaultLoader height={"calc(100vh - 70px - 60px)"} />
  ) : (
    <Box>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
      <Typography fontSize={18} fontWeight={700} mt={2}>
        Patient Details
      </Typography>
      <Container>
        <Box
          sx={{
            borderRadius: "10px",
            bgcolor: "#f6f6f6",
            p: "1rem",
            mt: "1rem",
          }}
        >
          <Typography fontWeight={700} mb={1}>
            Basic Information
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              alignItems: "start",
              columnGap: "3rem",
              rowGap: "1.75rem",
            }}
          >
            <UnderlinedText
              title={"First Name"}
              text={data?.patient?.first_name}
            />
            <UnderlinedText
              title={"Middle Name"}
              text={data?.patient?.middle_name}
            />
            <UnderlinedText
              title={"Last Name"}
              text={data?.patient?.last_name}
            />
            <UnderlinedText title={"Gender"} text={data?.patient?.gender} />
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "1rem",
              }}
            >
              <UnderlinedText
                title={"Date of birth"}
                text={data?.patient?.date_of_birth}
              />
              <UnderlinedText title={"Age"} text={data?.patient?.age} />
            </Box>
          </Box>
        </Box>
        <Box sx={{ p: "1rem" }}>
          {data?.intake_form_answers?.map((item) => (
            <Box sx={{ mt: "2.5rem" }}>
              <Typography fontWeight={700} mb={2}>
                {item?.title}
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: "3rem",
                }}
              >
                {item?.values?.map((el) => (
                  <Box>
                    <Typography fontSize={14}>{el?.question_name}</Typography>
                    <Box
                      sx={{
                        mt: ".5rem",
                        mb: ".75rem",
                        borderRadius: "5px",
                        px: "1rem",
                        py: ".5rem",
                        bgcolor: "#f6f6f6",
                      }}
                    >
                      <Typography
                        fontSize={14}
                        sx={{ color: "text.secondary" }}
                      >
                        {el?.question_answer}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
}

const UnderlinedText = ({ title, text }) => {
  return (
    <Box sx={{ borderBottom: "1px solid #ccc" }}>
      <Typography fontSize={10} sx={{ color: "text.secondary" }}>
        {title}
      </Typography>
      <Box sx={{ minHeight: "1.5rem" }}>
        <Typography fontSize={14}>{text}</Typography>
      </Box>
    </Box>
  );
};
