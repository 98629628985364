import { Box, Dialog, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import RACContent from "./RACContent";

export default function RefundAndCancellationsPopup({
  show,
  hide,
  showPatientConsent,
  doctor,
  contact,
}) {
  return (
    <Dialog open={show} onClose={hide} maxWidth="md">
      <IconButton
        onClick={hide}
        sx={{
          position: "absolute",
          top: ".5rem",
          right: ".5rem",
        }}
      >
        <Close
          sx={{
            borderRadius: "99rem",
            p: ".2rem",
            bgcolor: "#f1f1f1",
          }}
        />
      </IconButton>
      <Box
        sx={{
          mx: "auto",
          marginTop: "50px",
          py: 2,
          px: 6,
          overflowY: "auto",
        }}
      >
        <RACContent
          showPatientConsent={showPatientConsent}
          doctorName={doctor}
          contact={contact}
        />
      </Box>
    </Dialog>
  );
}
