import { createAction } from "redux-api-middleware";

export const SET_VITALSIGN_REQUEST = "SET_VITALSIGN_REQUEST";
export const SET_VITALSIGN_SUCCESS = "SET_VITALSIGN_SUCCESS";
export const SET_VITALSIGN_FAILURE = "SET_VITALSIGN_FAILURE";

export const SET_ALLERGY_REQUEST = "SET_ALLERGY_REQUEST";
export const SET_ALLERGY_SUCCESS = "SET_ALLERGY_SUCCESS";
export const SET_ALLERGY_FAILURE = "SET_ALLERGY_FAILURE";

export const SET_MEDICALHISTORY_REQUEST = "SET_MEDICALHISTORY_REQUEST";
export const SET_MEDICALHISTORY_SUCCESS = "SET_MEDICALHISTORY_SUCCESS";
export const SET_MEDICALHISTORY_FAILURE = "SET_MEDICALHISTORY_FAILURE";

export const SET_LAB_REQUEST = "SET_LAB_REQUEST";
export const SET_LAB_SUCCESS = "SET_LAB_SUCCESS";
export const SET_LAB_FAILURE = "SET_LAB_FAILURE";

export const SET_IMMUNITY_REQUEST = "SET_IMMUNITY_REQUEST";
export const SET_IMMUNITY_SUCCESS = "SET_IMMUNITY_SUCCESS";
export const SET_IMMUNITY_FAILURE = "SET_IMMUNITY_FAILURE";

export const SET_RECORDSCOUNT_REQUEST = "SET_RECORDSCOUNT_REQUEST";
export const SET_RECORDSCOUNT_SUCCESS = "SET_RECORDSCOUNT_SUCCESS";
export const SET_RECORDSCOUNT_FAILURE = "SET_RECORDSCOUNT_FAILURE";

export const GET_MEASUREMNT_LIST_REQUEST = "GET_MEASUREMNT_LIST_REQUEST";
export const GET_MEASUREMNT_LIST_SUCCESS = "GET_MEASUREMNT_LIST_SUCCESS";
export const GET_MEASUREMNT_LIST_FAILURE = "GET_MEASUREMNT_LIST_FAILURE";

export const GET_HISTORY_LIST_REQUEST = "GET_HISTORY_LIST_REQUEST";
export const GET_HISTORY_LIST_SUCCESS = "GET_HISTORY_LIST_SUCCESS";
export const GET_HISTORY_LIST_FAILURE = "GET_HISTORY_LIST_FAILURE";

export const GET_ALLERIES_LIST_REQUEST = "GET_ALLERIES_LIST_REQUEST";
export const GET_ALLERIES_LIST_SUCCESS = "GET_ALLERIES_LIST_SUCCESS";
export const GET_ALLERIES_LIST_FAILURE = "GET_ALLERIES_LIST_FAILURE";

export const GET_LAB_LIST_REQUEST = "GET_LAB_LIST_REQUEST";
export const GET_LAB_LIST_SUCCESS = "GET_LAB_LIST_SUCCESS";
export const GET_LAB_LIST_FAILURE = "GET_LAB_LIST_FAILURE";

export const GET_VACCIENS_LIST_REQUEST = "GET_VACCIENS_LIST_REQUEST";
export const GET_VACCIENS_LIST_SUCCESS = "GET_VACCIENS_LIST_SUCCESS";
export const GET_VACCIENS_LIST_FAILURE = "GET_VACCIENS_LIST_FAILURE";

export const SET_PATIENT_DASHBOARD_REQUEST = "SET_PATIENT_DASHBOARDT_REQUEST";
export const SET_PATIENT_DASHBOARD_SUCCESS = "SET_PATIENT_DASHBOARD_SUCCESS";
export const SET_PATIENT_DASHBOARD_FAILURE = "SET_PATIENT_DASHBOARD_FAILURE";

const BASE_URL = process.env.REACT_APP_API_URL;
export const vitalSignsRecord = (accessToken, groupedBy, url) => {
  return createAction({
    endpoint: url,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      SET_VITALSIGN_REQUEST,
      SET_VITALSIGN_SUCCESS,
      SET_VITALSIGN_FAILURE,
    ],
  });
};

export const allergyRecord = (accessToken, groupedBy, url) => {
  return createAction({
    endpoint: url,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    types: [SET_ALLERGY_REQUEST, SET_ALLERGY_SUCCESS, SET_ALLERGY_FAILURE],
  });
};

export const medicalHistoryRecord = (accessToken, groupedBy, url) => {
  return createAction({
    endpoint: url,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      SET_MEDICALHISTORY_REQUEST,
      SET_MEDICALHISTORY_SUCCESS,
      SET_MEDICALHISTORY_FAILURE,
    ],
  });
};
export const labRecord = (accessToken, groupedBy, url) => {
  return createAction({
    endpoint: url,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    types: [SET_LAB_REQUEST, SET_LAB_SUCCESS, SET_LAB_FAILURE],
  });
};

export const immunityRecord = (accessToken, groupedBy, url) => {
  return createAction({
    endpoint: url,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    types: [SET_IMMUNITY_REQUEST, SET_IMMUNITY_SUCCESS, SET_IMMUNITY_FAILURE],
  });
};

export const recordscount = (accessToken) => {
  let apiUrl = "FHIR/healthrecordsCount";

  return createAction({
    endpoint: BASE_URL + apiUrl,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      SET_RECORDSCOUNT_REQUEST,
      SET_RECORDSCOUNT_SUCCESS,
      SET_RECORDSCOUNT_FAILURE,
    ],
  });
};

export const get_vtl_measurement = (accessToken) => {
  let apiUrl = "FHIR/Observation/VitalSigns/Measurements";
  return createAction({
    endpoint: BASE_URL + apiUrl,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      GET_MEASUREMNT_LIST_REQUEST,
      GET_MEASUREMNT_LIST_SUCCESS,
      GET_MEASUREMNT_LIST_FAILURE,
    ],
  });
};

export const get_histories = (accessToken) => {
  let apiUrl = "FHIR/Condition/Histories";
  return createAction({
    endpoint: BASE_URL + apiUrl,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      GET_HISTORY_LIST_REQUEST,
      GET_HISTORY_LIST_SUCCESS,
      GET_HISTORY_LIST_FAILURE,
    ],
  });
};

export const get_alleries_intolerance = (accessToken) => {
  let apiUrl = "FHIR/AllergyIntolerance/Categories";
  return createAction({
    endpoint: BASE_URL + apiUrl,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      GET_ALLERIES_LIST_REQUEST,
      GET_ALLERIES_LIST_SUCCESS,
      GET_ALLERIES_LIST_FAILURE,
    ],
  });
};

export const patientDashboard = (accessToken) => {
  let apiUrl = "FHIR/Dashboard";
  return createAction({
    endpoint: BASE_URL + apiUrl,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      SET_PATIENT_DASHBOARD_REQUEST,
      SET_PATIENT_DASHBOARD_SUCCESS,
      SET_PATIENT_DASHBOARD_FAILURE,
    ],
  });
};

export const get_lab_test = (accessToken) => {
  let apiUrl = "FHIR/Observation/Lab/Tests";
  return createAction({
    endpoint: BASE_URL + apiUrl,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    types: [GET_LAB_LIST_REQUEST, GET_LAB_LIST_SUCCESS, GET_LAB_LIST_FAILURE],
  });
};

export const get_vaccines = (accessToken) => {
  let apiUrl = "FHIR/Immunization/Vaccines";
  return createAction({
    endpoint: BASE_URL + apiUrl,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      GET_VACCIENS_LIST_REQUEST,
      GET_VACCIENS_LIST_SUCCESS,
      GET_VACCIENS_LIST_FAILURE,
    ],
  });
};
