import ModalClose from "assets/images/ModalClose.svg";
import { Box, Dialog, IconButton, Typography } from "@mui/material";
import { Clock } from "./Icons";

export default function FeatureComingSoon({ show, hide }) {
  return (
    <Dialog open={show} onClose={hide}>
      <Box sx={{ position: "relative", width: "23.5rem" }}>
        <IconButton
          onClick={hide}
          sx={{ position: "absolute", top: ".5rem", right: ".5rem" }}
        >
          <Box component="img" src={ModalClose} />
        </IconButton>

        <Box
          sx={{
            px: "1.875rem",
            py: "3rem",
            display: "grid",
            placeItems: "center",
            gap: "1rem",
          }}
        >
          <Box
            component={Clock}
            sx={{ width: "3rem", height: "3rem", "& path": { fill: (theme) => theme.palette.primary.main } }}
          />
          <Typography fontSize={18}>This feature is coming soon!</Typography>
        </Box>
      </Box>
    </Dialog>
  );
}
