import { Box, Button, Typography } from "@mui/material";
import { useGetDashboardQuery } from "api/PractitionerApi";
import {
  CalendarAddFillIcon,
  CalendarClockIcon,
  MyPatientsIcon,
} from "components/UI/Icons";
import useAuth from "hooks/useAuth";
import useExistingUser from "hooks/useExistingUser";
import PractitionerAppointmentCard from "../components/PractitionerAppointmentCard";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import FeatureComingSoon from "../components/UI/FeatureComingSoon";

export default function PractitionerDashboard() {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();
  const navigate = useNavigate();
  const { data: dashboardData } = useGetDashboardQuery(
    {
      accessToken,
      userId: existingUser?.user?.id,
    },
    { refetchOnMountOrArgChange: true, refetchOnReconnect: true }
  );

  const [featureComingSoon, setFeatureComingSoon] = useState(false);

  return (
    <>
      <FeatureComingSoon
        show={featureComingSoon}
        hide={() => setFeatureComingSoon(false)}
      />
      <Box sx={{ p: { xs: "1rem .5rem", lg: "1.5rem" } }}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "2rem",
            mb: "2.5rem",
          }}
        >
          {[
            {
              icon: MyPatientsIcon,
              title: "My Patients",
              count: dashboardData?.patients_count || 0,
            },
            {
              icon: CalendarAddFillIcon,
              title: "Upcoming Appointments",
              count: dashboardData?.upcoming_appointments_count || 0,
            },
          ].map((item) => (
            <Box
              key={item.title}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                boxShadow: "4px 8px 16px 0px rgba(0, 0, 0, 0.16)",
                height: { xs: "5rem", lg: "7.5rem" },
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  px: { xs: ".75rem", lg: "2rem" },
                }}
              >
                <Box
                  component={item?.icon}
                  sx={{
                    "& path": { fill: (theme) => theme.palette.primary.main },
                  }}
                />
                <Typography
                  ml={2}
                  fontWeight={700}
                  sx={{ fontSize: { xs: "0.75rem", lg: "1rem" } }}
                >
                  {item?.title}
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: "#f5f5f5",
                  width: "5.125rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  fontSize={30}
                  fontWeight={700}
                  sx={{ color: "primary.main" }}
                >
                  {item?.count}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        {existingUser?.user?.features_status?.update_schedule && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: "2rem",
              borderRadius: "10px",
              bgcolor: "#f1fefe",
              mb: "2.5rem",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                component={CalendarClockIcon}
                sx={{
                  "& path": { fill: (theme) => theme.palette.primary.main },
                }}
              />
              <Typography ml={2}>My Schedule</Typography>
              <Typography fontSize={12} ml={2} sx={{ color: "text.secondary" }}>
                You can update the consultation schedule for your patients!
              </Typography>
            </Box>
            <Button
              variant="contained"
              onClick={() => navigate("/doctor/update_schedule")}
            >
              Update
            </Button>
          </Box>
        )}
        {dashboardData?.upcoming_appointments_count > 0 && (
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography fontSize={18} fontWeight={700}>
                Upcoming Appointments
              </Typography>
              {dashboardData?.upcoming_appointments_count > 2 && (
                <Button onClick={() => navigate("/doctor/my_appointments")}>
                  View All
                </Button>
              )}
            </Box>
            <Box sx={{ mt: "1.5rem", display: "grid", gap: "1.75rem" }}>
              {dashboardData?.upcoming_appointments?.map((item) => (
                <PractitionerAppointmentCard
                  key={item?.id}
                  id={item?.id}
                  title={item?.title}
                  status={item?.tag_name}
                  consultationId={item?.consultation_id}
                  appointmentType={item?.appointment_type}
                  date={item?.appointment_date}
                  endTime={item?.end_time}
                  startTime={item?.start_time}
                  patientName={item?.patient_name}
                  joinUrl={item?.join_url}
                  callDisabled={!item?.join_url}
                />
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
