import React, { useEffect } from "react";
import ProtectedRoute from "./Routes/ProtectedRoute";

import "./App.css";
import "./Styles/Utilities.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";

import { Route, useNavigate, Routes } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, LoginCallback } from "@okta/okta-react";
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";
import PractitionerSignUp from "./Pages/Auth/PractitionerSignUp";
import config from "./config";
import { OktaLogin } from "./Pages/OktaLogin";
import SetUserPage from "./Pages/Dashboard/SetUserPage";
import PatientProvider from "./Pages/Auth/PatientProvider";
import CheckAuthentication from "./Pages/Auth/CheckAuthentication";
import PatientMainLayout from "./Pages/Dashboard/PatientMainLayout";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import store from "./store/index";
import PatientEditProfile from "./Pages/Auth/PatientEditProfile";
import PatientView from "./Pages/Dashboard/PatientView";
import PractitionerView from "./Pages/Dashboard/PractitionerView";

import SetUpPageRoute from "Routes/setUpPageRoute";
import TermAndConditions from "Pages/StaticPage/TermsAndConditions";
import PrivacyPolicy from "Pages/StaticPage/PrivacyPolicy";
import Error404 from "Pages/Auth/Error404";
import AuthenticateRoute from "Routes/AuthenticateRoute";
import PatientDashboard from "Pages/MedicalRecords/PatientDashboard";
import VitalSign from "Pages/MedicalRecords/VitalSign";
import Allergy from "Pages/MedicalRecords/Allergy";
import Lab from "Pages/MedicalRecords/Lab";
import MedicalHistory from "Pages/MedicalRecords/MedicalHistory";
import Immunity from "Pages/MedicalRecords/Immunity";
import MedicalRecordsList from "Pages/MedicalRecords/MedicalRecordsList";
import MyDownloadsListPage from "Pages/MedicalRecords/MyDownloadsListPage";
import DoctorSpecialistsPage from "Pages/DoctorSpecialistsPage";
import DoctorPage from "Pages/DoctorPage";
import AllSpecialitiesPage from "Pages/AllSpecialitiesPage";
import ShareRecordsWithDoctorPage from "Pages/ShareRecordsWithDoctorPage";
import SavedDoctorsPage from "Pages/SavedDoctorsPage";
import GeneralIntakeFormPage from "Pages/GeneralIntakeForm";
import MyAppointmentsPage from "Pages/MyAppointmentsPage";
import BookAppointmentPage from "Pages/BookAppointmentPage";
import BookAppointmentSuccessPage from "Pages/BookAppointmentSuccessPage";
import AppointmentDetailsPage from "Pages/AppointmentDetailsPage";
import PractitionerDashboard from "Pages/PractitionerDashboard";
import PractitionerMainLayout from "components/Layouts/PractitionerMainLayout";
import PractitionerAppointmentDetailsPage from "Pages/PractitionerAppointmentDetailsPage";
import appConfig from "./appConfig";
import PatientIntakeFormDetailsPage from "./Pages/PatientIntakeFormDetailsPage";
import AddConsultationNotesPage from "./Pages/AddConsultationNotesPage";
import SlotBookedPage from "Pages/SlotBookedPage";
import UpdateSchedulePage from "Pages/UpdateSchedule";
import MobileApp from "Pages/MobileApp";
import { useMediaQuery } from "@mui/material";
import { theme } from "Styles/Theme";
import RefundAndCancellation from "Pages/StaticPage/RefundAndCancellation";

export const oktaAuthconfig = new OktaAuth(config.oidc);

function App() {
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const iconLink = document.createElement("link");
    iconLink.rel = "icon";
    iconLink.href = appConfig.icon;
    document.head.appendChild(iconLink);

    const appleIconLink = document.createElement("link");
    appleIconLink.rel = "apple-touch-icon";
    appleIconLink.href = appConfig.icon;
    document.head.appendChild(appleIconLink);

    document.querySelector("title").innerText = appConfig.name;
  }, []);

  useEffect(() => {
    const iconLink = document.createElement("link");
    iconLink.rel = "icon";
    iconLink.href = appConfig.icon;
    document.head.appendChild(iconLink);

    const appleIconLink = document.createElement("link");
    appleIconLink.rel = "apple-touch-icon";
    appleIconLink.href = appConfig.icon;
    document.head.appendChild(appleIconLink);

    document.querySelector("title").innerText = appConfig.name;
  }, []);

  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuthconfig, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  const triggerLogin = async () => {
    await oktaAuthconfig.signInWithRedirect();
  };

  const customAuthHandler = async () => {
    const previousAuthState =
      oktaAuthconfig.authStateManager.getPreviousAuthState();

    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      await triggerLogin();
    }
  };

  return mobileScreen &&
    window.location.pathname !== "/terms-and-conditions" &&
    window.location.pathname !== "/privacy-policy" &&
    window.location.pathname !== "/refund-and-cancellation" ? (
    <MobileApp />
  ) : (
    <Security
      oktaAuth={oktaAuthconfig}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <div className="App">
        <Provider store={store}>
          <div className="toast1">
            <ReduxToastr
              newestOnTop={false}
              preventDuplicates
              position="top-right"
              getState={(state) => state.toastr}
              progressBar
              closeOnToastrClick
              timeOut={5000}
              transitionIn="bounceIn"
              transitionOut="bounceOut"
            />
          </div>
        </Provider>
        <Routes>
          <Route
            path="/book_appointment_success"
            element={<BookAppointmentSuccessPage />}
          />
          <Route
            path="/slot_booked_successfully"
            element={<SlotBookedPage />}
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <CheckAuthentication />
              </ProtectedRoute>
            }
          />
          <Route element={<AuthenticateRoute userType={"Patient"} />}>
            <Route path="/patient/dashboard" element={<PatientMainLayout />}>
              <Route path="" element={<PatientDashboard />} />
              <Route
                path="all_specialities"
                element={<AllSpecialitiesPage />}
              />
              <Route
                path="doctors_list/:specialist"
                element={<DoctorSpecialistsPage />}
              />
              <Route
                path="doctors_list/:specialist/:id"
                element={<DoctorPage />}
              />
              <Route
                path="doctors_list/:specialist/:id/share_records"
                element={<ShareRecordsWithDoctorPage />}
              />
              <Route
                path="doctors_list/:specialist/:id/general_intake_form"
                element={<GeneralIntakeFormPage />}
              />
              <Route
                path="doctors_list/:specialist/:id/book_appointment"
                element={<BookAppointmentPage />}
              />
            </Route>
            <Route
              path="/patient/health_records"
              element={<PatientMainLayout />}
            >
              <Route path="vital_signs" element={<VitalSign />} />
              <Route path="allergies" element={<Allergy />} />
              <Route path="medical_histories" element={<MedicalHistory />} />
              <Route path="lab_reports" element={<Lab />} />
              <Route path="immunizations" element={<Immunity />} />
              <Route path="medical_reports" element={<MedicalRecordsList />} />
            </Route>
            <Route path="/patient/providers" element={<PatientProvider />} />
            <Route path="/patient/profile" element={<PatientView />} />
            <Route
              path="/patient/profile/edit"
              element={<PatientEditProfile />}
            />
            <Route path="/patient" element={<PatientMainLayout />}>
              <Route
                path="my_ehr_health_reports"
                element={<MyDownloadsListPage />}
              />
              <Route path="my_doctors" element={<SavedDoctorsPage />} />
              <Route path="my_appointments" element={<MyAppointmentsPage />} />
              <Route
                path="my_appointments/:appointmentId"
                element={<AppointmentDetailsPage />}
              />
              <Route
                path="my_appointments/:appointmentId/share_records"
                element={<ShareRecordsWithDoctorPage />}
              />
              <Route
                path="my_appointments/:appointmentId/book_appointment"
                element={<BookAppointmentPage />}
              />
            </Route>
          </Route>
          <Route element={<AuthenticateRoute userType={"Practitioner"} />}>
            <Route path="/doctor" element={<PractitionerMainLayout />}>
              <Route path="dashboard" element={<PractitionerDashboard />} />
              <Route path="my_appointments" element={<MyAppointmentsPage />} />
              <Route
                path="my_appointments/:appointmentId/patient_details"
                element={<PatientIntakeFormDetailsPage />}
              />
              <Route
                path="my_appointments/:appointmentId"
                element={<PractitionerAppointmentDetailsPage />}
              />
              <Route
                path="my_appointments/:appointmentId/add_clinical_notes"
                element={<AddConsultationNotesPage />}
              />
              <Route path="update_schedule" element={<UpdateSchedulePage />} />
            </Route>
            <Route
              path="/doctor/profile/edit"
              element={<PractitionerSignUp />}
            />
            <Route path="/doctor/profile" element={<PractitionerView />} />
          </Route>

          <Route
            path="/setuserpage"
            element={
              <SetUpPageRoute>
                <SetUserPage />
              </SetUpPageRoute>
            }
          />

          <Route path="/login/callback" element={<LoginCallback />} />
          <Route exact path="/login" element={<OktaLogin />} />
          <Route path="/terms-and-conditions" element={<TermAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/refund-and-cancellation"
            element={<RefundAndCancellation />}
          />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </div>
    </Security>
  );
}

export default App;
