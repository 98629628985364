import { createAction } from "redux-api-middleware";

export const SET_PRACTITIONERGET_REQUEST = "SET_PRACTITIONERGET_REQUEST";
export const SET_PRACTITIONERGET_SUCCESS = "SET_PRACTITIONERGET_SUCCESS";
export const SET_PRACTITIONERGET_FAILURE = "SET_PRACTITIONERGET_FAILURE";
export const SET_SPECIALITY_REQUEST = "SET_SPECIALITY_REQUEST";
export const SET_SPECIALITY_SUCCESS = "SET_SPECIALITY_SUCCESS";
export const SET_SPECIALITY_FAILURE = "SET_SPECIALITY_FAILURE";
export const SET_PRACTITIONERPOST_REQUEST = "SET_PRACTITIONERPOST_REQUEST";
export const SET_PRACTITIONERPOST_SUCCESS = "SET_PRACTITIONERPOST_SUCCESS";
export const SET_PRACTITIONERPOST_FAILURE = "SET_PRACTITIONERPOST_FAILURE";

const BASE_URL = process.env.REACT_APP_API_URL;

export const practitionerGetRecords = (accessToken, id) => {
  let apiUrl = "practitioners/";
  let index = id;

  return createAction({
    endpoint: BASE_URL + apiUrl + index,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      SET_PRACTITIONERGET_REQUEST,
      SET_PRACTITIONERGET_SUCCESS,
      SET_PRACTITIONERGET_FAILURE,
    ],
  });
};

export const practitionerPostRecords = (accessToken, id, data) => {
  let apiUrl = "practitioners/";
  let value = JSON.stringify(data);
  return createAction({
    endpoint: BASE_URL + apiUrl + id,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: value,

    types: [
      SET_PRACTITIONERPOST_REQUEST,
      SET_PRACTITIONERPOST_SUCCESS,
      SET_PRACTITIONERPOST_FAILURE,
    ],
  });
};

export const specialityRecords = (accessToken) => {
  let apiUrl = "specialities";
  return createAction({
    endpoint: BASE_URL + apiUrl,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      SET_SPECIALITY_REQUEST,
      SET_SPECIALITY_SUCCESS,
      SET_SPECIALITY_FAILURE,
    ],
  });
};
