import { useSetAppWalkthroughCompletionMutation } from "api/CommonApi";
import useAuth from "hooks/useAuth";
import useExistingUser from "hooks/useExistingUser";
import { useEffect, useState } from "react";
import ReactJoyride, { STATUS } from "react-joyride";

const steps = [
  {
    target: "#profile-dropdown",
    disableBeacon: true,
    content: "Access options like Profile, Help & Support, and more, etc.",
  },
  {
    target: "#HCP-CTA",
    content:
      "Connect with your Healthcare Providers to fetch your health information.",
  },
  {
    target: "#UMR-CTA",
    content:
      "Upload medical records from your local device into the application.",
  },
  {
    target: "#speciality-card",
    content:
      "Choose any specialty to get a second opinion consultation from our expert doctors!",
  },
  {
    target: "#more-specialities",
    content:
      "View the list of All Specialties to get a second opinion consultation from our expert doctors!",
  },
  {
    target: "#global-search",
    content: "A quick search for looking into a specific specialty or doctor.",
  },
  {
    target: "#my-appointments",
    content:
      "View information on your Upcoming, In Progress, and Completed appointments!",
  },
  {
    target: "#my-doctors",
    content: "View your list of Saved and consulted Doctors.",
  },
];

export default function PatientAppWalkthrough() {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();

  const [runWalkthrough, setRunWalkthrough] = useState(false);

  const [setAppWalkthroughCompletion] =
    useSetAppWalkthroughCompletionMutation();

  useEffect(() => {
    setRunWalkthrough(existingUser?.user?.web_app_walkthrough_status);
  }, [existingUser]);

  const handleJoyrideCallback = async ({ status }) => {
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunWalkthrough(false);
      await setAppWalkthroughCompletion({
        accessToken,
        userType: existingUser?.user?.type?.toLowerCase(),
        userId: existingUser?.user?.id,
      }).unwrap();
    }
  };

  return (
    <ReactJoyride
      callback={handleJoyrideCallback}
      spotlightClicks={false}
      disableCloseOnEsc
      disableOverlayClose
      continuous
      hideBackButton
      hideCloseButton
      run={runWalkthrough}
      scrollToFirstStep
      scrollOffset={80}
      spotlightPadding={0}
      showProgress
      showSkipButton
      steps={steps}
      styles={{
        options: {
          zIndex: 10000,
        },
      }}
    />
  );
}
