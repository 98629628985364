import { useState } from "react";
import { NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs } from "@mui/material";
import { useGetSpecialitiesListQuery } from "api/DoctorsApi";
import SpecialityCard from "components/AllSpecialities/SpecialityCard";
import { BCPatientAllSpecialities } from "components/UI/BreadcrumbsList";
import DefaultLoader from "components/UI/DefaultLoader";
import useAuth from "hooks/useAuth";
import { Navigate, useNavigate } from "react-router-dom";
import NoHcpPopup from "components/NoHcpPopup";

export default function AllSpecialitiesPage() {
  const { accessToken } = useAuth();
  const navigate = useNavigate();
  const [showNoHcpPopup, setShowNoHcpPopup] = useState("");

  const {
    data: specialitiesListData,
    isLoading,
    isFetching,
    isError,
  } = useGetSpecialitiesListQuery(
    { accessToken },
    { refetchOnReconnect: true }
  );

  return isError ? (
    <Navigate to="/page_not_found" />
  ) : (
    <Box sx={{ fontFamily: "Open Sans" }}>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        {BCPatientAllSpecialities}
      </Breadcrumbs>
      <Box sx={{ fontSize: "1.125rem", fontWeight: 700, mt: "1rem" }}>
        All Specialities
      </Box>
      {isLoading || isFetching ? (
        <DefaultLoader />
      ) : (
        <Box
          sx={{
            mt: "1.5rem",
            display: "flex",
            flexWrap: "wrap",
            gap: "1.25rem",
          }}
        >
          {specialitiesListData?.specialities?.map((el) => (
            <SpecialityCard
              key={el?.id}
              id={el?.id}
              img={el?.attachment_url}
              title={el?.display}
              setShowNoHcpPopup={setShowNoHcpPopup}
            />
          ))}
        </Box>
      )}
      {Boolean(showNoHcpPopup) && (
        <NoHcpPopup
          show={Boolean(showNoHcpPopup)}
          close={() => setShowNoHcpPopup("")}
          onProceed={() => { specialitiesListData?.connected_healthcare_providers_count > 0 || specialitiesListData?.upload_medical_reports_count > 0 ? navigate(showNoHcpPopup) : navigate("/patient/dashboard") }}
        />
      )}
    </Box>
  );
}
