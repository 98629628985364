import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import {
  useGetSpecialitiesListQuery,
  usePatientIntakeFormQuery,
} from "api/DoctorsApi";
import DefaultLoader from "components/UI/DefaultLoader";
import useAuth from "hooks/useAuth";
import useExistingUser from "hooks/useExistingUser";
import { useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useDispatch, useSelector } from "react-redux";
import { setShareReports } from "store/shareReports";
import PromptPopup from "components/UI/PromptPopup";
import { NavigateNext } from "@mui/icons-material";
import moment from "moment";
import { getAnswers, setAnswers, setTAndCAgreed } from "store/intakeForm";
import FeatureComingSoon from "components/UI/FeatureComingSoon";
import { linkColor } from "components/UI/BreadcrumbsList";
import PaymentAndProfileIncompletionPopup from "components/PaymentAndProfileIncompletionPopup";
import CustomLink from "components/UI/CustomLink";
import InfoText from "../components/UI/InfoText";
import DoctorCard from "components/UI/DoctorCard";

export default function GeneralIntakeFormPage() {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const RSIFanswers = useSelector(getAnswers);
  const ssIFAnswers = JSON.parse(sessionStorage.getItem("intakeForm") || "{}");

  const [showWarningPopup, setShowWarningPopup] = useState(false);

  const [intakeForm, setIntakeForm] = useState([]);
  const [intakeFormIds, setIntakeFormIds] = useState([]);
  const [intakeFormErrors, setIntakeFormErrors] = useState({});

  const [formType, setFormType] = useState("Primary Health Concerns");
  const [activeStep, setActiveStep] = useState(0);

  const [showCancelBookingPopup, setShowCancelBookingPopup] = useState(false);

  const [answersData, setAnswersData] = useState({});

  const [showFeatureComingSoonModal, setShowFeatureComingSoonModal] =
    useState(false);

  const handleCloseTab = (evt) => {
    evt.preventDefault();
    const confirmMsg =
      "Are you sure you want to cancel the booking? You will lose all your data filled so far?";
    evt.returnValue = confirmMsg;
    return confirmMsg;
  };

  useEffect(() => {
    if (!existingUser?.user?.profile_completion) {
      setShowWarningPopup(true);
    } else {
      window.history.pushState(null, "", window.location.pathname);
      window.addEventListener("popstate", handleLeavePage);
      window.addEventListener("beforeunload", handleCloseTab);
    }
    return () => {
      window.removeEventListener("beforeunload", handleCloseTab);
      window.removeEventListener("popstate", handleLeavePage);
    };
  }, []);

  const { data: specialitiesListData } = useGetSpecialitiesListQuery(
    { accessToken },
    { refetchOnReconnect: true, skip: params?.specialist === "about_doctor" }
  );

  const {
    data: intakeFormQuestionsData,
    isLoading,
    isFetching,
    isError,
  } = usePatientIntakeFormQuery(
    {
      accessToken,
      patientId: existingUser?.user?.id,
      formType,
      doctorId: params?.id,
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
      skip: ![0, 1].includes(activeStep),
    }
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setIntakeForm(intakeFormQuestionsData?.intake_form_questions);
      setIntakeFormIds(
        intakeFormQuestionsData?.intake_form_questions?.map((item) => item?.id)
      );
      intakeFormQuestionsData?.intake_form_questions?.forEach((item) => {
        if (
          answersData[item?.id] === undefined ||
          answersData[item?.id] === ""
        ) {
          setAnswersData((prev) => ({
            ...prev,
            [item?.id]:
              RSIFanswers[item?.id] ??
              ssIFAnswers[item?.id] ??
              item?.question_answer ??
              "",
          }));
        }
      });
    }
  }, [isLoading, isFetching, intakeFormQuestionsData]);

  const clearState = () => {
    dispatch(setTAndCAgreed(false));
    dispatch(setAnswers({}));
    dispatch(setShareReports({ doctorId: null, list: [], ids: [] }));
    setShowCancelBookingPopup(false);
  };

  const handleLeavePage = (evt, path) => {
    if (
      existingUser?.user?.profile_completion &&
      window.confirm(
        "Are you sure you want to cancel the booking? You will lose all your data filled so far?"
      )
    ) {
      dispatch(setTAndCAgreed(false));
      dispatch(setAnswers({}));
      if (state?.viaPopup) {
        navigate(`/patient/dashboard/doctors_list/${params?.specialist}`);
      } else {
        navigate(
          `/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}`
        );
      }
    } else {
      evt.preventDefault();
      window.history.pushState(null, "", window.location.pathname);
    }
  };

  const BreadcrumbsItemsViaSpecialities = [
    <CustomLink to={"/patient/dashboard"} onConfirmClick={clearState}>
      Dashboard
    </CustomLink>,
    <CustomLink
      to={"/patient/dashboard/all_specialities"}
      onConfirmClick={clearState}
    >
      All Specialities
    </CustomLink>,
    <CustomLink
      to={`/patient/dashboard/doctors_list/${params?.specialist}`}
      onConfirmClick={clearState}
    >
      {
        specialitiesListData?.specialities?.find(
          (el) => el?.id === parseInt(params?.specialist)
        )?.display
      }
    </CustomLink>,
    <CustomLink
      to={`/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}`}
      onConfirmClick={clearState}
    >
      About Doctor
    </CustomLink>,
    <Typography fontSize={12} color={linkColor} sx={{ cursor: "pointer" }}>
      General Intake Form
    </Typography>,
  ];

  const BreadcrumbsItemsViaPopup = [
    <CustomLink to={"/patient/dashboard"} onConfirmClick={clearState}>
      Dashboard
    </CustomLink>,
    <CustomLink
      to={"/patient/dashboard/all_specialities"}
      onConfirmClick={clearState}
    >
      All Specialities
    </CustomLink>,
    <CustomLink
      to={`/patient/dashboard/doctors_list/${params?.specialist}`}
      onConfirmClick={clearState}
    >
      {
        specialitiesListData?.specialities?.find(
          (el) => el?.id === parseInt(params?.specialist)
        )?.display
      }
    </CustomLink>,
    <Typography fontSize={12} color={linkColor} sx={{ cursor: "pointer" }}>
      General Intake Form
    </Typography>,
  ];

  const BreadcrumbsItems = [
    <CustomLink to={"/patient/dashboard"} onConfirmClick={clearState}>
      Dashboard
    </CustomLink>,
    <CustomLink
      to={`/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}`}
      onConfirmClick={clearState}
    >
      About Doctor
    </CustomLink>,
    <Typography fontSize={12} color={linkColor} sx={{ cursor: "pointer" }}>
      General Intake Form
    </Typography>,
  ];

  const formValidate = () => {
    let hasError = false;
    intakeFormIds.forEach((id) => {
      if (!answersData[id] || answersData[id] === "") {
        hasError = true;
        setIntakeFormErrors((prev) => ({ ...prev, [id]: true }));
      }
    });
    return hasError;
  };

  return isError ? (
    <Navigate to="/page_not_found" />
  ) : (
    <Box>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        {params?.specialist === "about_doctor"
          ? BreadcrumbsItems
          : state?.viaPopup
          ? BreadcrumbsItemsViaPopup
          : BreadcrumbsItemsViaSpecialities}
      </Breadcrumbs>
      {intakeFormQuestionsData?.practitioner && (
        <Box mt={2}>
          <DoctorCard
            img={intakeFormQuestionsData?.practitioner?.avatar_url}
            name={intakeFormQuestionsData?.practitioner?.full_name}
            consultationFees={
              intakeFormQuestionsData?.practitioner?.consultation_fees
            }
            exp={intakeFormQuestionsData?.practitioner?.years_of_experience}
            noOfLikes={intakeFormQuestionsData?.practitioner?.total_likes}
            noOfReviews={intakeFormQuestionsData?.practitioner?.total_reviews}
            qualification={intakeFormQuestionsData?.practitioner?.acadamic_details?.join(
              ", "
            )}
            specialization={intakeFormQuestionsData?.practitioner?.specializations?.join(
              ", "
            )}
            showActions={false}
            showLikesAndReviews={false}
          />
        </Box>
      )}
      <Typography sx={{ mb: "1rem", mt: "1rem", fontWeight: 700 }}>
        General Intake Form
      </Typography>
      <>
        <Container>
          <Stepper alternativeLabel activeStep={activeStep} sx={{ mb: "2rem" }}>
            {["Primary Health Concerns", "About Lifestyle"].map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {isLoading || isFetching ? (
            <DefaultLoader />
          ) : (
            <>
              {activeStep === 0 && (
                <Box sx={{ mb: "2rem" }}>
                  <InfoText
                    text={
                      "Please fill out the below fields as accurately as possible. This will help the doctor to have a clear view of your current health profile and provide accurate observations."
                    }
                  />
                  <Typography fontSize={10} sx={{ mt: "4px" }}>
                    *All your details will remain confidential
                  </Typography>
                </Box>
              )}
              <Typography fontWeight={700} sx={{ mb: "1.75rem" }}>
                {activeStep === 0 ? "Oncology Information" : "Lifestyle Review"}
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "1.5rem",
                }}
              >
                {intakeForm?.map((el) => (
                  <Box key={el?.id}>
                    <Typography fontSize={14} sx={{ mb: ".5rem" }}>
                      {el?.question_name}
                      {el?.required ? "*" : ""}
                    </Typography>
                    {el?.option_type === "date" && (
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDatePicker
                          minDate={moment(existingUser?.user?.date_of_birth)}
                          maxDate={moment(new Date())}
                          value={
                            answersData[el?.id]
                              ? moment(answersData[el?.id])
                              : null
                          }
                          onChange={(e) => {
                            setAnswersData({
                              ...answersData,
                              [el?.id]: moment(e._d).format("MMM DD, YYYY"),
                            });
                            if (
                              moment(existingUser?.user?.date_of_birth).diff(
                                e._d
                              ) > 0 ||
                              moment(e._d).format("MMM DD, YYYY") ===
                                "Invalid date" ||
                              moment(new Date()).diff(e._d) < 0
                            ) {
                              setIntakeFormErrors((prev) => ({
                                ...prev,
                                [el?.id]: true,
                              }));
                            } else {
                              setIntakeFormErrors((prev) => ({
                                ...prev,
                                [el?.id]: false,
                              }));
                            }
                          }}
                          format="MMM DD, YYYY"
                          sx={{
                            "& fieldset": { border: "none" },
                            bgcolor: "#f6f6f6",
                            width: "100%",
                            borderRadius: "5px",
                          }}
                          slotProps={{
                            textField: { placeholder: "MMM DD, YYYY" },
                          }}
                        />
                        {intakeFormErrors[el?.id] && (
                          <Typography
                            fontSize={12}
                            mx={"14px"}
                            mt={"3px"}
                            color={"error"}
                          >
                            Enter a valid date
                          </Typography>
                        )}
                      </LocalizationProvider>
                    )}
                    {(el?.option_type === "text" ||
                      el?.option_type === "text_area") && (
                      <TextField
                        value={answersData[el?.id]}
                        error={intakeFormErrors[el?.id] || false}
                        onChange={(e) => {
                          setAnswersData({
                            ...answersData,
                            [el?.id]: e.target.value,
                          });
                          if (e.target.value.trim() === "" && el?.required) {
                            setIntakeFormErrors((prev) => ({
                              ...prev,
                              [el?.id]: true,
                            }));
                          } else {
                            setIntakeFormErrors((prev) => ({
                              ...prev,
                              [el?.id]: false,
                            }));
                          }
                        }}
                        required={el?.required}
                        multiline={el?.option_type === "text_area"}
                        minRows={2}
                        placeholder={el?.placeholder}
                        sx={{
                          width: "100%",
                          borderRadius: "5px",
                          "& .MuiInputBase-root": {
                            bgcolor: "#f6f6f6",
                            borderRadius: "5px",
                          },
                          "& fieldset": { border: "none" },
                        }}
                        helperText={
                          intakeFormErrors[el?.id] &&
                          "This field cannot be empty"
                        }
                      />
                    )}
                    {el?.option_type === "radio" && (
                      <>
                        <RadioGroup
                          sx={{ flexDirection: "row" }}
                          value={answersData[el?.id] || null}
                          onChange={(e) => {
                            setAnswersData({
                              ...answersData,
                              [el?.id]: e.target.value,
                            });
                            setIntakeFormErrors((prev) => ({
                              ...prev,
                              [el?.id]: false,
                            }));
                          }}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                        {intakeFormErrors[el?.id] && (
                          <Typography fontSize={12} mx={"14px"} color={"error"}>
                            This field cannot be empty
                          </Typography>
                        )}
                      </>
                    )}
                  </Box>
                ))}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: "1.5rem",
                  "& > * + *": { ml: "1rem" },
                }}
              >
                {activeStep === 1 && (
                  <div>
                    <Button
                      variant="outlined"
                      sx={{ width: "11rem" }}
                      onClick={() => {
                        dispatch(
                          setAnswers({ ...RSIFanswers, ...answersData })
                        );
                        setActiveStep((prev) => prev - 1);
                        setFormType("Primary Health Concerns");
                      }}
                    >
                      Back
                    </Button>
                  </div>
                )}
                <div>
                  <Button
                    variant="contained"
                    sx={{ width: "11rem" }}
                    onClick={() => {
                      const hasError = formValidate();
                      const filteredObj = Object.fromEntries(
                        Object.entries(intakeFormErrors).filter(([k, _]) =>
                          intakeFormIds.includes(parseInt(k))
                        )
                      );
                      if (
                        !hasError &&
                        !Object.values(filteredObj)?.some((el) => el)
                      ) {
                        dispatch(
                          setAnswers({ ...RSIFanswers, ...answersData })
                        );
                        setActiveStep((prev) => prev + 1);
                        setFormType("About Lifestyle");
                        if (activeStep === 1) {
                          sessionStorage.setItem(
                            "intakeForm",
                            JSON.stringify({ ...RSIFanswers, ...answersData })
                          );
                          navigate(
                            `/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}/share_records`,
                            {
                              state: {
                                viaGeneralIntakeForm: true,
                                viaPopup: state?.viaPopup,
                              },
                            }
                          );
                        }
                      }
                    }}
                  >
                    Next
                  </Button>
                </div>
              </Box>
            </>
          )}
        </Container>
      </>
      <PromptPopup
        open={showCancelBookingPopup}
        handleClose={() => setShowCancelBookingPopup(false)}
        onNoClick={() => setShowCancelBookingPopup(false)}
        onYesClick={() => {
          clearState();
          if (state?.viaPopup) {
            navigate(`/patient/dashboard/doctors_list/${params?.specialist}`);
          } else {
            navigate(
              `/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}`
            );
          }
        }}
      >
        <Typography fontSize={14} textAlign={"center"}>
          Are you sure you want to cancel the booking? You will lose all your
          data filled so far.
        </Typography>
      </PromptPopup>
      <FeatureComingSoon
        show={showFeatureComingSoonModal}
        hide={() => setShowFeatureComingSoonModal(false)}
      />
      <PaymentAndProfileIncompletionPopup
        show={showWarningPopup}
        close={() => {
          setShowWarningPopup(false);
          navigate(+1);
        }}
      />
    </Box>
  );
}
