import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { linkColor } from "components/UI/BreadcrumbsList";
import UnsavedDataPopup from "components/UI/UnsavedDataPopup";

export default function CustomLink({ to, onConfirmClick, children }) {
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleLinkClick = () => {
    setShowConfirmation(true);
  };

  const handleConfirmation = (confirmed) => {
    setShowConfirmation(false);
    if (confirmed) {
      navigate(to);
    }
  };

  return (
    <>
      <Box onClick={handleLinkClick} sx={{ cursor: "pointer" }}>
        <Typography fontSize={12} color={linkColor}>
          {children}
        </Typography>
      </Box>
      <UnsavedDataPopup
        open={showConfirmation}
        handleClose={() => setShowConfirmation(false)}
        onCancel={() => setShowConfirmation(false)}
        onConfirm={() => {
          onConfirmClick();
          handleConfirmation(true);
        }}
      />
    </>
  );
}
