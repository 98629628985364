import { useEffect, useState } from "react";
import "Styles/ProfileForm.css";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import moment from "moment";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useSelector } from "react-redux";
import { useGetCountriesListQuery } from "api/CommonApi";
import useAuth from "hooks/useAuth";

const initialValues = {
  first_name: "",
  last_name: "",
  middle_name: "",
  language: "",
  mobile_number: "",
  country: "",
  street_name: "",
  gender: "",
  city: "",
  state: "",
  zipcode: "",
  date_of_birth: "",
};

const formSchema = yup.object({
  first_name: yup.string().required("Enter your first name"),
  last_name: yup.string().required("Enter your last name"),
  middle_name: yup.string(),
  mobile_number: yup
    .string()
    .required("Enter your mobile number")
    .matches(/^(\+)?[\d\s()-]{5,20}$/, { message: "Mobile number is invalid" }),
  // .matches(/^(\+\d{1,3}[- ]?)?\d{5,20}$/, {
  //   message: "Mobile number is invalid",
  // }),
  date_of_birth: yup.string().required("Select date of birth"),
  language: yup.string().required("Select your language"),
  country: yup.string().required("Enter you country"),
  street_name: yup.string().required("Enter your street name"),
  city: yup.string().required("Enter your city"),
  state: yup.string().required("Enter your state"),
  zipcode: yup
    .string()
    .required("Enter you zip code")
    .max(7, "Field should not exceed 7 characters"),
  gender: yup.string().required("Select your gender"),
});

const PractitionerProfileForm = ({
  practitionerData,
  handleTab,
  handleProfileData,
}) => {
  const { accessToken } = useAuth();
  const userInfo = useSelector((state) => state.auth.existingUser);

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState(initialValues);

  const { data: countriesList, isLoading: isCountriesListLoading } =
    useGetCountriesListQuery({
      accessToken,
    });

  useEffect(() => {
    setFormValues({
      first_name: userInfo?.user?.first_name,
      middle_name: practitionerData?.practitioner?.middle_name ?? "",
      last_name: userInfo?.user?.last_name,
      mobile_number: practitionerData?.practitioner?.mobile_number ?? "",
      date_of_birth: practitionerData?.practitioner?.date_of_birth,
      language: practitionerData?.practitioner?.preferred_language ?? "English",
      gender: practitionerData?.practitioner?.gender,
      country: practitionerData?.practitioner?.country,
      street_name: practitionerData?.practitioner?.street_name,
      city: practitionerData?.practitioner?.city,
      state: practitionerData?.practitioner?.state,
      zipcode: practitionerData?.practitioner?.zipcode,
    });
  }, [practitionerData]);

  const handleChange = async ({ name, value }) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));
    try {
      await formSchema.pick([name]).validate({ [name]: value.trim() });
      setFormErrors((prev) => ({ ...prev, [name]: "" }));
    } catch (err) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: err.toString().split(": ")[1],
      }));
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    try {
      const vals = {};
      Object.keys(formValues)?.forEach(
        (k) => (vals[k] = formValues[k]?.trim())
      );
      await formSchema.validate(vals, { abortEarly: false });
      setFormErrors(initialValues);

      handleTab(2);
      handleProfileData(formValues);
    } catch (validationErrors) {
      const newErrors = {};
      validationErrors.inner?.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setFormErrors(newErrors);
    }
  };

  return (
    <>
      <Box
        component={"form"}
        autoComplete="one-time-code"
        onSubmit={handleSubmit}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "1.25rem",
            alignItems: "start",
          }}
        >
          {[
            { label: "First Name *", value: "first_name" },
            { label: "Middle Name", value: "middle_name" },
            { label: "Last Name *", value: "last_name" },
            { label: "Mobile Number *", value: "mobile_number" },
          ].map((item) => (
            <Box key={item?.value} sx={{ display: "grid" }}>
              <Box component={"label"} htmlFor={item.value}>
                {item.label}
              </Box>
              <TextField
                id={item.value}
                name={item.value}
                value={formValues[item.value]}
                autoComplete="one-time-code"
                onChange={(evt) =>
                  handleChange({
                    name: evt.target.name,
                    value: evt.target.value,
                  })
                }
                size="small"
                error={Boolean(formErrors[item.value])}
                helperText={formErrors[item.value]}
              />
            </Box>
          ))}
          <Box sx={{ display: "grid" }}>
            <Box component={"label"} htmlFor={"date_of_birth"}>
              Date of Birth *
            </Box>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDatePicker
                id={"date_of_birth"}
                maxDate={moment(new Date())}
                value={
                  !formValues.date_of_birth
                    ? null
                    : moment(formValues.date_of_birth)
                }
                onChange={(evt) =>
                  handleChange({
                    name: "date_of_birth",
                    value:
                      moment(new Date()).diff(evt._d) < 0
                        ? moment(new Date()).format("MMM DD, YYYY")
                        : moment(evt._d).format("MMM DD, YYYY"),
                  })
                }
                format="MMM DD, YYYY"
                slotProps={{
                  textField: {
                    placeholder: "MMM DD, YYYY",
                    error: formErrors.date_of_birth,
                    size: "small",
                  },
                }}
              />
            </LocalizationProvider>
            {formErrors.date_of_birth && (
              <Typography fontSize={12} mt={"3px"} mx={"14px"} color={"error"}>
                {formErrors.date_of_birth}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "grid" }}>
            <Box component={"label"} htmlFor={"language"}>
              Preferred Language *
            </Box>
            <FormControl error={Boolean(formErrors.language)}>
              <Select
                id="language"
                value={formValues.language}
                onChange={(evt) =>
                  handleChange({
                    name: "language",
                    value: evt.target.value,
                  })
                }
                size="small"
              >
                <MenuItem value={"English"}>English</MenuItem>
              </Select>
            </FormControl>
            {formErrors.language && (
              <Typography fontSize={12} mt={"3px"} mx={"14px"} color={"error"}>
                {formErrors.language}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "grid" }}>
            <Box component={"label"}>Gender *</Box>
            <RadioGroup
              sx={{ flexDirection: "row", "& span": { fontSize: "0.875rem" } }}
              defaultChecked={formValues.gender}
              value={formValues.gender}
              onChange={(evt) =>
                handleChange({ name: "gender", value: evt.target.value })
              }
            >
              <FormControlLabel
                value="Male"
                control={<Radio checked={formValues.gender === "Male"} />}
                label="Male"
              />
              <FormControlLabel
                value="Female"
                control={<Radio checked={formValues.gender === "Female"} />}
                label="Female"
              />
            </RadioGroup>
            {formErrors.gender && (
              <Typography fontSize={12} mt={"3px"} mx={"14px"} color={"error"}>
                {formErrors.gender}
              </Typography>
            )}
          </Box>
        </Box>
        <hr />
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "1.25rem",
            alignItems: "start",
          }}
        >
          {!isCountriesListLoading && (
            <Box sx={{ display: "grid" }}>
              <Box component={"label"} htmlFor={"country"}>
                Country *
              </Box>
              <Autocomplete
                disablePortal
                id="country"
                value={formValues.country}
                options={countriesList?.countries?.map((item) => item?.name)}
                autoComplete="one-time-code"
                onChange={(_, val) =>
                  handleChange({
                    name: "country",
                    value: val,
                  })
                }
                disableClearable
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "one-time-code",
                    }}
                    error={Boolean(formErrors.country)}
                    helperText={formErrors.country}
                  />
                )}
                ListboxProps={{
                  sx: { maxHeight: "10rem" },
                }}
              />
            </Box>
          )}
          {[
            { label: "State *", value: "state" },
            { label: "City *", value: "city" },
            { label: "Zip Code *", value: "zipcode" },
            { label: "Street Name *", value: "street_name" },
          ].map((item) => (
            <Box key={item?.value} sx={{ display: "grid" }}>
              <Box component={"label"} htmlFor={item.value}>
                {item.label}
              </Box>
              <TextField
                id={item.value}
                name={item.value}
                value={formValues[item.value]}
                autoComplete="one-time-code"
                onChange={(evt) =>
                  handleChange({
                    name: evt.target.name,
                    value: evt.target.value,
                  })
                }
                size="small"
                error={Boolean(formErrors[item.value])}
                helperText={formErrors[item.value]}
              />
            </Box>
          ))}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "1rem" }}>
          <Button type="submit" variant={"contained"} sx={{ width: "8.75rem" }}>
            Next
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default PractitionerProfileForm;
