import { Box, Button, IconButton } from "@mui/material";
import { BookmarkIconFilled, BookmarkIconOutline } from "./Icons";
import LikeIcon from "assets/images/like-icon.svg";
import StarIcon from "assets/images/star-icon.svg";
import { useEffect, useState } from "react";
import ConsultPopup from "./ConsultPopup";
import { useNavigate, useParams } from "react-router-dom";
import ProfileCircle from "assets/images/ProfileCircle.png";
import {
  useRemoveBookmarkDoctorMutation,
  useSetBookmarkDoctorMutation,
} from "api/DoctorsApi";
import useAuth from "hooks/useAuth";
import useExistingUser from "hooks/useExistingUser";
import { toastr } from "react-redux-toastr";
import FeatureComingSoon from "./FeatureComingSoon";

export default function DoctorCard({
  id,
  img,
  name,
  qualification,
  specialization,
  exp,
  consultationFees,
  noOfLikes,
  noOfReviews,
  handleBookAppointmentClick,
  bookmarked,
  dontShrinkSpecialization = false,
  showActions = true,
  showLikesAndReviews = true,
}) {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();
  const params = useParams();
  const navigate = useNavigate();

  const [anchorElForConsultPopup, setAnchorElForConsultPopup] = useState(null);
  const [isBookmarked, setIsBookmarked] = useState(false);

  const [showFeatureComingSoon, setShowFeatureComingSoon] = useState(false);

  const [bookmarkDoctor] = useSetBookmarkDoctorMutation();
  const [unbookmardDoctor] = useRemoveBookmarkDoctorMutation();

  useEffect(() => {
    setIsBookmarked(bookmarked);
  }, [bookmarked]);

  const handleBookmarkDoctor = async () => {
    try {
      if (isBookmarked) {
        const res = await unbookmardDoctor({
          accessToken,
          patientId: existingUser.user.id,
          practitionerId: id,
        }).unwrap();
        toastr.success(res?.message);
      } else {
        const res = await bookmarkDoctor({
          accessToken,
          patientId: existingUser.user.id,
          practitionerId: id,
        }).unwrap();
        toastr.success(res?.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <Box
        sx={{
          bgcolor: "#f9f9f9",
          borderRadius: "5px",
          p: "0.875rem",
          fontFamily: "Open Sans",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", gap: "1.25rem", flex: 1 }}>
          <Box
            component={"img"}
            sx={{
              display: "block",
              height: "10rem",
              width: "7.375rem",
              borderRadius: "9px",
              objectFit: img ? "cover" : "contain",
            }}
            src={img || ProfileCircle}
            alt="doctor"
          />
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: ".5rem",
              fontSize: "0.875rem",
              "& p": { textAlign: "left", mb: 0 },
            }}
          >
            <Box component="p" sx={{ fontWeight: 700 }}>
              {name}
            </Box>
            <Box
              component="p"
              sx={{
                color: "#797979",
                width: { xs: "32ch", lg: "48ch", xl: "65ch" },
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              title={qualification}
            >
              {qualification}
            </Box>
            <Box
              component="p"
              sx={{
                fontSize: "0.75rem",
                color: "#797979",
                width: dontShrinkSpecialization
                  ? "auto"
                  : { xs: "32ch", lg: "48ch", xl: "65ch" },
                whiteSpace: dontShrinkSpecialization ? "normal" : "nowrap",
                overflow: dontShrinkSpecialization ? "unset" : "hidden",
                textOverflow: "ellipsis",
              }}
              title={specialization}
            >
              {specialization}
            </Box>
            <Box component="p" sx={{ fontSize: ".75rem" }}>
              <Box component={"span"} sx={{ fontWeight: "700" }}>
                {exp}
              </Box>{" "}
              <Box component={"span"} sx={{ color: "#797979" }}>
                Years of experience
              </Box>
            </Box>
            <Box
              component="p"
              sx={{
                bgcolor: "#e5fbfb",
                px: "8px",
                borderRadius: "99rem",
                fontSize: ".75rem",
                color: "#797979",
                width: "max-content",
              }}
            >
              Consultation Fees &nbsp;: &nbsp;
              <Box
                component={"span"}
                sx={{ fontSize: "0.875rem", fontWeight: 700, color: "#272727" }}
              >
                {consultationFees}
              </Box>
            </Box>
            {showLikesAndReviews && (
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <Box sx={{ display: "flex", gap: ".25rem" }}>
                  <Box component={"img"} src={LikeIcon} />
                  <Box component={"p"} sx={{ fontSize: "0.75rem" }}>
                    <Box component={"span"} sx={{ fontWeight: "700" }}>
                      {noOfLikes}
                    </Box>
                    &nbsp;
                    <Box component={"span"} sx={{ color: "#797979" }}>
                      Likes
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: ".25rem" }}>
                  <Box component={"img"} src={StarIcon} />
                  <Box component={"p"} sx={{ fontSize: "0.75rem" }}>
                    <Box component={"span"} sx={{ fontWeight: "700" }}>
                      {noOfReviews}
                    </Box>
                    &nbsp;
                    <Box component={"span"} sx={{ color: "#797979" }}>
                      Reviews
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {showActions && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton
                onClick={() => {
                  setIsBookmarked((prev) => !prev);
                  handleBookmarkDoctor();
                }}
              >
                {isBookmarked ? (
                  <Box
                    component={BookmarkIconFilled}
                    sx={{
                      "& path": {
                        fill: (theme) => theme.palette.primary.main,
                      },
                    }}
                  />
                ) : (
                  <Box
                    component={BookmarkIconOutline}
                    sx={{
                      "& path": {
                        stroke: (theme) => theme.palette.primary.main,
                      },
                    }}
                  />
                )}
              </IconButton>
            </Box>
            <Box sx={{ display: "grid", gap: "1.25rem" }}>
              {params?.id ? (
                <>
                  <Button
                    variant="contained"
                    sx={{ fontSize: "0.875rem", width: "10.125rem" }}
                    onClick={handleBookAppointmentClick}
                  >
                    Book Appointment
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ fontSize: "0.875rem", width: "10.125rem" }}
                    onClick={() => setShowFeatureComingSoon(true)}
                  >
                    Send a Message
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    sx={{ fontSize: "0.875rem", width: "10.125rem" }}
                    onClick={(evt) =>
                      setAnchorElForConsultPopup(evt.currentTarget)
                    }
                  >
                    Consult
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ fontSize: "0.875rem", width: "10.125rem" }}
                    onClick={() =>
                      navigate(
                        `/patient/dashboard/doctors_list/${params?.specialist}/${id}`
                      )
                    }
                  >
                    Know More
                  </Button>
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>
      <ConsultPopup
        open={Boolean(anchorElForConsultPopup)}
        anchorEl={anchorElForConsultPopup}
        handleClose={() => setAnchorElForConsultPopup(null)}
        handleBookAppointmentClick={handleBookAppointmentClick}
        handleSendAMessage={() => setShowFeatureComingSoon(true)}
      />
      <FeatureComingSoon
        show={showFeatureComingSoon}
        hide={() => setShowFeatureComingSoon(false)}
      />
    </>
  );
}
