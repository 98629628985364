import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toastr } from "react-redux-toastr";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const PaymentsApiSlice = createApi({
  reducerPath: "PaymentsApis",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
  }),
  tagTypes: ["cards"],
  endpoints: (builder) => ({
    addCard: builder.mutation({
      invalidatesTags: ["cards"],
      query: ({
        accessToken,
        patientId,
        cardNumber,
        expMonth,
        expYear,
        stripeId,
        brand,
        cvcCheck,
      }) => ({
        url: `/patients/${patientId}/payment_methods`,
        method: "POST",
        body: {
          payment_methods: {
            card_number: cardNumber,
            exp_month: expMonth,
            exp_year: expYear,
            stripe_payment_method_id: stripeId,
            brand,
            cvc_check: cvcCheck,
          },
        },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const res = await queryFulfilled;
          toastr.success(res?.data?.message);
        } catch (error) {
          console.error(error);
        }
      },
    }),
    getCardsList: builder.query({
      providesTags: ["cards"],
      query: ({ accessToken, patientId }) => ({
        url: `/patients/${patientId}/payment_methods`,
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    deleteCard: builder.mutation({
      invalidatesTags: ["cards"],
      query: ({ accessToken, cardId, patientId }) => ({
        url: `/patients/${patientId}/payment_method/${cardId}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const res = await queryFulfilled;
          toastr.success(res?.data?.message);
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateCard: builder.mutation({
      invalidatesTags: ["cards"],
      query: ({
        accessToken,
        patientId,
        cardId,
        cardNumber,
        expMonth,
        expYear,
        stripeId,
        brand,
        cvcCheck,
      }) => ({
        url: `patients/${patientId}/payment_methods/${cardId}`,
        method: "PATCH",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: {
          payment_methods: {
            card_number: cardNumber,
            exp_month: expMonth,
            exp_year: expYear,
            stripe_payment_method_id: stripeId,
            brand,
            cvc_check: cvcCheck,
          },
        },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const res = await queryFulfilled;
          toastr.success(res?.data?.message);
        } catch (error) {
          console.error(error);
        }
      },
    }),
  }),
});

export const {
  useAddCardMutation,
  useGetCardsListQuery,
  useDeleteCardMutation,
  useUpdateCardMutation,
} = PaymentsApiSlice;
