import { Typography } from "@mui/material";
import PromptPopup from "components/UI/PromptPopup";

export default function UnsavedDataPopup({
  open,
  handleClose,
  onConfirm,
  onCancel,
}) {
  return (
    <PromptPopup
      open={open}
      handleClose={handleClose}
      onNoClick={onCancel}
      onYesClick={onConfirm}
    >
      <Typography fontSize={14} textAlign={"center"}>
        Hey! This action will cancel the booking, and you will lose the data you
        have filled out so far. Are you sure you want to proceed?
      </Typography>
    </PromptPopup>
  );
}
