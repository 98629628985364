import { useViewGivenReviewQuery } from "api/AppointmentsApi";
import BasicModal from "./UI/BasicModal";
import useExistingUser from "hooks/useExistingUser";
import useAuth from "hooks/useAuth";
import { Box, CircularProgress, Typography } from "@mui/material";
import moment from "moment";
import { ThumbsDownIcon, ThumbsUpIcon } from "components/UI/Icons";

export default function ViewReviewPopup({ show, close, appointmentId }) {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();

  const { data, isLoading, isError } = useViewGivenReviewQuery(
    { accessToken, userId: existingUser?.user?.id, appointmentId },
    { refetchOnReconnect: true, refetchOnMountOrArgChange: true }
  );

  return isError ? (
    close()
  ) : (
    <BasicModal show={show} close={close}>
      {isLoading ? (
        <Box sx={{ minHeight: "10rem", display: "grid", placeItems: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Typography fontSize={14} fontWeight={700} mb={1}>
            My Review
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography fontSize={10} color={"text.secondary"}>
              {moment
                .utc(data?.patient_review?.submitted_time)
                .local()
                .format("MMM DD, YYYY")}
            </Typography>
            <Typography ml={2} fontSize={10} color={"text.secondary"}>
              {moment
                .utc(data?.patient_review?.submitted_time)
                .local()
                .format("hh:mm A")}
            </Typography>
          </Box>
          <Box mt={2} display={"flex"}>
            <Box
              sx={{
                height: "42px",
                width: "42px",
                borderRadius: "99rem",
                bgcolor: (theme) => theme.palette.primary.main,
                display: "grid",
                placeContent: "center",
              }}
            >
              <Box
                component={
                  data?.patient_review?.like ? ThumbsUpIcon : ThumbsDownIcon
                }
                sx={{
                  "& path": {
                    fill: "#fff",
                  },
                }}
              />
            </Box>
          </Box>
          <Typography
            whiteSpace={"pre-line"}
            fontSize={14}
            color={"text.secondary"}
            mt={2}
          >
            {data?.patient_review?.description}
          </Typography>
        </Box>
      )}
    </BasicModal>
  );
}
