import { Modal } from "reactstrap";
import ModalClose from "assets/images/ModalClose.svg";
import SuccessTick from "assets/images/success-tick-icon.svg";
import "Styles/UploadPopUp.css";
import { Box, IconButton } from "@mui/material";

export default function SuccessMessageModal({
  message,
  show,
  close,
  localDownload,
}) {
  return (
    <Modal
      isOpen={show}
      toggle={close}
      centered={true}
      style={{ "--bs-modal-width": "350px" }}
    >
      <Box sx={{ fontFamily: "Open Sans" }}>
        <div className="d-flex flex-column align-items-center justify-content-center pb-4">
          <div className="d-flex justify-content-end w-100">
            <IconButton onClick={close}>
              <img src={ModalClose} alt="closebutton" />
            </IconButton>
          </div>
          <div className="d-flex align-items-center justify-content-center flex-column w-75 gap-2">
            <img src={SuccessTick} alt="" className="mb-3" />
            <p className="w-100 text-center fw-bold text-black">{message}</p>
            {localDownload && (
              <Box
                sx={{
                  textAlign: "center",
                  border: "1px solid  #DEDEDE",
                  borderRadius: "5px",
                  padding: "15px 9px",
                  fontSize: "12px",
                  color: "#797979",
                }}
              >
                {localDownload}
              </Box>
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
}
