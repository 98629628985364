import React from "react";
import "Styles/Register.css";
import { Box } from "@mui/material";
import appConfig from "../../appConfig";

export const Loading = (props) => {
  return (
    <div className="center fetchload loader-color">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: ".5rem",
        }}
      >
        <Box component={"img"} src={appConfig.icon} sx={{ display: "block" }} />
        <Box
          component={"span"}
          sx={{
            animationName: "scaling-loader",
            animationDuration: "250ms",
            animationIterationCount: "infinite",
            animationDirection: "alternate",
          }}
        >
          Loading...
        </Box>
      </Box>
    </div>
  );
};

export default Loading;
