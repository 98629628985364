import { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Breadcrumbs,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import SearchIcon from "assets/images/SearchIcon.svg";
import { FilterWhiteIcon, NoDoctorIcon } from "components/UI/Icons";
import DoctorCard from "components/UI/DoctorCard";
import FilterModal from "components/DoctorsList/FilterModal";
import DefaultLoader from "components/UI/DefaultLoader";
import _ from "lodash";
import { useGetPractitionersListQuery } from "api/DoctorsApi";
import useAuth from "hooks/useAuth";
import { NavigateNext } from "@mui/icons-material";
import { linkColor } from "components/UI/BreadcrumbsList";
import PaymentAndProfileIncompletionPopup from "components/PaymentAndProfileIncompletionPopup";
import useExistingUser from "hooks/useExistingUser";
import CPagination from "components/UI/CPagination";

export default function DoctorSpecialistsPage() {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();
  const params = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const handleInputChange = _.debounce((evt) => {
    setSearchText(evt.target.value.trim());
  }, 300);

  const [gender, setGender] = useState(undefined);
  const [exp, setExp] = useState({ min: null, max: null });
  const [filterSelectedOptionsCount, setFilterSelectedOptionsCount] =
    useState(0);

  const [showWarningPopup, setShowWarningPopup] = useState(false);

  const {
    data: practitionersListData,
    isLoading,
    isFetching,
    isError,
  } = useGetPractitionersListQuery(
    {
      accessToken,
      specialistId: params?.specialist,
      search: searchText,
      page,
      perPage,
      gender,
      minExp: exp.min === null ? undefined : exp.min,
      maxExp: exp.max === null ? undefined : exp.max,
    },
    { refetchOnReconnect: true, refetchOnMountOrArgChange: true }
  );
  useEffect(() => {
    if (gender !== undefined && (exp.min !== null || exp.max !== null)) {
      setFilterSelectedOptionsCount(2);
    } else if (gender !== undefined || exp.min !== null || exp.max !== null) {
      setFilterSelectedOptionsCount(1);
    } else {
      setFilterSelectedOptionsCount(0);
    }
  }, [gender, exp]);

  const BreadcrumbsItems = [
    <Link to={"/patient/dashboard"}>
      <Typography fontSize={12} color={linkColor}>
        Dashboard
      </Typography>
    </Link>,
    <Link to={"/patient/dashboard/all_specialities"}>
      <Typography fontSize={12} color={linkColor}>
        All Specialities
      </Typography>
    </Link>,
    <Link to={`/patient/dashboard/doctors_list/${params?.specialist}`}>
      <Typography fontSize={12} color={linkColor}>
        {practitionersListData?.speciality_name}
      </Typography>
    </Link>,
  ];

  if (isError) {
    return <Navigate to="/page_not_found" replace />;
  }

  return isLoading ? (
    <DefaultLoader height={"calc(100vh - 60px - 70px)"} />
  ) : (
    <>
      <Box>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          {BreadcrumbsItems}
        </Breadcrumbs>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: "1.875rem",
          }}
        >
          <Box sx={{ fontSize: "1.125rem", fontWeight: 700 }}>
            {practitionersListData?.speciality_name} (
            {practitionersListData?.practitioner_count || 0})
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <button
              disabled={practitionersListData?.total_count === 0}
              className="btn noPadding"
              onClick={() => setShowFilterModal(true)}
            >
              <Badge badgeContent={filterSelectedOptionsCount} color="error">
                <Button
                  variant="contained"
                  sx={{
                    minWidth: 0,
                    p: ".3rem",
                    background: (theme) => theme.palette.primary.main,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "6px",
                  }}
                >
                  <FilterWhiteIcon />
                </Button>
              </Badge>
            </button>

            <Box>
              <TextField
                placeholder="Search for Doctors"
                disabled={practitionersListData?.total_count === 0}
                onChange={(e) => {
                  handleInputChange(e);
                  setPage(1);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box component={"img"} src={SearchIcon} alt="" />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                sx={{
                  width: "21.6875rem",
                  "& input": { p: ".5rem", fontSize: "0.875rem" },
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.875rem",
            marginBottom: "2rem",
          }}
        >
          {isFetching ? (
            <DefaultLoader height="calc(100vh - 354px )" />
          ) : practitionersListData?.practitioners.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "1rem",
                height: "calc(100vh - 354px )",
              }}
            >
              <Box
                component={NoDoctorIcon}
                sx={{
                  "& path": {
                    fill: (theme) => theme.palette.primary.main,
                  },
                }}
              />
              <Typography>Sorry! we couldn't find any doctors</Typography>
            </Box>
          ) : (
            practitionersListData?.practitioners.map((el) => (
              <DoctorCard
                key={el?.id}
                id={el?.id}
                name={el?.full_name}
                consultationFees={el?.consultation_fees ?? 0}
                exp={el?.years_of_experience || 0}
                img={el?.avatar_url}
                specialization={el?.specializations?.join(", ")}
                qualification={el?.acadamic_details.join(", ")}
                noOfLikes={el?.total_likes ?? 0}
                noOfReviews={el?.total_reviews ?? 0}
                bookmarked={el?.bookmarks}
                handleBookAppointmentClick={() => {
                  if (!existingUser?.user?.profile_completion) {
                    setShowWarningPopup(true);
                  } else {
                    navigate(
                      `/patient/dashboard/doctors_list/${params?.specialist}/${el?.id}/general_intake_form`,
                      { state: { viaPopup: true } }
                    );
                  }
                }}
              />
            ))
          )}
        </Box>
        {!isFetching && (
          <CPagination
            page={page}
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
            totalCount={practitionersListData?.practitioner_count}
          />
        )}
      </Box>

      {showFilterModal && (
        <FilterModal
          open={showFilterModal}
          handleClose={() => setShowFilterModal(false)}
          gender={gender}
          setPage={setPage}
          setGender={setGender}
          exp={exp}
          setExp={setExp}
        />
      )}
      <PaymentAndProfileIncompletionPopup
        show={showWarningPopup}
        close={() => setShowWarningPopup(false)}
      />
    </>
  );
}
