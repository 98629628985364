import { Box } from "@mui/material";
import appConfig from "../../appConfig";

export default function DefaultLoader({ height = "calc(100vh - 414px )" }) {
  return (
    <Box sx={{ minHeight: height, display: "grid", placeContent: "center" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: ".5rem",
        }}
      >
        <Box component={"img"} src={appConfig.icon} sx={{ display: "block" }} />
        <Box
          component={"span"}
          sx={{
            animationName: "scaling-loader",
            animationDuration: "250ms",
            animationIterationCount: "infinite",
            animationDirection: "alternate",
          }}
        >
          Loading...
        </Box>
      </Box>
    </Box>
  );
}
