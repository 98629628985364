const { createSlice } = require("@reduxjs/toolkit");

const bookafySlice = createSlice({
  name: "bookafy",
  initialState: {
    link: "",
  },
  reducers: {
    setBookafyLink: (state, action) => {
      state.link = action.payload;
    },
  },
});

export const getBookafyLink = (state) => state.bookafy.link;
export const { setBookafyLink } = bookafySlice.actions;
export default bookafySlice.reducer;
