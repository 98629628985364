import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { DashboardIcon, EventIcon } from "./UI/Icons";

export default function PractitionerSidebar() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <List component={"nav"} sx={{ "& span": { fontSize: "14px" } }}>
      <ListItemButton
        id="my-dashboard"
        onClick={() => navigate("/doctor/dashboard")}
        sx={
          location.pathname === "/doctor/dashboard"
            ? {
                background: (theme) => theme.palette.primary.main,
                ":hover": {
                  background: (theme) => theme.palette.primary.light,
                },
              }
            : null
        }
      >
        <ListItemIcon sx={{ minWidth: "32px !important" }}>
          {location.pathname === "/doctor/dashboard" ? (
            <Box
              component={DashboardIcon}
              sx={{
                "& path": { fill: "#fff" },
              }}
            />
          ) : (
            <DashboardIcon />
          )}
        </ListItemIcon>
        <Typography
          fontSize={14}
          sx={
            location.pathname === "/doctor/dashboard"
              ? {
                  color: "#FFFFFF",
                  fontWeight: 700,
                }
              : { color: "#838383" }
          }
        >
          Dashboard
        </Typography>
      </ListItemButton>
      <ListItemButton
        id="my-appointments"
        onClick={() => navigate("/doctor/my_appointments")}
        sx={
          location.pathname === "/doctor/my_appointments"
            ? {
                background: (theme) => theme.palette.primary.main,
                ":hover": {
                  background: (theme) => theme.palette.primary.light,
                },
              }
            : null
        }
      >
        <ListItemIcon sx={{ minWidth: "32px !important" }}>
          {location.pathname === "/doctor/my_appointments" ? (
            <Box
              component={EventIcon}
              sx={{
                "& path": { stroke: "#fff" },
              }}
            />
          ) : (
            <Box
              component={EventIcon}
              sx={{ "& path": { stroke: "#838383" } }}
            />
          )}
        </ListItemIcon>
        <Typography
          fontSize={14}
          sx={
            location.pathname === "/doctor/my_appointments"
              ? {
                  color: "#fff",
                  fontWeight: 700,
                }
              : { color: "#838383" }
          }
        >
          My Appointments
        </Typography>
      </ListItemButton>
    </List>
  );
}
