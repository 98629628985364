import React from 'react'
// import NoHealthRecord from "assets/images/norecord.png";
import { NoHealthRecord } from './Icons'

import { Box } from '@mui/material'
const NoHealthRecords = () => {
  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "calc(100vh - 406px)"
    }}>

      < Box component={NoHealthRecord} sx={{
        width: "40px", "& path": {
          fill: (theme) => theme.palette.primary.main,
        }
      }} />
      <Box sx={{ justifyContent: "center" }}>No Records Found</Box>

    </Box>
  )
}

export default NoHealthRecords
