import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const linkColor = "#52B5FE";

export const BCPatientDashboard = [
  <Link to={"/patient/dashboard"}>
    <Typography fontSize={12} color={linkColor}>
      Dashboard
    </Typography>
  </Link>,
];

export const BCPatientVitalSigns = [
  <Link to={"/patient/dashboard"}>
    <Typography fontSize={12} color={linkColor}>
      Dashboard
    </Typography>
  </Link>,
  <Link to={"/patient/health_records/vital_signs"}>
    <Typography fontSize={12} color={linkColor}>
      Vital Signs
    </Typography>
  </Link>,
];

export const BCPatientMedicalHistories = [
  <Link to={"/patient/dashboard"}>
    <Typography fontSize={12} color={linkColor}>
      Dashboard
    </Typography>
  </Link>,
  <Link to={"/patient/health_records/medical_histories"}>
    <Typography fontSize={12} color={linkColor}>
      Medical Histories
    </Typography>
  </Link>,
];

export const BCPatientLabResults = [
  <Link to={"/patient/dashboard"}>
    <Typography fontSize={12} color={linkColor}>
      Dashboard
    </Typography>
  </Link>,
  <Link to={"/patient/health_records/lab_reports"}>
    <Typography fontSize={12} color={linkColor}>
      Lab Results
    </Typography>
  </Link>,
];

export const BCPatientAllergies = [
  <Link to={"/patient/dashboard"}>
    <Typography fontSize={12} color={linkColor}>
      Dashboard
    </Typography>
  </Link>,
  <Link to={"/patient/health_records/allergies"}>
    <Typography fontSize={12} color={linkColor}>
      Allergies
    </Typography>
  </Link>,
];

export const BCPatientImmunizations = [
  <Link to={"/patient/dashboard"}>
    <Typography fontSize={12} color={linkColor}>
      Dashboard
    </Typography>
  </Link>,
  <Link to={"/patient/health_records/immunizations"}>
    <Typography fontSize={12} color={linkColor}>
      Immunizations
    </Typography>
  </Link>,
];

export const BCPatientMedicalReports = [
  <Link to={"/patient/dashboard"}>
    <Typography fontSize={12} color={linkColor}>
      Dashboard
    </Typography>
  </Link>,
  <Link to={"/patient/health_records/medical_reports"}>
    <Typography fontSize={12} color={linkColor}>
      Medical Reports
    </Typography>
  </Link>,
];

export const BCPatientMyDownloads = [
  <Link to={"/patient/dashboard"}>
    <Typography fontSize={12} color={linkColor}>
      Dashboard
    </Typography>
  </Link>,
  <Link to={"/patient/my_ehr_health_reports"}>
    <Typography fontSize={12} color={linkColor}>
      My EHR Health Reports
    </Typography>
  </Link>,
];

export const BCPatientSavedDoctors = [
  <Link to={"/patient/dashboard"}>
    <Typography fontSize={12} color={linkColor}>
      Dashboard
    </Typography>
  </Link>,
  <Link to={"/patient/my_doctors"}>
    <Typography fontSize={12} color={linkColor}>
      My Doctors
    </Typography>
  </Link>,
];

export const BCPatientAllSpecialities = [
  <Link to={"/patient/dashboard"}>
    <Typography fontSize={12} color={linkColor}>
      Dashboard
    </Typography>
  </Link>,
  <Link to={"/patient/dashboard/all_specialities"}>
    <Typography fontSize={12} color={linkColor}>
      All Specialities
    </Typography>
  </Link>,
];
