import React from "react";
import Header from "components/Layouts/Header";
const HomeLayout = (props) => {
  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header theme={""} isMenuOpened={true} openLeftMenuCallBack={false} />
      </div>
    </React.Fragment>
  );
};

export default HomeLayout;
