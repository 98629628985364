import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import {
  useGetConsultationNotesQuestionsQuery,
  usePostConsultationNotesMutation,
} from "../api/AppointmentsApi";
import useAuth from "../hooks/useAuth";
import useExistingUser from "../hooks/useExistingUser";
import InfoText from "../components/UI/InfoText";
import { NavigateNext } from "@mui/icons-material";
import { linkColor } from "../components/UI/BreadcrumbsList";
import DefaultLoader from "../components/UI/DefaultLoader";
import { toastr } from "react-redux-toastr";

export default function AddConsultationNotesPage() {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();
  const params = useParams();
  const navigate = useNavigate();

  const [formAnswers, setFormAnswers] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const { data, isLoading, isError } = useGetConsultationNotesQuestionsQuery(
    {
      accessToken,
      userId: existingUser?.user?.id,
      appointmentId: params?.appointmentId,
    },
    { refetchOnReconnect: true, refetchOnMountOrArgChange: true }
  );

  const [postConsultationNotes, { isLoading: isFormSubmitting }] =
    usePostConsultationNotesMutation();

  useEffect(() => {
    return () => {
      setFormAnswers({});
      setFormErrors({});
    };
  }, []);

  useEffect(() => {
    if (!isLoading) {
      data?.consultation_notes?.forEach((item) => {
        setFormAnswers((prev) => ({ ...prev, [item?.id]: item?.answer || "" }));
      });
    }
  }, [data, isLoading]);

  const formValidate = () => {
    let hasError = false;
    Object.keys(formAnswers).forEach((id) => {
      if (!formAnswers[id] || formAnswers[id] === "") {
        hasError = true;
        setFormErrors((prev) => ({ ...prev, [id]: true }));
      }
    });
    return hasError;
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const hasError = formValidate();
    if (!hasError) {
      try {
        const res = await postConsultationNotes({
          accessToken,
          userId: existingUser?.user?.id,
          appointmentId: params?.appointmentId,
          notesAnswer: Object.keys(formAnswers).map((id) => ({
            consultation_note_id: id,
            answer: formAnswers[id],
          })),
        }).unwrap();
        toastr.success(res?.message);
        navigate(`/doctor/my_appointments/${params?.appointmentId}`);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const breadcrumbs = [
    <Link
      to={`/${
        existingUser?.user?.type === "Patient" ? "patient" : "doctor"
      }/my_appointments`}
    >
      <Typography fontSize={12} color={linkColor}>
        My Appointments
      </Typography>
    </Link>,
    <Link
      to={`/${
        existingUser?.user?.type === "Patient" ? "patient" : "doctor"
      }/my_appointments`}
      state={{ tab: "completed" }}
    >
      <Typography fontSize={12} color={linkColor}>
        Completed Appointments
      </Typography>
    </Link>,
    <Link
      to={`/${
        existingUser?.user?.type === "Patient" ? "patient" : "doctor"
      }/my_appointments/${params?.appointmentId}`}
    >
      <Typography fontSize={12} color={linkColor}>
        Appointment Details
      </Typography>
    </Link>,
    <Link
      to={`/${
        existingUser?.user?.type === "Patient" ? "patient" : "doctor"
      }/my_appointments/${params?.appointmentId}/add_clinical_notes`}
    >
      <Typography fontSize={12} color={linkColor}>
        Clinical Notes
      </Typography>
    </Link>,
  ];

  if (isError) {
    return <Navigate to={"/page_not_found"} replace={true} />;
  }

  return isLoading ? (
    <DefaultLoader height={"calc(100vh - 70px - 60px)"} />
  ) : (
    <>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
      <Container>
        <Box mt={2}>
          <Typography fontSize={18} fontWeight={700} mb={2}>
            Clinical Notes
          </Typography>
          <InfoText
            text={
              "Please fill in your observations for the patient. Please make sure to fill in the right details as this will be the second opinion observation report for the patient."
            }
          />
          <Box component={"form"} mt={2} onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                rowGap: "1rem",
                columnGap: "2.5rem",
              }}
            >
              {data?.consultation_notes?.map((item) => (
                <Box key={item?.id}>
                  <Typography fontWeight={700} mb={1}>
                    {item?.title}
                  </Typography>
                  <Typography fontSize={12} mb={1}>
                    {item?.required && "* "}
                    {item?.sub_title}
                  </Typography>
                  <TextField
                    value={formAnswers[item?.id]}
                    onChange={(evt) => {
                      setFormAnswers((prev) => ({
                        ...prev,
                        [item?.id]: evt.target.value,
                      }));
                      if (evt.target.value.trim() === "" && item?.required) {
                        setFormErrors((prev) => ({
                          ...prev,
                          [item?.id]: true,
                        }));
                      } else {
                        setFormErrors((prev) => ({
                          ...prev,
                          [item?.id]: false,
                        }));
                      }
                    }}
                    error={formErrors[item?.id]}
                    multiline={true}
                    fullWidth={true}
                    placeholder={item?.placeholder}
                    minRows={4}
                    maxRows={4}
                    sx={{
                      width: "100%",
                      borderRadius: "5px",
                      "& .MuiInputBase-root": {
                        bgcolor: "#f6f6f6",
                        borderRadius: "5px",
                      },
                      "& fieldset": { border: "none" },
                    }}
                    helperText={
                      formErrors[item?.id] && "This field cannot be empty"
                    }
                  />
                </Box>
              ))}
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", mt: "1.5rem" }}
            >
              <Button
                type={"submit"}
                variant={"contained"}
                disabled={isFormSubmitting}
                sx={{ width: "25%" }}
              >
                {isFormSubmitting ? (
                  <CircularProgress size={"1.5rem"} />
                ) : (
                  "Submit"
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
}
