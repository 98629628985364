import { Link, useNavigate, Navigate, useParams } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import {
  useGetAppointmentDetailsQuery,
  useRequestRefundMutation,
} from "api/AppointmentsApi";
import AppointmentCard from "components/AppointmentCard";
import AppointmentShareReportItem from "components/AppointmentShareReportItem";
import DefaultLoader from "components/UI/DefaultLoader";
import useAuth from "hooks/useAuth";
import useExistingUser from "hooks/useExistingUser";
import { NavigateNext } from "@mui/icons-material";
import { linkColor } from "components/UI/BreadcrumbsList";
import { useDispatch } from "react-redux";
import { setBookafyLink } from "../store/bookafy";
import { useState } from "react";
import AddReviewPopup from "components/AddReviewPopup";
import ConsultationNotesListPopup from "components/ConsultationNotesListPopup";
import { fileTypeExt } from "utilities/constants";
import CancelAppointmentPopup from "components/CancelAppointmentPopup";
import moment from "moment";
import mtz from "moment-timezone";
import ViewReviewPopup from "components/ViewReviewPopup";
import { useRollbar } from "@rollbar/react";
import RescheduleInfoPopup from "components/RescheduleInfoPopup";

export default function AppointmentDetailsPage() {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();
  const rollbar = useRollbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const [showCancelAppointmentPopup, setShowCancelAppointmentPopup] =
    useState(false);
  const [showAddReviewPopup, setShowAddReviewPopup] = useState(false);
  const [showViewReviewPopup, setShowViewReviewPopup] = useState(false);
  const [showConsultationPopup, setShowConsultationPopup] = useState(false);
  const [showRescheduleInfoPopup, setShowRescheduleInfoPopup] = useState(false);

  const {
    data: appointmentDetailsData,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useGetAppointmentDetailsQuery(
    {
      accessToken,
      userType: existingUser?.user?.type?.toLowerCase() + "s",
      userId: existingUser?.user?.id,
      appointmentId: params?.appointmentId,
    },
    { refetchOnReconnect: true, refetchOnMountOrArgChange: true }
  );

  const [requestRefund, { isLoading: isRequestRefundLoading }] =
    useRequestRefundMutation();

  const handleRescheduleAppointment = () => {
    if (
      appointmentDetailsData?.appointment?.reschedule_button_status === false
    ) {
      setShowRescheduleInfoPopup(true);
    } else {
      sessionStorage.setItem(
        "appointmentDetails",
        JSON.stringify({
          appointmentId: params?.appointmentId,
          userId: existingUser?.user?.id,
          rescheduled: true,
        })
      );
      dispatch(setBookafyLink(appointmentDetailsData?.calendar_url));
      sessionStorage.setItem(
        "bookafyLink",
        JSON.stringify({ bookafyLink: appointmentDetailsData?.calendar_url })
      );
      navigate(
        `/patient/my_appointments/${params?.appointmentId}/book_appointment`
      );
    }
  };

  const handleDownloadInvoice = () => {
    fetch(
      appointmentDetailsData?.appointment?.payment_transaction
        ?.payment_invoice_url
    )
      .then((res) => res.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download =
          appointmentDetailsData?.appointment?.payment_transaction?.file_name;
        a.click();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleRefund = async () => {
    try {
      await requestRefund({
        accessToken,
        userId: existingUser?.user?.id,
        appointmentId: params?.appointmentId,
      }).unwrap();
    } catch (err) {
      rollbar.error("Error while requesting refund", err, {
        accessToken,
        userId: existingUser?.user?.id,
        appointmentId: params?.appointmentId,
      });
    }
  };

  const breadcrumbs = [
    <Link
      to={`/${
        existingUser?.user?.type === "Patient" ? "patient" : "doctor"
      }/my_appointments`}
    >
      <Typography fontSize={12} color={linkColor}>
        My Appointments
      </Typography>
    </Link>,
    <Link
      to={`/${
        existingUser?.user?.type === "Patient" ? "patient" : "doctor"
      }/my_appointments`}
      state={{ tab: appointmentDetailsData?.category }}
    >
      <Typography fontSize={12} color={linkColor}>
        {appointmentDetailsData?.category === "upcoming" &&
          "Upcoming Appointments"}
        {appointmentDetailsData?.category === "inprogress" &&
          "In Progress Appointments"}
        {appointmentDetailsData?.category === "completed" &&
          "Completed Appointments"}
      </Typography>
    </Link>,
    <Link
      to={`/${
        existingUser?.user?.type === "Patient" ? "patient" : "doctor"
      }/my_appointments/${params?.appointmentId}`}
    >
      <Typography fontSize={12} color={linkColor}>
        Appointment Details
      </Typography>
    </Link>,
  ];

  if (isError) {
    return <Navigate to={"/page_not_found"} replace />;
  }

  return isLoading || isFetching ? (
    <DefaultLoader height={"calc(100vh - 70px - 60px)"} />
  ) : (
    <>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          "& > * + *": {
            ml: { xs: 0, lg: "1rem" },
          },
          mt: "1.5rem",
        }}
      >
        <Box sx={{ maxWidth: { xs: "100%", lg: "43.75rem" }, width: "100%" }}>
          <AppointmentCard
            key={appointmentDetailsData?.appointment?.id}
            type={appointmentDetailsData?.category}
            id={appointmentDetailsData?.appointment?.id}
            status={
              appointmentDetailsData?.category !== "inprogress" &&
              appointmentDetailsData?.appointment?.tag_name
            }
            appointmentType={
              appointmentDetailsData?.appointment?.appointment_type
            }
            avatarUrl={appointmentDetailsData?.appointment?.avatar_url}
            consultationId={
              appointmentDetailsData?.appointment?.consultation_id
            }
            startTime={appointmentDetailsData?.appointment?.start_time}
            endTime={appointmentDetailsData?.appointment?.end_time}
            specializations={
              appointmentDetailsData?.appointment?.specializations
            }
            name={appointmentDetailsData?.appointment?.practitioner_name}
            unreadNotes={
              appointmentDetailsData?.appointment?.new_comments_count
            }
            navigationDisabled={true}
            appointmentDate={
              appointmentDetailsData?.appointment?.appointment_date
            }
            callDisabled={
              appointmentDetailsData?.category === "completed" ||
              (appointmentDetailsData?.category === "inprogress" &&
                moment
                  .utc(appointmentDetailsData?.appointment?.end_time)
                  .local()
                  .diff(new Date()) < 0) ||
              !appointmentDetailsData?.appointment?.join_url
            }
            showViewNoteButton={true}
            refetch={refetch}
            joinUrl={appointmentDetailsData?.appointment?.join_url}
            appointmentTitle={appointmentDetailsData?.appointment?.title}
          />
          <Box sx={{ bgcolor: "#F5F5F5", p: "1rem", pt: "1.5rem" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography fontSize={18} fontWeight={700}>
                Share Files (
                {appointmentDetailsData?.shared_medical_reports?.length || 0})
              </Typography>
              {appointmentDetailsData?.category === "upcoming" && (
                <Button
                  variant="contained"
                  onClick={() => navigate("share_records")}
                >
                  Add Files
                </Button>
              )}
              {appointmentDetailsData?.category === "completed" &&
                appointmentDetailsData?.appointment?.tag_name === null &&
                (appointmentDetailsData?.appointment?.submitted_review ? (
                  <Button
                    variant="contained"
                    onClick={() => setShowViewReviewPopup(true)}
                  >
                    View Review
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => setShowAddReviewPopup(true)}
                  >
                    Add Review
                  </Button>
                ))}
            </Box>
            <Box sx={{ mt: "1.5rem", display: "grid", gap: "1rem" }}>
              {appointmentDetailsData?.shared_medical_reports?.map((item) => (
                <AppointmentShareReportItem
                  key={item?.id}
                  id={item?.id}
                  appointmentId={params?.appointmentId}
                  fileName={`${item?.file_name}${
                    item?.report_type === "DownloadMedicalReport"
                      ? fileTypeExt[item?.file_type]
                      : ""
                  }`}
                  fileLink={item?.attachment_url}
                  sharedReportTime={item?.shared_report_time}
                  lastItem={
                    appointmentDetailsData?.shared_medical_reports?.length === 1
                  }
                  showRevokeOption={
                    appointmentDetailsData?.category === "upcoming"
                  }
                  verified={item?.report_type === "DownloadMedicalReport"}
                />
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            mt: { xs: "1rem", lg: 0 },
            maxWidth: { xs: "100%", lg: "16rem" },
            width: "100%",
            display: "grid",
            gap: "1.25rem",
            alignItems: "start",
            alignContent: "start",
          }}
        >
          {appointmentDetailsData?.category === "upcoming" && (
            <>
              <Button
                fullWidth
                variant={"contained"}
                onClick={handleRescheduleAppointment}
              >
                Reschedule Appointment
              </Button>
              <Button
                fullWidth
                variant={"outlined"}
                onClick={handleDownloadInvoice}
              >
                Download Invoice
              </Button>
              <Button
                fullWidth
                variant={"outlined"}
                color="error"
                onClick={() => setShowCancelAppointmentPopup(true)}
              >
                Cancel Appointment
              </Button>
            </>
          )}
          {appointmentDetailsData?.category === "completed" && (
            <>
              {appointmentDetailsData?.appointment?.tag_name === null && (
                <>
                  <Button
                    fullWidth
                    variant={"outlined"}
                    onClick={handleDownloadInvoice}
                  >
                    Download Invoice
                  </Button>
                  <Button
                    fullWidth
                    variant={"contained"}
                    onClick={() => setShowConsultationPopup(true)}
                  >
                    View Clinical Notes
                  </Button>
                </>
              )}
              {appointmentDetailsData?.appointment?.tag_name ===
                "Patient no show" && (
                <>
                  <Button
                    variant="contained"
                    onClick={handleRescheduleAppointment}
                  >
                    Reschedule Appointment
                  </Button>
                  <Button variant="outlined" onClick={handleDownloadInvoice}>
                    Download Invoice
                  </Button>
                </>
              )}
              {appointmentDetailsData?.appointment?.tag_name ===
                "Doctor no show" && (
                <>
                  {appointmentDetailsData?.appointment
                    ?.refund_button_status && (
                    <>
                      <Button
                        variant="contained"
                        onClick={handleRescheduleAppointment}
                        disabled={isRequestRefundLoading}
                      >
                        Reschedule Appointment
                      </Button>
                      <Button
                        variant="outlined"
                        disabled={isRequestRefundLoading}
                        onClick={handleRefund}
                      >
                        {isRequestRefundLoading ? (
                          <CircularProgress size={"1.5rem"} />
                        ) : (
                          "Request Refund"
                        )}
                      </Button>
                    </>
                  )}
                  <Button variant="outlined" onClick={handleDownloadInvoice}>
                    Download Invoice
                  </Button>
                </>
              )}
              {appointmentDetailsData?.appointment?.tag_name ===
                "Cancelled by doctor" && (
                <>
                  <CancelReasonCard
                    reason={
                      appointmentDetailsData?.appointment
                        ?.reason_for_cancellation
                    }
                    date={moment
                      .utc(appointmentDetailsData?.appointment?.cancelled_at)
                      .local()
                      .format("MMMM, DD YYYY")}
                    time={moment
                      .utc(appointmentDetailsData?.appointment?.cancelled_at)
                      .local()
                      .format("hh:mm A")}
                  />
                  <Button variant="outlined" onClick={handleDownloadInvoice}>
                    Download Invoice
                  </Button>
                </>
              )}
              {appointmentDetailsData?.appointment?.tag_name ===
                "Cancelled by patient" && (
                <>
                  <CancelReasonCard
                    reason={
                      appointmentDetailsData?.appointment
                        ?.reason_for_cancellation
                    }
                    date={moment
                      .utc(appointmentDetailsData?.appointment?.cancelled_at)
                      .local()
                      .format("MMM, DD YYYY")}
                    time={moment
                      .utc(appointmentDetailsData?.appointment?.cancelled_at)
                      .local()
                      .format("hh:mm A")}
                  />
                  <Button variant="outlined" onClick={handleDownloadInvoice}>
                    Download Invoice
                  </Button>
                </>
              )}
            </>
          )}
        </Box>
      </Box>
      {showCancelAppointmentPopup && (
        <CancelAppointmentPopup
          show={showCancelAppointmentPopup}
          hide={() => setShowCancelAppointmentPopup(false)}
          appointmentId={appointmentDetailsData?.appointment?.id}
        />
      )}
      {showAddReviewPopup && (
        <AddReviewPopup
          open={showAddReviewPopup}
          close={() => setShowAddReviewPopup(false)}
          appointmentId={params?.appointmentId}
          userId={existingUser?.user?.id}
          practitionerName={
            appointmentDetailsData?.appointment?.practitioner_name
          }
          practitionerId={appointmentDetailsData?.appointment?.practitioner_id}
        />
      )}
      {showViewReviewPopup && (
        <ViewReviewPopup
          show={showViewReviewPopup}
          close={() => setShowViewReviewPopup(false)}
          appointmentId={params?.appointmentId}
        />
      )}
      {showConsultationPopup && (
        <ConsultationNotesListPopup
          show={showConsultationPopup}
          close={() => setShowConsultationPopup(false)}
          appointmentId={params?.appointmentId}
        />
      )}
      {showRescheduleInfoPopup && (
        <RescheduleInfoPopup
          show={showRescheduleInfoPopup}
          hide={() => setShowRescheduleInfoPopup(false)}
        />
      )}
    </>
  );
}

const CancelReasonCard = ({ reason, date, time }) => {
  return (
    <Box
      sx={{
        border: "1px solid #e3e3e3",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <Box sx={{ bgcolor: "#e3e3e3", p: 1 }}>
        <Typography fontSize={14}>Reason for cancellation</Typography>
      </Box>
      <Typography
        sx={{ wordBreak: "break-word" }}
        p={1}
        fontSize={12}
        color={"text.secondary"}
      >
        {reason}
      </Typography>
      <Box sx={{ display: "flex", p: 1 }}>
        <Box
          sx={{
            px: 1,
            py: ".25rem",
            bgcolor: "#e3e3e3",
            borderRadius: "99rem",
          }}
        >
          <Typography fontSize={10}>
            {date}&nbsp;{mtz.tz(mtz.tz.guess()).format("z")}
          </Typography>
        </Box>
        <Box
          ml={1}
          sx={{
            px: 1,
            py: ".25rem",
            bgcolor: "#e3e3e3",
            borderRadius: "99rem",
          }}
        >
          <Typography fontSize={10}>{time}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
