import {
  SET_PATIENTGET_REQUEST,
  SET_PATIENTGET_SUCCESS,
  SET_PATIENTGET_FAILURE,
  SET_PATIENTPOST_REQUEST,
  SET_PATIENTPOST_SUCCESS,
  SET_PATIENTPOST_FAILURE,
  GET_EPIC_MEDICAL_RECD_REQUEST,
  GET_EPIC_MEDICAL_RECD_SUCCESS,
  GET_EPIC_MEDICAL_RECD_FAILURE,
  GET_HEALTHCARE_PROVIDER_LIST_REQUEST,
  GET_HEALTHCARE_PROVIDER_LIST_SUCCESS,
  GET_HEALTHCARE_PROVIDER_LIST_FAILURE,
  GET_SELECT_PROVIDER_ID,
} from "actions/patientAction";
const initialState = {
  loading: true,
  error: false,
  patientUpdated: false,
  patientRecords: [],
  records: [],
  recordLoad: false,
  healthcareProvides: [],
  selectedProviderId: null,
};

const patientReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PATIENTGET_REQUEST: {
      return { ...state, loading: true };
    }

    case SET_PATIENTGET_SUCCESS: {
      return { ...state, patientRecords: action.payload, loading: false };
    }

    case SET_PATIENTGET_FAILURE: {
      return { ...state, loading: true };
    }

    case SET_PATIENTPOST_REQUEST: {
      return { ...state, loading: true, patientUpdated: true };
    }

    case SET_PATIENTPOST_SUCCESS: {
      return { ...state, loading: false, patientUpdated: false };
    }

    case SET_PATIENTPOST_FAILURE: {
      return {
        ...state,
        loading: true,
        error: action.payload.error,
        patientUpdated: true,
      };
    }

    case GET_EPIC_MEDICAL_RECD_REQUEST: {
      return { ...state, recordLoad: true };
    }

    case GET_EPIC_MEDICAL_RECD_SUCCESS: {
      return { ...state, recordLoad: false, records: action.payload.records };
    }

    case GET_EPIC_MEDICAL_RECD_FAILURE: {
      return { ...state, recordLoad: false, error: action.payload.error };
    }

    case GET_HEALTHCARE_PROVIDER_LIST_REQUEST: {
      return { ...state, loading: true };
    }

    case GET_HEALTHCARE_PROVIDER_LIST_SUCCESS: {
      return {
        ...state,
        healthcareProvides: action.payload.fhir_healthcare_providers,
        error: action.payload.error,
      };
    }

    case GET_HEALTHCARE_PROVIDER_LIST_FAILURE: {
      return { ...state, healthcareProvides: [], error: action.payload.error };
    }

    case GET_SELECT_PROVIDER_ID: {
      return { ...state, selectedProviderId: action.selectedProviderId };
    }

    default:
      return state;
  }
};

export default patientReducer;
