import { useEffect, useState } from "react";
import Select from "react-select";
import { customStyles } from "Styles/custumStyle";
import { DownloadWhiteIcon, FilterWhiteIcon } from "components/UI/Icons";
import HealthRecordsMVPModal from "./HealthRecordsMVPModal";
import { KMedicalRecordType, KModalType } from "utilities/constants";
import PatientConsentPopup from "components/DHR/PatientConsentPopup";
import EnterFileNamePopup from "components/DHR/EnterFileNamePopup";
import DownloadingInfoPopup from "components/DHR/DownloadingInfoPopup";
import {
  useCreateDownloadMedicalReportsMutation,
  useGetPatientDashboardQuery,
} from "api/MedicalRecordApi";
import useAuth from "hooks/useAuth";
import useExistingUser from "hooks/useExistingUser";
import IconsInfo from "components/HealthRecord/IconsInfo";
import { toastr } from "react-redux-toastr";
import { Badge, Button } from "@mui/material";

export default function HealthRecordHeader({
  icon,
  title,
  handleGroupBy,
  medicalRecordType,
  groupBy,
  code,
  setCode,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  mostRecentDataChecked,
  setMostRecentDataChecked,
  hcps,
  setHcps,
  encounters,
  setEncounters,
  categories,
  setCategories,
}) {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();

  const [showDownloadReportsModal, setShowDownloadReportsModal] =
    useState(false);
  const [showFilterReportsModal, setShowFilterReportsModal] = useState(false);
  const [showPatientConsentPopup, setShowPatientConsentPopup] = useState(false);
  const [showEnterFileNamePopup, setShowEnterFileNamePopup] = useState(false);
  const [showDownloadingInfoPopup, setShowDownloadingInfoPopup] =
    useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const [filterSelectedOptionsCount, setFilterSelectedOptionsCount] =
    useState(0);

  const [dataForDownload, setDataForDownload] = useState({});

  const [createDownloadMedicalReports, { isLoading }] =
    useCreateDownloadMedicalReportsMutation();

  const handleCreateDownloadMedicalReports = (fileName) => {
    createDownloadMedicalReports({
      accessToken,
      patientId: existingUser.user.id,
      resourceType: title,
      ...dataForDownload,
      fileName,
    })
      .unwrap()
      .then((res) => {
        if (res?.success) {
          setShowEnterFileNamePopup(false);
          setShowDownloadingInfoPopup(true);
        } else {
          toastr.error(res?.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const { data: patientDashboardInfo } = useGetPatientDashboardQuery(
    { accessToken },
    { refetchOnMountOrArgChange: true, refetchOnReconnect: true }
  );

  useEffect(() => {
    if (patientDashboardInfo) {
      if (title === KMedicalRecordType.VITAL_SIGNS) {
        setTotalCount(patientDashboardInfo?.vital_signs);
      } else if (title === KMedicalRecordType.LAB_RESULTS) {
        setTotalCount(patientDashboardInfo?.labs);
      } else if (title === KMedicalRecordType.ALLERGIES) {
        setTotalCount(patientDashboardInfo?.allergy_intolerances);
      } else if (title === KMedicalRecordType.MEDICAL_HISTORIES) {
        setTotalCount(patientDashboardInfo?.medical_histories);
      } else if (title === KMedicalRecordType.IMMUNIZATIONS) {
        setTotalCount(patientDashboardInfo?.immunizations);
      }
    }
  }, [patientDashboardInfo]);

  useEffect(() => {
    setFilterSelectedOptionsCount(
      categories?.length +
      hcps?.length +
      code?.length +
      encounters?.length +
      (fromDate !== null && toDate !== null ? 1 : 0)
    );
  }, [categories, hcps, encounters, code, fromDate, toDate]);

  return (
    <>
      <div className="my-3 d-flex align-items-center justify-content-between">
        {showPatientConsentPopup && (
          <PatientConsentPopup
            show={showPatientConsentPopup}
            close={() => {
              setDataForDownload({});
              setShowPatientConsentPopup(false);
            }}
            onClickYes={() => {
              setShowPatientConsentPopup(false);
              setShowEnterFileNamePopup(true);
            }}
          />
        )}
        {showEnterFileNamePopup && (
          <EnterFileNamePopup
            show={showEnterFileNamePopup}
            close={() => {
              setDataForDownload({});
              setShowEnterFileNamePopup(false);
            }}
            handleDownload={(fileName) => {
              handleCreateDownloadMedicalReports(fileName);
            }}
            isLoading={isLoading}
          />
        )}
        {showDownloadingInfoPopup && (
          <DownloadingInfoPopup
            show={showDownloadingInfoPopup}
            close={() => {
              setShowDownloadingInfoPopup(false);
            }}
          />
        )}
        <div className="d-flex gap-2">
          <img src={icon} className="ic-img" alt="" />
          <h3 className="black">{title}</h3>
        </div>
        {totalCount > 0 && (
          <div className="d-flex align-items-center gap-2">
            <div className="d-flex">
              <div className="my-2 text-right pt-2">
                <span
                  className="pr-10"
                  style={{ fontSize: "14px", whiteSpace: "nowrap" }}
                >
                  Group by
                </span>
              </div>
              <div className="my-2" style={{ width: "154px" }}>
                <Select
                  className={
                    medicalRecordType?.observations?.length === 0
                      ? "disablecolor   "
                      : " group-select"
                  }
                  defaultValue={groupBy[0]}
                  options={groupBy}
                  isDisabled={medicalRecordType?.observations?.length === 0}
                  styles={customStyles}
                  onChange={handleGroupBy}
                />
              </div>
            </div>
            <button
              className="btn noPadding"
              onClick={() => setShowFilterReportsModal(true)}
            >
              <Badge badgeContent={filterSelectedOptionsCount} color="error">
                <Button variant="contained" sx={{
                  minWidth: 0,
                  width: "30px", height: "30px", p: ".3rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "6px"
                }}><FilterWhiteIcon /></Button>
              </Badge>
            </button>
            {existingUser?.user?.features_status?.download_medical_report && (
              < Button
                variant="contained"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: 0,
                  width: "30px", height: "30px",
                  borderRadius: "6px",
                  p: ".3rem"
                }} onClick={() => setShowDownloadReportsModal(true)}><DownloadWhiteIcon /></Button>
            )}
          </div>
        )}
        {showDownloadReportsModal && (
          <HealthRecordsMVPModal
            show={showDownloadReportsModal}
            close={() => setShowDownloadReportsModal(false)}
            modalType={KModalType.DOWNLOAD}
            code={[]}
            setDataForDownload={setDataForDownload}
            setShowPatientConsentPopup={setShowPatientConsentPopup}
            type={title}
            fromDate={null}
            toDate={null}
            mostRecentDataChecked={mostRecentDataChecked}
            setMostRecentDataChecked={setMostRecentDataChecked}
          />
        )}
        {showFilterReportsModal && (
          <HealthRecordsMVPModal
            show={showFilterReportsModal}
            close={() => setShowFilterReportsModal(false)}
            type={title}
            setCode={setCode}
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
            code={code}
            modalType={KModalType.FILTER}
            mostRecentDataChecked={mostRecentDataChecked}
            setMostRecentDataChecked={setMostRecentDataChecked}
            hcps={hcps}
            encounters={encounters}
            setHcps={setHcps}
            setEncounters={setEncounters}
            categories={categories}
            setCategories={setCategories}
          />
        )}
      </div>
      {totalCount > 0 && <IconsInfo />
      }
    </>
  );
}
