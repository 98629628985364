import { useState } from "react";
import { Box, Dialog, IconButton } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import { Close } from "@mui/icons-material";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default function PDFViewer({ show, close, pdfLink }) {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  console.log(pdfLink);

  return (
    <Dialog open={show} onClose={close} fullWidth maxWidth={"xl"}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: 1,
          p: 1,
        }}
      >
        <IconButton onClick={close}>
          <Close
            sx={{
              borderRadius: "99rem",
              p: ".2rem",
              bgcolor: "#f1f1f1",
            }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          overflowY: "auto",
          minHeight: "70vh",
          bgcolor: (theme) => theme.palette.grey[100],
          "& .react-pdf__Page": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: (theme) => theme.palette.grey[100] + " !important",
            my: 1,
          },
        }}
      >
        <Document
          file={pdfLink}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={""}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              loading={""}
              width={"900"}
              key={index}
              pageNumber={index + 1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          ))}
        </Document>
      </Box>
    </Dialog>
  );
}
