import { Box, CircularProgress, Typography } from "@mui/material";
import { useGetCommentsQuery } from "api/AppointmentsApi";
import BasicModal from "components/UI/BasicModal";
import { NotesWarningIcon } from "components/UI/Icons";
import useAuth from "hooks/useAuth";
import useExistingUser from "hooks/useExistingUser";
import moment from "moment";

export default function ViewNotesPopup({ appointmentId, show, close }) {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();
  const { data, isLoading, isFetching } = useGetCommentsQuery(
    {
      accessToken,
      userId: existingUser?.user?.id,
      appointmentId,
    },
    { refetchOnReconnect: true, refetchOnMountOrArgChange: true }
  );
  return (
    <BasicModal show={show} close={close}>
      {isLoading || isFetching ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "18.75rem",
          }}
        >
          <CircularProgress />
        </Box>
      ) : data?.comments?.length === 0 ? (
        <Box
          sx={{
            minHeight: "18.75rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            "& > * + *": { mt: "1rem" },
          }}
        >
          <Box
            component={NotesWarningIcon}
            sx={{ "& path": { stroke: (theme) => theme.palette.primary.main } }}
          />
          <Typography>
            {existingUser?.user?.type === "Patient"
              ? "No notes yet!"
              : "No notes shared to the patient"}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ pt: "1rem" }}>
          <Typography fontSize={14} fontWeight={700} mb={2}>
            {existingUser?.user?.type === "Patient"
              ? "Here's a note for you from your Doctor!"
              : "Previous notes sent to the patient!"}
          </Typography>
          <Box
            sx={{
              display: "grid",
              gap: "1rem",
              maxHeight: "40vh",
              overflowY: "auto",
            }}
          >
            {data?.comments?.map((item) => (
              <Box
                key={item?.id}
                sx={{
                  bgcolor: "#fafafa",
                  borderRadius: "5px",
                  p: "1rem",
                  display: "grid",
                  gap: ".5rem",
                }}
              >
                <Typography fontSize={14} sx={{ wordBreak: "break-word" }}>
                  {item?.description}
                </Typography>
                <Box>
                  <Typography fontSize={10} color="text.secondary">
                    {moment(item?.commented_time).format("MMM, DD, yyyy")}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {moment(item?.commented_time).format("hh:mm a")}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </BasicModal>
  );
}
