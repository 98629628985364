import { Box, Button } from "@mui/material";

export default function DoctorSpecialityCard({
  img,
  title,
  id,
  setShowNoHcpPopup,
}) {
  const handleOnClick = () => {
    setShowNoHcpPopup(`/patient/dashboard/doctors_list/${id}`);
  };

  return (
    <Button
      id={"speciality-card"}
      sx={{
        display: "grid",
        gap: 1,
        justifyItems: "center",
        alignItems: "start",
        alignContent: "start",
        px: 0,
        py: 0.25,
        fontFamily: "Open Sans",
        minWidth: "6rem",
      }}
      onClick={handleOnClick}
    >
      <Box
        component={"img"}
        src={img}
        alt=""
        sx={{
          width: "4rem",
          height: "4rem",
          borderRadius: "99rem",
          cursor: "pointer",
        }}
      />
      <Box
        sx={{
          fontSize: "0.75rem",
          textAlign: "center",
          cursor: "pointer",
          width: "min-content",
        }}
      >
        {title}
      </Box>
    </Button>
  );
}
