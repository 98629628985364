import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toastr } from "react-redux-toastr";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const AppointmentsApiSlice = createApi({
  reducerPath: "AppointmentsApis",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
  }),
  tagTypes: ["appointmentDetails"],
  endpoints: (builder) => ({
    getUpcomingAppointments: builder.query({
      query: ({ accessToken, userType, userId, page, perPage, date }) => ({
        url: `${userType}/${userId}/appointments/upcoming_list`,
        params: {
          page,
          per_page: perPage,
          date,
        },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getCompletedAppointments: builder.query({
      query: ({ accessToken, userType, userId, page, perPage, date }) => ({
        url: `${userType}/${userId}/appointments/completed_list`,
        params: {
          page,
          per_page: perPage,
          date,
        },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getComments: builder.query({
      query: ({ accessToken, userId, appointmentId }) => ({
        url: `/patients/${userId}/comments`,
        params: {
          appointment_id: appointmentId,
        },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getAppointmentDetails: builder.query({
      keepUnusedDataFor: 0.000001,
      providesTags: ["appointmentDetails"],
      query: ({ accessToken, userType, userId, appointmentId }) => ({
        url: `/${userType}/${userId}/appointments/${appointmentId}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    revokeSharedReport: builder.mutation({
      invalidatesTags: ["appointmentDetails"],
      query: ({
        accessToken,
        appointmentId,
        userType,
        userId,
        sharedReportId,
      }) => ({
        url: `/${userType}/${userId}/appointments/${appointmentId}/shared_medical_reports/${sharedReportId}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const res = await queryFulfilled;
          if (res?.data?.success) {
            toastr.success(res?.data?.message);
          } else {
            toastr.error(res?.data?.message);
          }
        } catch (error) {
          console.error(error);
        }
      },
    }),
    cancelAppointment: builder.mutation({
      invalidatesTags: ["appointmentDetails"],
      query: ({ accessToken, userType, userId, appointmentId, reason }) => ({
        url: `/${userType}/${userId}/appointments/${appointmentId}/cancel`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: {
          reason_for_cancellation: reason,
        },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const res = await queryFulfilled;
          if (res?.data?.success) {
            toastr.success(res?.data?.message);
          } else {
            toastr.error(res?.data?.message);
          }
        } catch (error) {
          console.error(error);
        }
      },
    }),
    addReviewToPractitioner: builder.mutation({
      invalidatesTags: ["appointmentDetails"],
      query: ({
        accessToken,
        userId,
        appointmentId,
        practitionerId,
        like,
        description,
      }) => ({
        url: `/patients/${userId}/appointments/${appointmentId}/reviews`,
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: {
          patient_reviews: {
            practitioner_id: practitionerId,
            like,
            description,
          },
        },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const res = await queryFulfilled;
          if (res?.data?.success) {
            toastr.success(res?.data?.message);
          } else {
            toastr.error(res?.data?.message);
          }
        } catch (error) {
          console.error(error);
        }
      },
    }),
    viewGivenReview: builder.query({
      query: ({ accessToken, userId, appointmentId }) => ({
        url: `/patients/${userId}/appointments/${appointmentId}/reviews`,
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getPatientsIntakeForm: builder.query({
      query: ({ accessToken, userId, appointmentId }) => ({
        url: `/practitioners/${userId}/appointments/${appointmentId}/patient_details`,
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    addMoreShareFiles: builder.mutation({
      query: ({ accessToken, userId, appointmentId, reportsList }) => ({
        url: `/patients/${userId}/appointments/${appointmentId}/shared_medical_reports`,
        method: "POST",
        body: {
          appointments: {
            share_medical_reports_attributes: reportsList,
          },
        },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getConsultationNotesQuestions: builder.query({
      query: ({ accessToken, userId, appointmentId }) => ({
        url: `/practitioners/${userId}/appointments/${appointmentId}/consultation_note_questions`,
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    postConsultationNotes: builder.mutation({
      query: ({ accessToken, userId, appointmentId, notesAnswer }) => ({
        url: `/practitioners/${userId}/appointments/${appointmentId}/consultation_note_answers`,
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: {
          consultation_note: {
            consultation_note_answers: notesAnswer,
          },
        },
      }),
    }),
    getConsultationNotes: builder.query({
      query: ({ accessToken, userType, userId, appointmentId }) => ({
        url: `/${userType}/${userId}/appointments/${appointmentId}/consultation_note_answers`,
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getInprogressAppointments: builder.query({
      query: ({ accessToken, userType, userId, page, perPage, date }) => ({
        url: `/${userType}/${userId}/appointments/inprogress_list`,
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        params: {
          page,
          per_page: perPage,
          date,
        },
      }),
    }),
    updateAppointmentStatus: builder.mutation({
      query: ({ accessToken, userType, userId, appointmentId, status }) => ({
        url: `/${userType}/${userId}/appointments/${appointmentId}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: {
          status,
        },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const res = await queryFulfilled;
          if (res?.data?.success) {
            toastr.success(res?.data?.message);
          } else {
            toastr.error(res?.data?.message);
          }
        } catch (error) {
          console.error(error);
        }
      },
    }),
    requestRefund: builder.mutation({
      invalidatesTags: ["appointmentDetails"],
      query: ({ accessToken, userId, appointmentId }) => ({
        url: `/patients/${userId}/appointments/${appointmentId}/refund_request`,
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const res = await queryFulfilled;
          if (res?.data?.success) {
            toastr.success(res?.data?.message);
          } else {
            toastr.error(res?.data?.message);
          }
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateFileStatus: builder.mutation({
      invalidatesTags: ["appointmentDetails"],
      query: ({ accessToken, userId, userType, appointmentId, reportId }) => ({
        url: `/${userType}s/${userId}/appointments/${appointmentId}/shared_medical_reports/${reportId}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
  }),
});
export const {
  useGetUpcomingAppointmentsQuery,
  useGetCompletedAppointmentsQuery,
  useGetCommentsQuery,
  useGetAppointmentDetailsQuery,
  useRevokeSharedReportMutation,
  useCancelAppointmentMutation,
  useAddReviewToPractitionerMutation,
  useGetPatientsIntakeFormQuery,
  useAddMoreShareFilesMutation,
  useGetConsultationNotesQuestionsQuery,
  usePostConsultationNotesMutation,
  useGetConsultationNotesQuery,
  useGetInprogressAppointmentsQuery,
  useUpdateAppointmentStatusMutation,
  useViewGivenReviewQuery,
  useRequestRefundMutation,
  useUpdateFileStatusMutation,
} = AppointmentsApiSlice;
