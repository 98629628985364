import { Box } from "@mui/material";
import "Styles/Register.css";
import "Styles/HealthRecords.css";
import { Outlet } from "react-router-dom";

import PatientSidebar from "components/UI/PatientSidebar";
import PatientAppWalkthrough from "components/UI/PatientAppWalkthrough";

const PatientMainLayout = () => {
  return (
    <Box>
      <PatientAppWalkthrough />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "228px 1fr", lg: "265px 1fr" },
          minHeight: "calc(100vh - 70px - 60px)",
          mt: "70px",
          mb: "60px",
        }}
      >
        <Box sx={{ borderRight: "1px solid #e9e9ef" }}>
          <PatientSidebar />
        </Box>
        <Box
          sx={{
            px: { xs: "1rem", lg: "2rem" },
            py: { xs: ".5rem", lg: "1rem" },
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default PatientMainLayout;
