import React from "react";
import { Container } from "reactstrap";
import PractitionerProfileForm from "components/Layouts/PractitionerProfileForm";

const PractitionerSignUp = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="row">
            <div className="col-lg-12">
              <div>
                <div>
                  <h4
                    className="mb-0 fw-bold text-center"
                    style={{ paddingTop: "10px" }}
                  >
                    Doctor Profile Update
                  </h4>
                </div>
                <PractitionerProfileForm />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PractitionerSignUp;
