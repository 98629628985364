import { oktaAuthconfig } from "App";
import { toastr } from "react-redux-toastr";

const apiInterceptorMiddleware = () => (next) => (action) => {
  if (action.meta?.requestStatus === "rejected") {
    switch (action?.payload?.status) {
      case 400:
        if (process.env.REACT_APP_ENVIRONMENT === "development") {
          toastr.error(action?.payload?.data?.message);
        }
        break;
      case 401:
        if (process.env.REACT_APP_ENVIRONMENT === "development") {
          toastr.error("401");
        }
        oktaAuthconfig.signOut({
          postLogoutRedirectUri: window.location.origin + "/",
          clearTokensBeforeRedirect: true,
        });
        break;
      case 500:
        if (process.env.REACT_APP_ENVIRONMENT === "development") {
          toastr.error(action?.payload?.data?.message);
        }
        break;
      default:
        break;
    }
  }

  return next(action);
};

export default apiInterceptorMiddleware;
