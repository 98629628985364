import { createAction } from "redux-api-middleware";

export const SET_PATIENTGET_REQUEST = "SET_PATIENTGET_REQUEST";
export const SET_PATIENTGET_SUCCESS = "SET_PATIENTGET_SUCCESS";
export const SET_PATIENTGET_FAILURE = "SET_PATIENTGET_FAILURE";

export const SET_PATIENTPOST_REQUEST = "SET_PATIENTPOST_REQUEST";
export const SET_PATIENTPOST_SUCCESS = "SET_PATIENTPOST_SUCCESS";
export const SET_PATIENTPOST_FAILURE = "SET_PATIENTPOST_FAILURE";

export const GET_EPIC_MEDICAL_RECD_REQUEST = "GET_EPIC_MEDICAL_RECD_REQUEST";
export const GET_EPIC_MEDICAL_RECD_SUCCESS = "GET_EPIC_MEDICAL_RECD_SUCCESS";
export const GET_EPIC_MEDICAL_RECD_FAILURE = "GET_EPIC_MEDICAL_RECD_FAILURE";

export const GET_HEALTHCARE_PROVIDER_LIST_REQUEST =
  "GET_HEALTHCARE_PROVIDER_LIST_REQUEST";
export const GET_HEALTHCARE_PROVIDER_LIST_SUCCESS =
  "GET_HEALTHCARE_PROVIDER_LIST_SUCCESS";
export const GET_HEALTHCARE_PROVIDER_LIST_FAILURE =
  "GET_HEALTHCARE_PROVIDER_LIST_FAILURE";

export const GET_SELECT_PROVIDER_ID = "GET_SELECT_PROVIDER_ID";

const BASE_URL = process.env.REACT_APP_API_URL;

export const patientGetRecords = (accessToken, id) => {
  let apiUrl = "patients/";
  let index = id;
  return createAction({
    endpoint: BASE_URL + apiUrl + index,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      SET_PATIENTGET_REQUEST,
      SET_PATIENTGET_SUCCESS,
      SET_PATIENTGET_FAILURE,
    ],
  });
};

export const patientPostRecords = (accessToken, id, data) => {
  let apiUrl = "patients/";
  let test = JSON.stringify(data);
  // let dta=JSON.stringify(data)
  return createAction({
    endpoint: BASE_URL + apiUrl + id,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: test,
    types: [
      SET_PATIENTPOST_REQUEST,
      SET_PATIENTPOST_SUCCESS,
      SET_PATIENTPOST_FAILURE,
    ],
  });
};

export const getEpicRecords = (access, patientId, oktaToken, providerId) => {
  let dta = JSON.stringify({
    patient_ehr_provider: {
      patient_ehr_id: access.patient,
      provider_name: "Epic",
      expires_in: access.expires_in,
      access_token: access.access_token,
      fhir_api_endpoint_id: localStorage.getItem("providerId"),
    },
  });

  // let dta=JSON.stringify(data)
  return createAction({
    endpoint: BASE_URL + `patients/${patientId}/ehr_provider`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${oktaToken}`,
    },
    body: dta,
    types: [
      GET_EPIC_MEDICAL_RECD_REQUEST,
      GET_EPIC_MEDICAL_RECD_SUCCESS,
      GET_EPIC_MEDICAL_RECD_FAILURE,
    ],
  });
};

export const getHealthcareProviderList = (accessToken) => {
  let apiUrl = "healthcare_providers/list";

  return createAction({
    endpoint: BASE_URL + apiUrl,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      GET_HEALTHCARE_PROVIDER_LIST_REQUEST,
      GET_HEALTHCARE_PROVIDER_LIST_SUCCESS,
      GET_HEALTHCARE_PROVIDER_LIST_FAILURE,
    ],
  });
};

export const getselectedProviderId = (providerId) => {
  return function (dispatch) {
    dispatch({
      type: GET_SELECT_PROVIDER_ID,
      selectedProviderId: providerId,
    });
  };
};
