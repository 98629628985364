import { Avatar, Box, Button, Typography } from "@mui/material";
import { BookmarkIcon } from "./Icons";
import { useNavigate } from "react-router-dom";

export default function ConsultedDoctorCard({
  img,
  practitionerId,
  doctorName,
  specialization,
  bookmarked,
}) {
  const navigate = useNavigate();

  return (
    <Button
      sx={{
        width: "285px",
        height: "80px",
        boxShadow: "4px 8px 16px 0px rgba(90, 90, 90, 0.16)",
        borderRadius: "10px",
        p: "1rem",
      }}
      onClick={() => {
        navigate(
          `/patient/dashboard/doctors_list/about_doctor/${practitionerId}`
        );
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "flex-start",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", textAlign: "left" }}>
          <Avatar src={img} sx={{ height: "50px", width: "50px" }} />
          <Box ml={1}>
            <Typography
              fontSize={12}
              fontWeight={700}
              sx={{ color: "text.primary" }}
            >
              {doctorName}
            </Typography>
            <Typography
              fontSize={12}
              noWrap={true}
              sx={{ color: "text.secondary", width: "22ch" }}
            >
              {specialization}
            </Typography>
          </Box>
        </Box>
        {bookmarked && (
          <Box
            component={BookmarkIcon}
            sx={{
              "& g path": {
                fill: (theme) => theme.palette.primary.main,
              },
            }}
          />
        )}
      </Box>
    </Button>
  );
}
