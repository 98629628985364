import { Box, Typography } from "@mui/material";
import { TickIcon } from "components/UI/Icons";
import { Link } from "react-router-dom";

export default function RescheduleCard() {
  return (
    <Box
      sx={{
        maxWidth: "42rem",
        width: "100%",
        display: "grid",
        placeItems: "center",
        bgcolor: "#fff",
        p: "1.75rem",
        borderRadius: "10px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
      }}
    >
      <Box
        component={TickIcon}
        sx={{
          "& path": { fill: (theme) => theme.palette.primary.main },
          height: "3.75rem",
          width: "3.75rem",
        }}
      />
      <Typography
        fontSize={20}
        fontWeight={700}
        sx={{ mb: "1rem", color: "primary.main" }}
      >
        Appointment confirmed!
      </Typography>
      <Typography fontSize={14} maxWidth={"45ch"} textAlign={"center"} mb={2}>
        Your appointment is rescheduled successfully.
      </Typography>
      <Typography fontSize={14} textAlign={"center"} mb={3}>
        You can find more information in the appointment details page.
      </Typography>
      <Box
        component={Link}
        sx={{ color: (theme) => theme.palette.primary.main }}
        to={`/patient/my_appointments/${
          JSON.parse(sessionStorage.getItem("appointmentDetails"))
            ?.appointmentId
        }`}
      >
        Go To Appointment Details Page
      </Box>
    </Box>
  );
}
