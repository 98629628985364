import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { toastr } from "react-redux-toastr";
import { ChevronRight } from "@mui/icons-material";

import MedicalReportUpload from "./HealthRecord/MedicalReportUpload";

import { DownloadIcon, UploadIcon } from "./UI/Icons";
import {
  useCreateDownloadMedicalReportsMutation,
  useGetHealthRecordsListQuery,
} from "api/MedicalRecordApi";
import useAuth from "hooks/useAuth";

import HealthRecordsMVPModal from "./HealthRecord/HealthRecordsMVPModal";
import { KModalType } from "utilities/constants";
import useExistingUser from "hooks/useExistingUser";
import EnterFileNamePopup from "./DHR/EnterFileNamePopup";
import DownloadingInfoPopup from "./DHR/DownloadingInfoPopup";
import PatientConsentPopup from "./DHR/PatientConsentPopup";

const UMR_SUPPORTED_FORMATS =
  process.env.REACT_APP_SUPPORTED_FORMAT_UMR || "application/pdf";

export default function UploadAndDownloadBtn({ refetch, downloadDisabled }) {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [fileExt, setFileExt] = useState("");
  const [selectedFile, setSelectedFile] = useState({
    file: null,
    fileName: "",
  });
  const [downloadFor, setDownloadFor] = useState("");
  const [dataForDownload, setDataForDownload] = useState({});
  const [mostRecentDataChecked, setMostRecentDataChecked] = useState(false);

  const [showHealthRecordsModal, setShowHealthRecordsModal] = useState(false);
  const [showDownloadReportsModal, setShowDownloadReportsModal] =
    useState(false);
  const [showPatientConsentPopup, setShowPatientConsentPopup] = useState(false);
  const [showEnterFileNamePopup, setShowEnterFileNamePopup] = useState(false);
  const [showDownloadingInfoPopup, setShowDownloadingInfoPopup] =
    useState(false);

  const [
    createDownloadMedicalReports,
    { isLoading: downloadMedicalReportLoading },
  ] = useCreateDownloadMedicalReportsMutation();

  const fileInputForUploadRef = useRef(null);

  const { data: healthRecords, isLoading } = useGetHealthRecordsListQuery(
    { accessToken },
    {
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
      skip: !showHealthRecordsModal,
    }
  );

  useEffect(() => {
    if (selectedFile.file) {
      setShowUploadFileModal(true);
    }
  }, [selectedFile]);

  const handleFileUpload = (evt) => {
    if (evt.target.files[0]) {
      if (
        !UMR_SUPPORTED_FORMATS.split(",").includes(evt.target.files[0].type)
      ) {
        toastr.error(
          "Unsupported file format. The app supports only PDF, .jpg, .jpeg, and .png formats."
        );
      } else if (Math.round(evt.target?.files[0]?.size / 1024) < 307200) {
        setFileExt(evt.target.files[0].name.split(".").pop());
        setSelectedFile({
          file: evt.target.files[0],
          fileName: evt.target.files[0].name.split(".").slice(0, -1).join("."),
        });
      } else {
        toastr.error("File size exceeds the maximum limit of 300 MB");
      }
      evt.target.value = "";
    }
  };

  const handleCreateDownloadMedicalReports = (fileName) => {
    if (downloadFor !== "") {
      createDownloadMedicalReports({
        accessToken,
        patientId: existingUser.user.id,
        resourceType: downloadFor,
        ...dataForDownload,
        fileName,
      })
        .unwrap()
        .then((res) => {
          if (res?.success) {
            setShowEnterFileNamePopup(false);
            setShowDownloadingInfoPopup(true);
          } else {
            toastr.error(res?.message);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        justifyContent: "space-evenly",
        width: "100%",
      }}
    >
      {existingUser?.user?.features_status?.download_medical_report &&
        !downloadDisabled && (
          <Button
            sx={{
              height: "8.5rem",
              width: "8rem",
              display: "grid",
              placeItems: "center",
              bgcolor: "#faf6e5",
              fontWeight: 700,
              fontSize: "0.875rem",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
            }}
            onClick={() => setShowHealthRecordsModal(true)}
          >
            <Box
              component={DownloadIcon}
              sx={{ "& path": { fill: (theme) => theme.palette.primary.main } }}
            />
            <Box>Download EHR data</Box>
          </Button>
        )}
      <Button
        sx={{
          height: "8.5rem",
          // width: existingUser?.user?.features_status?.download_medical_report
          //   ? "8rem"
          //   : "100%",
          width: "8rem",
          display: "grid",
          placeItems: "center",
          bgcolor: "#e5fbfb",
          fontWeight: 700,
          fontSize: "0.875rem",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
        }}
        onClick={() => fileInputForUploadRef?.current?.click()}
      >
        <Box
          component={UploadIcon}
          sx={{ "& path": { fill: (theme) => theme.palette.primary.main } }}
        />
        <Box>Upload from your device</Box>
        <Box
          type="file"
          ref={fileInputForUploadRef}
          component={"input"}
          accept={UMR_SUPPORTED_FORMATS}
          onChange={handleFileUpload}
          sx={{ display: "none" }}
        />
      </Button>
      {showUploadFileModal && (
        <MedicalReportUpload
          show={showUploadFileModal}
          close={() => {
            setFileExt("");
            setSelectedFile({
              file: null,
              fileName: "",
            });
            setShowUploadFileModal(false);
            refetch();
          }}
          fileExt={fileExt}
          selectedFile={selectedFile}
          setFileExt={setFileExt}
          setSelectedFile={setSelectedFile}
        />
      )}
      {showHealthRecordsModal && (
        <Dialog
          open={showHealthRecordsModal}
          onClose={() => setShowHealthRecordsModal(false)}
        >
          <Box
            sx={{
              borderRadius: "0.625rem",
              bgcolor: "#fff",
              width: "18.75rem",
              py: "1.25rem",
              px: "1.5rem",
            }}
          >
            <Typography
              sx={{ fontSize: "0.875rem", fontWeight: 700, mb: "1rem" }}
            >
              Select the health category you want to download your data from!
            </Typography>
            <Box sx={{ mb: "1rem" }}>
              {isLoading ? (
                <Box
                  sx={{
                    minHeight: "18.75rem",
                    display: "grid",
                    placeContent: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                Object.keys(healthRecords)?.map((el) => (
                  <Box
                    key={el}
                    sx={{
                      borderBottom: "1px solid #e6e6e6",
                      "&:last-child": { borderBottom: "none" },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      py: "1rem",
                      px: ".5rem",
                      cursor:
                        healthRecords[el]?.count !== 0
                          ? "pointer"
                          : "not-allowed",
                      "&:hover": {
                        bgcolor:
                          healthRecords[el]?.count !== 0 ? "#f2f2f2" : "#fff",
                      },
                    }}
                    onClick={() => {
                      if (healthRecords[el]?.count !== 0) {
                        setDownloadFor(el);
                        setShowDownloadReportsModal(true);
                        setShowHealthRecordsModal(false);
                      }
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component={"img"}
                        src={healthRecords[el]?.icon}
                        sx={{ height: "2.25rem", width: "2.25rem" }}
                      />
                      <Box ml={2}>
                        <Typography sx={{ fontSize: "0.75rem" }}>
                          {el}
                        </Typography>
                        <Typography
                          sx={{ fontSize: "0.625rem", color: "#838383" }}
                        >
                          {healthRecords[el]?.count}
                        </Typography>
                      </Box>
                    </Box>
                    <ChevronRight color="primary" />
                  </Box>
                ))
              )}
            </Box>
            <Button
              variant="contained"
              sx={{ width: "8.75rem", mx: "auto", display: "block" }}
              onClick={() => setShowHealthRecordsModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </Dialog>
      )}
      {showDownloadReportsModal && (
        <HealthRecordsMVPModal
          show={showDownloadReportsModal}
          close={() => setShowDownloadReportsModal(false)}
          modalType={KModalType.DOWNLOAD}
          code={[]}
          setDataForDownload={setDataForDownload}
          setShowPatientConsentPopup={setShowPatientConsentPopup}
          type={downloadFor}
          fromDate={null}
          toDate={null}
          mostRecentDataChecked={mostRecentDataChecked}
          setMostRecentDataChecked={setMostRecentDataChecked}
        />
      )}
      {showPatientConsentPopup && (
        <PatientConsentPopup
          show={showPatientConsentPopup}
          close={() => {
            setDataForDownload({});
            setShowPatientConsentPopup(false);
          }}
          onClickYes={() => {
            setShowPatientConsentPopup(false);
            setShowEnterFileNamePopup(true);
          }}
        />
      )}
      {showEnterFileNamePopup && (
        <EnterFileNamePopup
          show={showEnterFileNamePopup}
          close={() => {
            setDataForDownload({});
            setShowEnterFileNamePopup(false);
          }}
          handleDownload={(fileName) => {
            handleCreateDownloadMedicalReports(fileName);
          }}
          isLoading={downloadMedicalReportLoading}
        />
      )}
      {showDownloadingInfoPopup && (
        <DownloadingInfoPopup
          show={showDownloadingInfoPopup}
          close={() => {
            setShowDownloadingInfoPopup(false);
            refetch();
          }}
          fromShareReportsPage={true}
        />
      )}
    </Box>
  );
}
