import BasicModal from "./UI/BasicModal";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { WarningRounded as Warning } from "@mui/icons-material";

export default function PaymentAndProfileIncompletionPopup({ show, close }) {
  return (
    <BasicModal show={show} close={close}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          "& > * + *": { mt: "1rem" },
        }}
      >
        <Warning color="primary" sx={{ fontSize: "5rem" }} />
        <Box>
          <Typography fontSize={14} textAlign={"center"} mb={2}>
            Fill out your profile details on the My Profile page to proceed with
            the appointment booking. This is a mandatory step.
          </Typography>
        </Box>
        <Box
          component={Link}
          to={"/patient/profile"}
          sx={{
            textDecoration: "underline !important",
            fontFamily: "Open Sans",
            color: "primary.main",
          }}
        >
          Go To My Profile
        </Box>
      </Box>
    </BasicModal>
  );
}
