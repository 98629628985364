import { Box, Typography } from "@mui/material";
import Footer from "components/Layouts/Footer";
import HomeLayout from "components/Layouts/HomeLayout";
import Loading from "components/UI/Loading";
import useExistingUser from "hooks/useExistingUser";
import useSessionAuth from "hooks/useSessionAuth";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export default function AuthenticateRoute({ userType }) {
  const { existingUser } = useExistingUser();
  const sessionToken = useSessionAuth();
  const location = useLocation();

  return sessionToken ? (
    existingUser?.user?.type === userType ? (
      <>
        <HomeLayout />
        {userType === "Practitioner" &&
        existingUser?.user?.status !== "active" &&
        location.pathname === "/doctor/dashboard" ? (
          !existingUser?.user?.profile_completion ? (
            <Navigate to={"/doctor/profile/edit"} />
          ) : (
            <Box
              sx={{ minHeight: "100vh", display: "grid", placeItems: "center" }}
            >
              <Box
                sx={{
                  border: "1px solid #e9e9ef",
                  borderRadius: "5px",
                  p: "1.25rem",
                }}
              >
                <Typography fontWeight={700} sx={{ mb: ".5rem" }}>
                  Thanks for registering with us.
                </Typography>
                <Typography color={"#333"} fontSize={14}>
                  We kindly request you to wait until our verification process
                  is completed.
                  <br /> You will receive a confirmation email shortly.
                </Typography>
              </Box>
            </Box>
          )
        ) : userType === "Practitioner" ? (
          !existingUser?.user?.profile_completion &&
          location.pathname !== "/doctor/profile/edit" ? (
            <Navigate to={"/doctor/profile/edit"} replace={true} />
          ) : (
            <Outlet />
          )
        ) : userType === "Patient" &&
          !existingUser?.user?.profile_completion &&
          location.pathname !== "/patient/profile/edit" ? (
          <Navigate to={"/patient/profile/edit"} replace={true} />
        ) : (
          <Outlet />
        )}
        <Footer />
      </>
    ) : (
      <Loading />
    )
  ) : (
    <Navigate to="/login" />
  );
}
