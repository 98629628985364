import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import BasicModal from "components/UI/BasicModal";
import moment from "moment";
import useExistingUser from "hooks/useExistingUser";

export default function AppointmentsFilterModal({
  show,
  hide,
  filterDate,
  setFilterDate,
}) {
  const { existingUser } = useExistingUser();
  const [selectedDate, setSelectedDate] = useState(filterDate);

  return (
    <BasicModal show={show} close={hide} size="23rem">
      <Box mt={2}>
        <Typography fontSize={14} fontWeight={700} mb={2}>
          Filter appointments by date
        </Typography>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateCalendar
            minDate={moment(existingUser?.user?.date_of_birth)}
            value={selectedDate}
            onChange={(e) => setSelectedDate(e)}
          />
        </LocalizationProvider>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "0.625rem",
          }}
        >
          <Button variant="outlined" onClick={() => setSelectedDate(null)}>
            Clear
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setFilterDate(selectedDate);
              hide();
            }}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </BasicModal>
  );
}
