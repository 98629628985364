import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const PractitionerApiSlice = createApi({
  reducerPath: "PractitionerApiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
  }),

  endpoints: (builder) => ({
    getSpecializationList: builder.query({
      query: ({ accessToken }) => ({
        url: `specializations`,
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
      transformResponse: (res) =>
        res?.specializations?.map((item) => ({
          specialization_id: item?.id,
          display: item?.display,
        })),
    }),
    getDashboard: builder.query({
      query: ({ accessToken, userId }) => ({
        url: `/practitioners/${userId}/dashboard`,
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    addNotesToPatient: builder.mutation({
      query: ({
        accessToken,
        userId,
        patientId,
        appointmentId,
        description,
      }) => ({
        url: `practitioners/${userId}/comments`,
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: {
          comments: {
            patient_id: patientId,
            description,
            appointment_id: appointmentId,
          },
        },
      }),
    }),
  }),
});
export const {
  useGetSpecializationListQuery,
  useGetDashboardQuery,
  useAddNotesToPatientMutation,
} = PractitionerApiSlice;
