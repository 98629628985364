import { Link } from "react-router-dom";
import { Box, Dialog, IconButton } from "@mui/material";

import DownloadInfoIcon from "assets/images/download-info.svg";
import ModalClose from "assets/images/ModalClose.svg";

export default function DownloadingInfoPopup({
  show,
  close,
  fromShareReportsPage = false,
}) {
  return (
    <Dialog open={show} onClose={close}>
      <Box
        sx={{
          px: "1.125rem",
          py: "1.25rem",
          display: "grid",
          placeItems: "center",
          gap: 2,
          fontFamily: "Open Sans",
          position: "relative",
          width: "330px",
        }}
      >
        <IconButton
          onClick={close}
          sx={{ position: "absolute", top: ".5rem", right: ".5rem" }}
        >
          <img src={ModalClose} alt="closebutton" />
        </IconButton>
        <Box component={"img"} sx={{}} src={DownloadInfoIcon} alt="" />
        {!fromShareReportsPage ? (
          <Box component={"p"} sx={{ textAlign: "center" }}>
            Yay! Download successful. You can view the downloaded files in{" "}
            <Box
              component={Link}
              to="/patient/my_ehr_health_reports"
              sx={{
                color: "primary.main",
                fontWeight: 700,
                textDecoration: "underline !important",
              }}
            >
              My EHR Health Reports
            </Box>
            .
          </Box>
        ) : (
          <Box component="p" sx={{ textAlign: "center" }}>
            Report successfully downloaded. You can view this file under the{" "}
            <b>Share Medical Reports</b> and <b>My EHR Health Reports</b> page.
          </Box>
        )}
      </Box>
    </Dialog>
  );
}
