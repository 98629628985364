import { useState, useEffect, useRef } from "react";
import {
  TextField,
  Autocomplete,
  Box,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  Close,
  ExpandMore,
  InfoOutlined,
  VisibilityOff,
  Visibility,
} from "@mui/icons-material";
import { useGetSpecializationListQuery } from "api/PractitionerApi";
import { useGetSpecialitiesListQuery } from "api/DoctorsApi";
import useAuth from "hooks/useAuth";
import moment from "moment";
import BootstrapTooltip from "components/UI/BootstrapTooltip";
import { toastr } from "react-redux-toastr";
import { KYearsOfExperienceList } from "utilities/constants";

const AcademicProfileForm = ({
  activeTab,
  practitionerData,
  handleTab,
  AddAcademic,
  handleAcademicDetails,
  certificationAdd,
  AddCertificate,
  certificateClose,
  selectedOption,
  academicList,
  setAcademic,
  academic,
  onDeleteRecord,
  certificateList,
  yearsOfExperience,
  setYearsOfExperience,
  selectedSpecialization,
  setSelectedSpecialization,
  specializationListPayload,
  setSpecializationListPayload,
  specializationResponse,
  specialitiesResponse,
  specialitiesListPayload,
  setSpecialitiesListPayload,
  setSelectedOption,
}) => {
  const { accessToken } = useAuth();

  const [specialization, setSpecialization] = useState([]);
  const [specialities, setSpecialities] = useState([]);

  const { data: SpecializationList, isLoading: isSpecializationListLoading } =
    useGetSpecializationListQuery({
      accessToken,
    });

  const { data: SpecialitiesList, isLoading: isSpecialitiesListLoading } =
    useGetSpecialitiesListQuery({
      accessToken,
    });

  const [certificateError, setCertificateError] = useState(false);
  const [academicDetailsError, setAcademicDetailsError] = useState(false);
  const [specialityError, setSpecialityError] = useState(false);
  const [specializationError, setSpecializationError] = useState(false);
  const [npiError, setNpiError] = useState(false);
  const [yearsOfExperienceError, setYearsOfExperienceError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [certFile, setCertFile] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [npiValue, setNpiValue] = useState(
    practitionerData?.practitioner?.professional_detail?.nip_number || ""
  );
  const [isAcademicDetailsAdded, setIsAcademicDetailsAdded] = useState(false);
  const [isCertificateAdded, setisCertificateAdded] = useState(false);

  const inputFileUpload = useRef();

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (evt) => {
    evt.preventDefault();
  };

  useEffect(() => {
    setYearsOfExperience(
      practitionerData?.practitioner?.professional_detail?.years_of_experience
    );
  }, []);

  useEffect(() => {
    if (!isSpecializationListLoading) {
      const vals = selectedSpecialization.map((el) => el.display);
      setSpecialization(
        SpecializationList.filter((el) => !vals.includes(el.display))
      );
    }
  }, [selectedSpecialization, isSpecializationListLoading]);

  useEffect(() => {
    if (!isSpecialitiesListLoading) {
      const vals = selectedOption.map((el) => el.display);
      setSpecialities(
        SpecialitiesList?.specialities
          ?.map((el) => ({ speciality_id: el?.id, display: el?.display }))
          ?.filter((el) => !vals.includes(el.display))
      );
    }
  }, [selectedOption, isSpecialitiesListLoading]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (
      !certificateError &&
      !specialityError &&
      !academicDetailsError &&
      !npiError &&
      !yearsOfExperienceError &&
      !specializationError &&
      !isAcademicDetailsAdded &&
      !isCertificateAdded
    ) {
      handleTab(3);
      handleAcademicDetails({ nip_number: npiValue });
    }
  };

  const handleSpecialization = (value) => {
    const deletedItem = selectedSpecialization.find(
      (option) =>
        !value.some((selectedOption) => selectedOption.id === option.id)
    );

    if (deletedItem) {
      if (specializationResponse.some((obj) => obj.id === deletedItem?.id)) {
        setSpecializationListPayload([
          ...specializationListPayload,
          { ...deletedItem, _destroy: true },
        ]);
      }
      setSelectedSpecialization(value);
    } else {
      setSelectedSpecialization(value);
    }
  };

  const handleSpeciality = (value) => {
    const deletedItem = selectedOption.find(
      (option) =>
        !value.some((selectedOption) => selectedOption.id === option.id)
    );

    if (deletedItem) {
      if (specialitiesResponse.some((obj) => obj.id === deletedItem?.id)) {
        setSpecialitiesListPayload([
          ...specialitiesListPayload,
          { ...deletedItem, _destroy: true },
        ]);
      }
      setSelectedOption(value);
    } else {
      setSelectedOption(value);
    }
  };

  return (
    <>
      <Box component={"form"} onSubmit={handleSubmit}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "1.25rem",
            alignItems: "start",
          }}
        >
          <Box sx={{ display: "grid" }}>
            <Box component={"label"} htmlFor={"npi_number"}>
              NPI (National Provider ID) *
            </Box>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={npiValue}
              onChange={(evt) => {
                const val = evt.target.value.trim();
                setNpiError(val.length === 0);
                if (val.length < 13) {
                  setNpiValue(val);
                }
              }}
              size="small"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              error={npiError}
            />
            {npiError && npiValue.trim().length === 0 && (
              <Typography fontSize={12} mt={"3px"} mx={"14px"} color={"error"}>
                Enter your NPI
              </Typography>
            )}
          </Box>
          <Box sx={{ gridColumn: "2/4" }}>
            <Box component={"label"} htmlFor={"specialities"}>
              Specialities *
            </Box>
            <Autocomplete
              multiple
              disableClearable={true}
              filterSelectedOptions={true}
              limitTags={3}
              id="specialities"
              options={specialities}
              getOptionLabel={(opt) => opt?.display}
              onChange={(e, value) => {
                setSpecialityError(value.length === 0);
                handleSpeciality(value);
              }}
              value={selectedOption}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Specialities"
                  error={specialityError}
                  helperText={specialityError && "Choose the speciality"}
                />
              )}
            />
          </Box>
          <Box sx={{ gridColumn: "1/3" }}>
            <Box component={"label"} htmlFor={"specializations"}>
              Specializations *
            </Box>
            <Autocomplete
              multiple
              disableClearable={true}
              filterSelectedOptions={true}
              limitTags={3}
              id="specialization"
              options={specialization}
              getOptionLabel={(opt) => opt?.display}
              onChange={(e, value) => {
                setSpecializationError(value.length === 0);
                handleSpecialization(value);
              }}
              value={selectedSpecialization}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Specializations"
                  error={specializationError}
                  helperText={
                    specializationError && "Choose the specializations"
                  }
                />
              )}
            />
          </Box>
          <Box>
            <Box component={"label"} htmlFor={"years_of_experience"}>
              Years of Experience *
            </Box>
            <Autocomplete
              id="years_of_experience"
              value={yearsOfExperience ?? null}
              disableClearable={true}
              options={KYearsOfExperienceList}
              onChange={(_, val) => {
                if (val === "" || /^\d+$/.test(val)) {
                  setYearsOfExperience(Math.abs(val.replace(".", "")));
                }
                if (parseInt(val) > 50 || val === "") {
                  setYearsOfExperienceError(true);
                } else {
                  setYearsOfExperienceError(false);
                }
              }}
              sx={{ "& .MuiAutocomplete-input": { p: "0 !important" } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select"
                  error={yearsOfExperienceError}
                  helperText={
                    yearsOfExperienceError && "Enter valid experience"
                  }
                />
              )}
            />
          </Box>
          <Box sx={{ display: "grid" }}>
            <Box component={"label"} htmlFor={"college_name"}>
              College Name *
            </Box>
            <TextField
              id={"college_name"}
              name={"college_name"}
              value={academic.college_name}
              onChange={(evt) => {
                setAcademicDetailsError(false);
                setAcademic((prev) => ({
                  ...prev,
                  college_name: evt.target.value.trimStart(),
                }));
              }}
              size="small"
              error={academicDetailsError}
            />
            {academicDetailsError &&
              academicList.length === 0 &&
              !isAcademicDetailsAdded && (
                <Typography
                  fontSize={12}
                  mt={"3px"}
                  mx={"14px"}
                  color={"error"}
                >
                  Please add your academic details
                </Typography>
              )}
            {isAcademicDetailsAdded && (
              <Typography fontSize={12} mt={"3px"} mx={"14px"} color={"error"}>
                *Please Click on Add to update your academic details
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "grid" }}>
            <Box component={"label"} htmlFor={"degree"}>
              Degree *
            </Box>
            <TextField
              id={"degree"}
              name={"degree"}
              value={academic.course}
              onChange={(evt) => {
                setAcademicDetailsError(false);
                setAcademic((prev) => ({
                  ...prev,
                  course: evt.target.value.trimStart(),
                }));
              }}
              size="small"
              error={academicDetailsError}
            />
          </Box>
          <Box sx={{ display: "grid" }}>
            <Box component={"label"} htmlFor={"year"}>
              Year *
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "9fr 3fr",
                gap: "1rem",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileDatePicker
                  id={"year"}
                  maxDate={moment(new Date())}
                  openTo="year"
                  format="YYYY"
                  views={["year"]}
                  value={!academic.year ? null : moment(academic.year)}
                  onChange={(evt) => {
                    setAcademicDetailsError(false);
                    setAcademic((prev) => ({
                      ...prev,
                      year:
                        moment(new Date()).diff(evt._d) < 0
                          ? moment(new Date()).format("YYYY")
                          : moment(evt._d).format("YYYY"),
                    }));
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      error: academicDetailsError,
                    },
                  }}
                />
              </LocalizationProvider>
              <Button
                variant="contained"
                disabled={
                  !(academic.college_name && academic.course && academic.year)
                }
                onClick={() => {
                  setAcademicDetailsError(false);
                  AddAcademic();
                  setIsAcademicDetailsAdded(false);
                }}
              >
                Add
              </Button>
            </Box>
          </Box>
          {academicList?.length > 0 && (
            <Box sx={{ gridColumn: "1/4" }}>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleAccordionChange("panel1")}
              >
                <AccordionSummary
                  sx={{
                    bgcolor: (theme) => theme.palette.primary.light,
                    color: "white",
                  }}
                  expandIcon={<ExpandMore sx={{ color: "white" }} />}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                      gap: ".75rem",
                    }}
                  >
                    <Typography fontSize={14}>College Name</Typography>
                    <Typography fontSize={14}>Degree</Typography>
                    <Typography fontSize={14}>Year</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ pb: ".5rem", display: "grid", gap: ".5rem" }}
                >
                  {academicList?.map((item, idx) => (
                    <Box
                      key={`${item?.college_name}${item?.year}`}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr 1fr",
                          gap: ".75rem",
                          width: "100%",
                        }}
                      >
                        <Typography fontSize={14}>
                          {item?.college_name}
                        </Typography>
                        <Typography fontSize={14}>{item?.course}</Typography>
                        <Typography fontSize={14}>{item?.year}</Typography>
                      </Box>
                      <IconButton
                        sx={{ p: 0 }}
                        onClick={() => {
                          onDeleteRecord(item?.id, idx);
                        }}
                      >
                        <Close color="error" />
                      </IconButton>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Box>
          )}
          <Box sx={{ display: "grid" }}>
            <Box component={"label"} htmlFor={"Certification"}>
              Certification *
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "8fr 1fr 3fr",
                alignItems: "center",
                gap: ".25rem",
              }}
            >
              <Box
                component="input"
                sx={{ display: "none" }}
                type="file"
                ref={inputFileUpload}
                accept="application/pdf, .png, .jpg, .jpeg, .doc,.docx,application/msword"
                onChange={(evt) => {
                  if (Math.round(evt.target?.files[0]?.size / 1024) < 5120) {
                    certificationAdd(evt);
                    setCertFile(evt.target.files[0]);
                    evt.target.value = "";
                  } else {
                    evt.target.value = "";
                    toastr.error("File size should not exceed 5 MB");
                  }
                }}
              />
              <Button
                sx={{ p: 0 }}
                disabled={certificateList.length >= 5}
                onClick={() => inputFileUpload.current.click()}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "grid",
                    borderRadius: "5px",
                    gridTemplateColumns: "5fr 7fr",
                    border: "1px solid #ccc",
                    color: "#666",
                    fontSize: "0.75rem",
                    "& > *": { px: ".5rem", py: ".5rem" },
                  }}
                >
                  <Box component={"div"} sx={{ bgcolor: "#eee" }}>
                    Choose file
                  </Box>
                  <Typography
                    fontSize={12}
                    noWrap={true}
                    title={certFile?.name || ""}
                  >
                    {certFile?.name ?? "No file chosen"}
                  </Typography>
                </Box>
              </Button>
              <Box sx={{ display: "grid", placeItems: "center" }}>
                <BootstrapTooltip
                  title={
                    <Box
                      component={"ul"}
                      sx={{
                        maxWidth: "200px",
                        pl: "1.5rem",
                        pr: "1rem",
                        py: ".75rem",
                        m: 0,
                      }}
                    >
                      <li>Max 5 certificates can be uploaded</li>
                      <li>File size should not exceed 5 MB</li>
                      <li>File format should be pdf, doc, png, jpg, jpeg</li>
                    </Box>
                  }
                >
                  <InfoOutlined />
                </BootstrapTooltip>
              </Box>
              <Button
                variant="contained"
                disabled={!certFile?.name}
                className="btn btn-info   add-button waves-effect waves-light"
                onClick={(e) => {
                  AddCertificate();
                  setCertFile({});
                  setisCertificateAdded(false);
                }}
              >
                Add
              </Button>
            </Box>
            {certificateError &&
              certificateList.length === 0 &&
              !isCertificateAdded && (
                <Typography
                  fontSize={12}
                  mt={"3px"}
                  mx={"14px"}
                  color={"error"}
                >
                  Please upload certification
                </Typography>
              )}
            {isCertificateAdded && (
              <Typography fontSize={12} mt={"3px"} mx={"14px"} color={"error"}>
                *Please Click on Add to upload your certification
              </Typography>
            )}
          </Box>
          {certificateList.length > 0 && (
            <Box
              sx={{
                width: "100%",
                gridColumn: "1/4",
                display: "grid",
                gridTemplateColumns: "repeat( auto-fit, 12.5rem )",
                gap: "1.25rem",
              }}
            >
              {certificateList.map((item, index) => (
                <Box
                  key={item?.filename || item?.title}
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "11fr 1fr",
                    gap: ".5rem",
                    alignItems: "center",
                    px: "1rem",
                    py: ".5rem",
                    borderRadius: "5px",
                    bgcolor: (theme) => theme.palette.grey[100],
                  }}
                >
                  <Typography
                    fontSize={12}
                    title={item?.filename || item?.title}
                    noWrap={true}
                  >
                    {item?.filename || item?.title}
                  </Typography>
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={() => {
                      certificateClose(item?.id, index);
                    }}
                  >
                    <Close color="error" />
                  </IconButton>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "1.75rem",
          }}
        >
          <Button
            variant="outlined"
            sx={{ width: "8.75rem" }}
            onClick={() => {
              !(academic.college_name && academic.course && academic.year) &&
                !certFile?.name &&
                handleTab(activeTab - 1);
              setIsAcademicDetailsAdded(
                academic.college_name && academic.course && academic.year
              );
              setisCertificateAdded(certFile?.name);
            }}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            sx={{ width: "8.75rem" }}
            onClick={() => {
              setCertificateError(certificateList.length === 0);
              setAcademicDetailsError(academicList.length === 0);
              setSpecialityError(selectedOption?.length === 0);
              setSpecializationError(selectedSpecialization?.length === 0);
              setNpiError(npiValue.length === 0);
              setYearsOfExperienceError(
                !yearsOfExperience ||
                  yearsOfExperience === "" ||
                  yearsOfExperience === null
              );
              setIsAcademicDetailsAdded(
                academic.college_name && academic.course && academic.year
              );
              setisCertificateAdded(certFile?.name);
            }}
            type="submit"
          >
            Next
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AcademicProfileForm;
