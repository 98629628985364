import {
  SET_VITALSIGN_REQUEST,
  SET_VITALSIGN_SUCCESS,
  SET_VITALSIGN_FAILURE,
  SET_ALLERGY_REQUEST,
  SET_ALLERGY_SUCCESS,
  SET_ALLERGY_FAILURE,
  SET_MEDICALHISTORY_REQUEST,
  SET_MEDICALHISTORY_SUCCESS,
  SET_MEDICALHISTORY_FAILURE,
  SET_LAB_REQUEST,
  SET_LAB_SUCCESS,
  SET_LAB_FAILURE,
  SET_IMMUNITY_REQUEST,
  SET_IMMUNITY_SUCCESS,
  SET_IMMUNITY_FAILURE,
  GET_MEASUREMNT_LIST_REQUEST,
  GET_MEASUREMNT_LIST_SUCCESS,
  GET_MEASUREMNT_LIST_FAILURE,
  GET_HISTORY_LIST_REQUEST,
  GET_HISTORY_LIST_SUCCESS,
  GET_HISTORY_LIST_FAILURE,
  GET_ALLERIES_LIST_REQUEST,
  GET_ALLERIES_LIST_SUCCESS,
  GET_ALLERIES_LIST_FAILURE,
  GET_LAB_LIST_REQUEST,
  GET_LAB_LIST_SUCCESS,
  GET_LAB_LIST_FAILURE,
  GET_VACCIENS_LIST_REQUEST,
  GET_VACCIENS_LIST_SUCCESS,
  GET_VACCIENS_LIST_FAILURE,
  SET_PATIENT_DASHBOARD_REQUEST,
  SET_PATIENT_DASHBOARD_SUCCESS,
  SET_PATIENT_DASHBOARD_FAILURE,
} from "actions/medicalRecords";

const initialState = {
  loading: true,
  vitalSignRecords: [],
  allergyRecords: [],
  medicalHistoryRecords: [],
  immunityRecords: [],
  labRecords: [],
  patientDashboardInfo: [],
  error: null,
  vitalMrstlst: [],
  mediacalHistories: [],
  allergyCategories: [],
  labTests: [],
  vacciens: [],
};

const medicalRecordsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VITALSIGN_REQUEST:
      return { ...state, loading: true };

    case SET_VITALSIGN_SUCCESS:
      return { ...state, vitalSignRecords: action.payload, loading: false };

    case SET_VITALSIGN_FAILURE:
      return { ...state, error: action.payload.error, loading: true };

    case SET_ALLERGY_REQUEST:
      return { ...state, loading: true };

    case SET_ALLERGY_SUCCESS:
      return { ...state, allergyRecords: action.payload, loading: false };

    case SET_ALLERGY_FAILURE:
      return { ...state, error: action.payload.error, loading: true };

    case SET_MEDICALHISTORY_REQUEST:
      return { ...state, loading: true };

    case SET_MEDICALHISTORY_SUCCESS:
      return {
        ...state,
        medicalHistoryRecords: action.payload,
        loading: false,
      };

    case SET_MEDICALHISTORY_FAILURE:
      return { ...state, error: action.payload.error, loading: true };

    case SET_LAB_REQUEST:
      return { ...state, loading: true };

    case SET_LAB_SUCCESS:
      return { ...state, labRecords: action.payload, loading: false };

    case SET_LAB_FAILURE:
      return { ...state, error: action.payload.error, loading: true };

    case SET_IMMUNITY_REQUEST:
      return { ...state, loading: true };

    case SET_IMMUNITY_SUCCESS:
      return { ...state, immunityRecords: action.payload, loading: false };

    case SET_IMMUNITY_FAILURE:
      return { ...state, error: action.payload.error, loading: true };

    case SET_PATIENT_DASHBOARD_REQUEST:
      return { ...state, loading: true };

    case SET_PATIENT_DASHBOARD_SUCCESS:
      return { ...state, patientDashboardInfo: action.payload, loading: false };

    case SET_PATIENT_DASHBOARD_FAILURE:
      return { ...state, error: action.payload.error, loading: true };

    case GET_MEASUREMNT_LIST_REQUEST:
      return { ...state, loading: true };

    case GET_MEASUREMNT_LIST_SUCCESS:
      var result = action.payload.measurements.map((s) => ({
        text: s,
        checked: false,
      }));
      return {
        ...state,
        vitalMrstlst: result,
        error: action.payload.error,
        loading: false,
      };

    case GET_MEASUREMNT_LIST_FAILURE:
      return { ...state, error: action.payload.error, loading: true };

    case GET_HISTORY_LIST_REQUEST:
      return { ...state, loading: true };

    case GET_HISTORY_LIST_SUCCESS:
      var result = action.payload.histories.map((s) => ({
        text: s,
        checked: false,
      }));
      return {
        ...state,
        mediacalHistories: result,
        error: action.payload.error,
        loading: false,
      };

    case GET_HISTORY_LIST_FAILURE:
      return { ...state, error: action.payload.error, loading: true };

    case GET_ALLERIES_LIST_REQUEST:
      return { ...state, loading: true };

    case GET_ALLERIES_LIST_SUCCESS:
      var result = action.payload.categories.map((s) => ({
        text: s,
        checked: false,
      }));
      return {
        ...state,
        allergyCategories: result,
        error: action.payload.error,
        loading: false,
      };

    case GET_ALLERIES_LIST_FAILURE:
      return { ...state, error: action.payload.error, loading: true };

    case GET_LAB_LIST_REQUEST:
      return { ...state, loading: true };

    case GET_LAB_LIST_SUCCESS:
      var result = action.payload.tests.map((s) => ({
        text: s,
        checked: false,
      }));
      return {
        ...state,
        labTests: result,
        error: action.payload.error,
        loading: false,
      };

    case GET_LAB_LIST_FAILURE:
      return { ...state, error: action.payload.error, loading: true };

    case GET_VACCIENS_LIST_REQUEST:
      return { ...state, loading: true };

    case GET_VACCIENS_LIST_SUCCESS:
      var result = action.payload.vaccines.map((s) => ({
        text: s,
        checked: false,
      }));
      return {
        ...state,
        vacciens: result,
        error: action.payload.error,
        loading: false,
      };

    case GET_VACCIENS_LIST_FAILURE:
      return { ...state, error: action.payload.error, loading: true };

    default:
      return state;
  }
};

export default medicalRecordsReducer;
