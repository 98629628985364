import {
  Box,
  TextField,
  Button,
  CircularProgress,
  Dialog,
} from "@mui/material";
import { useState } from "react";

export default function EnterFileNamePopup({
  show,
  close,
  handleDownload,
  isLoading,
}) {
  const [fileName, setFileName] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleDownloadClick = (evt) => {
    evt.preventDefault();
    handleDownload(fileName);
  };

  return (
    <Dialog open={show} onClose={close}>
      <Box
        component={"form"}
        onSubmit={handleDownloadClick}
        sx={{
          width: "330px",
          p: "1.875rem",
          fontFamily: "Open Sans",
          display: "grid",
          gap: "1.75rem",
        }}
      >
        <Box>
          <Box
            component={"label"}
            htmlFor="enter-file-name"
            sx={{ fontWeight: 700 }}
          >
            Enter file name
          </Box>
          <TextField
            id="enter-file-name"
            type="text"
            autoFocus
            fullWidth
            placeholder="Enter file name"
            value={fileName}
            error={errorMsg !== ""}
            helperText={errorMsg}
            onChange={(evt) => {
              if (evt.target.value.trim() === "") {
                setErrorMsg("Enter a valid file name");
              } else {
                if (evt.target.value?.length > 64) {
                  setErrorMsg(" The file name should not exceed 64 characters");
                } else if (
                  !(
                    /^[-_0-9a-zA-Z ]+$/.test(evt.target.value) &&
                    evt.target.value.length <= 64
                  )
                ) {
                  setErrorMsg(
                    "Only alphanumeric, spaces, hyphens, and underscores are permitted"
                  );
                } else {
                  setErrorMsg("");
                }
              }
              setFileName(evt.target.value);
            }}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            sx={{ fontWeight: 700, width: "7.5rem" }}
            onClick={close}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={errorMsg !== "" || fileName === "" || isLoading}
            sx={{ fontWeight: 700, width: "7.5rem" }}
          >
            {isLoading ? <CircularProgress size={"1.5rem"} /> : "Download"}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
