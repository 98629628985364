import useSessionAuth from "hooks/useSessionAuth";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const sessionToken = useSessionAuth()

  return sessionToken ? children : <Navigate to='/login'/>;
};

export default ProtectedRoute;
