import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const CommonApiSlices = createApi({
  reducerPath: "CommonApiSlices",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
  }),
  tagTypes: ["Bookmarks"],
  endpoints: (builder) => ({
    getCountriesList: builder.query({
      keepUnusedDataFor: 0.0000000003,
      query: ({ accessToken }) => ({
        url: `country/lists`,
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    setAppWalkthroughCompletion: builder.mutation({
      query: ({ accessToken, userType, userId }) => ({
        url: `/${userType}s/${userId}/update_app_walkthrough`,
        method: "POST",
        headers: { authorization: `Bearer ${accessToken}` },
        body: {
          [userType]: {
            web_app_walkthrough_status: false,
          },
        },
      }),
    }),
  }),
});

export const {
  useGetCountriesListQuery,
  useSetAppWalkthroughCompletionMutation,
} = CommonApiSlices;
