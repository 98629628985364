import { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { checkExistingUser } from "actions/auth";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import Loading from "components/UI/Loading";
const CheckAuthentication = (props) => {
  const { authState, oktaAuth } = useOktaAuth();

  const userInfo = useSelector((state) => state.auth.existingUser);
  const dispatch = useDispatch();

  let navigate = useNavigate();
  const successToast = (isExistingAccount) => {
    if (!isExistingAccount) {
      toastr.success(" Account created successfully!");
    } else {
      toastr.success("Signed in successfully");
    }
  };

  useEffect(() => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn === "false" && userInfo?.success !== undefined) {
      authState?.accessToken?.accessToken &&
        localStorage.setItem("isLoggedIn", true);
      const isLoggedInCheck = localStorage.getItem("isLoggedIn", true);
      if (isLoggedInCheck === "true") {
        successToast(userInfo?.success);
      }
    }
  }, [authState, oktaAuth, userInfo]);

  useEffect(() => {
    authState?.accessToken?.accessToken
      ? dispatch(checkExistingUser(authState?.accessToken?.accessToken)).then(
          (response) => {
            let isExistingUser = response.payload.success;

            if (isExistingUser) {
              let usertype = response.payload.user.type;
              usertype === "Patient"
                ? navigate("/patient/dashboard")
                : response.payload.user.profile_completion
                ? navigate("/doctor/dashboard")
                : navigate("/doctor/profile/edit");
            } else {
              navigate("/setuserpage");
            }
          }
        )
      : navigate("/login");
  }, [authState, oktaAuth]);

  return <Loading />;
};

export default CheckAuthentication;
