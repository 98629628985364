export const KMedicalRecordType = {
  VITAL_SIGNS: "Vital Signs",
  ALLERGIES: "Allergies",
  LAB_RESULTS: "Lab Results",
  MEDICAL_HISTORIES: "Medical Histories",
  IMMUNIZATIONS: "Immunizations",
};

export const KModalType = {
  FILTER: "filter modal",
  DOWNLOAD: "download modal",
};

export const KYearsOfExperienceList = [...Array(51).keys()].map((el) =>
  el.toString()
);

export const fileTypeExt = {
  "application/pdf": ".pdf",
  "images/png": ".png",
  "images/jpeg": ".jpeg",
};
