import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Badge, Box, Breadcrumbs, Button, Typography } from "@mui/material";
import {
  useGetCompletedAppointmentsQuery,
  useGetInprogressAppointmentsQuery,
  useGetUpcomingAppointmentsQuery,
} from "api/AppointmentsApi";
import useAuth from "hooks/useAuth";
import useExistingUser from "hooks/useExistingUser";
import TabPageLayout from "components/Layouts/TabPageLayout";
import DefaultLoader from "components/UI/DefaultLoader";
import AppointmentCard from "components/AppointmentCard";
import CPagination from "components/UI/CPagination";
import { WhiteFilterIcon, NotesWarningIcon } from "components/UI/Icons";
import PractitionerAppointmentCard from "../components/PractitionerAppointmentCard";
import { NavigateNext } from "@mui/icons-material";
import { linkColor } from "../components/UI/BreadcrumbsList";
import AppointmentsFilterModal from "components/AppointmentsFilterModal";
import moment from "moment";

export default function MyAppointmentsPage() {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();
  const location = useLocation();

  const [tabValue, setTabValue] = useState(() => {
    if (location.state?.tab === "upcoming" || !location.state?.tab) return 0;
    if (location.state?.tab === "inprogress") return 1;
    if (location.state?.tab === "completed") return 2;
  });
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterDate, setFilterDate] = useState(null);

  const {
    data: upcomingAppointmentsListData,
    isLoading: isUpcomingAppointmentsLoading,
    isFetching: isUpcomingAppointmentsFetching,
  } = useGetUpcomingAppointmentsQuery(
    {
      accessToken,
      userType: existingUser?.user?.type?.toLowerCase() + "s",
      userId: existingUser?.user?.id,
      page,
      perPage,
      date: filterDate ? moment(filterDate).format("YYYY-MM-DD") : undefined,
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
      skip: tabValue !== 0,
    }
  );

  const {
    data: completedAppointmentsListData,
    isLoading: isCompletedAppointmentsLoading,
    isFetching: isCompletedAppointmentsFetching,
  } = useGetCompletedAppointmentsQuery(
    {
      accessToken,
      userType: existingUser?.user?.type?.toLowerCase() + "s",
      userId: existingUser?.user?.id,
      page,
      perPage,
      date: filterDate ? moment(filterDate).format("YYYY-MM-DD") : undefined,
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
      skip: tabValue !== 2,
    }
  );

  const {
    data: inprogressAppointmentsData,
    isLoading: isInprogressAppointmentsLoading,
    isFetching: isInprogressAppointmentsFetching,
  } = useGetInprogressAppointmentsQuery(
    {
      accessToken,
      userType: existingUser?.user?.type?.toLowerCase() + "s",
      userId: existingUser?.user?.id,
      page,
      perPage,
      date: filterDate ? moment(filterDate).format("YYYY-MM-DD") : undefined,
    },
    {
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
      skip: tabValue !== 1,
    }
  );

  const handleTabChange = (_, newValue) => {
    setPage(1);
    setTabValue(newValue);
    setFilterDate(null);
  };

  const breadcrumbs = [
    <Link
      to={`/${
        existingUser?.user?.type === "Patient" ? "patient" : "doctor"
      }/my_appointments`}
      state={{ tab: "upcoming" }}
    >
      <Typography fontSize={12} color={linkColor}>
        My Appointments
      </Typography>
    </Link>,
    <Link
      to={`/${
        existingUser?.user?.type === "Patient" ? "patient" : "doctor"
      }/my_appointments`}
    >
      <Typography fontSize={12} color={linkColor}>
        {tabValue === 0 && "Upcoming Appointments"}
        {tabValue === 1 && "In Progress Appointments"}
        {tabValue === 2 && "Completed Appointments"}
      </Typography>
    </Link>,
  ];

  return (
    <Box>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "2rem",
          mt: "1rem",
        }}
      >
        <Box
          sx={{ fontSize: "1.125rem", fontWeight: 700, alignSelf: "center" }}
        >
          My Appointments{" "}
          <Typography variant="span">
            {tabValue === 0 &&
              Boolean(upcomingAppointmentsListData?.appointments_count) &&
              `(${upcomingAppointmentsListData?.appointments_count})`}
            {tabValue === 1 &&
              Boolean(inprogressAppointmentsData?.appointments_count) &&
              `(${inprogressAppointmentsData?.appointments_count})`}
            {tabValue === 2 &&
              Boolean(completedAppointmentsListData?.appointments_count) &&
              `(${completedAppointmentsListData?.appointments_count})`}
          </Typography>
        </Box>
      </Box>
      <TabPageLayout
        maxWidth={"43.75rem"}
        handleTabChange={handleTabChange}
        tabValue={tabValue}
        tabList={["Upcoming", "In Progress", "Completed"]}
        panelList={[
          <Box>
            {isUpcomingAppointmentsFetching || isUpcomingAppointmentsLoading ? (
              <DefaultLoader />
            ) : upcomingAppointmentsListData?.appointments_count === 0 ? (
              <NoAppointmentsFound />
            ) : (
              <Box
                sx={{ display: "grid", gap: "1.75rem", maxWidth: "43.75rem" }}
              >
                {upcomingAppointmentsListData?.appointments?.map((item) =>
                  existingUser?.user?.type === "Patient" ? (
                    <AppointmentCard
                      key={item?.id}
                      id={item?.id}
                      status={item?.tag_name}
                      appointmentType={item?.appointment_type}
                      avatarUrl={item?.avatar_url}
                      consultationId={item?.consultation_id}
                      appointmentDate={item?.appointment_date}
                      startTime={item?.start_time}
                      endTime={item?.end_time}
                      specializations={item?.specializations}
                      name={item?.practitioner_name}
                      unreadNotes={item?.comments_highlight}
                      joinUrl={item?.join_url}
                      appointmentTitle={item?.title}
                      callDisabled={!item?.join_url}
                    />
                  ) : (
                    <PractitionerAppointmentCard
                      key={item?.id}
                      id={item?.id}
                      appointmentType={item?.appointment_type}
                      consultationId={item?.consultation_id}
                      status={item?.tag_name}
                      startTime={item?.start_time}
                      endTime={item?.end_time}
                      date={item?.appointment_date}
                      title={item?.title}
                      patientName={item?.patient_name}
                      joinUrl={item?.join_url}
                      callDisabled={!item?.join_url}
                    />
                  )
                )}
                <CPagination
                  page={page}
                  setPage={setPage}
                  perPage={perPage}
                  setPerPage={setPerPage}
                  totalCount={upcomingAppointmentsListData?.appointments_count}
                />
              </Box>
            )}
          </Box>,
          <Box>
            {isInprogressAppointmentsFetching ||
            isInprogressAppointmentsLoading ? (
              <DefaultLoader />
            ) : inprogressAppointmentsData?.appointments_count === 0 ? (
              <NoAppointmentsFound />
            ) : (
              <Box
                sx={{ display: "grid", gap: "1.75rem", maxWidth: "43.75rem" }}
              >
                {inprogressAppointmentsData?.appointments?.map((item) =>
                  existingUser?.user?.type === "Patient" ? (
                    <AppointmentCard
                      key={item?.id}
                      id={item?.id}
                      appointmentType={item?.appointment_type}
                      avatarUrl={item?.avatar_url}
                      consultationId={item?.consultation_id}
                      appointmentDate={item?.appointment_date}
                      startTime={item?.start_time}
                      endTime={item?.end_time}
                      specializations={item?.specializations}
                      name={item?.practitioner_name}
                      unreadNotes={item?.comments_highlight}
                      joinUrl={item?.join_url}
                      appointmentTitle={item?.title}
                      callDisabled={
                        moment.utc(item?.end_time).local().diff(new Date()) <
                          0 || !item?.join_url
                      }
                    />
                  ) : (
                    <PractitionerAppointmentCard
                      key={item?.id}
                      id={item?.id}
                      appointmentType={item?.appointment_type}
                      consultationId={item?.consultation_id}
                      startTime={item?.start_time}
                      endTime={item?.end_time}
                      date={item?.appointment_date}
                      title={item?.title}
                      patientName={item?.patient_name}
                      joinUrl={item?.join_url}
                      callDisabled={
                        moment.utc(item?.end_time).local().diff(new Date()) <
                          0 || !item?.join_url
                      }
                    />
                  )
                )}
                <CPagination
                  page={page}
                  setPage={setPage}
                  perPage={perPage}
                  setPerPage={setPerPage}
                  totalCount={inprogressAppointmentsData?.appointments_count}
                />
              </Box>
            )}
          </Box>,
          <Box>
            <Box>
              {isCompletedAppointmentsFetching ||
              isCompletedAppointmentsLoading ? (
                <DefaultLoader />
              ) : completedAppointmentsListData?.appointments_count === 0 ? (
                <NoAppointmentsFound />
              ) : (
                <Box
                  sx={{ display: "grid", gap: "1.75rem", maxWidth: "43.75rem" }}
                >
                  {completedAppointmentsListData?.appointments?.map((item) =>
                    existingUser?.user?.type === "Patient" ? (
                      <AppointmentCard
                        key={item?.id}
                        type="completed"
                        id={item?.id}
                        status={item?.tag_name}
                        callDisabled={true}
                        appointmentType={item?.appointment_type}
                        avatarUrl={item?.avatar_url}
                        consultationId={item?.consultation_id}
                        appointmentDate={item?.appointment_date}
                        startTime={item?.start_time}
                        endTime={item?.end_time}
                        specializations={item?.specializations}
                        name={item?.practitioner_name}
                        unreadNotes={item?.comments_highlight}
                        joinUrl={item?.join_url}
                        appointmentTitle={item?.title}
                      />
                    ) : (
                      <PractitionerAppointmentCard
                        key={item?.id}
                        id={item?.id}
                        type="completed"
                        appointmentType={item?.appointment_type}
                        consultationId={item?.consultation_id}
                        status={item?.tag_name}
                        startTime={item?.start_time}
                        endTime={item?.end_time}
                        date={item?.appointment_date}
                        title={item?.title}
                        callDisabled={true}
                        patientName={item?.patient_name}
                        joinUrl={item?.join_url}
                      />
                    )
                  )}
                  <CPagination
                    page={page}
                    setPage={setPage}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    totalCount={
                      completedAppointmentsListData?.appointments_count
                    }
                  />
                </Box>
              )}
            </Box>
          </Box>,
        ]}
        actions={
          tabValue !== 1 &&
          ((tabValue === 0 &&
            !isUpcomingAppointmentsFetching &&
            upcomingAppointmentsListData?.total_count !== 0) ||
            (tabValue === 2 &&
              !isCompletedAppointmentsFetching &&
              completedAppointmentsListData?.total_count !== 0)) && (
            <Badge badgeContent={filterDate && 1} color="error">
              <Button
                variant="contained"
                sx={{ minWidth: 0, p: ".3rem" }}
                onClick={() => setShowFilterModal(true)}
              >
                <WhiteFilterIcon />
              </Button>
            </Badge>
          )
        }
      />
      {showFilterModal && (
        <AppointmentsFilterModal
          show={showFilterModal}
          hide={() => setShowFilterModal(false)}
          filterDate={filterDate}
          setFilterDate={setFilterDate}
        />
      )}
    </Box>
  );
}

const NoAppointmentsFound = () => (
  <Box
    sx={{
      minHeight: "calc(100vh - 414px)",
      display: "grid",
      placeContent: "center",
    }}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        "& > * + *": { mt: ".5rem" },
      }}
    >
      <Box
        component={NotesWarningIcon}
        sx={{
          "& path": {
            fill: (theme) => theme.palette.primary.main,
          },
        }}
      />
      <Typography fontSize={14}>No appointments to show!</Typography>
    </Box>
  </Box>
);
