import { Box, Breadcrumbs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBookafyLink, setBookafyLink } from "store/bookafy";
import DefaultLoader from "components/UI/DefaultLoader";
import DoctorCard from "components/UI/DoctorCard";
import {
  useGetDoctorDetailsQuery,
  useGetSpecialitiesListQuery,
} from "api/DoctorsApi";
import useAuth from "hooks/useAuth";
import { Link, useLocation, useParams } from "react-router-dom";
import { NavigateNext } from "@mui/icons-material";
import CustomLink from "components/UI/CustomLink";
import { setAnswers, setTAndCAgreed } from "store/intakeForm";
import { setShareReports } from "store/shareReports";
import { linkColor } from "components/UI/BreadcrumbsList";

export default function BookAppointmentPage() {
  const { accessToken } = useAuth();
  const params = useParams();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [link, setLink] = useState("");

  const RSBookafyLink = useSelector(getBookafyLink);

  const { data: doctorDetailData } = useGetDoctorDetailsQuery({
    accessToken,
    practitionerId: params?.id,
  });

  const { data: specialitiesListData } = useGetSpecialitiesListQuery(
    { accessToken },
    { refetchOnReconnect: true, skip: params?.specialist === "about_doctor" }
  );

  useEffect(() => {
    setLink(
      RSBookafyLink ||
        JSON.parse(sessionStorage.getItem("bookafyLink") || "{}")?.bookafyLink
    );
    return () => dispatch(setBookafyLink(""));
  }, []);

  const clearState = () => {
    dispatch(setTAndCAgreed(false));
    dispatch(setAnswers({}));
    dispatch(setShareReports({ doctorId: null, list: [], ids: [] }));
  };

  const BreadcrumbsItemsViaSpecialities = [
    <CustomLink to={"/patient/dashboard"} onConfirmClick={clearState}>
      Dashboard
    </CustomLink>,
    <CustomLink
      to={"/patient/dashboard/all_specialities"}
      onConfirmClick={clearState}
    >
      All Specialities
    </CustomLink>,
    <CustomLink
      to={`/patient/dashboard/doctors_list/${params?.specialist}`}
      onConfirmClick={clearState}
    >
      {
        specialitiesListData?.specialities?.find(
          (el) => el?.id === parseInt(params?.specialist)
        )?.display
      }
    </CustomLink>,
    <CustomLink
      to={`/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}`}
      onConfirmClick={clearState}
    >
      About Doctor
    </CustomLink>,
    <Link
      to={`/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}/general_intake_form`}
    >
      <Typography fontSize={12} color={linkColor}>
        General Intake Form
      </Typography>
    </Link>,
    <Link
      to={`/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}/share_records`}
      onConfirmClick={clearState}
    >
      <Typography fontSize={12} color={linkColor}>
        Share Medical Reports
      </Typography>
    </Link>,
    <Typography fontSize={12} color={linkColor} sx={{ cursor: "pointer" }}>
      Book Appointment
    </Typography>,
  ];

  const BreadcrumbsItemsViaPopup = [
    <CustomLink to={"/patient/dashboard"} onConfirmClick={clearState}>
      Dashboard
    </CustomLink>,
    <CustomLink
      to={"/patient/dashboard/all_specialities"}
      onConfirmClick={clearState}
    >
      All Specialities
    </CustomLink>,
    <CustomLink
      to={`/patient/dashboard/doctors_list/${params?.specialist}`}
      onConfirmClick={clearState}
    >
      {
        specialitiesListData?.specialities?.find(
          (el) => el?.id === parseInt(params?.specialist)
        )?.display
      }
    </CustomLink>,
    <Link
      to={`/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}/general_intake_form`}
    >
      <Typography fontSize={12} color={linkColor}>
        General Intake Form
      </Typography>
    </Link>,
    <Link
      to={`/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}/share_records`}
      onConfirmClick={clearState}
    >
      <Typography fontSize={12} color={linkColor}>
        Share Medical Reports
      </Typography>
    </Link>,
    <Typography fontSize={12} color={linkColor} sx={{ cursor: "pointer" }}>
      Book Appointment
    </Typography>,
  ];

  const BreadcrumbsItems = [
    <CustomLink to={"/patient/dashboard"} onConfirmClick={clearState}>
      Dashboard
    </CustomLink>,
    <CustomLink
      to={`/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}`}
      onConfirmClick={clearState}
    >
      About Doctor
    </CustomLink>,
    <Link
      to={`/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}/general_intake_form`}
    >
      <Typography fontSize={12} color={linkColor}>
        General Intake Form
      </Typography>
    </Link>,
    <Link
      to={`/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}/share_records`}
      onConfirmClick={clearState}
    >
      <Typography fontSize={12} color={linkColor}>
        Share Medical Reports
      </Typography>
    </Link>,
    <Typography fontSize={12} color={linkColor} sx={{ cursor: "pointer" }}>
      Book Appointment
    </Typography>,
  ];

  return (
    <Box>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        {params?.specialist === "about_doctor"
          ? BreadcrumbsItems
          : state?.viaPopup
          ? BreadcrumbsItemsViaPopup
          : BreadcrumbsItemsViaSpecialities}
      </Breadcrumbs>
      {doctorDetailData?.practitioner && (
        <Box mt={2}>
          <DoctorCard
            name={doctorDetailData?.practitioner?.full_name}
            consultationFees={
              doctorDetailData?.practitioner?.consultation_fees ?? 0
            }
            exp={
              doctorDetailData?.practitioner?.professional_detail
                ?.years_of_experience || 0
            }
            img={doctorDetailData?.practitioner?.avatar_url}
            specialization={
              doctorDetailData?.practitioner?.specializations &&
              (doctorDetailData?.practitioner?.specializations?.join(", ") ||
                "")
            }
            dontShrinkSpecialization={true}
            qualification={
              doctorDetailData?.practitioner?.acadamic_details &&
              (doctorDetailData?.practitioner?.acadamic_details
                ?.map((el) => el?.course)
                ?.join(", ") ||
                "")
            }
            showLikesAndReviews={false}
            showActions={false}
          />
        </Box>
      )}
      <Box
        sx={{
          height: "calc(100vh - 70px - 1.5rem - 60px)",
          position: "relative",
          zIndex: 0,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            zIndex: -99,
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
          }}
        >
          <DefaultLoader height="100%" />
        </Box>
        <Box
          component={"iframe"}
          src={link}
          title="book appointment"
          sx={{
            height: "calc(100vh - 70px - 1.5rem - 60px)",
            width: "100%",
            zIndex: 99,
          }}
        />
      </Box>
    </Box>
  );
}
