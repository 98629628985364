import { Close } from "@mui/icons-material";
import { Box, Dialog, IconButton } from "@mui/material";

export default function BasicModal({ children, show, close, size = "330px", noClose = false }) {
  return (
    <Dialog open={show} onClose={close}>
      <Box
        sx={{
          borderRadius: "5px",
          px: "1rem",
          py: "1.5rem",
          position: "relative",
          width: size,
        }}
      >
        <IconButton
          onClick={close}
          sx={{
            position: "absolute",
            top: ".5rem",
            right: ".5rem",
          }}
        >
          {!noClose && <Close
            sx={{
              borderRadius: "99rem",
              p: ".2rem",
              bgcolor: "#f1f1f1",
            }}
          />}
        </IconButton>
        {children}
      </Box>
    </Dialog>
  );
}
