export { ReactComponent as DoctorIcon } from "assets/images/icon-doctor.svg";
export { ReactComponent as PersonIcon } from "assets/images/icon-person.svg";
export { ReactComponent as DownloadIcon } from "assets/images/download-blue.svg";
export { ReactComponent as DownloadIcon2 } from "assets/images/download-blue-2.svg";
export { ReactComponent as UploadIcon } from "assets/images/upload-blue.svg";
export { ReactComponent as LogoutIcon } from "assets/images/logout.svg";
export { ReactComponent as CallIcon } from "assets/images/icon-call.svg";
export { ReactComponent as VideoCallIcon } from "assets/images/icon-video-call.svg";
export { ReactComponent as NotesWarningIcon } from "assets/images/icon-notes-warning.svg";
export { ReactComponent as DashboardIcon } from "assets/images/icon-dashboard.svg";
export { ReactComponent as HealthRecordIcon } from "assets/images/HealthRecords.svg";
export { ReactComponent as VitalSignsIcon } from "assets/images/vital.svg";
export { ReactComponent as MedicalHistoriesIcon } from "assets/images/record.svg";
export { ReactComponent as AllergyIcon } from "assets/images/allergy.svg";
export { ReactComponent as LabResultsIcon } from "assets/images/labresult.svg";
export { ReactComponent as ImmunizationsIcon } from "assets/images/immunization.svg";
export { ReactComponent as EventIcon } from "assets/images/icon-event.svg";
export { ReactComponent as TickIcon } from "assets/images/success-tick-icon.svg";
export { ReactComponent as MyPatientsIcon } from "assets/images/icon-patients.svg";
export { ReactComponent as CalendarAddFillIcon } from "assets/images/icon-calendar-fill.svg";
export { ReactComponent as CalendarClockIcon } from "assets/images/icon-calendar-clock.svg";
export { ReactComponent as DateOutlinedIcon } from "assets/images/icon-date-outlined.svg";
export { ReactComponent as ClockIcon } from "assets/images/icon-clock.svg";
export { ReactComponent as HelpIcon } from "assets/images/icon-help.svg";
export { ReactComponent as BookmarkIcon } from "assets/images/bookmark-outline-icon.svg";
export { ReactComponent as ThumbsUpIcon } from "assets/images/icon-thumbs-up.svg";
export { ReactComponent as ThumbsDownIcon } from "assets/images/icon-thumbs-down.svg";
export { ReactComponent as ReverseIcon } from "assets/images/icon-reverse.svg";
export { ReactComponent as VerifiedIcon } from "assets/images/icon-verified.svg";
export { ReactComponent as WhiteFilterIcon } from "assets/images/filter-white.svg";
export { ReactComponent as MenuIcon } from "assets/images/eclipse-menu-icon.svg";
export { ReactComponent as HealthcareDownloadIcon } from "assets/images/healthcare-download.svg";
export { ReactComponent as FilterIcon } from "assets/images/healthcare-filter.svg";
export { ReactComponent as DownloadWhiteIcon } from "assets/images/whiteDownload.svg";
export { ReactComponent as FilterWhiteIcon } from "assets/images/whiteFilter.svg";
export { ReactComponent as Clock } from "assets/images/clock_soon.svg";
export { ReactComponent as NoDoctorIcon } from "assets/images/doctor-icon.svg";
export { ReactComponent as NoResultsFoundIcon } from "assets/images/noRecordGlobalSearch.svg";
export { ReactComponent as NoDoctorsFound } from "assets/images/NoDoctors.svg";
export { ReactComponent as NoSpecialitiesFound } from "assets/images/NoSpecialities.svg";
export { ReactComponent as BookmarkIconFilled } from "assets/images/bookmark-filled-icon.svg";
export { ReactComponent as BookmarkIconOutline } from "assets/images/bookmark-outline-icon.svg";
export { ReactComponent as SearchIcon } from "assets/images/SearchIcon.svg";
export { ReactComponent as NoHealthRecord } from "assets/images/NoHealthRecord.svg";
export { ReactComponent as AddProfileBtn } from "assets/images/AddProfileBtn.svg";
export { ReactComponent as GradCap } from "assets/images/grad-cap.svg";
export { ReactComponent as UserTie } from "assets/images/user-tie.svg";
export { ReactComponent as Home } from "assets/images/home.svg";
