import { useEffect, useState } from "react";
import ReactJoyride, { STATUS } from "react-joyride";
import { useSetAppWalkthroughCompletionMutation } from "api/CommonApi";
import useExistingUser from "hooks/useExistingUser";
import useAuth from "hooks/useAuth";
import { useNavigate } from "react-router";

const steps = [
  {
    target: "#my-profile-heading",
    disableBeacon: true,
    content: "Look into your profile details ",
  },
  {
    target: "#Edit-CTA",
    content: "Use this option to edit your profile details.",
  },
  {
    target: "#Dashboard-CTA",
    content:
      "Navigate to the Dashboard to access more features of the application.",
  },
];

export default function PracProfileWalkthrough() {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();
  const navigate = useNavigate();

  const [runWalkthrough, setRunWalkthrough] = useState(false);

  const [setAppWalkthroughCompletion] =
    useSetAppWalkthroughCompletionMutation();

  useEffect(() => {
    setRunWalkthrough(existingUser?.user?.web_app_walkthrough_status);
  }, [existingUser]);

  const handleJoyrideCallback = async ({ status }) => {
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunWalkthrough(false);
      if (status === STATUS.SKIPPED) {
        await setAppWalkthroughCompletion({
          accessToken,
          userType: existingUser?.user?.type?.toLowerCase(),
          userId: existingUser?.user?.id,
        }).unwrap();
      } else if (status === STATUS.FINISHED) {
        navigate("/doctor/dashboard");
      }
    }
  };
  return (
    <ReactJoyride
      callback={handleJoyrideCallback}
      spotlightClicks={false}
      disableCloseOnEsc
      disableOverlayClose
      continuous
      hideBackButton
      hideCloseButton
      run={runWalkthrough}
      scrollToFirstStep
      scrollOffset={80}
      spotlightPadding={0}
      showProgress
      showSkipButton
      steps={steps}
      styles={{
        options: {
          zIndex: 10000,
        },
      }}
    />
  );
}
