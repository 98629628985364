import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
} from "@mui/material";

export default function PromptPopup({
  open,
  handleClose,
  children,
  noText = "No",
  yesText = "Yes",
  onNoClick,
  onYesClick,
  yesBtnDisabled = false,
  noBtnDisabled = false,
}) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box sx={{ width: "20.625rem", p: "1.875rem" }}>
        <Box sx={{ mb: "1.875rem" }}>{children}</Box>
        <DialogActions sx={{ justifyContent: "center", gap: "40px" }}>
          <Button
            sx={{ width: "7.375rem" }}
            variant="outlined"
            onClick={onNoClick}
            disabled={noBtnDisabled}
          >
            {noText}
          </Button>
          <Button
            sx={{ width: "7.375rem" }}
            variant="contained"
            onClick={onYesClick}
            disabled={yesBtnDisabled}
            autoFocus
          >
            {yesBtnDisabled ? <CircularProgress size={"1.5rem"} /> : yesText}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
