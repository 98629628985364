import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_CLR || "#044b7f",
    },
    secondary: {
      main: process.env.REACT_APP_SECONDARY_CLR || "#FF975C",
    },
    error: {
      main: "#f08686",
    },
    text: {
      primary: "#272727",
      secondary: "#838383",
    },
  },
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          borderWidth: "2px",
          "&:focus": {
            borderWidth: "2px",
          },
          "&:hover": {
            borderWidth: "2px",
          },
        },
        root: {
          textTransform: "none",
          fontFamily: ["Open Sans", "sans-serif"].join(","),
        },
      },
    },
  },
});
