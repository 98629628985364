import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Button, CircularProgress } from "@mui/material";
import appConfig from "appConfig";
import "Styles/Register.css";
import Header from "components/Layouts/Header";
import Footer from "components/Layouts/Footer";
export const OktaLogin = (props) => {
  let navigate = useNavigate();
  const [btnClicked, setBtnClicked] = useState(false);

  const { authState, oktaAuth } = useOktaAuth();

  const login = async () => {
    setBtnClicked(true);
    oktaAuth.signInWithRedirect({ originalUri: "/" });
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      navigate("/");
    }
  }, [authState, oktaAuth]);

  return (
    <>
      <Header />
      <Container fluid className="landingpage">
        <Container>
          <Row>
            <Col lg={6} className="about1">
              <div className="about1p"></div>
            </Col>
            <Col lg={6} className="landing-card">
              <div>
                <Card className="about2card">
                  <CardBody className="about2 ">
                    <img
                      className="logoicon"
                      src={appConfig.icon}
                      alt="DolphinIcon"
                    />
                    <Row className="about2row">
                      <div className="about2h3">
                        <h3>Welcome to {appConfig.name}!</h3>
                      </div>
                    </Row>
                    <Row className="about2btnrow">
                      <div className="d-grid about2btn">
                        <Button
                          variant="contained"
                          disabled={btnClicked}
                          onClick={login}
                        >
                          {btnClicked ? (
                            <CircularProgress size={"1.5rem"} />
                          ) : (
                            "Sign in / Sign up"
                          )}
                        </Button>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Footer />
    </>
  );
};
