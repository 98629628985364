import { Box } from "@mui/material";
import RACContent from "components/UI/RACContent";
import { useSearchParams } from "react-router-dom";

export default function RefundAndCancellation() {
  const [searchParams] = useSearchParams();

  return (
    <Box sx={{ maxWidth: "980px", width: "100%", mx: "auto", p: 2 }}>
      <RACContent
        showPatientConsent={searchParams.get("show-patient-consent")}
        doctorName={searchParams.get("doctorName") || "doctor"}
        contact={searchParams.get("contact") || "[email/phone number]"}
      />
    </Box>
  );
}
