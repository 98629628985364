import React from "react";
import "Styles/TermsCondition.css";
import { Box } from "@mui/material";
import TACContent from "components/UI/TACContent";
function TermsAndConditions() {
  return (
    <Box sx={{ maxWidth: "980px", width: "100%", mx: "auto", p: 2 }}>
      <TACContent />
    </Box>
  );
}

export default TermsAndConditions;
