import { Box, Tab, Tabs, Typography } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function TabPageLayout({
  tabValue,
  handleTabChange,
  tabList,
  panelList,
  maxWidth,
  actions,
}) {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          maxWidth,
        }}
      >
        <Box sx={{ position: "relative", width: "max-content" }}>
          <Box
            sx={{
              position: "absolute",
              height: "2px",
              width: "100%",
              bgcolor: "#dadada",
              bottom: "2px",
            }}
          ></Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            sx={{
              "& .MuiTabs-indicator": {
                height: "6px",
                bottom: "0",
                borderRadius: "99rem",
              },
            }}
          >
            {tabList.map((item) => (
              <Tab
                label={item}
                sx={{
                  textTransform: "none",
                  fontWeight: "700",
                  color: "#272727",
                  px: "3rem",
                }}
              />
            ))}
          </Tabs>
        </Box>
        {actions}
      </Box>
      {panelList.map((item, idx) => (
        <TabPanel value={tabValue} index={idx}>
          {item}
        </TabPanel>
      ))}
    </Box>
  );
}
