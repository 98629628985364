import { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useAddNotesToPatientMutation } from "../api/PractitionerApi";
import BasicModal from "./UI/BasicModal";
import useAuth from "../hooks/useAuth";
import useExistingUser from "../hooks/useExistingUser";
import { toastr } from "react-redux-toastr";
import { useGetCommentsQuery } from "../api/AppointmentsApi";
import moment from "moment/moment";

export default function AddNoteToPatientPopup({
  show,
  close,
  patientId,
  appointmentId,
}) {
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();
  const { data, isLoading: isCommentsDataLoading } = useGetCommentsQuery(
    {
      accessToken,
      userId: patientId,
      appointmentId,
    },
    { refetchOnReconnect: true, refetchOnMountOrArgChange: true }
  );

  const [addNotesToPatient, { isLoading }] = useAddNotesToPatientMutation();

  const [comment, setComment] = useState("");

  const handleAddNotes = async (evt) => {
    evt.preventDefault();
    try {
      const response = await addNotesToPatient({
        accessToken: accessToken,
        userId: existingUser?.user?.id,
        patientId,
        appointmentId,
        description: comment,
      }).unwrap();
      if (response?.success) {
        toastr.success(response?.message);
      } else {
        toastr.error(response?.message);
      }
      close();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <BasicModal show={show} close={close}>
      <Box sx={{ mt: "1rem" }}>
        <Typography>Add a note to your patient!</Typography>
        <Box sx={{ mt: "1rem" }}>
          <form onSubmit={handleAddNotes}>
            <Typography>Comments</Typography>
            <TextField
              value={comment}
              onChange={(evt) => {
                if (evt.target.value.length < 251) setComment(evt.target.value);
              }}
              sx={{ width: "100%", mt: ".25rem" }}
              multiline={true}
              minRows={4}
              maxRows={4}
              variant={"outlined"}
              helperText={
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >{`${comment.length}/250`}</Box>
              }
            />
            <Box
              sx={{ display: "flex", justifyContent: "center", mt: ".75rem" }}
            >
              <Button
                disabled={isLoading || comment === ""}
                type={"submit"}
                variant={"contained"}
                sx={{ width: "50%" }}
              >
                {isLoading ? <CircularProgress size={"1.5rem"} /> : "Submit"}
              </Button>
            </Box>
          </form>
        </Box>
        {!isCommentsDataLoading && data?.comments?.length > 0 && (
          <Box sx={{ mt: "1rem" }}>
            <Typography>Previous notes sent to the patient!</Typography>
            <Box
              sx={{
                mt: ".5rem",
                display: "grid",
                gap: ".5rem",
                maxHeight: "30vh",
                overflowY: "auto",
              }}
            >
              {data?.comments?.map((item) => (
                <Box
                  key={item?.id}
                  sx={{
                    bgcolor: "#fafafa",
                    borderRadius: "5px",
                    p: "1rem",
                    display: "grid",
                    gap: ".5rem",
                  }}
                >
                  <Typography fontSize={14} sx={{ wordBreak: "break-word" }}>
                    {item?.description}
                  </Typography>
                  <Typography fontSize={10} color="text.secondary">
                    {moment(item?.commented_time).format("MMM, DD, yyyy")}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {moment(item?.commented_time).format("hh:mm a")}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </BasicModal>
  );
}
