import { useState, useEffect } from "react";
import { Container, Tooltip } from "reactstrap";
import { useOktaAuth } from "@okta/okta-react";
import { checkExistingUser } from "actions/auth";
import ProfileCircle from "assets/images/ProfileCircle.png";
import { AddProfileBtn } from "components/UI/Icons";
import "Styles/Register.css";
import { useDispatch, useSelector } from "react-redux";
import "Styles/ProfileForm.css";
import "react-datepicker/dist/react-datepicker.css";
import { patientPostRecords, patientGetRecords } from "actions/patientAction";
import info from "assets/images/info.svg";
import { useNavigate } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import Loading from "components/UI/Loading";
import { toBase64 } from "utilities";
import {
  Box,
  Button,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Select,
  Typography,
  FormControl,
  Autocomplete,
} from "@mui/material";
import * as yup from "yup";
import moment from "moment/moment";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useGetCountriesListQuery } from "api/CommonApi";

const initialValues = {
  first_name: "",
  last_name: "",
  middle_name: "",
  mobile_number: "",
  date_of_birth: "",
  country: "",
  language: "",
  street_name: "",
  city: "",
  zip_code: "",
  state: "",
  gender: "",
};

const formSchema = yup.object({
  first_name: yup.string().required("Enter your first name"),
  last_name: yup.string().required("Enter your last name"),
  middle_name: yup.string(),
  mobile_number: yup
    .string()
    .required("Enter your mobile number")
    .matches(/^(\+)?[\d\s()-]{5,20}$/, { message: "Mobile number is invalid" }),
  // .matches(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, {
  //   message: "Mobile number is invalid",
  // }),
  date_of_birth: yup.string().required("Select date of birth"),
  language: yup.string().required("Select your language"),
  country: yup.string().required("Enter you country"),
  street_name: yup.string().required("Enter your street name"),
  city: yup.string().required("Enter your city"),
  state: yup.string().required("Enter your state"),
  zip_code: yup
    .string()
    .required("Enter you zip code")
    .max(7, "Field should not exceed 7 characters"),
  gender: yup.string().required("Select your gender"),
});

const PatientEditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authState } = useOktaAuth();

  const userInfo = useSelector((state) => state.auth.existingUser);

  const [id, setId] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState(initialValues);

  const { data: countriesList } = useGetCountriesListQuery({
    accessToken: authState?.accessToken?.accessToken,
  });

  useEffect(() => {
    if (authState) {
      if (authState?.accessToken?.accessToken) {
        !userInfo.length > 0 &&
          dispatch(checkExistingUser(authState?.accessToken?.accessToken)).then(
            (res) => {
              setId(res.payload.user.id);
              dispatch(
                patientGetRecords(
                  authState?.accessToken?.accessToken,
                  res.payload.user.id
                )
              )
                .then((res) => {
                  setIsLoading(false);
                  setProfilePic(res.payload.patient.avatar_url);
                  setFormValues({
                    first_name: res?.payload?.patient?.first_name,
                    last_name: res?.payload?.patient?.last_name,
                    middle_name: res?.payload?.patient?.middle_name ?? "",
                    mobile_number: res?.payload?.patient?.mobile_number,
                    date_of_birth: res?.payload?.patient?.date_of_birth,
                    country: res?.payload?.patient?.country,
                    language: res?.payload?.patient?.preferred_language ?? "",
                    street_name: res?.payload?.patient?.street_name,
                    city: res?.payload?.patient?.city,
                    zip_code: res?.payload?.patient?.zipcode,
                    state: res?.payload?.patient?.state,
                    gender: res?.payload?.patient?.gender,
                  });
                })
                .catch((err) => {
                  setIsLoading(false);
                });
            }
          );
      }
    }
  }, [authState]);

  const handleChange = async ({ name, value }) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));
    try {
      await formSchema.pick([name]).validate({ [name]: value.trim() });
      setFormErrors((prev) => ({ ...prev, [name]: "" }));
    } catch (err) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: err.toString().split(": ")[1],
      }));
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    try {
      const vals = {};
      Object.keys(formValues)?.forEach(
        (k) => (vals[k] = formValues[k]?.trim())
      );
      await formSchema.validate(vals, { abortEarly: false });
      setFormErrors(initialValues);

      const isImageUrl = validURL(profilePic);

      const d = {
        patient: {
          first_name: formValues.first_name,
          last_name: formValues.last_name,
          middle_name: formValues.middle_name,
          mobile_number: formValues.mobile_number,
          avatar_img: profilePic,
          preferred_language: formValues.language,
          date_of_birth: moment(formValues.date_of_birth).format("YYYY-MM-DD"),
          country: formValues.country,
          gender: formValues.gender,
          zipcode: formValues.zip_code,
          street_name: formValues.street_name,
          city: formValues.city,
          state: formValues.state,
        },
      };
      if (isImageUrl) {
        delete d.patient.avatar_img;
      }
      if (authState) {
        if (authState?.accessToken?.accessToken) {
          setIsLoading(false);
          dispatch(
            patientPostRecords(authState?.accessToken?.accessToken, id, d)
          )
            .then((res) => {
              if (res?.payload?.success) {
                setIsLoading(res?.payload?.success);
                const toRoute = userInfo?.user?.profile_completion
                  ? "/patient/profile"
                  : "/patient/dashboard";
                dispatch(
                  checkExistingUser(authState?.accessToken?.accessToken)
                ).then(() =>
                  navigate(toRoute, { state: { from: "edit-profile" } })
                );
                toastr.success("Profile updated successfully");
              }
            })
            .catch((err) => {
              toastr.error("Error:", err);
            });
        }
      }
    } catch (validationErrors) {
      const newErrors = {};
      validationErrors.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setFormErrors(newErrors);
    }
  };

  const toggleToolTip = () => setTooltipOpen(!tooltipOpen);

  const validURL = (str) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };

  const limitErrorToast = () => {
    toastr.error("Photo size should not exceed 1 MB");
  };
  const profileImage = (e) => {
    let changeToBase64 = e.currentTarget.files[0];

    if (Math.round(e.target?.files[0]?.size / 1024) < 1024) {
      toBase64(changeToBase64).then((res) => setProfilePic(res));
    } else {
      limitErrorToast();
    }
  };

  return (
    <div>
      <div className="page-content">
        {isLoading ? (
          <Loading />
        ) : (
          <Container className="pb-50 ">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  <div>
                    <h4
                      className="text-center mb-0 fw-bold"
                      style={{ paddingTop: "10px" }}
                    >
                      Patient Profile Edit
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row center pt-50  mb-3">
              <div className="col-lg-2 gap-2 profileImg mb-3">
                <div className="profileimgouter">
                  <img
                    className="profilePicSize"
                    src={profilePic == null ? ProfileCircle : profilePic}
                    alt="ProfileImg"
                  />
                  <Box
                    component={AddProfileBtn}
                    sx={{
                      "& path": {
                        fill: (theme) => theme.palette.primary.main,
                      },
                      position: "absolute",
                      right: 0,
                      bottom: 0,
                    }}
                  />
                  <input
                    type="file"
                    className="form-control fetchprofielimg"
                    id="basicpill-companyuin-input"
                    onChange={profileImage}
                    accept=".png, .jpg, .jpeg"
                    // {...Formik.getFieldProps("profile-pic")}
                  />
                </div>
                <div className="i-icon">
                  <div>Profile Photo </div>

                  <img
                    src={info}
                    className="point"
                    id="profile-image"
                    alt="i-pic"
                  />
                </div>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen}
                  target="profile-image"
                  toggle={toggleToolTip}
                >
                  <ul className="max-5">
                    <li>Photo size should not exceed 1 MB</li>
                    <li>Supported type: png, jpg, jpeg</li>
                  </ul>
                </Tooltip>
              </div>
            </div>

            <form
              className="border-line-patient"
              autoComplete="one-time-code"
              onSubmit={handleSubmit}
            >
              <div className="row center  pb-20 mb-3"></div>
              <div className="row  mbsd-3 ">
                <h5>Personal Details</h5>
              </div>

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "20px",
                  alignItems: "start",
                }}
              >
                <Box sx={{ display: "grid" }}>
                  <Box component={"label"} htmlFor={"first_name"}>
                    First Name *
                  </Box>
                  <TextField
                    id={"first_name"}
                    name={"first_name"}
                    value={formValues.first_name}
                    autoComplete="one-time-code"
                    onChange={(evt) =>
                      handleChange({
                        name: evt.target.name,
                        value: evt.target.value,
                      })
                    }
                    size="small"
                    error={Boolean(formErrors.first_name)}
                    helperText={formErrors.first_name}
                  />
                </Box>
                <Box sx={{ display: "grid" }}>
                  <Box component={"label"} htmlFor={"middle_name"}>
                    Middle Name
                  </Box>
                  <TextField
                    id={"middle_name"}
                    name={"middle_name"}
                    value={formValues.middle_name}
                    autoComplete="one-time-code"
                    onChange={(evt) =>
                      handleChange({
                        name: evt.target.name,
                        value: evt.target.value,
                      })
                    }
                    size="small"
                    error={Boolean(formErrors.middle_name)}
                    helperText={formErrors.middle_name}
                  />
                </Box>
                <Box sx={{ display: "grid" }}>
                  <Box component={"label"} htmlFor={"last_name"}>
                    Last Name *
                  </Box>
                  <TextField
                    id={"last_name"}
                    name={"last_name"}
                    value={formValues.last_name}
                    autoComplete="one-time-code"
                    onChange={(evt) =>
                      handleChange({
                        name: evt.target.name,
                        value: evt.target.value,
                      })
                    }
                    size="small"
                    error={Boolean(formErrors.last_name)}
                    helperText={formErrors.last_name}
                  />
                </Box>
                <Box sx={{ display: "grid" }}>
                  <Box component={"label"} htmlFor={"mobile_number"}>
                    Mobile Number *
                  </Box>
                  <TextField
                    id={"mobile_number"}
                    name={"mobile_number"}
                    value={formValues.mobile_number}
                    autoComplete="one-time-code"
                    onChange={(evt) =>
                      handleChange({
                        name: evt.target.name,
                        value: evt.target.value,
                      })
                    }
                    size="small"
                    error={Boolean(formErrors.mobile_number)}
                    helperText={formErrors.mobile_number}
                  />
                </Box>
                <Box sx={{ display: "grid" }}>
                  <Box component={"label"} htmlFor={"date_of_birth"}>
                    Date of Birth *
                  </Box>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileDatePicker
                      id={"date_of_birth"}
                      maxDate={moment(new Date())}
                      value={
                        !formValues.date_of_birth
                          ? null
                          : moment(formValues.date_of_birth)
                      }
                      onChange={(evt) =>
                        handleChange({
                          name: "date_of_birth",
                          value:
                            moment(new Date()).diff(evt._d) < 0
                              ? moment(new Date()).format("MMM DD, YYYY")
                              : moment(evt._d).format("MMM DD, YYYY"),
                        })
                      }
                      format="MMM DD, YYYY"
                      slotProps={{
                        textField: {
                          placeholder: "MMM DD, YYYY",
                          error: formErrors.date_of_birth,
                          size: "small",
                        },
                      }}
                    />
                  </LocalizationProvider>
                  {formErrors.date_of_birth && (
                    <Typography
                      fontSize={12}
                      mt={"3px"}
                      mx={"14px"}
                      color={"error"}
                    >
                      {formErrors.date_of_birth}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ display: "grid" }}>
                  <Box component={"label"}>Gender *</Box>
                  <RadioGroup
                    sx={{
                      flexDirection: "row",
                      "& span": { fontSize: "0.875rem" },
                    }}
                    defaultChecked={formValues.gender}
                    value={formValues.gender}
                    onChange={(evt) =>
                      handleChange({ name: "gender", value: evt.target.value })
                    }
                  >
                    <FormControlLabel
                      value="Male"
                      control={<Radio checked={formValues.gender === "Male"} />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="Female"
                      control={
                        <Radio checked={formValues.gender === "Female"} />
                      }
                      label="Female"
                    />
                  </RadioGroup>
                  {formErrors.gender && (
                    <Typography
                      fontSize={12}
                      mt={"3px"}
                      mx={"14px"}
                      color={"error"}
                    >
                      {formErrors.gender}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ display: "grid" }}>
                  <Box component={"label"} htmlFor={"language"}>
                    Preferred Language *
                  </Box>
                  <FormControl error={Boolean(formErrors.language)}>
                    <Select
                      id="language"
                      value={formValues.language}
                      onChange={(evt) =>
                        handleChange({
                          name: "language",
                          value: evt.target.value,
                        })
                      }
                      size="small"
                    >
                      <MenuItem value={"English"}>English</MenuItem>
                    </Select>
                  </FormControl>
                  {formErrors.language && (
                    <Typography
                      fontSize={12}
                      mt={"3px"}
                      mx={"14px"}
                      color={"error"}
                    >
                      {formErrors.language}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box sx={{ mt: "2rem" }} className="row mbsd-3">
                <h5>Current Address</h5>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "20px",
                  alignItems: "start",
                }}
              >
                <Box sx={{ display: "grid" }}>
                  <Box component={"label"} htmlFor={"country"}>
                    Country *
                  </Box>
                  <Autocomplete
                    disablePortal
                    id="country"
                    value={formValues.country}
                    options={countriesList?.countries?.map(
                      (item) => item?.name
                    )}
                    autoComplete="one-time-code"
                    onChange={(_, val) =>
                      handleChange({
                        name: "country",
                        value: val,
                      })
                    }
                    disableClearable
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "one-time-code",
                        }}
                        error={Boolean(formErrors.country)}
                        helperText={formErrors.country}
                      />
                    )}
                    ListboxProps={{
                      sx: { maxHeight: "10rem" },
                    }}
                  />
                </Box>
                <Box sx={{ display: "grid" }}>
                  <Box component={"label"} htmlFor={"c_state"}>
                    State *
                  </Box>
                  <TextField
                    id={"state"}
                    name={"state"}
                    value={formValues.state}
                    autoComplete="one-time-code"
                    onChange={(evt) =>
                      handleChange({
                        name: evt.target.name,
                        value: evt.target.value,
                      })
                    }
                    size="small"
                    error={Boolean(formErrors.state)}
                    helperText={formErrors.state}
                  />
                </Box>
                <Box sx={{ display: "grid" }}>
                  <Box component={"label"} htmlFor={"c_city"}>
                    City *
                  </Box>
                  <TextField
                    id={"city"}
                    name={"city"}
                    value={formValues.city}
                    autoComplete="one-time-code"
                    onChange={(evt) =>
                      handleChange({
                        name: evt.target.name,
                        value: evt.target.value,
                      })
                    }
                    size="small"
                    error={Boolean(formErrors.city)}
                    helperText={formErrors.city}
                  />
                </Box>
                <Box sx={{ display: "grid" }}>
                  <Box component={"label"} htmlFor={"c_zip_code"}>
                    Zip Code *
                  </Box>
                  <TextField
                    id={"zip_code"}
                    name={"zip_code"}
                    value={formValues.zip_code}
                    autoComplete="one-time-code"
                    onChange={(evt) =>
                      handleChange({
                        name: evt.target.name,
                        value: evt.target.value,
                      })
                    }
                    size="small"
                    error={Boolean(formErrors.zip_code)}
                    helperText={formErrors.zip_code}
                  />
                </Box>
                <Box sx={{ display: "grid" }}>
                  <Box component={"label"} htmlFor={"c_street_name"}>
                    Street Name *
                  </Box>
                  <TextField
                    id={"street_name"}
                    name={"street_name"}
                    value={formValues.street_name}
                    autoComplete="one-time-code"
                    onChange={(evt) =>
                      handleChange({
                        name: evt.target.name,
                        value: evt.target.value,
                      })
                    }
                    size="small"
                    error={Boolean(formErrors.street_name)}
                    helperText={formErrors.street_name}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1.5rem",
                  mt: "2rem",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{ width: "8.75rem" }}
                  onClick={() => {
                    navigate("/patient/profile");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ width: "8.75rem" }}
                >
                  {isLoading === true ? "Updating profile" : "Update"}
                </Button>
              </Box>
            </form>
          </Container>
        )}
      </div>
    </div>
  );
};

export default PatientEditProfile;
