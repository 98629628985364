import { createAction } from "redux-api-middleware";

export const SET_USERTYPE_REQUEST = "SET_USERTYPE_REQUEST";
export const SET_USERTYPE_SUCCESS = "SET_USERTYPE_SUCCESS";
export const SET_USERTYPE_FAILURE = "SET_USERTYPE_FAILURE";

export const CHECK_EXISTING_USER_REQUEST = "CHECK_EXISTING_USER_REQUEST";
export const CHECK_EXISTING_USER_SUCCESS = "CHECK_EXISTING_USER_SUCCESS";
export const CHECK_EXISTING_USER_FAILURE = "CHECK_EXISTING_USER_FAILURE";

export const GET_EPIC_TOPKEN_REQUEST = "GET_EPIC_TOPKEN_REQUEST";
export const GET_EPIC_TOPKEN_SUCCESS = "GET_EPIC_TOPKEN_SUCCESS";
export const GET_EPIC_TOPKEN_FAILURE = "GET_EPIC_TOPKEN_FAILURE";

const BASE_URL = process.env.REACT_APP_API_URL;

export const setUserType = (userType, accessToken) => {
  let apiUrl =
    userType === "Patient" ? "patients/create" : "practitioners/create";
  return createAction({
    endpoint: BASE_URL + apiUrl,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    types: [SET_USERTYPE_REQUEST, SET_USERTYPE_SUCCESS, SET_USERTYPE_FAILURE],
  });
};

export const checkExistingUser = (accessToken) => {
  return createAction({
    endpoint: BASE_URL + "users/find",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      CHECK_EXISTING_USER_REQUEST,
      CHECK_EXISTING_USER_SUCCESS,
      CHECK_EXISTING_USER_FAILURE,
    ],
  });
};

export const getEpicAccessToken = (code, accessToken, clientId) => {
  console.log(code, accessToken, clientId, "code, accessToken, clientId");
  const params = new URLSearchParams();
  params.append("grant_type", "authorization_code");
  params.append(
    "redirect_uri",
    process.env.REACT_APP_BASE_URL + "/patient/dashboard"
  );
  params.append("code", code);
  params.append("client_id", clientId);
  params.append("state", "1234");

  return createAction({
    endpoint: accessToken,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: params,
    types: [
      GET_EPIC_TOPKEN_REQUEST,
      GET_EPIC_TOPKEN_SUCCESS,
      GET_EPIC_TOPKEN_FAILURE,
    ],
  });
};
