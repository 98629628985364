import { Box, Typography } from "@mui/material";
import { Error } from "@mui/icons-material";

export default function InfoText({ text }) {
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Box
        sx={{
          bgcolor: "primary.main",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: "2px",
        }}
      >
        <Error sx={{ color: "#fff" }} />
      </Box>
      <Box
        sx={{
          border: "1px solid #e3e3e3",
          p: "0.625rem",
          width: "100%",
        }}
      >
        <Typography fontSize={10}>{text}</Typography>
      </Box>
    </Box>
  );
}
