import { NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Button, Typography } from "@mui/material";
import {
  useGetDoctorDetailsQuery,
  useGetSpecialitiesListQuery,
} from "api/DoctorsApi";
import PaymentAndProfileIncompletionPopup from "components/PaymentAndProfileIncompletionPopup";
import { linkColor } from "components/UI/BreadcrumbsList";
import DefaultLoader from "components/UI/DefaultLoader";
import DoctorCard from "components/UI/DoctorCard";
import useAuth from "hooks/useAuth";
import useExistingUser from "hooks/useExistingUser";
import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";

export default function DoctorPage() {
  const params = useParams();
  const { accessToken } = useAuth();
  const { existingUser } = useExistingUser();
  const navigate = useNavigate();

  const [readmoreAbout, setReadmoreAbout] = useState(false);
  const [doctorDetails, setDoctorDetails] = useState({});

  const [showWarningPopup, setShowWarningPopup] = useState(false);

  const {
    data: doctorDetailData,
    isLoading,
    isFetching,
    isError,
  } = useGetDoctorDetailsQuery({
    accessToken,
    practitionerId: params?.id,
  });

  const { data: specialitiesListData } = useGetSpecialitiesListQuery(
    { accessToken },
    { refetchOnReconnect: true, skip: params?.specialist === "about_doctor" }
  );

  const BreadcrumbsItemsViaSpecialities = [
    <Link to={"/patient/dashboard"}>
      <Typography fontSize={12} color={linkColor}>
        Dashboard
      </Typography>
    </Link>,
    <Link to={"/patient/dashboard/all_specialities"}>
      <Typography fontSize={12} color={linkColor}>
        All Specialities
      </Typography>
    </Link>,
    <Link to={`/patient/dashboard/doctors_list/${params?.specialist}`}>
      <Typography fontSize={12} color={linkColor}>
        {
          specialitiesListData?.specialities?.find(
            (el) => el?.id === parseInt(params?.specialist)
          )?.display
        }
      </Typography>
    </Link>,
    <Link
      to={`/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}`}
    >
      <Typography fontSize={12} color={linkColor}>
        About Doctor
      </Typography>
    </Link>,
  ];

  const BreadcrumbsItems = [
    <Link to={"/patient/dashboard"}>
      <Typography fontSize={12} color={linkColor}>
        Dashboard
      </Typography>
    </Link>,
    <Link
      to={`/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}`}
    >
      <Typography fontSize={12} color={linkColor}>
        About Doctor
      </Typography>
    </Link>,
  ];

  useEffect(() => {
    if (!isLoading) {
      setDoctorDetails(doctorDetailData?.practitioner);
    }
  }, [doctorDetailData, isLoading]);

  if (isError) {
    return <Navigate to="/page_not_found" replace />;
  }

  return isLoading || isFetching ? (
    <DefaultLoader height={"calc(100vh - 70px - 60px)"} />
  ) : (
    <Box>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        {params?.specialist === "about_doctor"
          ? BreadcrumbsItems
          : BreadcrumbsItemsViaSpecialities}
      </Breadcrumbs>
      <Box sx={{ display: "grid", gap: "1.875rem", mt: "1rem" }}>
        <DoctorCard
          id={doctorDetails?.id}
          name={doctorDetails?.full_name}
          consultationFees={doctorDetails?.consultation_fees ?? 0}
          exp={doctorDetails?.professional_detail?.years_of_experience || 0}
          img={doctorDetails?.avatar_url}
          specialization={
            doctorDetails?.specializations &&
            (doctorDetails?.specializations?.join(", ") || "")
          }
          dontShrinkSpecialization={true}
          qualification={
            doctorDetails?.acadamic_details &&
            (doctorDetails?.acadamic_details
              ?.map((el) => el?.course)
              ?.join(", ") ||
              "")
          }
          noOfLikes={doctorDetails?.total_likes}
          noOfReviews={doctorDetails?.total_reviews}
          handleBookAppointmentClick={() => {
            if (!existingUser?.user?.profile_completion) {
              setShowWarningPopup(true);
            } else {
              navigate(
                `/patient/dashboard/doctors_list/${params?.specialist}/${params?.id}/general_intake_form`
              );
            }
          }}
          bookmarked={doctorDetails?.bookmarks}
        />
      </Box>
      <Box sx={{ mt: "1.875rem", display: "grid", gap: "1.75rem" }}>
        <DoctorPageSection title={"About"}>
          <Box component={"p"} sx={{ fontSize: "0.875rem" }}>
            {!isLoading &&
              doctorDetails?.professional_detail?.professional_bio?.slice(
                0,
                readmoreAbout ? undefined : 150
              ) + " "}
            {doctorDetails?.professional_detail?.professional_bio?.length >
              150 && (
              <Button
                sx={{
                  p: 0,
                  color: "primary.main",
                  textDecoration: "underline",
                }}
                onClick={() => setReadmoreAbout((prev) => !prev)}
              >
                {readmoreAbout ? "Read Less" : "Read More"}
              </Button>
            )}
          </Box>
        </DoctorPageSection>
        <DoctorPageSection title={"Education"}>
          <Box sx={{ display: "grid", gridTemplateColumns: "30% 30% 30%" }}>
            {doctorDetails?.acadamic_details
              ?.map((el) => `${el?.course}, ${el?.college_name}, ${el?.year}`)
              .map((el) => (
                <Box
                  key={el}
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "min-content 1fr",
                    gap: ".5rem",
                    fontSize: "0.875rem",
                  }}
                >
                  <Box
                    sx={{
                      mt: "4px",
                      height: "13px",
                      width: "13px",
                      bgcolor: "primary.main",
                      borderRadius: "99rem",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      width: "70%",
                      overflow: "hidden",
                      wordWrap: "break-word",
                    }}
                  >
                    {el}
                  </Box>
                </Box>
              ))}
          </Box>
        </DoctorPageSection>
        <DoctorPageSection title={"Services Provided "}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "1rem",
              width: "70%",
            }}
          >
            {doctorDetails?.specialities?.map((el) => (
              <Box
                key={el}
                sx={{
                  borderRadius: "99rem",
                  bgcolor: "#f6f6f6",
                  px: "1.5rem",
                  py: ".25rem",
                  width: "max-content",
                  fontSize: "0.875rem",
                }}
              >
                {el}
              </Box>
            ))}
          </Box>
        </DoctorPageSection>
      </Box>
      <PaymentAndProfileIncompletionPopup
        show={showWarningPopup}
        close={() => setShowWarningPopup(false)}
      />
    </Box>
  );
}

const DoctorPageSection = ({ title, children }) => {
  return (
    <Box>
      <Box
        component={"h2"}
        sx={{ fontSize: "1rem", fontWeight: 700, mb: "1rem" }}
      >
        {title}
      </Box>
      <Box sx={{ px: "1.625rem" }}>{children}</Box>
    </Box>
  );
};
