import thunk from "redux-thunk";
import { apiMiddleware } from "redux-api-middleware";
import DhophinHReducer from "reducers";
import { configureStore } from "@reduxjs/toolkit";
import { MedicalRecordApiSlice } from "api/MedicalRecordApi";
import { DoctorsApiSlice } from "api/DoctorsApi";
import { PractitionerApiSlice } from "api/PractitionerApi";
import { PaymentsApiSlice } from "api/PaymentsApi";
import { AppointmentsApiSlice } from "api/AppointmentsApi";
import { CommonApiSlices } from "api/CommonApi";
import shareReports from "./shareReports";
import intakeForm from "./intakeForm";
import bookafy from "./bookafy";
import apiInterceptorMiddleware from "api/ApiInterceptor.middleware";

const store = configureStore({
  reducer: {
    ...DhophinHReducer,
    shareReports: shareReports,
    intakeForm: intakeForm,
    bookafy: bookafy,
    [MedicalRecordApiSlice.reducerPath]: MedicalRecordApiSlice.reducer,
    [DoctorsApiSlice.reducerPath]: DoctorsApiSlice.reducer,
    [PractitionerApiSlice.reducerPath]: PractitionerApiSlice.reducer,
    [PaymentsApiSlice.reducerPath]: PaymentsApiSlice.reducer,
    [AppointmentsApiSlice.reducerPath]: AppointmentsApiSlice.reducer,
    [CommonApiSlices.reducerPath]: CommonApiSlices.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(
      apiMiddleware,
      thunk,
      MedicalRecordApiSlice.middleware,
      DoctorsApiSlice.middleware,
      PractitionerApiSlice.middleware,
      PaymentsApiSlice.middleware,
      AppointmentsApiSlice.middleware,
      CommonApiSlices.middleware,
      apiInterceptorMiddleware
    ),
});

export default store;
