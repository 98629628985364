import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Close, InfoOutlined } from "@mui/icons-material";
import BootstrapTooltip from "components/UI/BootstrapTooltip";
import * as yup from "yup";
import { toastr } from "react-redux-toastr";

const initialValues = {
  professional_bio: "",
  linkedin_link: "",
  website_link: "",
};

const formSchema = yup.object({
  professional_bio: yup.string().required("Enter professional bio"),
  linkedin_link: yup
    .string()
    .matches(
      /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?$/,
      { excludeEmptyString: true, message: "Enter correct url!" }
    )
    .nullable(),
  website_link: yup
    .string()
    .matches(
      /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?$/,
      { excludeEmptyString: true, message: "Enter correct url!" }
    )
    .nullable(),
});

const ProfessionalDetailForm = ({
  profilePic,
  pratitionerProfileSubmit,
  handleTab,
  setProfileImage,
  activeTab,
  Awards,
  AddAwards,
  awardlist,
  removeAwards,
}) => {
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState(initialValues);
  const [awardFile, setAwardFile] = useState({});
  const [isAwardAdded, setIsAwardAdded] = useState(false);

  const inputFileUpload = useRef(null);
  const profilePicInputRef = useRef(null);

  const practitionerData = useSelector(
    (state) => state?.practitionerRecords?.practitionerRecords
  );

  useEffect(() => {
    setFormValues({
      professional_bio:
        practitionerData?.practitioner?.professional_detail?.professional_bio,
      linkedin_link:
        practitionerData?.practitioner?.professional_detail?.linkedin_link?.replace(
          /(http:\/\/|https:\/\/)/g,
          ""
        ) || "",
      website_link:
        practitionerData?.practitioner?.professional_detail?.website_link?.replace(
          /(http:\/\/|https:\/\/)/g,
          ""
        ) || "",
    });
  }, [practitionerData]);

  const handleChange = async ({ name, value }) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));
    try {
      await formSchema.pick([name]).validate({ [name]: value.trim() });
      setFormErrors((prev) => ({ ...prev, [name]: "" }));
    } catch (err) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: err.toString().split(": ")[1],
      }));
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (!isAwardAdded) {
      const vals = {};
      Object.keys(formValues)?.forEach(
        (k) => (vals[k] = formValues[k]?.trim() || "")
      );
      try {
        await formSchema.validate(vals, { abortEarly: false });
        setFormErrors(initialValues);

        pratitionerProfileSubmit(formValues);
      } catch (validationErrors) {
        const newErrors = {};
        validationErrors.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        setFormErrors(newErrors);
      }
    }
  };

  return (
    <>
      <Box
        component={"form"}
        sx={{ display: "grid", gap: "1.25rem" }}
        onSubmit={handleSubmit}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "2fr 10fr",
            gap: "1.25rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                p: ".25rem",
                borderRadius: "99rem",
                borderColor: (theme) => theme.palette.grey[300],
                borderWidth: "2px",
                borderStyle: "solid",
                height: "6.5rem",
                width: "6.5rem",
                position: "relative",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  p: "0.1rem",
                  minWidth: 0,
                  borderRadius: "99rem",
                  position: "absolute",
                  bottom: ".25rem",
                  right: ".25rem",
                  zIndex: 99,
                }}
                onClick={() => profilePicInputRef.current.click()}
              >
                <Box
                  ref={profilePicInputRef}
                  component="input"
                  type="file"
                  onChange={setProfileImage}
                  sx={{ display: "none" }}
                />
                <Add sx={{ fontSize: "1rem" }} />
              </Button>
              <Avatar sx={{ width: "100%", height: "100%" }} src={profilePic} />
            </Box>
            <Box sx={{ display: "flex", mt: ".75rem" }}>
              <Typography mr={1} fontSize={14}>
                Profile Photo
              </Typography>
              <BootstrapTooltip
                title={
                  <Box
                    component={"ul"}
                    sx={{
                      maxWidth: "200px",
                      pl: "1.5rem",
                      pr: "1rem",
                      py: ".75rem",
                      m: 0,
                    }}
                  >
                    <li>Photo size should not exceed 1 MB</li>
                    <li>Supported type: png, jpg, jpeg</li>
                  </Box>
                }
              >
                <InfoOutlined />
              </BootstrapTooltip>
            </Box>
          </Box>
          <Box sx={{ display: "grid" }}>
            <Box component="label" htmlFor="professional_bio">
              Professional Bio *
            </Box>
            <TextField
              id="professional_bio"
              value={formValues.professional_bio}
              onChange={(evt) => {
                if (evt.target.value.length < 501) {
                  handleChange({
                    name: "professional_bio",
                    value: evt.target.value,
                  });
                }
              }}
              multiline={true}
              minRows={5}
              maxRows={5}
              fullWidth={true}
              error={Boolean(formErrors.professional_bio)}
              helperText={
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography fontSize={12}>
                    {formErrors.professional_bio}
                  </Typography>
                  <Typography fontSize={12}>
                    {formValues.professional_bio?.length}/500
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            gap: "1.25rem",
            gridTemplateColumns: "1fr 1fr",
            alignItems: "start",
          }}
        >
          <Box sx={{ display: "grid" }}>
            <Box component="label" htmlFor="linkedin_link">
              Linkedin Profile URL
            </Box>
            <TextField
              id="linkedin_link"
              value={formValues.linkedin_link}
              onChange={(evt) => {
                handleChange({
                  name: "linkedin_link",
                  value: evt.target.value,
                });
              }}
              fullWidth={true}
              size="small"
              placeholder="www.linkedin.com/in/benjamin-grant-72381ujy3u"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">https://</InputAdornment>
                ),
              }}
              error={Boolean(formErrors.linkedin_link)}
              helperText={formErrors.linkedin_link}
            />
          </Box>
          <Box sx={{ display: "grid" }}>
            <Box component="label" htmlFor="website_link">
              Website URL
            </Box>
            <TextField
              id="website_link"
              value={formValues.website_link}
              onChange={(evt) => {
                handleChange({
                  name: "website_link",
                  value: evt.target.value,
                });
              }}
              fullWidth={true}
              size="small"
              placeholder="example.com"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">https://</InputAdornment>
                ),
              }}
              error={Boolean(formErrors.website_link)}
              helperText={formErrors.website_link}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "1.25rem",
          }}
        >
          <Box>
            <Box sx={{ display: "grid" }}>
              <Box component={"label"} htmlFor={"Certification"}>
                Awards and Publications
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "8fr 1fr 3fr",
                  alignItems: "center",
                  gap: ".25rem",
                }}
              >
                <Box
                  component="input"
                  sx={{ display: "none" }}
                  type="file"
                  ref={inputFileUpload}
                  accept="application/pdf, .png, .jpg, .jpeg, .doc,.docx,application/msword"
                  onChange={(evt) => {
                    if (Math.round(evt.target?.files[0]?.size / 1024) < 5120) {
                      Awards(evt);
                      setAwardFile(evt.target.files[0]);
                      evt.target.value = "";
                    } else {
                      evt.target.value = "";
                      toastr.error("File size should not exceed 5 MB");
                    }
                  }}
                />
                <Button
                  sx={{ p: 0 }}
                  disabled={awardlist.length >= 5}
                  onClick={() => inputFileUpload.current.click()}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "grid",
                      borderRadius: "5px",
                      gridTemplateColumns: "5fr 7fr",
                      border: "1px solid #ccc",
                      color: "#666",
                      fontSize: "0.75rem",
                      "& > *": { px: ".5rem", py: ".5rem" },
                    }}
                  >
                    <Box component={"div"} sx={{ bgcolor: "#eee" }}>
                      Choose file
                    </Box>
                    <Typography
                      fontSize={12}
                      noWrap={true}
                      title={awardFile?.name || ""}
                    >
                      {awardFile?.name ?? "No file chosen"}
                    </Typography>
                  </Box>
                </Button>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  <BootstrapTooltip
                    title={
                      <Box
                        component={"ul"}
                        sx={{
                          maxWidth: "200px",
                          pl: "1.5rem",
                          pr: "1rem",
                          py: ".75rem",
                          m: 0,
                        }}
                      >
                        <li>Max 5 certificates can be uploaded</li>
                        <li>File size should not exceed 5 MB</li>
                        <li>File format should be pdf, doc, png, jpg, jpeg</li>
                      </Box>
                    }
                  >
                    <InfoOutlined />
                  </BootstrapTooltip>
                </Box>
                <Button
                  variant="contained"
                  disabled={!awardFile?.name}
                  className="btn btn-info   add-button waves-effect waves-light"
                  onClick={(e) => {
                    AddAwards();
                    setAwardFile({});
                    setIsAwardAdded(false);
                  }}
                >
                  Add
                </Button>
              </Box>
            </Box>
            {isAwardAdded && (
              <Typography fontSize={12} mt={"3px"} mx={"14px"} color={"error"}>
                *Click on Add to upload your Awards and Publications
              </Typography>
            )}
          </Box>
        </Box>
        {awardlist.length > 0 && (
          <Box
            sx={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "repeat( auto-fit, 12.5rem )",
              gap: "1.25rem",
            }}
          >
            {awardlist.map((item, index) => (
              <Box
                key={item?.filename || item?.title}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "11fr 1fr",
                  gap: ".5rem",
                  alignItems: "center",
                  px: "1rem",
                  py: ".5rem",
                  borderRadius: "5px",
                  bgcolor: (theme) => theme.palette.grey[100],
                }}
              >
                <Typography
                  fontSize={12}
                  title={item?.filename || item?.title}
                  noWrap={true}
                >
                  {item?.filename || item?.title}
                </Typography>
                <IconButton
                  sx={{ p: 0 }}
                  onClick={() => {
                    removeAwards(item?.id, index);
                  }}
                >
                  <Close color="error" />
                </IconButton>
              </Box>
            ))}
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            sx={{ width: "8.75rem" }}
            onClick={() => {
              !awardFile?.name && handleTab(activeTab - 1);
              setIsAwardAdded(awardFile?.name);
            }}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            sx={{ width: "8.75rem" }}
            onClick={() => setIsAwardAdded(awardFile?.name)}
            type="submit"
          >
            Update
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ProfessionalDetailForm;
