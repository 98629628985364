import { Box, Typography } from "@mui/material";

export default function RACContent({
  showPatientConsent,
  doctorName,
  contact = process.env.REACT_APP_CONTACT_DETAILS || "[Contact / Email]",
}) {
  return (
    <Box
      sx={{
        display: "grid",
        gap: "2rem",
        fontFamily: "Open Sans",
        "& li": { mb: 0.5 },
      }}
    >
      {showPatientConsent && (
        <Box>
          <Typography fontSize={18} mb={1} fontWeight={700} color={"#495057"}>
            Patient Consent
          </Typography>
          <Typography fontSize={14} mb={0.5}>
            By accepting the terms & conditions, the patient,
          </Typography>
          <ul>
            <li>
              <Typography fontSize={14}>
                Provides consent to share the selected files with sensitive
                health data to {doctorName} for consultation purposes.
              </Typography>
            </li>
            <li>
              <Typography fontSize={14}>
                Provides confirmation that the medical information shared with
                the doctor for consultation purposes is correct.
              </Typography>
            </li>
          </ul>
        </Box>
      )}
      <Box mb={1}>
        <Typography fontSize={24} fontWeight={700} color={"#495057"} mb={2}>
          Cancellation &amp; Refund Policy
        </Typography>
        <Typography fontSize={18} mb={1} fontWeight={700} color={"#495057"}>
          Cancellation Policy:
        </Typography>
        <ol>
          <li>
            <Typography fontSize={14}>
              <b>Cancellation Window: </b>
              Patients are eligible to cancel their scheduled appointments up to
              24 hours prior to the scheduled appointment time without incurring
              any cancellation fees.
            </Typography>
          </li>
          <li>
            <Typography fontSize={14}>
              <b>Late Cancellation: </b>Patients are eligible to cancel their
              scheduled appointments
              <Box component="ol" sx={{ listStyleType: "lower-roman" }}>
                <li>
                  anytime between 0 to 6 hours before the scheduled appointment
                  time and will be subject to full cancellation fee of the total
                  appointment cost.
                </li>
                <li>
                  less than 24 hours before the scheduled appointment time will
                  be subject to a cancellation fee of 50% of the total
                  appointment cost.
                </li>
              </Box>
            </Typography>
          </li>
          <li>
            <Typography fontSize={14}>
              <b>Cancellation Process: </b>To cancel an appointment, patients
              must log in to their account on our mobile app or web platform and
              navigate to the My Appointments section.
            </Typography>
          </li>
        </ol>
      </Box>
      <Box>
        <Typography fontSize={18} mb={1} fontWeight={700} color={"#495057"}>
          No-Show Policy:
        </Typography>
        <Box component={"ol"} sx={{ listStyleType: "lower-roman" }}>
          <li>
            <Typography fontSize={14}>
              In the event that a patient does not appear for a scheduled
              appointment without giving advance notice, they will have the
              opportunity to reschedule the same appointment once. However, if
              they fail to attend the rescheduled appointment, no refund will be
              issued. For arranging a new appointment, the patient can reach out
              to our customer support team at {contact}.
            </Typography>
          </li>
          <li>
            <Typography fontSize={14}>
              In the event that a doctor is unable to attend a scheduled
              appointment without giving prior notice, the patient has the
              option to receive a refund for the appointment or to reschedule
              with the same doctor for the next available time slot.
            </Typography>
          </li>
        </Box>
      </Box>
      <Box>
        <Typography fontSize={18} mb={1} fontWeight={700} color={"#495057"}>
          Refund Policy:
        </Typography>
        <ol>
          <li>
            <Typography fontSize={14}>
              <b>Refund Eligibility:</b> Refunds will be considered under the
              following circumstances:
            </Typography>
          </li>
          <ul>
            <li>
              <Typography fontSize={14}>
                Technical Issues: In case of technical problems preventing the
                doctor appointment from taking place.
              </Typography>
            </li>
            <li>
              <Typography fontSize={14}>
                Doctor Cancellation: If the doctor cancels the appointment for
                any reason.
              </Typography>
            </li>
            <li>
              <Typography fontSize={14}>
                Patient Cancellation: If the patient cancels 24 hours prior and
                anytime between 6 to 24 hours to the scheduled time
              </Typography>
            </li>
          </ul>
          <li>
            <Typography fontSize={14}>
              <b>Refund Process:</b> To request for a refund, the patient can
              opt the Refund option in the My Appointments section in their
              mobile app or web platform.
            </Typography>
          </li>
          <li>
            <Typography fontSize={14}>
              <b>Refund Amount:</b> Approved refunds will be processed within 5
              to 10 business days from the date of the refund request. Refunds
              will be issued in the original form of payment.
            </Typography>
          </li>
        </ol>
      </Box>
      <Box>
        <Typography fontSize={18} mb={1} fontWeight={700} color={"#495057"}>
          Exceptions and Special Cases:
        </Typography>
        <ol>
          <li>
            <Typography fontSize={14}>
              <b>Rescheduling Appointments: </b>Patients may request to
              reschedule their appointments
            </Typography>
            <Box component="ol" sx={{ listStyleType: "lower-roman" }}>
              <li>
                <Typography fontSize={14}>
                  Anytime before 24 hours of the scheduled time without
                  incurring any fees. Rescheduling outside of this time window
                  will not be possible.
                </Typography>
              </li>
              <li>
                <Typography fontSize={14}>
                  For a Doctor No show or a Patient No show appointment without
                  incurring any additional fees.
                </Typography>
              </li>
            </Box>
          </li>
        </ol>
      </Box>
      <Box>
        <Typography fontSize={18} mb={1} fontWeight={700} color={"#495057"}>
          Contact Information:
        </Typography>
        <Typography fontSize={14}>
          For any questions or concerns regarding our cancellation and refund
          policies, please contact our customer support team at {contact}.
        </Typography>
      </Box>
    </Box>
  );
}
