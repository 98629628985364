import { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Box, Typography } from "@mui/material";

import Styles from "Styles/MedicalRecordItem.module.css";

import DocIcon from "assets/images/doc-icon.svg";
import { MenuIcon } from "components/UI/Icons";
import SuccessMessageModal from "components/UI/SuccessMessageModal";
import DeleteAlertModal from "components/UI/DeleteAlertModal";

import {
  useDeleteDownloadMedicalRecordMutation,
  useDeletePatientMedicalRecordMutation,
} from "api/MedicalRecordApi";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useAuth from "hooks/useAuth";
import appConfig from "../../appConfig";

export default function MedicalRecordItem({
  name,
  uploadedDate,
  fileUrl,
  id,
  onDeleteModalClose,
}) {
  const { accessToken } = useAuth();
  const { user } = useSelector((state) => state.auth.existingUser);
  const { pathname } = useLocation();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(false);
  const [deleteBtnsDisabled, setDeleteBtnsDisabled] = useState(false);

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const [deleteMedicalRecord] = useDeletePatientMedicalRecordMutation();
  const [deleteDownloadRecord] = useDeleteDownloadMedicalRecordMutation();

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleDownloadReport = () => {
    fetch(fileUrl)
      .then((res) => res.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = name;
        a.click();
      })
      .then(() => {
        setShowSuccessMessage(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeleteMedicalRecord = () => {
    setDeleteBtnsDisabled(true);
    deleteMedicalRecord({
      accessToken,
      patientId: user.id,
      reportId: id,
    })
      .unwrap()
      .then((res) => {
        setShowDeleteAlert(false);
        setDeleteSuccessMessage(true);
        setDeleteBtnsDisabled(false);
      })
      .catch((err) => {
        setDeleteBtnsDisabled(false);
      });
  };

  const handleDeleteDownloadRecord = async () => {
    setDeleteBtnsDisabled(true);
    try {
      await deleteDownloadRecord({
        accessToken,
        patientId: user.id,
        recordId: id,
      }).unwrap();
      setShowDeleteAlert(false);
      setDeleteSuccessMessage(true);
      setDeleteBtnsDisabled(false);
    } catch (error) {
      setDeleteBtnsDisabled(false);
    }
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { xs: "4fr 2fr 1fr", lg: "1fr 1fr 1fr" },
        px: "0.625rem",
        py: "0.875rem",
        bgcolor: "#f9f9f9",
      }}
    >
      <div className="d-flex gap-2 align-items-center">
        <div className="">
          <img src={DocIcon} alt="" className="" />
        </div>
        <Box
          component={"a"}
          target={"_blank"}
          rel={"noreferrer"}
          href={fileUrl}
        >
          <Typography
            sx={{
              color: (theme) => theme.palette.primary.main,
              fontWeight: 700,
            }}
          >
            {name}
          </Typography>
        </Box>
      </div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "baseline", gap: ".5rem" }}>
          <Box
            component={"span"}
            sx={{ fontSize: "0.625rem", color: "#838383", fontWeight: 600 }}
          >
            {pathname.split("/")[2] === "my_ehr_health_reports" ? (
              <span>Downloaded on</span>
            ) : (
              <span>Uploaded on</span>
            )}
          </Box>
          <Box component={"span"} sx={{ fontWeight: 700 }}>
            {uploadedDate}
          </Box>
        </Box>
      </Box>
      <div className="d-flex gap-1 align-items-center justify-content-end">
        <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={"down"}>
          <DropdownToggle className={Styles["dropdown-menu-btn"]}>
            <Box
              component={MenuIcon}
              sx={{
                "& circle": {
                  fill: (theme) => theme.palette.primary.main,
                },
              }}
            />
          </DropdownToggle>
          <DropdownMenu>
            <Box
              component={DropdownItem}
              sx={{ fontWeight: 700 }}
              onClick={() => handleDownloadReport()}
            >
              Download Report
            </Box>
            <DropdownItem divider />
            <Box
              component={DropdownItem}
              sx={{
                fontWeight: 700,
                color: "error.main",
                "&:hover": { color: "error.main" },
              }}
              onClick={() => setShowDeleteAlert(true)}
            >
              Delete Report
            </Box>
          </DropdownMenu>
        </Dropdown>
      </div>
      <SuccessMessageModal
        show={showSuccessMessage}
        close={() => setShowSuccessMessage(false)}
        message="Report successfully downloaded!"
        localDownload={`Please note that you have downloaded the file to your local device. ${appConfig.shortName} will not be responsible for any mishandling of data or data breaches outside the application.`}
      />
      <SuccessMessageModal
        show={deleteSuccessMessage}
        close={() => {
          onDeleteModalClose();
          setDeleteSuccessMessage(false);
        }}
        message="Your file is successfully deleted!"
      />
      <DeleteAlertModal
        buttonsDisabled={deleteBtnsDisabled}
        show={showDeleteAlert}
        close={() => setShowDeleteAlert(false)}
        message="Are you sure you want to delete the report?"
        handleDeleteMedicalRecord={
          pathname.split("/")[2] === "my_ehr_health_reports"
            ? handleDeleteDownloadRecord
            : handleDeleteMedicalRecord
        }
      />
    </Box>
  );
}
